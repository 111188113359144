import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import IndiceDataBoard from './CategoryBoard_Commodity'
import IndexDataBoard from './CategoryBoard_Index'

const WorldIndice = () => {
    const [component, setComponent] = useState(<IndexDataBoard/> )
    
    const [dataYahoo, setDataYahoo] =useState([])
    
  return (<div style={{width: '100%'}}>
        <div style={{display: 'flex'}}>
            <Button variant='contained' sx={{width:"50%", backgroundColor: component===<IndexDataBoard/>?'royalblue':'dodgerblue'}} onClick={() => setComponent(<IndiceDataBoard/>)}>
                Commodity    
            </Button>
            <Button variant='contained' sx={{width:"50%", backgroundColor: component===<IndiceDataBoard/>?'royalblue':'dodgerblue'}} onClick={() => setComponent(<IndexDataBoard/>)}>
                Index    
            </Button>
        </div>
        <div>
            {component}
        </div>
    </div>
  )
}

export default WorldIndice