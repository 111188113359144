import React from 'react'
import { AiFillGold } from 'react-icons/ai';
import {DataGrid, GridToolbar, bgBG } from "@mui/x-data-grid"
import { OilBarrel } from '@mui/icons-material';
import { GiWoodPile, GiSteelClaws, GiPig, GiBowlOfRice, GiPopcorn, GiCoffeeBeans, GiChocolateBar, GiClothes, GiGasPump, GiMilkCarton, GiChicken, GiOat, GiJellyBeans, GiCargoShip, GiFertilizerBag, GiPencil,GiTyre,GiCoalWagon,GiWheat } from 'react-icons/gi';
import {BiBone} from 'react-icons/bi';
import { Construction, Cake, GasMeter } from '@mui/icons-material';
import { Button } from '@mui/material';
import {ChartCommodity} from './CategoryChart_Commodity';
import { useGetYahooDataQuery } from '../../api/apiSlice.dashboard';



const IndiceDataBoard = () => {

    //setChart
    const [chart, setChart] = React.useState(<><ChartCommodity chartControlID={'chartControlID'} id={'ChartCommodity'} height={620} TickerName={'C_BRENTOIL'} name={'BRENT OIL'}/></>)


    
    const x = useGetYahooDataQuery().data;
    const {isSuccess} = useGetYahooDataQuery()



  

    const columns = [
        
        {
            field: 'Icon',
            width: 30,
            renderCell: (params) => {
                if(params.row.Ticker.includes('OIL')&&!params.row.Ticker.includes('SOY')){
                  return <div
                      style={{
                        color: "royalblue"
                      }}
                    >
                      <OilBarrel/>
                  </div>
                }
                if(params.row.Ticker.includes('LUMBER')){
                  return <div
                      style={{
                        color: "brown",
                        fontSize: "23px"
                      }}
                    >
                      <GiWoodPile/>
                  </div>
                }
                if(params.row.Ticker.includes('Thiếc')||
                params.row.Ticker.includes('ALUMI')){
                  return <div
                      style={{
                        color: "grey"
                      }}
                    >
                      <Construction/>
                  </div>
                }
                if(params.row.Ticker.includes('SIL')||params.row.Ticker.includes('PLA')||params.row.Ticker.includes('PALLA')||params.row.Ticker.includes('TIN')||params.row.Ticker.includes('NICKEL')){
                  return <div
                      style={{
                        color: "lightgrey",
                        fontSize: "23px"
                      }}
                    >
                      <AiFillGold/>
                  </div>
                }
                if(params.row.Ticker.includes('HRC')||params.row.Ticker.includes('sắt')){
                  return <div
                      style={{
                        color: "grey",
                        fontSize: "23px"
                      }}
                    >
                      <GiSteelClaws/>
                  </div>
                }
                if(params.row.Ticker.includes('GOLD')){
                  return <div
                      style={{
                        color: "darkgoldenrod",
                        fontSize: "23px"
                      }}
                    >
                      <AiFillGold/>
                  </div>
                }
                if(params.row.Ticker.includes('COPP')){
                  return <div
                      style={{
                        color: "brown",
                        fontSize: "23px"
                      }}
                    >
                      <AiFillGold/>
                  </div>
                }
                if(params.row.Ticker.includes('SUGA')){
                  return <div
                      style={{
                        color: "white"
                      }}
                    >
                      <Cake/>
                  </div>
                }
                if(params.row.Ticker.includes('PORK')||params.row.Ticker.includes('LEAN')){
                  return <div
                      style={{
                        color: "Pink",
                        fontSize: "30px"
                      }}
                    >
                      <GiPig/>
                  </div>
                }
                if(params.row.Ticker.includes('OAT')){
                  return <div
                      style={{
                        color: "white",
                        fontSize: "25px"
                      }}
                    >
                      <GiOat/>
                  </div>
                }
                if(params.row.Ticker.includes('RICE')){
                  return <div
                      style={{
                        color: "white",
                        fontSize: "23px"
                      }}
                    >
                      <GiBowlOfRice/>
                  </div>
                }
                if(params.row.Ticker.includes('CATTLE')){
                  return <div
                      style={{
                        color: "darkorange",
                        fontSize: "23px"
                      }}
                    >
                      <GiChicken/>
                  </div>
                }
                if(params.row.Ticker.includes('CORN')){
                  return <div
                      style={{
                        color: "orange",
                        fontSize: "23px"
                      }}
                    >
                      <GiPopcorn/>
                  </div>
                }
                if(params.row.Ticker.includes('MILK')){
                  return <div
                      style={{
                        color: "white",
                        fontSize: "23px"
                      }}
                    >
                      <GiMilkCarton/>
                  </div>
                }
                if(params.row.Ticker.includes('BEAN')){
                  return <div
                      style={{
                        color: "green",
                        fontSize: "25px"
                      }}
                    >
                      <GiJellyBeans/>
                  </div>
                }
                if(params.row.Ticker.includes('COCOA')){
                  return <div
                      style={{
                        color: "brown",
                        fontSize: "25px"
                      }}
                    >
                      <GiChocolateBar/>
                  </div>
                }
                if(params.row.Ticker.includes('COFFEE')){
                  return <div
                      style={{
                        color: "brown",
                        fontSize: "25px"
                      }}
                    >
                      <GiCoffeeBeans/>
                  </div>
                }
                if(params.row.Ticker.includes('COTTON')){
                  return <div
                      style={{
                        color: "violet",
                        fontSize: "25px"
                      }}
                    >
                      <GiClothes/>
                  </div>
                }
        
                if(params.row.Ticker.includes('GAS')){
                  return <div
                      style={{
                        color: "forestgreen",
                        fontSize: "25px"
                      }}
                    >
                      <GasMeter/>
                  </div>
                }

                if(params.row.Ticker.includes('BALTIC')){
                  return <div
                      style={{
                        color: "royalblue",
                        fontSize: "25px"
                      }}
                    >
                      <GiCargoShip/>
                  </div>
                }
                if(params.row.Ticker.includes('ZINC')){
                  return <div
                      style={{
                        color: "white",
                        fontSize: "25px"
                      }}
                    >
                      <BiBone/>
                  </div>
                }
                if(params.row.Ticker.includes('COAL')){
                  return <div
                      style={{
                        color: "gold",
                        fontSize: "25px"
                      }}
                    >
                      <GiCoalWagon/>
                  </div>
                }
                if(params.row.Ticker.includes('UREA')){
                  return <div
                      style={{
                        color: "green",
                        fontSize: "25px"
                      }}
                    >
                      <GiFertilizerBag/>
                  </div>
                }
                if(params.row.Ticker.includes('LEAD')){
                  return <div
                      style={{
                        color: "white",
                        fontSize: "25px"
                      }}
                    >
                      <GiPencil/>
                  </div>
                }
                if(params.row.Ticker.includes('WHEAT')){
                  return <div
                      style={{
                        color: "darkgoldenrod",
                        fontSize: "25px"
                      }}
                    >
                      <GiWheat/>
                  </div>
                }
                if(params.row.Ticker.includes('RUBBER')){
                  return <div
                      style={{
                        color: "grey",
                        fontSize: "25px"
                      }}
                    >
                      <GiTyre/>
                  </div>
                }
                
              
        }
        },{
            field: 'Ticker',
            width: 150,
            renderCell: (params) => {
             return <div style={{display: 'flex', flexDirection: 'column', cursor: 'pointer'}} onClick={() => setChart(<ChartCommodity chartControlID={`chartControlID`} id={`ChartCommodity`}height={620} TickerName={params.row.Ticker} name={params.row.Ticker.replace('C_','')}/>)}>
              <div style={{fontSize: 14, fontWeight: 600, color: 'white'}}>
                  {params.row.Ticker?.replace('C_','')}
              </div>
            </div>
              
                
            }
        },
        
        {
            field: 'Close',
            width: 70,
            renderCell: (params) => {
                return <div style={{fontSize: 12.95, color: 'white'}}>
                    {params.row.Close}
                </div>
            }
        },
        {
            field: 'Change',
            width: 75,
            renderCell: (params) => {
                function col(v){
                    if(v>0) return 'lime'
                    if(v<0) return 'red'
                    else return 'gold'
                }
                return <div style={{fontSize: 12.95, color: col(params.row.Change)}}>
                    {params.row.Change>0?`+${params.row.Change?.toFixed(2)}`:params.row.Change?.toFixed(2)}%
                </div>
            }
        },
        
        {
            field: 'weeklyChange',
            headerName: 'W',
            width: 80,
            renderCell: (params) => {
                function volAvg(v,v1,v2){
                  if(v>v2) return '#BA55D3'
                  else if(v<=v2 && v>v1) return 'royalblue'
                  else if(v<=v1 && v>0.5) return 'green'
                  else if(v<=0.5 && v>-0.5) return 'darkgoldenrod'
                  else if(v<=-0.5 && v>-v1) return '#CD5C5C'
                  else if(v<=-v1 && v>-v2) return '#DC143C'
                  else if(v<-v2) return 'darkred'
                }
                const weeklyChange = params.row.weeklyChange
                return <div style={{display: 'flex', flexDirection: 'column', fontSize: 13, backgroundColor: volAvg(weeklyChange,3,7), color: 'white', width: 50, height: 30, textAlign: 'center', borderRadius: 10}}>
                    
                    <div>{weeklyChange?.toFixed(2)}%</div>
                    
                </div>
            },
        },
        {
            field: 'monthlyChange',
            headerName: 'M',
            width: 80,
            renderCell: (params) => {
                function volAvg(v,v1,v2){
                  if(v>v2) return '#BA55D3'
                  else if(v<=v2 && v>v1) return 'royalblue'
                  else if(v<=v1 && v>2) return 'green'
                  else if(v<=2 && v>-2) return 'darkgoldenrod'
                  else if(v<=-2 && v>-v1) return '#CD5C5C'
                  else if(v<=-v1 && v>-v2) return '#DC143C'
                  else if(v<-v2) return 'darkred'
                }
                const monthlyChange = params.row.monthlyChange
                return <div style={{display: 'flex', flexDirection: 'column', fontSize: 13, backgroundColor: volAvg(monthlyChange,3,7), color: 'white', width: 50, height: 30, textAlign: 'center', borderRadius: 10}}>
                    
                    <div>{monthlyChange?.toFixed(2)}%</div>
                    
                </div>
            },
        },
        {
            field: 'quarterlyChange',
            headerName: 'Q',
            width: 80,
            renderCell: (params) => {
                function volAvg(v,v1,v2){
                  if(v>v2) return '#BA55D3'
                  else if(v<=v2 && v>v1) return 'royalblue'
                  else if(v<=v1 && v>3) return 'green'
                  else if(v<=3 && v>-3) return 'darkgoldenrod'
                  else if(v<=-3 && v>-v1) return '#CD5C5C'
                  else if(v<=-v1 && v>-v2) return '#DC143C'
                  else if(v<-v2) return 'darkred'
                }
                const quarterlyChange = params.row.quarterlyChange
                return <div style={{display: 'flex', flexDirection: 'column', fontSize: 13, backgroundColor: volAvg(quarterlyChange,3,7), color: 'white', width: 50, height: 30, textAlign: 'center', borderRadius: 10}}>
                    
                    <div>{quarterlyChange?.toFixed(2)}%</div>
                    
                </div>
            },
        },
        {
          field: 'Cổ Phiếu',
          width: 120,
          renderCell: (params) => {
            if(params.row.Ticker.includes('BRENTOIL')) return 'PVS,BSR,PVD,PVC'
            if(params.row.Ticker.includes('COAL')) return 'NBC,TVD,TC6'
            if(params.row.Ticker.includes('COTTON')) return 'GIL,TNG,VGT,MSH'
            if(params.row.Ticker.includes('GOLD')) return 'PNJ'
            if(params.row.Ticker.includes('HRC')) return 'HPG,NKG,HSG,VGS'
            if(params.row.Ticker.includes('HOGS')) return 'DBC,HAG,BAF'
            if(params.row.Ticker.includes('ALUM')) return 'NSH'
            if(params.row.Ticker.includes('SUGAR')) return 'QNS,LSS,SBT,SLS'
            if(params.row.Ticker.includes('UREA')) return 'DCM,DPM'
            if(params.row.Ticker.includes('BALTIC')) return 'GMD,PVT'
            if(params.row.Ticker.includes('RUBBER')) return 'GVR,PHR,DPR'
            if(params.row.Ticker.includes('GAS')) return 'GAS,CNG,PGC,ASP'
            if(params.row.Ticker.includes('MILK')) return 'VNM,MCM'
          }
        }

        //YahooQueryData('GC=F', ['1D', 'close'], 0)


    ]

    
    

    //Data
    var rows = [];

    for(let i=0; i<x?.length; i++){
        function createData(TickerName,Close,High,Low,Change,weeklyChange,monthlyChange,quarterlyChange) {
            return { 
                Ticker: TickerName,
                Close: Close,
                High: High,
                Low: Low,
                Change: Change,
                weeklyChange: weeklyChange,
                monthlyChange: monthlyChange,
                quarterlyChange: quarterlyChange
            };
        }

        rows.push(
            createData(
                String(x[i]?.TickerName),
                Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_close'],
                Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_high'],
                Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_low'],
                ((Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_close']-Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[1]?.['price_close'])/Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[1]?.['price_close']*100),
                ((Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_close']-Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[4]?.['price_close'])/Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[4]?.['price_close']*100),
                ((Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_close']-Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[19]?.['price_close'])/Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[19]?.['price_close']*100),
                ((Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_close']-Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[56]?.['price_close'])/Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[56]?.['price_close']*100),

            )
        )
    }

        
   
    return <div style={{ width: '100%', display: 'flex'}}>
    <div style={{border: '3px solid #4d547d', backgroundColor: '#202020', color: 'white', height: 700, minWidth: 500, width: '60%'}}>
      {chart}
    </div>
    <div style = {{  backgroundColor: '#101010', color: 'white', height: 700, width: '40%'}}>
      
    {isSuccess?<DataGrid
            rows = {rows?.filter((x) => {return x?.Ticker?.includes('C_')&&!x?.Ticker?.includes('SOY')&&!x?.Ticker?.includes('LEAD')&&!x?.Ticker?.includes('TIN')&&!x?.Ticker?.includes('ZINC')&&!x?.Ticker?.includes('PLAT')})?.sort((a,b) => {return b.Change-a.Change})}
            rowsHeight = {5}
            columns = {columns}
            getRowId={(row) =>  row.Ticker}
            sx={{
                '--DataGrid-containerBackground': '#404040',
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                    color: 'primary.light',
                },
                'css-t89xny-MuiDataGrid-columnHeaderTitle':{
                  color: 'green'
                },
                '.MuiDataGrid-footerContainer':{
                  backgroundColor: '#404040',
                  color: 'white'
                },
                '& .super-app.negative': {
                    backgroundColor: '#d47483',
                    color: '#1a3e72',
                    fontWeight: '600',
                  },
                  '& .super-app.positive': {
                    backgroundColor: 'MediumSeaGreen',
                    color: '#1a3e72',
                    fontWeight: '600',
                  },
                  '& .super-app.base': {
                    backgroundColor: 'SandyBrown',
                    color: '#1a3e72',
                    fontWeight: '600',
                  },
                  '& .super-app.negative1': {
                      color: '#d47483',
                    },
                    '& .super-app.positive1': {
                      color: 'MediumSeaGreen',
                    },
                    '& .super-app.base1': {
                      color: 'SandyBrown',
                    },
                  '& .super-app.bold': {
                    fontWeight: 'bold'
                  },
                  '& .super-app.fontup': {
                    fontSize: '18px'
                  },
            }}
            localeText={{
                toolbarDensity: 'Size',
                toolbarDensityLabel: 'Size',
                toolbarDensityCompact: 'Small',
                toolbarDensityStandard: 'Medium',
                toolbarDensityComfortable: 'Large',
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            rowHeight={35} 
        />:<></>}
        </div>
        </div>;
}

export default IndiceDataBoard