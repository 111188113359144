import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5stock from '@amcharts/amcharts5/stock'
import { useEffect, Component} from "react";
import React from "react";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";



export class ChartScoreMakerSector extends Component {

    constructor(props){
        super(props);
        this.state = {
            series: null,
            series2: null,
            series3: null
            
        }
    }

    componentDidMount(){
        let root = am5.Root.new(this.props.id);
        root._logo.dispose();
        root.setThemes([
            am5themes_Dark.new(root)
        ]);

        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                wheelY: "zoomX"
            })
        );
        chart.get("colors").set("colors", [
            am5.color('#FF00FF'),
            am5.color('#FFD700'),
            am5.color('#FFA500'),
        ]);
        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.2,
            baseInterval: {
            timeUnit: "day",
            count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 70,
                cellStartLocation: 0.025,
                cellEndLocation: 0.975,
                fontSize: 20
            }),
            tooltip: am5.Tooltip.new(root, {}),
        
        }));
        
        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            max: 10,
            min: -10,
            renderer: am5xy.AxisRendererY.new(root, {
                minGridDistance: 30,extraTooltipPrecision: 1,
                
            })
        }));

        xAxis.get("renderer").labels.template.setAll({
            fontSize: 10
          });

        yAxis.get("renderer").labels.template.setAll({
            fontSize: 10
          });

    

        xAxis.set(
            "tooltip",
            am5.Tooltip.new(root, {
                themeTags: ["axis"]
            })
        );

        yAxis.set(
            "tooltip",
            am5.Tooltip.new(root, {
                themeTags: ["axis"]
            })
        );

        let series = chart.series.push( am5xy.SmoothedXLineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
            labelText:"{valueX.formatDate()}: {valueY}",
            pointerOrientation:"horizontal"
            })
        }));

        
        let series2 = chart.series.push( am5xy.SmoothedXLineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
            labelText:"{valueX.formatDate()}: {valueY}",
            pointerOrientation:"horizontal"
            })
        }));
        
        let series3 = chart.series.push( am5xy.SmoothedXLineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
            labelText:"{valueX.formatDate()}: {valueY}",
            pointerOrientation:"horizontal"
            })
        }));
        
        

        

        // let rangeDataItem = yAxis.makeDataItem({
        //     value: -1000,
        //     endValue: 0
        //     });
    
        // let range = series.createAxisRange(rangeDataItem);
    
        // range.strokes.template.setAll({
        //     stroke: am5.color('#DC143C'),
        //     strokeWidth: 3
        // });
    
        // range.fills.template.setAll({
        //     fill: am5.color('#DC143C'),
        //     fillOpacity: 0.5,
        //     visible: true
        // });

        // let rangeDataItem2 = yAxis.makeDataItem({
        //     value: 1000,
        //     endValue: 0
        // });
        
        // let range2 = series.createAxisRange(rangeDataItem2);
        
        // range2.strokes.template.setAll({
        //     stroke: am5.color('#32CD32'),
        //     strokeWidth: 3
        // });
        
        // range2.fills.template.setAll({
        //     fill: am5.color('#32CD32'),
        //     fillOpacity: 0.5,
        //     visible: true
        // });

        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal"
        }));

        // add series range 0
        let seriesRangeDataItem = yAxis.makeDataItem({ value: 0, endValue: 0 });
        let seriesRange = series.createAxisRange(seriesRangeDataItem);
        seriesRange.fills.template.setAll({
            visible: true,
            opacity: 0.3
        });


        seriesRangeDataItem.get("grid").setAll({
            strokeOpacity: 1,
            visible: true,
            stroke: am5.color('#909090'),
            strokeDasharray: [5, 5]
        })

        seriesRangeDataItem.get("label").setAll({
            location:0,
            visible:true,
            text:"Base",
            inside:true,
            centerX:0,
            centerY:am5.p100,
            fontWeight:"bold"
        })

        // add series range 1
        let seriesRangeDataItem1 = yAxis.makeDataItem({ value: 5, endValue: 0 });
        let seriesRange1 = series.createAxisRange(seriesRangeDataItem1);
        seriesRange1.fills.template.setAll({
            visible: true,
            opacity: 0.3
        });


        seriesRangeDataItem1.get("grid").setAll({
            strokeOpacity: 1,
            visible: true,
            stroke: am5.color('#32CD32'),
            strokeDasharray: [5, 5]
        })

        seriesRangeDataItem1.get("label").setAll({
            location:0,
            visible:true,
            text: "UP",
            inside:true,
            centerX:0,
            centerY:am5.p100,
            fontWeight:"bold"
        })

        // add series range 2
        let seriesRangeDataItem2 = yAxis.makeDataItem({ value: -5, endValue: 0 });
        let seriesRange2 = series.createAxisRange(seriesRangeDataItem2);
        seriesRange2.fills.template.setAll({
            visible: true,
            opacity: 0.3
        });


        seriesRangeDataItem2.get("grid").setAll({
            strokeOpacity: 1,
            visible: true,
            stroke: am5.color('#FF0000'),
            strokeDasharray: [5, 5]
        })

        seriesRangeDataItem2.get("label").setAll({
            location:0,
            visible:true,
            text: "DN",
            inside:true,
            centerX:0,
            centerY:am5.p0,
            fontWeight:"bold"
        })

        //
        

        let legend = chart.children.push(am5.Legend.new(root, {
            nameField: "name",
            fillField: "color",
            strokeField: "color",
            centerX: am5.percent(0),
            x: am5.percent(30),
            y: am5.percent(-4),
          }));
          legend.labels.template.setAll({
            fontSize: 9,
            fontWeight: "600",
            height: 15
          });
          
          legend.data.setAll([{
            name: "MAKER",
            color: am5.color('#FF00FF')
          }, {
            name: "SLONG",
            color: am5.color('#FFA500')
          }, {
            name: "SMID",
            color: am5.color('#FFD700')
          }]);
    
            
        
        chart.appear(1000, 100);
        
        this.setState({
            series: series,
            series2: series2,
            series3: series3
        })
        this.root = root;

        }

        componentWillUnmount() {
            if (this.root) {
            this.root.dispose();
            }
        }

    render() {
        
        var date = new Date();
        let setDate = new Date(date)
        date.setHours(0, 0, 0, 0);
        am5.time.add(date, "day", -1);

        var data_ = [];
        var data2_ = [];
        var data3_ = [];
        var series = this?.state?.series
        var series2 = this?.state?.series2
        var series3 = this?.state?.series3
        
            
            const data = this?.props?.data
            
            for(let i2=39; i2>=0;i2--){
                setDate.setDate(setDate.getDate()-1)
                data_.push({
                    date: setDate.getTime(),
                    value: !Number(data?.[0]?.LastDaysData?.Maker?.[i2]) || 'N/A'>-1000?0.001:Number(data?.[0].LastDaysData?.Maker?.[i2]) || 'N/A'
                })
                data2_.push({
                    date: setDate.getTime(),
                    value: !Number(data?.[0]?.LastDaysData?.Score?.[i2]) || 'N/A'>-1000?0.001:Number(data?.[0].LastDaysData?.Score?.[i2]) || 'N/A'
                })
                
                data3_.push({
                    date: setDate.getTime(),
                    value: !Number(data?.[0]?.LastDaysData?.Score?.[i2]) || 'N/A'>-1000
                        ?0:
                        Number(data?.[0]?.LastDaysData?.SLONG?.[i2]) || 'N/A'
                })
            
            }
            series?.data?.setAll(data_);
            series2?.data?.setAll(data2_);
            series3?.data?.setAll(data3_);
            
            
        
        return (
        <span>
            
            <div id={this.props.id} style={{ height: 300, backgroundColor: '#202020',borderRadius: 5, border: '1px solid white'}}>
            </div>
        </span>

          
        );
    }

    


}

