import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useTitle from '../../hooks/useTitle'

const Welcome = () => {

    const { username, isManager, isAdmin } = useAuth()

    useTitle(`Finlens StockApp -> ${username}`)

    const date = new Date()
    const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(date)

    const content = (
        <section className="welcome">


            <h1>Hello {username}!</h1>

            <p><Link to="/dash/dashboard">DashBoard</Link></p>
            <p><Link to="/dash/matrix">Matrix</Link></p>
            <p><Link to="/dash/deepanalysis">Deep Analysis</Link></p>

            <p><Link to="/dash/notes">Notes</Link></p>

            <p><Link to="/dash/notes/new">Thêm Note</Link></p>

            {(isManager || isAdmin) && <p><Link to="/dash/users">Quản Lý List User</Link></p>}

            {(isManager || isAdmin) && <p><Link to="/dash/users/new">Thêm User / Set Admin Manager</Link></p>}

        </section>
    )

    return content
}
export default Welcome