import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFileCirclePlus,
    faFilePen,
    faUserGear,
    faUserPlus,
    faRightFromBracket
} from "@fortawesome/free-solid-svg-icons"
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useSendLogoutMutation } from '../features/auth/authApiSlice'
import useAuth from '../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import { Button, Typography } from '@mui/material'

const DASH_REGEX = /^\/dash(\/)?$/
const NOTES_REGEX = /^\/dash\/notes(\/)?$/
const USERS_REGEX = /^\/dash\/users(\/)?$/

const DashHeader = () => {
    const { isManager, isAdmin } = useAuth()

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/')
    }, [isSuccess, navigate])

    const onNewNoteClicked = () => navigate('/dash/notes/new')
    const onNewUserClicked = () => navigate('/dash/users/new')
    const onNotesClicked = () => navigate('/dash/notes')
    const onUsersClicked = () => navigate('/dash/users')

    let dashClass = null
    if (!DASH_REGEX.test(pathname) && !NOTES_REGEX.test(pathname) && !USERS_REGEX.test(pathname)) {
        dashClass = "dash-header__container--small"
    }

    let newNoteButton = null
    if (NOTES_REGEX.test(pathname)) {
        newNoteButton = (
            <button
                className="icon-button"
                title="New Note"
                onClick={onNewNoteClicked}
            >
                <FontAwesomeIcon icon={faFileCirclePlus} />
            </button>
        )
    }

    let newUserButton = null
    if (USERS_REGEX.test(pathname)) {
        newUserButton = (
            <button
                className="icon-button"
                title="New User"
                onClick={onNewUserClicked}
            >
                <FontAwesomeIcon icon={faUserPlus} />
            </button>
        )
    }

    let userButton = null
    if (isManager || isAdmin) {
        if (!USERS_REGEX.test(pathname) && pathname.includes('/dash')) {
            userButton = (
                <button
                    className="icon-button"
                    title="Users"
                    onClick={onUsersClicked}
                >
                    <FontAwesomeIcon icon={faUserGear} />
                </button>
            )
        }
    }

    let notesButton = null
    if (!NOTES_REGEX.test(pathname) && pathname.includes('/dash')) {
        notesButton = (
            <button
                className="icon-button"
                title="Notes"
                onClick={onNotesClicked}
            >
                <FontAwesomeIcon icon={faFilePen} />
            </button>
        )
    }

    const logoutButton = (
        <Button
            variant="outlined" color="error"
            onClick={sendLogout}
            sx={{padding: 2, height: 30, marginTop: 0.75}}
        >
            Logout
        </Button>
    )

    const errClass = isError ? "errmsg" : "offscreen"

    let buttonContent
    if (isLoading) {
        buttonContent = <PulseLoader color={"#FFF"} />
    } else {
        buttonContent = (
            <>
                {/* {newNoteButton} */}
                {newUserButton}
                {/* {notesButton} */}
                {userButton}
                {logoutButton}
            </>
        )
    }

    const { username, status, email } = useAuth()

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <header className="dash-header" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Link to="/dash">
                        <Typography variant="h4" fontWeight={600}>Finlens StockApp</Typography>
                    </Link>
                    <div style={{display: 'flex', paddingTop: 6 }}>
                        <Link to="/dash/dashboard">
                            <Button variant="text">Dashboard</Button>
                        </Link>
                        <Link to="/dash/matrix" style={{marginLeft: 10}}>
                            <Button variant="text">Matrix</Button>
                        </Link>
                        <Link to="/dash/deepanalysis" style={{marginLeft: 10}}>
                            <Button variant="text">DeepAnalysis</Button>
                        </Link>
                        
                        <Link to="/dash/quantportfolio" style={{marginLeft: 10}}>
                            <Button variant="text">Q-PORTFOLIO</Button>
                        </Link>

                        
                        <Link to="/dash/quantforecast" style={{marginLeft: 10}}>
                            <Button variant="text">QUANT FORECAST&AI</Button>
                        </Link>


                    </div>

                    <nav className="dash-header__nav">
                        {buttonContent}
                    </nav>
            </header>
        </>
    )

    return content
}
export default DashHeader