
export function ColorByData(v) {
    if(v>100000) return '#FF00FF'
    if(v>50000) return '#00FF7F'
    if(v>20000) return '#7CFC00'
    if(v>5000) return '#20B2AA'
    if(v>0) return '#FFFF00'
    if(v<-10000) return 'red'
    if(v>-10000) return '#FF6347'
  }
  
  
  export const Tplus = {
    'darkyellow': (params) => params.value?.includes("SG:HOLD")||params.value?.includes("SG:Wait")||params.value?.includes("SG:"),
    'darkgreen': (params) => params.value?.includes("SG:BUY")| params.value?.includes("SG:Buy")|params.value?.includes("SG:OBBuy"),
    'darkred': (params) => params.value?.includes("SG:SELL")| params.value?.includes("SG:Sell")| params.value?.includes("SG:ObsSell"),
    'darkpink': (params) => params.value?.includes("SG:DANGER"),
    'lightdarkaqua': (params) => params.value?.includes('=>GREEN') && ! params.value?.includes("SG:BUY"),
    'lightgreen': (params) => params.value?.includes('SG:Observe') || params.value?.includes('SG:GoOver')
  };

  
export function ColorByChange(v) {
    if(v>=6) return '#FF00FF'
    if(v<6 & v>0) return '#00FF7F'
    if(v<0 & v>-6) return 'red'
    if(v<=-6) return '#FF6347'
    else return 'goldenrod'
  }

export function BoxS2Col(v,v2) {
    if(v?.includes('=>GREEN')) return 'royalblue'
    if(v?.includes('=>RED')) return '#FF4500'
    if(v?.includes('GREEN') && Number(v2)<5) return 'green'
    if(v?.includes('GREEN') && Number(v2)>5) return '#48D1CC'
    if(v?.includes('RED')) return 'darkred'
    else return 'goldenrod'
    
  }
