import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; 
import { useState, useMemo, useEffect } from 'react';
import "./MatrixBoard.matrix.css"
import dayjs from 'dayjs';
import { useGetDividendQuery, useGetFundamentalQuery } from '../api/apiSlice.matrix';
import { SymbolCalendarWarning, GetTickerMV, GetFundaScore, Valuation, StatBoard, OrderFlow } from './MatrixComponents.matrix';
import { BoxS2Col, ColorByChange, ColorByData, Tplus } from './MatrixStyling.matrix';
import { ImageChart } from './Image.tsx';

function MatrixBoard (props)  {
  
    const {data, isSuccess, dataFunda, isSuccessFunda} = props
    const [row, setRow] = useState([])
    const [funda, setFunda] = useState([])

    useEffect(() => {
      setRow(data)
      
    }, [data])

    
    
 
 // Column Definitions: Defines the columns to be displayed.
 const [colDefs, setColDefs] = useState([
   { field: "TICKERVN" ,
    width: 130,
    maxWidth: 150,
    cellRenderer: (params) => {
      return <span>
          <div style={{ fontWeight: 'bold', marginBottom: '10%', lineHeight: 1}}>
            <span onClick={() => props.setParams({
              TickerName: String(params.data.Ticker)
            })}>
              <button
                style={{height: 10, width: 50}}
               onClick={() => {
                props.setParams({
                  TickerName: String(params.data.Ticker)
                })
              }}>

{params.data.Ticker}
              </button>
              
            </span>
          </div>
           
          <img alt='' src={params.data.imagelogo} style={{width: '100px',height: '50px', borderRadius: '5px', backgroundColor: '#eae9d4'}}/> 
          
      </span>
  },
   },
   {
    field: 'Statistics',
    cellRenderer: (params) => {
      var Chg5D = Number((params.data.SB3CL0-params.data.SB3CL4)/params.data.SB3CL4*100).toFixed(2)
      var Chg5DType
      if(Chg5D<-10) {Chg5DType = 'XX5'}
      else if(Chg5D<0 && Chg5D>=-10) {Chg5DType = 'X5'}
      else if(Chg5D>=0 && Chg5D<5) {Chg5DType = 'A5'}
      else if(Chg5D>=5 && Chg5D<10) {Chg5DType = 'B5'}
      else if(Chg5D>=10 && Chg5D<20) {Chg5DType = 'C5'}
      else if(Chg5D>=20 && Chg5D<30) {Chg5DType = 'D5'}
      else if(Chg5D>=30) {Chg5DType = '$5'}

      function sByChg(v){
        if(v<=-6) return <span style = {{fontWeight: 'bold', color: '#87CEFA'}}>F</span>
        if(v>-6 && v<0) return <span style = {{fontWeight: 600, color: 'orangered'}}>-</span>
        if(v<6 && v>0) return <span style = {{ color: 'lime'}}>+</span>
        if(v>=6) return <span style = {{fontWeight: "bold", color: '#DDA0DD'}}>C</span>
        else return <span style = {{fontWeight: 500, color: 'gold'}}>_</span>
      }

      


      
      return <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex'}}>
        <div style={{width: 100, height: 50, fontWeight: 500}}>
          <div style={{width: 80, height: 15,
                fontSize: 10,
                lineHeight: 1.8,
                background: '#404040', color: 'white', fontStyle: 'italic', textDecoration: 'underline'}}>Stability</div>
          <div style={{
                backgroundColor: 
                  params.data.S1Rating?.includes('A')||params.data.S1Rating?.includes('B')?
                    'forestgreen'
                    :
                    params.data.S1Rating?.includes('C')||params.data.S1Rating?.includes('D')||params.data.S1Rating?.includes('$')?'royalblue':params.data.S1Rating?.includes('X')?
                    'darkred'
                    :'darkred',
                width: 80,
                height: 20,
                fontSize: 10,
                lineHeight: 2
              }}>
            <span 
            >
              <span style={{fontWeight: 500}}>{params.data.S1Rating}</span>
            </span>
          </div>
          <div style={{
                backgroundColor: 
                  params.data.S1BOS?.includes('+')?
                    'forestgreen'
                    :
                    params.data.S1BOS?.includes('Range')?
                    'darkgoldenrod'
                    :'darkred',
                width: 80,
                height: 20,
                fontSize: 10,
                lineHeight: 2,borderTop: '0.5px dashed white'
              }}>
            <span
            >
              {params.data.S1BOS}
            </span>
          </div>
          <div style={{
                
                width: 80,
                height: 20,
                fontSize: 10,
                lineHeight: 2,
                backgroundColor: 'darkslategray',borderTop: '0.5px dashed white'
              }}>
            <span
            >
              {sByChg(params.data.SB3C9)}{sByChg(params.data.SB3C8)}{sByChg(params.data.SB3C7)}{sByChg(params.data.SB3C6)}{sByChg(params.data.SB3C5)}{sByChg(params.data.SB3C4)}{sByChg(params.data.SB3C3)}{sByChg(params.data.SB3C2)}{sByChg(params.data.SB3C1)}{sByChg(params.data.SB3C0)}
            </span>
          </div>
        </div>
        
        <div style={{
                backgroundColor: BoxS2Col(params.data.S2Col,params.data.S2No),
                width: 90,
                height: 75,
              }}>
                
          <div style={{width: 90, height: 15,
                lineHeight: 1.8, fontSize: 10,
                background: '#404040', color: 'white', fontStyle: 'italic', textDecoration: 'underline'}}>Status</div>
              <div style={{
                lineHeight: 1.5,
                fontWeight: 'bold',
                fontSize: 15,
                marginTop: 12}}>

                {params.data.S2Col}{params.data.S2No}
              </div>
              <div style={{fontSize: 10,borderTop: '0.5px dashed white', lineHeight: 2.5}}>
                {params.data.S2Status}
              </div>
          </div>
      </div>




      <div style={{marginTop: 10, display: 'flex'}}>
        <div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{width: 80, height: 15,
                    fontSize: 10,
                    lineHeight: 1.8,
                    background: '#404040', color: 'white', fontStyle: 'italic', textDecoration: 'underline'}}>Score</div>
            </div>
            <div style={{
                  backgroundColor: params.data.S3DeltaScore>1?'green':params.data.S3DeltaScore<-1?'darkred':'darkgoldenrod',
                  width: 80,
                  height: 20,
                  fontSize: 10,
                  lineHeight: 2
                }}>
              <span
              >
                <span style={{fontWeight: 'bold'}}>DeltaD: </span>
                {Number(params.data.S3DeltaScore)?.toFixed(2)}
              </span>
            </div>
            <div style={{
                  backgroundColor: params.data.S3ScoreAverage>2?'green':params.data.S3ScoreAverage<-2?'darkred':'darkgoldenrod',
                  width: 80,
                  height: 20,
                  fontSize: 10,
                  lineHeight: 2,borderTop: '0.5px dashed white'
                }}>
              <span
              >
                
                <span style={{fontWeight: 'bold'}}>Avg: </span>
                {Number(params.data.S3ScoreAverage)?.toFixed(2)}
              </span>
            </div>
            <div style={{
                  backgroundColor: params.data.S3ScoreStrengthDelta>0.5?'green':params.data.S3ScoreStrengthDelta<-0.5?'darkred':'darkgoldenrod',
                  width: 80,
                  height: 30,
                  fontSize: 10,
                  lineHeight: 1.5,borderTop: '0.5px dashed white'
                }}>
              <span
              >
                
                <div>
                  <span style={{fontWeight: 'bold'}}>StrD: </span>
                {Number(params.data.S3ScoreStrengthDelta)?.toFixed(2)}</div>
                <div>

                  ({params.data.S3DeltaScoreDaily})
                </div>
              </span>
            </div>
          </div>

          <div style={{marginLeft: 25}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{width: 80, height: 15,
                    fontSize: 10,
                    lineHeight: 1.8,
                    background: '#404040', color: 'white', fontStyle: 'italic', textDecoration: 'underline'}}>Maker</div>
            </div>
            <div style={{
                  backgroundColor: params.data.S4DeltaMaker>1?'green':params.data.S4DeltaMaker<-1?'darkred':'darkgoldenrod',
                  width: 80,
                  height: 20,
                  fontSize: 10,
                  lineHeight: 2
                }}>
              <span
              >
                <span style={{fontWeight: 'bold'}}>DeltaD: </span>
                {Number(params.data.S4DeltaMaker)?.toFixed(2)}
              </span>
            </div>
            <div style={{
                  backgroundColor: params.data.S4MakerAverage>2?'green':params.data.S4MakerAverage<-2?'darkred':'darkgoldenrod',
                  width: 80,
                  height: 20,
                  fontSize: 10,
                  lineHeight: 2,borderTop: '0.5px dashed white'
                }}>
              <span
              >
                
                <span style={{fontWeight: 'bold'}}>Avg: </span>
                {Number(params.data.S4MakerAverage)?.toFixed(2)}
              </span>
            </div>
            <div style={{
                  backgroundColor: params.data.S4MakerStrengthDelta>0.5?'green':params.data.S4MakerStrengthDelta<-0.5?'darkred':'darkgoldenrod',
                  width: 80,
                  height: 30,
                  fontSize: 10,
                  lineHeight: 1.5,borderTop: '0.5px dashed white'
                }}>
              <span
              >
                
                <div>
                  <span style={{fontWeight: 'bold'}}>StrD: </span>
                {Number(params.data.S4MakerStrengthDelta)?.toFixed(2)}</div>
                <div>

                  ({params.data.S4DeltaMakerDaily})
                </div>
              </span>
            </div>
          </div>
          
      </div>

      
          
      </div>
      
    }
   }
   
   ,{
    field: 'T4',
    headerName: 'Analysis',
    filter: 'agTextColumnFilter',
    sortable: false,
    headerTooltip: 'Chu kì',
    suppressMenu: true,
    suppressSizeToFit: true,
    
    autoHeight: true,       
    minWidth: 500,
    maxWidth: 575,
    cellRenderer: (params) => StatBoard(params)
   },

    {
        field: 'T3',
        filter: 'agTextColumnFilter',
        sortable: true,
        headerTooltip: 'Chu kì',
        suppressMenu: true,
        suppressSizeToFit: true,
        
        autoHeight: true,       
        minWidth: 75,
        maxWidth: 75,
        cellClassRules: Tplus,
        cellRenderer: (params) => {
            return <span style={{lineHeight: 1}}>
                <div>S: {params.data.SB3SC3}.00</div>
                <div>M: {params.data.SB3MK3}.0</div>
                <div>C: {Number(params.data.SB3C3)}%</div>
                <div>R: {Math.round((Number(params.data.SB3H3)-Number(params.data.SB3L3))/Number(params.data.SB3H3) *100)}%</div>
                <div>{params.data.T3?.includes('GREEN')?'GREEN':(params.data.T3?.includes('YELLOW')?"YELLOW":params.data.T3?.includes('RED')?'RED':"N/A")}</div>
                <div>-------</div>
                
            <div style={{color: ColorByData(params.data.CB2Delta3), fontWeight: 600}}>
                <div>D2: {Number(params.data.CB2Delta3)}</div>
                <div>D5: {Number(params.data.CB5Delta3)}</div>
            </div>
                <div>-------</div>
                <span style={{ fontWeight: 'bold'}}>
                    <div>{params.data.SB2BuySellList3===""?"WAIT":(params.data.SB2BuySellList3?.includes('Observe')?'OBSERVE':params.data.SB2BuySellList3)}</div>
                </span>
            </span>
        }
        
        
        },
        {
            field: 'T2',
            filter: 'agTextColumnFilter',
            sortable: true,
            headerTooltip: 'Chu kì',
            suppressMenu: true,
            suppressSizeToFit: true,
            
            autoHeight: true,       
            minWidth: 75,
            maxWidth: 75,
            cellClassRules: Tplus,
            cellRenderer: (params) => {
                return <span style={{lineHeight: 1}}>
                    <div>S: {params.data.SB3SC2}.00</div>
                    <div>M: {params.data.SB3MK2}.0</div>
                    <div>C: {Number(params.data.SB3C2)}%</div>
                    <div>R: {Math.round((Number(params.data.SB3H2)-Number(params.data.SB3L2))/Number(params.data.SB3H2) *100)}%</div>
                    <div>{params.data.T2?.includes('GREEN')?'GREEN':(params.data.T2?.includes('YELLOW')?"YELLOW":params.data.T2?.includes('RED')?'RED':"N/A")}</div>
                    <div>-------</div>
                    
                    <div style={{color: ColorByData(params.data.CB2Delta2), fontWeight: 600}}>
                        <div>D2: {Number(params.data.CB2Delta2)}</div>
                        <div>D5: {Number(params.data.CB5Delta2)}</div>
                    </div>
                    <div>-------</div>
                    <span style={{ fontWeight: 'bold'}}>
                        <div>{params.data.SB2BuySellList2===""?"WAIT":(params.data.SB2BuySellList2?.includes('Observe')?'OBSERVE':params.data.SB2BuySellList2)}</div>
                    </span>
                </span>
            }
            
            
            },
            {
                field: 'T1',
                filter: 'agTextColumnFilter',
                sortable: true,
                headerTooltip: 'Chu kì',
                suppressMenu: true,
                suppressSizeToFit: true,
                
                autoHeight: true,       
                minWidth: 75,
                maxWidth: 75,
                
                cellClassRules: Tplus,
                cellRenderer: (params) => {
                    return <span style={{lineHeight: 1}}>
                        <div>S: {params.data.SB3SC1}.00</div>
                        <div>M: {params.data.SB3MK1}.0</div>
                        <div>C: {Number(params.data.SB3C1)}%</div>
                        <div>R: {Math.round((Number(params.data.SB3H1)-Number(params.data.SB3L1))/Number(params.data.SB3H1) *100)}%</div>
                        <div>{params.data.T1?.includes('GREEN')?'GREEN':(params.data.T1?.includes('YELLOW')?"YELLOW":params.data.T1?.includes('RED')?'RED':"N/A")}</div>
                        <div>-------</div>
                        
                    <div style={{color: ColorByData(params.data.CB2Delta1), fontWeight: 600}}>
                        <div>D2: {Number(params.data.CB2Delta1)}</div>
                        <div>D5: {Number(params.data.CB5Delta1)}</div>
                    </div>
                        <div>-------</div>
                        <span style={{ fontWeight: 'bold'}}>
                            <div>{params.data.SB2BuySellList1===""?"WAIT":(params.data.SB2BuySellList1?.includes('Observe')?'OBSERVE':params.data.SB2BuySellList1)}</div>
                        </span>
                    </span>
                }
                
                
              },
              {
                  field: 'T0',
                  filter: 'agTextColumnFilter',
                  sortable: true,
                  headerTooltip: 'Chu kì',
                  suppressMenu: true,
                  suppressSizeToFit: true,
                  
                  autoHeight: true,       
                  minWidth: 75,
                  maxWidth: 75,
                  
                  cellClassRules: Tplus,
                  cellRenderer: (params) => {
                      return <span style={{lineHeight: 1}}>
                          <div>S: {params.data.SB3SC0}.00</div>
                          <div>M: {params.data.SB3MK0}.0</div>
                          <div>C: {Number(params.data.SB3C0)}%</div>
                          <div>R: {Math.round((Number(params.data.SB3H0)-Number(params.data.SB3L0))/Number(params.data.SB3H0) *100)}%</div>
                          <div>{params.data.T0?.includes('GREEN')?'GREEN':(params.data.T0?.includes('YELLOW')?"YELLOW":params.data.T0?.includes('RED')?'RED':"N/A")}</div>
                          <div>-------</div>
                          
                      <div style={{color: ColorByData(params.data.CB2Delta0), fontWeight: 600}}>
                          <div>D2: {Number(params.data.CB2Delta0)}</div>
                          <div>D5: {Number(params.data.CB5Delta0)}</div>
                      </div>
                          <div>-------</div>
                          <span style={{ fontWeight: 'bold'}}>
                              <div>{params.data.SB2BuySellList0===""?"WAIT":(params.data.SB2BuySellList0?.includes('Observe')?'OBSERVE':params.data.SB2BuySellList0)}</div>
                          </span>
                      </span>
                  }
                  
                  
                  
                    },
   {  field: "SB3SC0A",
      headerName: 'SCORE', 
      width: 45, 
      filter: 'agNumberColumnFilter',
      cellStyle: (params) => {
        return (
        {
          'white-space': 'normal',alignText: 'center',
          'backgroundColor': Number(params?.data?.SB3SC0A)>2?'darkgreen':Number(params?.data?.SB3SC0A)<-2?'maroon':'darkgoldenrod',
          'fontFamily': 'Arial',
          'fontSize': 14
        }
        )
        },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
      
      cellRenderer: (params) => {
        return <div style={{
            paddingLeft: 2,
            alignText: 'center',
            fontWeight: 500
          }}>
          {Number(params?.data?.SB3SC0A)>0?'+':''}{Number(params?.data?.SB3SC0A).toFixed(1)}
        </div>
      }
    },
    {  field: "DeltaScore",
      headerName: 'DeltaScore', 
      width: 35, 
      filter: 'agNumberColumnFilter',
      cellStyle: (params) => {
        return (
        {
          'white-space': 'normal',alignText: 'center',
          'color': Number(params?.data?.DeltaScore)>0.5?'limegreen':Number(params?.data?.DeltaScore)<-0.5?'crimson':'darkgoldenrod',
          'fontFamily': 'Arial',
          backgroundColor: '#101010'
        }
        )
        },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
      cellRenderer: (params) => {
        return <div style={{
            paddingLeft: 2,
            alignText: 'center',
            fontWeight: 'bold'
          }}>
          {Number(params?.data?.DeltaScore)>0?'+':''}{Number(params?.data?.DeltaScore).toFixed(1)}
        </div>
      }
    },
    
    {  field: "SB3MK0A",
      headerName: 'MAKER', 
      width: 45, 
      filter: 'agNumberColumnFilter',
      cellStyle: (params) => {
        return (
        {
          'white-space': 'normal',alignText: 'center',
          'backgroundColor': Number(params?.data?.SB3MK0A)>2?'darkgreen':Number(params?.data?.SB3MK0A)<-2?'maroon':'darkgoldenrod',
          'fontFamily': 'Arial',
          'fontSize': 14
        }
        )
        },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
      cellRenderer: (params) => {
        return <div style={{
            paddingLeft: 2,
            alignText: 'center',
            fontWeight: 500
          }}>
          {Number(params?.data?.SB3MK0A)>0?'+':''}{Number(params?.data?.SB3MK0A).toFixed(1)}
        </div>
      }
    },
    {  field: "S4DeltaMaker",
      headerName: 'DeltaMaker', 
      width: 35, 
      filter: 'agNumberColumnFilter',
      cellStyle: (params) => {
        return (
        {
          'white-space': 'normal',alignText: 'center',
          'color': Number(params?.data?.S4DeltaMaker)>0.5?'limegreen':Number(params?.data?.S4DeltaMaker)<-0.5?'crimson':'gold',
          'fontFamily': 'Arial',
          'backgroundColor': '#101010'
        }
        )
        },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
      cellRenderer: (params) => {
        return <div style={{
            paddingLeft: 2,
            alignText: 'center',
            fontWeight: 'bold'
          }}>
          {Number(params?.data?.S4DeltaMaker)>0?'+':''}{Number(params?.data?.S4DeltaMaker).toFixed(1)}
        </div>
      }
    },
    {
      field: 'T4',
      headerName: 'image',
      width: 450, 
      cellRenderer: ImageChart
    },
    {  field: "ChuKiTangGiam",
      headerName: 'Trend%', 
      width: 75, 
      filter: 'agNumberColumnFilter',
      cellStyle: {'white-space': 'normal',alignText: 'center'},
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
    },
    {  field: "Change(%)",
      headerName: 'DayChg(%)', 
      width: 75, 
      filter: 'agNumberColumnFilter',
      cellStyle: {'white-space': 'normal',alignText: 'center'},
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
      cellRenderer: (params) => {
        return <div style={{
          color: ColorByChange(params.data['Change(%)'])
        }}>
          {params.data['Change(%)']}%
        </div>
      }
    },
    {  field: "Volume",
      headerName: 'Volume', 
      width: 120, 
      filter: 'agNumberColumnFilter',
      cellStyle: {'white-space': 'normal',alignText: 'center'},
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB,
    },

    {
      field: 'ScoreFunda',
      filter: 'agNumberColumnFilter',
      width: 300,

      cellRenderer: (params) => {return  <div style={{display: 'flex', flexDirection: 'column'}}>
        <div>{GetFundaScore(params.data.Ticker)}</div>
        <div style={{marginTop: 5}}>{Valuation(params.data.Ticker,params.data.SectorByICBL2!==""?params.data.SectorByICBL2:'0',params.data.SB3CL0)}</div>
        </div>}
    },


 ]);
 
  const defaultColDef = useMemo(() => {
            return {
            floatingFilter: true,
            editable: false,
            sortable: true,
            filter: true,
            filterParams: {
                textCustomComparator: 
                function(filter, value, filterText) {
                    const filterValues = filterText.split(',');
                    // loop through filterValues and see if the value contains any of them
                    return filterValues.some((item) => {
                    return value.indexOf(item) >= 0;
                
                }
                    )},
                defaultToNothingSelected: true,
            
            },
            cellStyle: {'white-space': 'normal',textalign: 'center',fontFamily:'Arial', height: '100%'},
            autoGroupColumnDef: {
                field: 'SB3SC0A',
                comparator: function(valueA, valueB, nodeA, nodeB, isDescending) {
                    return (valueA == valueB) ? 0 : (valueA < valueB) ? 1 : -1;
                },
            },
        }
        }, []);

        useEffect(() => {
          setColDefs(colDefs.filter(x => {return x.field.length > 0}))
        }, [])

  return (
    <div
      className="ag-theme-quartz-dark" // applying the Data Grid theme
      style={{ height: 1000, fontSize: 12 }} // the Data Grid will fill the size of the parent container
    >

      <AgGridReact
          rowData={row}
          columnDefs={colDefs}
          headerHeight={40}
          paginationPageSizeSelector={[5, 10, 20, 50, 100]}
          pagination={true}
          paginationPageSize={5}
          defaultColDef={defaultColDef}
          sideBar={'column'}
          animateRows={true}
          rowHeight={275}
          
      />
    </div>
  )
}

export default MatrixBoard