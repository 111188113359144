import useTitle from 'hooks/useTitle'
import React, {useEffect, useState} from 'react'
import SectorBoard from './components/SectorBoard.dashboard'
import { Button, Typography, NativeSelect } from '@mui/material'
import BubbleChart from './components/BubbleChart.dashboard'
import BulletChart from './components/BulletChart.dashboard'
import IndiceDataBoard from './components/WorldMarket/CategoryBoard_Commodity'
import IndexDataBoard from './components/WorldMarket/CategoryBoard_Index'
import WorldIndice from './components/WorldMarket'
import BienDoCPDaily from './components/BienDoCPDaily.dashboard'
import BienDoCPIntraday from './components/BienDoCPIntraday.dashboard'
import { ChartScoreMakerSector } from './components/BySector/TopScore.bysector.dashboard'
import { TopByCriteria } from './components/BySector/TopByCriteria.bysector'
import InvestorType from './components/InvestorType.deri.dashboard'
import { StockChartVNINDEX } from './components/StockChart.deri.dashboard'
import LienTangCompare from './components/LienTangCompare.deri.dashboard'
import T1_FutureCorrelation from './components/FutureCorrelation.deri.dashboard'
import { useGetFutureDataQuery, useGetParamQuery, useGetSectorQuery, useGetStockDataQuery, useGetVolatilityQuery, useGetYahooDataQuery } from './api/apiSlice.dashboard'
import { PulseLoader } from 'react-spinners'
import ChartBubbleSector from './components/BubbleChartSector.dashboard'
import { SectorBenchmark } from './DashboardComponent'
import BubbleChartForEachSector from './components/BubbleChartForEachSector.dashboard'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { RangeBySector } from './components/BySector/RangeBySector.bysector'
import { FaCheckCircle } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { GoXCircle } from "react-icons/go";
import { BienDoCPIntraday5Day } from './components/BienDoCPIntraday5Day.dashboard'

const Dashboard = () => {
  const [params,setParams] = useState({
    Sector: 'BDS',
    CondForOverview: {
      VolumeAverage: 100000,
      Sector: 'BDS'
    }
  })

  const [params2,setParams2] = useState({
    Sector: 'BDS',
    CondForOverview: {
      VolumeAverage: 100000,
      Sector: 'BDS'
    }
  })

  const [params3,setParams3] = useState({
    Sector: 'BDS',
    CondForOverview: {
      VolumeAverage: 100000,
      Sector: 'BDS'
    }
  })

  const {isSuccess, data} = useGetStockDataQuery()
  const {isSuccess: isSuccessSector, data: dataSector} = useGetSectorQuery()
  const {isSuccess: isSuccessYahoo, data: dataYahoo} = useGetYahooDataQuery()
  const {isSuccess: isSuccessParam, data: dataParam} = useGetParamQuery()
  const {isSuccess: isSuccessFuture, data: dataFuture} = useGetFutureDataQuery()
  const {isSuccess: isSuccessVolatility, data: dataVolatility} = useGetVolatilityQuery()
  const [DataParam, setDataParam] = useState([])
  const [DataVolatility, setDataVolatility] = useState([])
  const [DataSector, setDataSector] = useState([])
  const [DataFuture, setDataFuture] = useState([])
  const [DataVNINDEX, setDataVNINDEX] = useState([])
  const [DataYahoo, setDataYahoo] = useState([])
  const [Data, setData] = useState([])
  useEffect(() => {
    setDataParam(dataParam)
    console.log(dataParam)
  }, [isSuccessParam])
  useEffect(() => {
    setDataVolatility(dataVolatility)
    console.log(dataVolatility)
  }, [isSuccessVolatility])
  useEffect(() => {
    setDataSector(dataSector)
    console.log(dataSector)
  }, [isSuccessSector])
  useEffect(() => {
    setDataFuture(dataFuture)
  }, [isSuccessFuture])
  useEffect(() => {
    setDataYahoo(dataYahoo)
  }, [isSuccessYahoo])
  useEffect(() => {
    setData(data)
    setDataVNINDEX(data?.filter(x => x.Ticker === "VNINDEX"))
    console.log(data)
  }, [isSuccess])
    useTitle("Finlens DashBoard")
  const [component, setComponent] = useState('Overview')
  function BubbleChartParam(data_){
    
    var STOCK_FAIR = data_?.filter(x => {
      return Number(x.AllAspect)>-2 && Number(x.SB3MK0A)>-2
    })?.length / data_?.length
    
    var STOCK_STRONGPARTIAL = data_?.filter(x => {
      return Number(x.AllAspect)>0 || Number(x.SB3MK0A)>0
    })?.length / data_?.length

    var STOCK_STRONGFULL = data_?.filter(x => {
      return Number(x.AllAspect)>0 && Number(x.SB3MK0A)>0
    })?.length / data_?.length
    function calculateDensityScore(array, param) {
      const benchmark = Number(data?.filter(x => x.Ticker === "VNINDEX")?.[0]?.[param])
      const totalCount = array?.length;
  
      if (totalCount === 0) return 0; // Handle empty array
  
      // Calculate the sum of absolute distances from the benchmark
      const totalDistance = array?.reduce((acc, num) => acc + Math.abs(num - benchmark), 0);
  
      // Calculate average distance from the benchmark
      const averageDistance = totalDistance / totalCount;
  
      // Normalize the score to a range of -1 to +1
      // Assuming maxDistance is the furthest distance from the benchmark you want to consider
      var maxDistance = array?Math.max(...(array?.map(num => Math.abs(num - benchmark)))):null;
  
      // Calculate the density score
      const densityScore = 1 - (averageDistance / maxDistance);

      return densityScore*10;
    }

    var array1 = Data?.map(x => Number(x.SB3MK0A))
    var array1_Q1 = array1?.[Math.round((array1?.length+1)/4)]
    var array1_Q2 = array1?.[Math.round((array1?.length+1)/2)]
    var array1_Q3 = array1?.[Math.round(3*(array1?.length+1)/4)]
    var array1_IQR = array1_Q3-array1_Q1;
    var array1_Outliner = Data?.filter(x => {
      return x.SB3MK0A >= (array1_Q3 + array1_IQR*1.5) || x.SB3MK0A <= (array1_Q1 - array1_IQR*1.5)
    })



    
    

    return {
      Benchmark: ((STOCK_FAIR+STOCK_STRONGPARTIAL*0.7+STOCK_STRONGFULL*0.3)/2*10)?.toFixed(2),
      Density: ((calculateDensityScore(Data?.map(x => Number(x.SB3MK0A)),'SB3MK0A')+calculateDensityScore(Data?.map(x => Number(x.AllAspect)), 'AllAspect'))/2)?.toFixed(2),
      Outliner: {
        length: array1_Outliner?.length,
        pct: array1_Outliner?.length / array1?.length
      }
     
    }
  }
  



  const DASHBOARD_OVERVIEW = () => {
    return (isSuccess)?<React.Fragment>
    
    <div style={{paddingTop: 20, width: '90%', margin: 'auto', maxWidth: 1500, minWidth: 1000}}>
      <Typography variant="h5" fontWeight="bold" paddingBottom = {0.3} color={'#00BFFF'} sx={{textDecorationLine: 'underline'}}>Định Lượng Tổng Quan Cổ Phiếu</Typography>
      
      
      <BubbleChart dataSector={DataSector} setParams = {setParams2} data = {Data} id={'BubbleChart'} height={700} />
      
    </div>

    

    </React.Fragment>:<PulseLoader color={"#FFF"} />
  }

  const DASHBOARD_SECTOR = () => {

    function SectorNameVN(x) {
      var SectorName="";
                if(x==="B_") {SectorName="BlueChip*"}
                else if(x==="M_") {SectorName="Midcap*"}
                else if(x==="P_") {SectorName="Penny*"}
                else if(x==="BAN LE") {SectorName="Bán Lẻ"}
                else if(x==="BAO HIEM") {SectorName="Bảo Hiểm"}
                else if(x==="BDS") {SectorName="BDS"}
                else if(x==="BKCN") {SectorName="BDS-KCN"}
                else if(x==="CAO SU") {SectorName="Cao Su"}
                else if(x==="CHUNG KHOAN") {SectorName="Chứng Khoán"}
                else if(x==="CONG NGHE") {SectorName="Công Nghệ"}
                else if(x==="DAU KHI") {SectorName="Dầu Khí"}
                else if(x==="DET MAY") {SectorName="Dệt May"}
                else if(x==="DTC") {SectorName="Đầu Tư Công"}
                else if(x==="DUOC PHAM") {SectorName="Dược Phẩm"}
                else if(x==="DIEN") {SectorName="Điện"}
                else if(x==="DUONG") {SectorName="Đường"}
                else if(x==="Future") {SectorName="FUTURE**"}
                else if(x==="Index") {SectorName="INDEX**"}
                else if(x==="NGAN HANG") {SectorName="Ngân Hàng"}
                else if(x==="NHUA") {SectorName="Nhựa"}
                else if(x==="NONG SAN") {SectorName="Nông Sản"}
                else if(x==="PHAN BON") {SectorName="Phân Bón"}
                else if(x==="THAN") {SectorName="Than"}
                else if(x==="THEP") {SectorName="Thép"}
                else if(x==="THUC PHAM") {SectorName="Thực Phẩm"}
                else if(x==="THUY SAN") {SectorName="Thủy Sản"}
                else if(x==="VAN TAI BIEN") {SectorName="Vận Tải Biển"}
                else if(x==="VLXD") {SectorName="VLXD"}
                else if(x==="XAY DUNG") {SectorName="Xây Dựng"}
                else if(x==="VIN") {SectorName="NHÓM VINGROUP"}
                else if(x==="MASAN") {SectorName="NHÓM MASAN"}
                else if(x==="FPT") {SectorName="NHÓM FPT"}
                else if(x==="GELEX") {SectorName="NHÓM GELEX"}
                else if(x==="HAGL") {SectorName="NHÓM HAGL"}
                else if(x==="SH?") {SectorName="NHÓM SH"}
                else if(x==="VIETTEL") {SectorName="NHÓM VIETTEL"}
                else if(x==="APEC!") {SectorName="NHÓM APEC"}
                else if(x==="HANGKHONG") {SectorName="NHÓM HANGKHONG"}
      return SectorName
    }
    function ColByNum(x){
      if(x>=7) {
        return <div
          style={{
            color: 'forestgreen',
            fontWeight: 'bold'

          }}
        > 
          {x} - CAO
        </div>
      }

      if(x>=5) {
        return <div
          style={{
            color: 'goldenrod',
            fontWeight: 'bold'

          }}
        > 
          {x} - KHÁ
        </div>
      }

      
      if(x>=3) {
        return <div
          style={{
            color: 'darkorange',
            fontWeight: 'bold'

          }}
        > 
          {x} - KÉM
        </div>
      }

      
      else {
        return <div
          style={{
            color: 'red',
            fontWeight: 'bold'

          }}
        > 
          {x} - THẤP
        </div>
      }
        
    }

    const handleChange = (event) => {
      setParams(prevState => 
        ({...prevState, Sector: event.target.value})
      );
    };

    var STOCK_FAIR = Data?.filter(x => {
      return x.Sector?.includes(params.Sector) && Number(x.AllAspect)>-2 && Number(x.SB3MK0A)>-2
    })?.length / Data?.filter(x => {
      return x.Sector?.includes(params.Sector)})?.length
    
    var STOCK_STRONGPARTIAL = Data?.filter(x => {
      return x.Sector?.includes(params.Sector)})?.filter(x => {
      return Number(x.AllAspect)>0 || Number(x.SB3MK0A)>0
    })?.length /  Data?.filter(x => {
      return x.Sector?.includes(params.Sector)})?.length

    var STOCK_STRONGFULL = Data?.filter(x => {
      return x.Sector?.includes(params.Sector) && Number(x.AllAspect)>0 && Number(x.SB3MK0A)>0
    })?.length / Data?.filter(x => {
      return x.Sector?.includes(params.Sector)})?.length
    return (DataSector?.length>0)?<React.Fragment>
    

    <div style={{minWidth: 1000,paddingTop: 20, width: '90%', margin: 'auto', maxWidth: 1500}}>
        <Typography variant="h4" fontWeight="bold" color={'#00BFFF'} textDecoration="underline">Phân Tích Ngành</Typography>
        <div style={{
          backgroundColor: '#151515',
          padding: 10,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}>
          <div style={{
            textAlign: 'center'
          }}>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
              color = {'beige'}
            >
              NGÀNH
            </Typography>
            <div style={{

            }}>
                    <NativeSelect
                      defaultValue={params.Sector}
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Sector"
                      onChange={handleChange}
                      sx={{
                        backgroundColor: '#202020',
                        boxShadow: '2px black',
                        color: '#808080',
                        width: '100%',
                        minWidth: 250,
                        textAlign: 'center'
                      }}
                    ><option style={{backgroundColor: '#202020', textAlign: 'center', color: 'darkred', fontWeight: 'bold'}} value={'Index'}>INDEX</option>
                    <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'darkred', fontWeight: 'bold'}} value={'Future'}>FUTURE</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'darkred', fontWeight: 'bold'}} value={'B_'}>DÒNG - BlueChip</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'darkred', fontWeight: 'bold'}} value={'M_'}>DÒNG - MidCap</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'darkred', fontWeight: 'bold'}} value={'P_'}>DÒNG - Penny</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'goldenrod', fontWeight: 'bold'}} value={'BDS'}>NGÀNH BIG 3 - Bất Động Sản</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'goldenrod', fontWeight: 'bold'}} value={'NGAN HANG'}>NGÀNH BIG 3 - Ngân Hàng </option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'goldenrod', fontWeight: 'bold'}} value={'CHUNG KHOAN'}>NGÀNH BIG 3 - Chứng Khoán</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'BAN LE'}>Bán Lẻ</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'XAY DUNG'}>Xây Dựng</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'THEP'}>Thép (HH)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'DAU KHI'}>Dầu Khí (HH)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'DET MAY'}>Dệt May (HH)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'DIEN'}>Điện</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'THUC PHAM'}>Thực Phẩm</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'THUY SAN'}>Thủy Sản (TP)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'NONG SAN'}>Nông Sản (TP)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'DTC'}>Đầu Tư Công</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'PHAN BON'}>Phân Bón (HC)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'CAO SU'}>Cao Su (HC)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'NHUA'}>Nhựa (HC)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'THAN'}>Than (HC)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'VAN TAI BIEN'}>Vận Tải</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'VLXD'}>Vật Liệu Xây Dựng</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'DUOC PHAM'}>Dược Phẩm</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'BKCN'}>Bất Động Sản - Khu Công Nghiệp</option>
                    </NativeSelect></div>
          </div>

          
          <div style={{
            textAlign: 'center',
            lineHeight: 1
          }}>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
              color = {'beige'}
            >
              BENCHMARK(10)
            </Typography>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
            >
              {ColByNum(((STOCK_FAIR+STOCK_STRONGPARTIAL*0.7+STOCK_STRONGFULL*0.3)/2*10)?.toFixed(2))}
            </Typography>
          </div>

          
          <div style={{
            textAlign: 'center',
            lineHeight: 1
          }}>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
              color = {'beige'}
            >
              RANKING(10)
            </Typography>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
            >
              {ColByNum(SectorBenchmark(dataSector, params.Sector).RANKING?.toFixed(2))}
            </Typography>
          </div>

          
          <div style={{
            textAlign: 'center',
            lineHeight: 1
          }}>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
              color = {'beige'}
            >
              UNIFORMITY(10)
            </Typography>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
            >
              {ColByNum(SectorBenchmark(dataSector, params.Sector).UNIFORMITY?.toFixed(2))}
            </Typography>
          </div>

          
          <div style={{
            textAlign: 'center',
            lineHeight: 1
          }}>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
              color = {'beige'}
            >
              STABILITY(10)
            </Typography>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
            >
              {ColByNum(SectorBenchmark(dataSector, params.Sector).STABILITY?.toFixed(2))}
            </Typography>
          </div>

          
          <div style={{
            textAlign: 'center',
            lineHeight: 1
          }}>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
              color = {'beige'}
            >
              RANGE(10)
            </Typography>
            <Typography
              fontWeight = {'bold'}
              variant = {'h6'}
            >
              {ColByNum(SectorBenchmark(dataSector, params.Sector).RANGE?.toFixed(2))}
            </Typography>
          </div>
        
          </div>
          
          <div style={{display: 'flex', minWidth: 1000,
          backgroundColor: '#151515',
          paddingLeft: '1.5%',
          paddingRight: '1.5%',
          paddingBottom: '1.5%',
          borderTop: '1px solid white',}}>
            
            <span style={{width: '50%', marginTop: 5}}>
            <Typography
              textAlign={'center'}
              fontStyle={'italic'}
              color={'#808080'}
            >
              Tương Đối Nhà Đầu Tư Cá Nhân & Tạo Lập Ngành
            </Typography>
              <ChartScoreMakerSector id={'ChartScoreMakerSector'} data={DataSector?.filter(x => {return x.Sector === params.Sector})}/>
            </span>
            
            <span style={{width: '50%', marginTop: 5}}>
              <Typography
                textAlign={'center'}
                fontStyle={'italic'}
                color={'#808080'}
              >
                TOP 5 Cổ Phiếu Theo Các Tiêu Chí
              </Typography>
              <TopByCriteria data={DataSector?.filter(x => {return x.Sector === params.Sector})} id={'TopByCriteria'} sector={params.Sector}/>
            </span>

            
          </div>

              
          {/* <div style={{paddingTop: 20, width: '100%', margin: 'auto', height: 300}}>
            <RangeBySector id={'RangeBySector'} data={data?.filter(x => {
              return x.Sector?.includes(params.Sector)
            })?.[0]?.LastDaysData?.BienDoCoPhieu} />
          </div> */}

          <div>
            
            {/* <BubbleChartForEachSector dataSector={DataSector} setParams = {setParams} data = {Data} id={'BubbleChart_'} height={400} currentSector = {params.Sector}/> */}
      
          </div>
        
        
        <SectorBoard data={DataSector} setParams = {setParams}/>
        
    </div>

    </React.Fragment>:<PulseLoader color={"#FFF"} />
  }

  

  const DASHBOARD_CORRELATION = () => {

    function gK_V(key){
      return ((DataVolatility?.[0]?.[key] / Data?.length)*100)?.toFixed(2)
    }



    function IconWithText(iconComp, text, colorText){
      return <div style={{
        display: 'flex',
        flexDirection: 'row'
      }}>
        <span>
          {iconComp}
        </span> &nbsp;
        <span style = {{color: colorText, fontSize: 14}}>
          {text}
        </span>
      </div>
    }

    function VolatilityComment(){
      var comment1, comment2, comment3, comment4
      var benchmark1 = 0, benchmark2 = 0, benchmark3 = 0, benchmark4 = 0, benchmark = 5
      if(gK_V('GV_0')>gK_V('GV_1')){
        if(gK_V('GV_1')>gK_V('GV_2')){
          if(gK_V('GV_2')>gK_V('GV_3')){
            benchmark1 = benchmark1 - 6
            comment1 = IconWithText(<GoXCircle color = {'red'}/>, `Số mã Giảm Vừa Tăng Liên Tiếp 3 Ngày (${(gK_V('GV_2')-gK_V('GV_3'))?.toFixed(2)}%)`, 'orangered')
          }
          else {
            benchmark1 = benchmark1 - 4
            comment1 = IconWithText(<GoXCircle color = {'orange'}/>, `Số mã Giảm Vừa Tăng Liên Tiếp 2 Ngày (${(gK_V('GV_1')-gK_V('GV_2'))?.toFixed(2)}%)`, 'darkorange')
          }
        }

        
        else {
          benchmark1 = benchmark1 - 2
          comment1 = IconWithText(<IoIosWarning color = {'gold'}/>, `Số mã Giảm Vừa Tăng Phiên Hôm Nay (${(gK_V('GV_0')-gK_V('GV_1'))?.toFixed(2)}%)`, 'goldenrod')
        }
      }

      else{
        if(gK_V('GV_1')<=gK_V('GV_2')){
          benchmark1 = benchmark1 + 4
          if(gK_V('GV_2')<=gK_V('GV_3')){
            benchmark1 = benchmark1 + 6
            comment1 = IconWithText(<FaCheckCircle color = {'limegreen'}/>, `Số mã Giảm Vừa Giảm Liên Tiếp 3 Ngày (${(gK_V('GV_2')-gK_V('GV_3'))?.toFixed(2)}%)`, 'limegreen')
          }
        }
        else{
          benchmark1 = benchmark1 + 2
        }
      }

      if(gK_V('TV_0')>gK_V('TV_1')){
        if(gK_V('TV_1')>gK_V('TV_2')){
          if(gK_V('TV_2')>gK_V('TV_3')){
            benchmark2 = benchmark2 + 6
            comment2 = IconWithText(<FaCheckCircle color = {'limegreen'}/>, `Số mã Tăng Vừa Tăng Liên Tiếp 3 Ngày (${(gK_V('TV_2')-gK_V('TV_3'))?.toFixed(2)}%)`, 'royalblue')
          }
          else {
            benchmark2 = benchmark2 + 4
            comment2 = IconWithText(<FaCheckCircle color = {'limegreen'}/>, `Số mã Tăng Vừa Tăng Liên Tiếp 2 Ngày (${(gK_V('TV_1')-gK_V('TV_2'))?.toFixed(2)}%)`, 'limegreen')
          }
        }
        else {
          benchmark2 = benchmark2 + 2
        }
      }

      else{
        if(gK_V('TV_1')<=gK_V('TV_2')){
          if(gK_V('TV_2')<=gK_V('TV_3')){
            benchmark2 = benchmark2 -6
            comment2 = IconWithText(<GoXCircle color = {'red'}/>, `Số mã Tăng Vừa Giảm Liên Tiếp 3 Ngày (${(gK_V('TV_2')-gK_V('TV_3'))?.toFixed(2)}%)`, 'orangered')
          }
          else {
            benchmark2 = benchmark2 -4
            comment2 = IconWithText(<GoXCircle color = {'orange'}/>, `Số mã Tăng Vừa Giảm Liên Tiếp 2 Ngày (${(gK_V('TV_1')-gK_V('TV_2'))?.toFixed(2)}%)`, 'darkorange')
          }
        }
        else{
          benchmark2 = benchmark2 -2
        }
      }

      var smoothaverage = (Math.abs(gK_V('GV_0')-gK_V('GV_1')) + Math.abs(gK_V('GV_1')-gK_V('GV_2')) + Math.abs(gK_V('GV_2')-gK_V('GV_3')) + Math.abs(gK_V('GV_3')-gK_V('GV_4')) + Math.abs(gK_V('GV_4')-gK_V('GV_5')))/5

      if(smoothaverage > 5){
        benchmark3 = benchmark3 - 3
        comment3 = IconWithText(<GoXCircle color = {'red'}/>, `Biên Độ Giảm Biến Động Mạnh (${smoothaverage?.toFixed(2)}%)`, 'orangered')
      }

      else{
        benchmark3 = benchmark3 + 3
        comment3 = IconWithText(<FaCheckCircle color = {'limegreen'}/>, `Biên Độ Giảm Giữ Ổn Định (${smoothaverage?.toFixed(2)}% / Phiên)`, 'limegreen')
      }

      if(gK_V('TC_0')>42){
        benchmark4 = benchmark4 - 2
        comment4 = IconWithText(<IoIosWarning color = {'gold'}/>, `Số Mã Ở Biên Độ Tham Chiếu Cao >42% (${Number(gK_V('TC_0'))?.toFixed(2)}%)`, 'goldenrod')

      }

      
      else if(gK_V('TC_0')<33){
        benchmark4 = benchmark4 - 2
        comment4 = IconWithText(<IoIosWarning color = {'gold'}/>, `Số Mã Ở Biên Độ Tham Chiếu Thấp <33% (${Number(gK_V('TC_0'))?.toFixed(2)}%)`, 'goldenrod')

      }

      else {
        benchmark4 = benchmark4 + 2
        comment4 = IconWithText(<IoIosWarning color = {'limegreen'}/>, `Số Mã Ở Biên Độ Tham Chiếu Ổn Định (${Number(gK_V('TC_0'))?.toFixed(2)}%)`, 'limegreen')
      }

      function colorbenchmark(x){
        if(x>=8) return 'limegreen'
        if(x>=5) return 'gold'
        else return 'red'
      }


      

   


      return <div style={{
        margin: 'auto',
        width: '100%',
        height: '100%',
        lineHeight: '100%',
        paddingTop: '30%',
        textAlign: 'center'}}>
        {
          !comment1?.length>0 || !comment2?.length>0?
            <div style={{fontSize: 15}}>
              <div style={{
                lineHeight: '100%',
              }}>
                <div style={{color: 'beige', fontWeight: 'bold', textAlign: 'center', fontSize: 15}}>
                  BM(10)
                </div> &nbsp;
                <div style={{color: colorbenchmark(benchmark1 + benchmark2 + benchmark3 + benchmark4+5), fontWeight: 'bold', fontSize: 30}}>
                  {benchmark1 + benchmark2 + benchmark3 + benchmark4+5>10?'10.00':(benchmark1 + benchmark2 + benchmark3 + benchmark4+5)<0?'0.00':`${(benchmark1 + benchmark2 + benchmark3 + benchmark4+5)}.00`}
                </div>              
              </div>
              <div style={{
                width: '100%',
                borderBottom: '1px solid white',
                marginTop: '20%'
                
              }}>

              </div>
              {comment1}
              <br/>
              {comment2}
              <br/>
              {comment3}
              <br/>
              {comment4}
            </div>:
            <div style={{
              color: 'white',
              fontSize: 20
            }}>
              No Comment Created!
            </div>
        }
      </div>

    }

    
    return (DataVolatility?.length>0&&DataParam?.length>0)?<div style={{maxWidth: 1500, minWidth: 1000, width: '90%', margin: 'auto'}}>
      <div style={{paddingTop: 20, width: '100%', margin: 'auto'}}>
        <Typography variant="h5" fontWeight="bold" paddingBottom = {0.3} color={'#00BFFF'} textDecoration="underline">Biên Độ Cổ Phiếu</Typography>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{width: '42.5%', borderRight: '2px solid white'}}>
            <BienDoCPDaily data={DataVolatility} id={'BienDoCPDaily'}/>
          </div>
          
          <div style={{width: '42.5%', borderLeft: '2px solid white'}}>
            <BienDoCPIntraday data={DataParam} id={'BienDoCPIntraday'}/>
          </div>
          <div style={{
            width: '15%'
          }}>
            <div style={{
              backgroundColor: '#202020',
              minWidth: 150,
              height: '100%',
              lineHeight: '100%',
              padding: '10%',
              margin: 'auto',
              borderLeft: '1px dashed darkgray'
            }}>
                <span>
                  {
                    VolatilityComment()
                  }
                </span>

            </div>
          </div>
        </div>

        <div style={{
          width: '42.5%'
        }}>
          <BienDoCPIntraday5Day data={DataParam} id={'BienDoCPIntraday5Day'}/>
        </div>
        
        
      </div>
        {/* CommentBIENDOCP DATAVOLATILITYGMTMGNTNGVTVTC_ DATAPARAMCN_LN */}
        

      <div style={{paddingTop: 20, width: '100%', margin: 'auto'}}>
        <Typography variant="h5" fontWeight="bold" paddingBottom = {0.3} color={'#00BFFF'} textDecoration="underline">Tương Quan</Typography>
        <div style={{display: 'flex'}}>
          <div style={{width: '50%'}}>
            
            {/* <StockChartVNINDEX chartControlID={'rgjrig'} id={'fbifnbfibn'} data={data?.filter(x => {return x.Ticker === "VNINDEX"})}/> */}
            <InvestorType data={DataParam} id = {'InvestorType'} />
          </div>
          <div style={{width: '50%', borderLeft: '2px solid white'}}>
            <div>
              <Typography paddingTop={0.8} paddingLeft={4} fontWeight={600} backgroundColor={'#303030'} sx={{color: '#DCDCDC'}} variant={'body1'}>
                Liên Tầng Thị Trường 5 Cấp
              </Typography>
              <LienTangCompare data={DataSector} id={'LienTangCompare'}/>
            </div>
            <div style={{borderTop: '2px solid white', backgroundColor: '#202020'}}>
              <Typography paddingTop={0.8} paddingLeft={4} fontWeight={600} backgroundColor={'#303030'}  sx={{color: '#DCDCDC'}} variant={'body1'}>
                Tương Đối Thị Trường Phái Sinh và Cơ Sở
              </Typography>
              <T1_FutureCorrelation data={DataFuture} id={'FutureCorrel'}/>
            </div>
          </div>
        </div>
        
      </div>
    </div>:<PulseLoader color={"#FFF"} />
  }

  const DASHBOARD_WORLDINDEX = () => {
    return (DataYahoo?.length>0)?<>
    
       <div style={{paddingTop: 20, width: '90%', margin: 'auto'}}>
        
        <Typography variant="h4" fontWeight="bold" paddingBottom = {0.3} color={'#00BFFF'} textDecoration="underline">Thị Trường Thế Giới</Typography>
        <WorldIndice/>
      </div>
    </>:<PulseLoader color={"#FFF"} />
  }
  return <>
    <div style={{
      width: '95%', 
      margin: 'auto',
      display: 'flex',
      backgroundColor: '#87CEFA',
      minWidth: 1000,
      maxWidth: 1500
    }}>
      <Button onClick={() => {
        setComponent('Overview')
      }} variant="outlined" sx={{width: '100%', fontWeight: 'bold'}}>
        Overview
      </Button>
      <Button onClick={() => {
        setComponent('Sector')
      }} variant="outlined" sx={{width: '100%', fontWeight: 'bold'}}>
        Sector
      </Button>
      <Button onClick={() => {
        setComponent('Range')
      }} variant="outlined" sx={{width: '100%', fontWeight: 'bold'}}>
        Range & Correlation
      </Button>
      <Button onClick={() => {
        setComponent('World')
      }} variant="outlined" sx={{width: '100%', fontWeight: 'bold'}}>
        World Indice & Index
      </Button>
    </div>
    {
      component==="Overview"?
        <DASHBOARD_OVERVIEW/>:
        component==='Sector'?
          <DASHBOARD_SECTOR/>:
          component==='Range'?
            <DASHBOARD_CORRELATION/>:
            component==='World'?
              <DASHBOARD_WORLDINDEX/>:
                <></>
    }
    </>
  
}

export default Dashboard