import { Box, Checkbox, FormControlLabel, FormGroup, Slider, ToggleButton, ToggleButtonGroup, Typography, Input } from "@mui/material";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useEffect, useState } from "react";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { yellow, green, blue, purple, grey} from '@mui/material/colors';

export const CKTGFilter = (props) => {
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const handleChange1 = (event) => {
      setChecked1(!checked1);
  };
  const handleChange2 = (event) => {
      setChecked2(!checked2);
  };
  const handleChange3 = (event) => {
      setChecked3(!checked3);
  };
  const handleChange4 = (event) => {
      setChecked4(!checked4);
  };
  useEffect(() => {
      if(checked1===true)
      props.setSearchAll(prevState => 
          ({...prevState, TrendCycle: [0,10]})
      )
      if(checked1===false)
      props.setSearchAll(prevState => 
          ({...prevState, TrendCycle: [-200,200]})
      )
  },[checked1])
  useEffect(() => {
      if(checked2===true)
      props.setSearchAll(prevState => 
          ({...prevState, TrendCycle: [-500,500]})
      )
      if(checked2===false)
      props.setSearchAll(prevState => 
          ({...prevState, TrendCycle: [-200,200]})
      )
  },[checked2])
  useEffect(() => {
      if(checked3===false)
      props.setSearchAll(prevState => 
          ({...prevState, TrendCycle: [-500,500]})
      )
      if(checked3===true)
      props.setSearchAll(prevState => 
          ({...prevState, TrendCycle: [11,20]})
      )
  },[checked3])
  useEffect(() => {
      if(checked4===false)
      props.setSearchAll(prevState => 
          ({...prevState, TrendCycle: [-500,500]})
      )
      if(checked4===true)
      props.setSearchAll(prevState => 
          ({...prevState, TrendCycle: [31,500]})
      )
  },[checked4])
  function valuetext(value) {
      return `${value}°C`;
  }
  const marks = [
  {
      value: 0,
      label: '0',
  },
  {
      value: -50,
      label: '-50',
  },
  {
      value: 20,
      label: '20',
  },
  {
      value: 50,
      label: '50',
  },
  {
      value: 100,
      label: '100',
  },
  ];

  function colorbydata(v){
  if(Number(v)>10) return 'limegreen'
  if(Number(v)<=-10) return 'crimson'
  else return 'gold'
  }
      
  return <Box sx={{ 
          width: '100%' }}
      >
      <div style={{display: 'flex'}}>
          <div style={{display: 'flex'}}>
          <Typography variant={'body1'} fontWeight={'bold'} paddingTop={'5px'} color={'dodgerblue'}>
              Trend 
          </Typography> &nbsp;
          <Typography variant={'body2'} fontWeight={600} paddingTop={'7px'} color={'lightgrey'}>
              ({props?.cktg?.[0]}{'=>'}{props?.cktg?.[1]})
          </Typography>

          <FormGroup sx = {{marginLeft: '10px', paddingTop: '3px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              
              <FormControlLabel 
                  control={<Checkbox onChange={handleChange2} 
                  sx={{
                      color: grey[300],
                      '&.Mui-checked': {
                          color: grey[400],
                      },
                      zoom: '60%'
                  }} />} label="ALL"
               />
              <FormControlLabel 
                  control={<Checkbox onChange={handleChange1} 
                  sx={{
                      color: green[800],
                      '&.Mui-checked': {
                          color: green[900],
                      },
                      zoom: '60%'
                  }} />} label="0-10**"
               />
               <FormControlLabel 
                  control={<Checkbox onChange={handleChange3} 
                  sx={{
                      color: blue[800],
                      '&.Mui-checked': {
                          color: blue[900],
                      },
                      zoom: '60%'
                  }} />} label="11-20*"
               />
          </FormGroup>

          </div>
          
      </div>
      <Slider
              aria-label="Score"
              defaultValue={[-100,200]}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              shiftStep={30}
              step={2}
              min={-100}
              max={200}
              sx={{color: '#DADADA',marginLeft: 1.5, height: '1px'}}
              onChange={(e) => {
              props.setSearchAll(prevState => 
                  ({...prevState, TrendCycle: e.target.value})
              )
              }}
              size = {'small'}
              color="secondary"
          />
      </Box>
}
export const MakerFilter = (props) => {
  function valuetext(value) {
      return `${value}°C`;
  }
  
  const [checked1, setChecked1] = useState(false);
  useEffect(() => {
    if(checked1===true)
    props.setSearchAll(prevState => 
        ({...prevState, Maker: {...prevState.Maker, Delta: 0}})
    )
    if(checked1===false)
    props.setSearchAll(prevState => 
        ({...prevState, Maker: {...prevState.Maker, Delta: -100}})
    )
  },[checked1])
  
  const [checked2, setChecked2] = useState(false);
  useEffect(() => {
    if(checked2===true)
    props.setSearchAll(prevState => 
        ({...prevState, Maker: {...prevState.Maker, Value: [0,50]}})
    )
    if(checked2===false)
    props.setSearchAll(prevState => 
        ({...prevState, Maker: {...prevState.Maker, Value: [-50,50]}})
    )
  },[checked2])
  const [checked3, setChecked3] = useState(false);
  useEffect(() => {
    if(checked3===true)
    props.setSearchAll(prevState => 
        ({...prevState, Maker: {...prevState.Maker, Value: [5,50]}})
    )
    if(checked3===false)
    props.setSearchAll(prevState => 
        ({...prevState, Maker: {...prevState.Maker, Value: [-50,50]}})
    )
  },[checked3])


  return <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
  <div style={{display: 'flex'}}>
  <Typography variant={'body1'} fontWeight={'bold'} paddingTop={'5px'} color={'dodgerblue'}>
      Maker 
  </Typography> &nbsp;
  <Typography variant={'body2'} fontWeight={600} paddingTop={'7px'} color={'lightgrey'}>
      ({props?.maker?.Value?.[0]}{'=>'}{props?.maker?.Value?.[1]})
  </Typography>

  &nbsp;<></>&nbsp;<></>&nbsp;
  <FormGroup sx = {{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '5px'}}>

      <FormControlLabel
        control={<Checkbox 
        onClick={() => setChecked2(!checked2)}
        sx={{
          color: 'limegreen',
          '&.Mui-checked': {
            color: 'forestgreen',
          },
          zoom: '60%'
        }} />} label=">0"
      />

      <FormControlLabel
        control={<Checkbox 
        onClick={() => setChecked1(!checked1)}
        sx={{
          color: 'dodgerblue',
          '&.Mui-checked': {
            color: 'dodgerblue',
          },
          zoom: '60%'
        }} />} label="D>0"
      />
      
    
    </FormGroup>
  
  </div>
  
  <div >
  
  <Box sx={{ 
          width: '100%',
          paddingLeft: '15px',
          paddingRight: '15px',
          height: '40%'
           }}
      >
      
  <div style={{}}>
      <Slider
          aria-label="Maker"
          className="MuiSlider"
          defaultValue={[-50,50]}
          getAriaValueText={valuetext}
          valueLabelDisplay="auto"
          shiftStep={30}
          step={1}
          min={-50}
          max={50}
          size = {'small'}
          
      color="secondary"
          sx={{
              height: '1px',
          }}
          onChange={(e) => {
          props.setSearchAll(prevState => 
              ({...prevState, Maker: {...prevState.Maker, Value: e.target.value}})
          )
          }}
      />
      
      
      </div>

      
      
      </Box>
</div></div>
}

export const ScoreFilter = (props) => {
  const [checked1, setChecked1] = useState(false);
    useEffect(() => {
      if(checked1===true)
      props.setSearchAll(prevState => 
          ({...prevState, Score: {...prevState.Score, Delta: 0}})
      )
      if(checked1===false)
      props.setSearchAll(prevState => 
          ({...prevState, Score: {...prevState.Score, Delta: -100}})
      )
    },[checked1])
    
    const [checked2, setChecked2] = useState(false);
    useEffect(() => {
      if(checked2===true)
      props.setSearchAll(prevState => 
          ({...prevState, Score: {...prevState.Score, Value: [0,50]}})
      )
      if(checked2===false)
      props.setSearchAll(prevState => 
          ({...prevState, Score: {...prevState.Score, Value: [-50,50]}})
      )
    },[checked2])

    
    const [checked3, setChecked3] = useState(false);
    useEffect(() => {
      if(checked3===true)
      props.setSearchAll(prevState => 
          ({...prevState, Score: {...prevState.Score, Value: [5,50]}})
      )
      if(checked3===false)
      props.setSearchAll(prevState => 
          ({...prevState, Score: {...prevState.Score, Value: [-50,50]}})
      )
    },[checked3])
    function valuetext(value) {
        return `${value}°C`;
    }

    return <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
    <div style={{display: 'flex'}}>
    <Typography variant={'body1'} fontWeight={'bold'} paddingTop={'5px'} color={'dodgerblue'}>
        Score 
    </Typography> &nbsp;
    <Typography variant={'body2'} fontWeight={600} paddingTop={'7px'} color={'lightgrey'}>
        ({props?.score?.Value?.[0]}{'=>'}{props?.score?.Value?.[1]})
    </Typography>
    &nbsp;<></>&nbsp;<></>&nbsp;
    <FormGroup sx = {{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '5px'}}>
      
      <FormControlLabel
          control={<Checkbox 
          onClick={() => setChecked2(!checked2)}
          sx={{
            color: 'limegreen',
            '&.Mui-checked': {
              color: 'forestgreen',
            },
            zoom: '60%'
          }} />} label=">0"
      />
      <FormControlLabel
        control={<Checkbox 
        onClick={() => setChecked1(!checked1)}
        sx={{
          color: 'dodgerblue',
          '&.Mui-checked': {
            color: 'dodgerblue',
          },
          zoom: '60%'
        }} />} label="D>0"
      />
    </FormGroup>

    
    </div>
    
    <div >
    
    <Box sx={{ 
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '15px',
            height: '30%'
             }}
        >
        
    <div style={{}}>
        <Slider
            aria-label="Score"
            className="MuiSlider-colorSuccess"
            defaultValue={[-50,50]}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            shiftStep={30}
            step={1}
            min={-50}
            max={50}
            size = {'small'}
            
        color="secondary"
            sx={{
                height: '1px',
            }}
            onChange={(e) => {
            props.setSearchAll(prevState => 
                ({...prevState, Score: {...prevState.Score, Value: e.target.value}})
            )
            }}
        />
        
        
        </div>

        
        
        </Box>
</div></div>
}

export const VolumeSearch = (props) => {

  return <>
      <div style = {{marginTop: 10}}>   
          <Typography variant="body1" sx = {{fontWeight: 'bold', color: 'dodgerblue'}}>
              Average Volume {'>='} {props.volume}
          </Typography>
      <div>
              <input
                  placeholder='  > Volume (Default 100,000)'
                  className = "VolumeFilter"
                  style= {{fontSize: 18, height: 50, width: '100%', color: 'white', backgroundColor: 'rgba(0,0,0,.85)', border: '2px solid dodgerblue', borderRadius: 3, padding: 5}}
                  onChange={(e) => {
                      props.setSearchAll(prevState => 
                          ({...prevState, Volume: e.target.value})
                  )
                  }}
              />
       </div>
          </div>
  </> 
}

export const RangeSelect = (props) => {

  const [value, setValue] = useState('ALL')
  
  const handleChange = (event) => {
    props.setSearchAll(prevState => ({
      ...prevState,
      Range: event.target.value
    }))
    setValue(event.target.value)
  };


  return <Box sx={{ maxWidth: 200, marginTop: '8px'  }}>
      <FormControl fullWidth>
        <Typography
          sx={{
            color: 'dodgerblue',
            fontWeight: 'bold'
          }}
          variant={'body1'}
        >
          Range 
        </Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Range"
          onChange={handleChange}
          variant = {'standard'}
          sx={{
            border: '3px solid dodgerblue',
            color: 'white',
            backgroundColor: '#303030',
            padding: '5px'
          }}
        >
          <MenuItem value={'ALL'}>{'ALL'}</MenuItem>
          <MenuItem value={'A'}>{'A5 (0->5%)'}</MenuItem>
          <MenuItem value={'B5'}>{'B5 (5->10%)'}</MenuItem>
          <MenuItem value={'C5'}>{'C5 (10->20%)'}</MenuItem>
          <MenuItem value={'D5'}>{'D5 (20->30%)'}</MenuItem>
          <MenuItem value={'E5'}>{'E5 (30->50%)'}</MenuItem>
          <MenuItem value={'X5'}>{'X5 (<0%)'}</MenuItem>
        </Select>
      </FormControl>
    </Box>
}

export const Status5DSelect = (props) => {
  const [value, setValue] = useState(5);


  return <div style={{marginTop: 10, maxWidth: 200,}}> 
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <Typography variant={'body1'} fontWeight={600} color='dodgerblue'>
        Status 
      </Typography>
      <input        
        placeholder={"Enter Days..."}
        onChange={(e) => {
          props.setSearchAll(prevState => ({
            ...prevState,
            Status: {
              ...prevState.Status,
              No: e.target.value,
            }
          }))
        }}
        style= {{fontSize: 12, marginTop: 3, marginLeft: '6px', width: '100%', color: 'white', backgroundColor: 'rgba(0,0,0,.85)', border: '2px solid dodgerblue', borderRadius: 3, padding: 5}}
      >
          
            
      </input>
    </div>
    
    <ToggleButtonGroup
    fullWidth
      color="primary"
      value={value}
      exclusive
      onChange={(e) => setValue(e.target.value)}
      aria-label="Platform"
      sx={{
        height: 20,
        marginTop: '5px'
      }}
    ><ToggleButton onClick = {() => {
      props.setSearchAll(prevState => ({
        ...prevState,
        Status: {
          ...prevState.Status,
          Type: 'ALL',
        }
      }))
    }} value="ALL" sx = {{color: 'white', border: '2px solid dodgerblue', width: '20%', fontWeight: 'bold',fontSize: 9}}>{'ALL'}</ToggleButton>
      <ToggleButton onClick = {() => {
        props.setSearchAll(prevState => ({
          ...prevState,
          Status: {
            ...prevState.Status,
            Type: '=>GREEN',
          }
        }))
      }} value="=>G" sx = {{color: 'white', border: '2px solid dodgerblue', width: '20%', fontWeight: 'bold',fontSize: 9}}>{'=>G'}</ToggleButton>
      <ToggleButton
        onClick = {() => {
          props.setSearchAll(prevState => ({
            ...prevState,
            Status: {
              ...prevState.Status,
              Type: '=>RED',
            }
          }))
        }}
       value="=>R" sx = {{color: 'white', border: '2px solid dodgerblue', width: '20%', fontWeight: 'bold',fontSize: 9}}>{'=>R'}</ToggleButton>
      <ToggleButton
        onClick = {() => {
        props.setSearchAll(prevState => ({
          ...prevState,
          Status: {
            ...prevState.Status,
            Type: 'GREEN'
          }
        }))
      }} value="G" sx = {{color: 'white', border: '2px solid dodgerblue', width: '20%',fontSize: 9}}>
        G
      </ToggleButton>
      <ToggleButton 
      onClick = {() => {
        props.setSearchAll(prevState => ({
          ...prevState,
          Status: {
            ...prevState.Status,
            Type: 'YELLOW'            
          }
        }))
      }} value="Y" sx = {{color: 'white', border: '2px solid dodgerblue', width: '20%',fontSize: 9}}>Y</ToggleButton>
      <ToggleButton
      onClick = {() => {
        props.setSearchAll(prevState => ({
          ...prevState,
          Status: {
            ...prevState.Status,
            Type: 'RED'
            
          }
        }))
      }} value="R" sx = {{color: 'white', border: '2px solid dodgerblue', width: '20%',fontSize: 9}}>R</ToggleButton>
    </ToggleButtonGroup>
  </div>
}

export const SectorFilter = (props) => {
  const [value, setValue] = useState('ALL')
  
  const handleChange = (event) => {
    props.setSearchAll(prevState => ({
      ...prevState,
      Sector: event.target.value
    }))
    setValue(event.target.value)
  };
  return <Box sx={{ maxWidth: 200, marginTop: '8px'  }}>
  <FormControl fullWidth>
    <Typography
      sx={{
        color: 'dodgerblue',
        fontWeight: 'bold'
      }}
      variant={'body1'}
    >
      Sector 
    </Typography>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label="Sector"
      onChange={handleChange}
      variant = {'standard'}
      sx={{
        border: '3px solid dodgerblue',
        color: 'white',
        backgroundColor: '#303030',
        padding: '5px'
      }}
    >
      <MenuItem value={'ALL'}>{'ALL'}</MenuItem>
      <MenuItem value={'NGAN'}>{'Ngân Hàng'}</MenuItem>
      <MenuItem value={'CHUNG'}>{'Chứng Khoán'}</MenuItem>
      <MenuItem value={'BDS'}>{'Bất Động Sản'}</MenuItem>
      <MenuItem value={'THEP'}>{'Thép'}</MenuItem>
    </Select>
    </FormControl>
  </Box>
}