import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5stock from '@amcharts/amcharts5/stock'
import { useEffect, Component} from "react";
import * as am5percent from "@amcharts/amcharts5/percent";
import React from "react";
import {Typography} from '@mui/material'


export class T1_FutureCorrelation extends Component {

    componentDidMount(){

            
          
        let root = am5.Root.new(this.props.id);
        
        root._logo.dispose();


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
        am5themes_Dark.new(root)
        ]);

       
        
  
  

  
  fetch(`${process.env.REACT_APP_BASE_URL}/data/futuredata`)
  .then((res) => res.json())
  .then((data) => { 
    var data_ = data[0]

    // Create chart
  // https://www.amcharts.com/docs/v5/charts/xy-chart/
  let chart = root.container.children.push(am5xy.XYChart.new(root, {
    panX: true,
    panY: true,
    wheelY: "zoomXY",
    pinchZoomX:true,
    pinchZoomY:true
  }));
  
  // Create axes
  // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
    renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 100, minorGridEnabled: true }),
    min: data_?.Correlation?.Type20D?.sortedX?.[0]*0.9985,
    tooltip: am5.Tooltip.new(root, {})
  }));
  
  xAxis.ghostLabel.set("forceHidden", true);
  xAxis.children.push(am5.Label.new(root, {
    text: 'VNINDEX Chg (%)',
    textAlign: 'center',
    x: am5.p50,
    fontWeight: '500',
    fontSize: 14,
  textDecoration: "underline"
  }));
  
  
  let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
    renderer: am5xy.AxisRendererY.new(root, {}),
    min: Math.min(...data_?.Correlation?.Type20D?.sortedYbyX)*0.9985,
    max: Math.max(...data_?.Correlation?.Type20D?.sortedYbyX)*1.0015,
    tooltip: am5.Tooltip.new(root, {})
  }));
  
  yAxis.ghostLabel.set("forceHidden", true);

    xAxis.get("renderer").labels.template.setAll({
        fontSize: 14
    });

    yAxis.get("renderer").labels.template.setAll({
        fontSize: 14
    });

    yAxis.children.unshift(am5.Label.new(root, {
        text: 'Future Chg (%)',
        textAlign: 'center',
        y: am5.p50,
        rotation: -90,
        fontWeight: '500',
        fontSize: 14,
  textDecoration: "underline"
      }));

  
  // Create series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  let series0 = chart.series.push(am5xy.LineSeries.new(root, {
    calculateAggregates: true,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "ay",
    valueXField: "ax",
    tooltip: am5.Tooltip.new(root, {
      labelText: "x: {valueX} y:{valueY}"
    })
  }));
  
  
  // Add bullet
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
  series0.bullets.push(function() {
    let graphics = am5.Circle.new(root, {
      stroke: series0.get("stroke"),
      fill: series0.get("fill"),
      radius: 4,
    });
    return am5.Bullet.new(root, {
      sprite: graphics
    });
  });
  series0.set("stroke", am5.color('rgb(255,255,255)'));
  series0.set("fill", am5.color('rgb(80,80,80)'));
  
  
  // Create second series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  let series1 = chart.series.push(am5xy.LineSeries.new(root, {
    calculateAggregates: true,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "by",
    valueXField: "bx",
    tooltip: am5.Tooltip.new(root, {
      labelText: "x: {valueX} y:{valueY}"
    })
  }));
  
  // Create second series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  let series2 = chart.series.push(am5xy.LineSeries.new(root, {
    calculateAggregates: true,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "by",
    valueXField: "bx",
    tooltip: am5.Tooltip.new(root, {
      labelText: "x: {valueX} y:{valueY}"
    })
  }));
  
  
  // Create second series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  let series3 = chart.series.push(am5xy.LineSeries.new(root, {
    calculateAggregates: true,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "by",
    valueXField: "bx",
    tooltip: am5.Tooltip.new(root, {
      labelText: "x: {valueX} y:{valueY}"
    })
  }));
  
  
  series0.strokes.template.set("strokeOpacity", 0);
  series1.strokes.template.set("strokeOpacity", 0);
  series2.strokes.template.set("strokeOpacity", 0);
  series3.strokes.template.set("strokeOpacity", 0);
  
  // Add bullet
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
  series1.bullets.push(function() {
    let graphics = am5.Triangle.new(root, {
      fill: series1.get("fill"),
      width: 15,
      height: 13,
      rotation: 180
    });
    return am5.Bullet.new(root, {
      sprite: graphics
    });
  });

  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
  series2.bullets.push(function() {
    let graphics = am5.Triangle.new(root, {
      fill: series2.get("fill"),
      width: 15,
      height: 13,
      rotation: 180
    });
    return am5.Bullet.new(root, {
      sprite: graphics
    });
  });

  
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
  series3.bullets.push(function() {
    let graphics = am5.Triangle.new(root, {
      fill: series3.get("fill"),
      width: 15,
      height: 13,
      rotation: 180
    });
    return am5.Bullet.new(root, {
      sprite: graphics
    });
  });
  
  // trend series
  let trendSeries0 = chart.series.push(am5xy.LineSeries.new(root, {
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "y",
    valueXField: "x",
    stroke: series0.get("stroke")
  }));
  
  
  trendSeries0.data.setAll(data_?.Correlation?.Type20D?.Regression)
  trendSeries0.set("stroke", am5.color('rgb(204,0,0)'));
  
  let trendSeries1 = chart.series.push(am5xy.LineSeries.new(root, {
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "y",
    valueXField: "x",
    stroke: series1.get("stroke")
  }));
  
  trendSeries1.data.setAll(data_?.Correlation?.Type10D?.Regression)
  trendSeries1.set("stroke", am5.color('rgb(204,102,0)'));

  let trendSeries2 = chart.series.push(am5xy.LineSeries.new(root, {
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "y",
    valueXField: "x",
    stroke: series2.get("stroke")
  }));
  
  trendSeries2.data.setAll(data_?.Correlation?.Type5D?.Regression)
  trendSeries2.set("stroke", am5.color('rgb(204,204,0)'));

  
  let trendSeries3 = chart.series.push(am5xy.LineSeries.new(root, {
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "y",
    valueXField: "x",
    stroke: series3.get("stroke")
  }));
  
  trendSeries3.data.setAll(data_?.Correlation?.Type3D?.Regression)
  trendSeries3.set("stroke", am5.color('rgb(0,204,0)'));
  
  // Add cursor
  // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
  chart.set("cursor", am5xy.XYCursor.new(root, {
    xAxis: xAxis,
    yAxis: yAxis,
    snapToSeries: [series0, series1]
  }));
  

        
    series0.data.setAll(data_?.Correlation?.Type20D?.Position);
    series1.data.setAll(data_?.Correlation?.Type10D?.Position);
    series2.data.setAll(data_?.Correlation?.Type5D?.Position);
    series3.data.setAll(data_?.Correlation?.Type3D?.Position);

        
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series0.appear(1000);
    series1.appear(1000);
    series2.appear(1000);
    series3.appear(1000);
    
    trendSeries0.appear(1000);
    trendSeries1.appear(1000);
    
    trendSeries2.appear(1000);
    trendSeries3.appear(1000);
  
  })
  
  
  
  
  
  
        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
    }

    render() {
        return (
                <div style={{width: '100%', height: 350}}>
                    
                    <div id={this.props.id} style={{width: '100%', height: 350, borderRadius: 10, paddingTop: 10}}>
                    </div>
            </div>
        );
    }
}

export default T1_FutureCorrelation;