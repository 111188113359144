import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5stock from '@amcharts/amcharts5/stock'
import { useEffect, Component} from "react";
import React from "react";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { AppBar, Toolbar, Typography } from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

class BienDoCPDaily extends Component {

    constructor(props){
        super(props); //
        this.state = {
          PctUp: 0,
          PctConst: 0,
          PctDn: 0,
          Up: 0,
          Const: 0,
          Dn: 0,
          UpChgAvg5: 0,
          UpAvg5: 0,
          DnChgAvg5: 0,
          DnAvg5: 0
        }
      }
    

    componentDidMount(){
        
    let root = am5.Root.new(this.props.id);

       





    root._logo.dispose();


    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
    am5themes_Dark.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
    panX: true,
    panY: true,
    wheelX: "panX",
    wheelY: "zoomX",
    layout: root.verticalLayout,
    pinchZoomX:true
    }));

    
    chart.get("colors").set("colors", [
        am5.color('#008B8B'),
        am5.color('#DC143C'),
        am5.color('#F08080'),
        am5.color('#DAA520'),
        am5.color('#2E8B57'),
        am5.color('#228B22'),
        am5.color('#9932CC')
    ]);



    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
    behavior: "none"
    }));
    cursor.lineY.set("visible", false);



    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
    categoryField: "day",
    startLocation: 0.5,
    endLocation: 0.5,
    renderer: am5xy.AxisRendererX.new(root, {})
    }));


    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
    min: 0,
    max: 100,
    calculateTotals: true,
    numberFormat: "#'%'",
    renderer: am5xy.AxisRendererY.new(root, {})
    }));

    xAxis.get("renderer").labels.template.setAll({
        fontSize: 15
    });

    yAxis.get("renderer").labels.template.setAll({
        fontSize: 15
    });
    




    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(am5.Legend.new(root, {
    centerX: am5.p50,
    x: am5.p50
    }));

    legend.data.setAll(chart.series.values);


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    var data = this?.props?.data

            var DATAUP = data[0]

            console.log(DATAUP)
            

            var date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            let setDate = new Date(date)
            let setDate2 = new Date(date)
            let setDate3 = new Date(date)
            date.setHours(0, 0, 0, 0);
            am5.time.add(date, "day", 0);
            
    // The data
    var data_ = [
        {
        "day": "19",
        "Giảm Mạnh": DATAUP?.GM_19,
        "Giảm Nhẹ": DATAUP?.GN_19,
        "Giảm Vừa": DATAUP?.GV_19,
        "Tham Chiếu": DATAUP?.TC_19,
        "Tăng Nhẹ": DATAUP?.TN_19,
        "Tăng Vừa": DATAUP?.TV_19,
        "Tăng Mạnh": DATAUP?.TM_19
        },{
        "day": "18",
        "Giảm Mạnh": DATAUP?.GM_18,
        "Giảm Nhẹ": DATAUP?.GN_18,
        "Giảm Vừa": DATAUP?.GV_18,
        "Tham Chiếu": DATAUP?.TC_18,
        "Tăng Nhẹ": DATAUP?.TN_18,
        "Tăng Vừa": DATAUP?.TV_18,
        "Tăng Mạnh": DATAUP?.TM_18
        },{
        "day": "17",
        "Giảm Mạnh": DATAUP?.GM_17,
        "Giảm Nhẹ": DATAUP?.GN_17,
        "Giảm Vừa": DATAUP?.GV_17,
        "Tham Chiếu": DATAUP?.TC_17,
        "Tăng Nhẹ": DATAUP?.TN_17,
        "Tăng Vừa": DATAUP?.TV_17,
        "Tăng Mạnh": DATAUP?.TM_17
        },{
        "day": "16",
        "Giảm Mạnh": DATAUP?.GM_16,
        "Giảm Nhẹ": DATAUP?.GN_16,
        "Giảm Vừa": DATAUP?.GV_16,
        "Tham Chiếu": DATAUP?.TC_16,
        "Tăng Nhẹ": DATAUP?.TN_16,
        "Tăng Vừa": DATAUP?.TV_16,
        "Tăng Mạnh": DATAUP?.TM_16
        },{
        "day": "15",
        "Giảm Mạnh": DATAUP?.GM_15,
        "Giảm Nhẹ": DATAUP?.GN_15,
        "Giảm Vừa": DATAUP?.GV_15,
        "Tham Chiếu": DATAUP?.TC_15,
        "Tăng Nhẹ": DATAUP?.TN_15,
        "Tăng Vừa": DATAUP?.TV_15,
        "Tăng Mạnh": DATAUP?.TM_15
        },{
        "day": "14",
        "Giảm Mạnh": DATAUP?.GM_14,
        "Giảm Nhẹ": DATAUP?.GN_14,
        "Giảm Vừa": DATAUP?.GV_14,
        "Tham Chiếu": DATAUP?.TC_14,
        "Tăng Nhẹ": DATAUP?.TN_14,
        "Tăng Vừa": DATAUP?.TV_14,
        "Tăng Mạnh": DATAUP?.TM_14
        },{
        "day": "13",
        "Giảm Mạnh": DATAUP?.GM_13,
        "Giảm Nhẹ": DATAUP?.GN_13,
        "Giảm Vừa": DATAUP?.GV_13,
        "Tham Chiếu": DATAUP?.TC_13,
        "Tăng Nhẹ": DATAUP?.TN_13,
        "Tăng Vừa": DATAUP?.TV_13,
        "Tăng Mạnh": DATAUP?.TM_13
        },{
        "day": "12",
        "Giảm Mạnh": DATAUP?.GM_12,
        "Giảm Nhẹ": DATAUP?.GN_12,
        "Giảm Vừa": DATAUP?.GV_12,
        "Tham Chiếu": DATAUP?.TC_12,
        "Tăng Nhẹ": DATAUP?.TN_12,
        "Tăng Vừa": DATAUP?.TV_12,
        "Tăng Mạnh": DATAUP?.TM_12
        },{
        "day": "11",
        "Giảm Mạnh": DATAUP?.GM_11,
        "Giảm Nhẹ": DATAUP?.GN_11,
        "Giảm Vừa": DATAUP?.GV_11,
        "Tham Chiếu": DATAUP?.TC_11,
        "Tăng Nhẹ": DATAUP?.TN_11,
        "Tăng Vừa": DATAUP?.TV_11,
        "Tăng Mạnh": DATAUP?.TM_11
        },{
        "day": "10",
        "Giảm Mạnh": DATAUP?.GM_10,
        "Giảm Nhẹ": DATAUP?.GN_10,
        "Giảm Vừa": DATAUP?.GV_10,
        "Tham Chiếu": DATAUP?.TC_10,
        "Tăng Nhẹ": DATAUP?.TN_10,
        "Tăng Vừa": DATAUP?.TV_10,
        "Tăng Mạnh": DATAUP?.TM_10
        },{
        "day": "9",
        "Giảm Mạnh": DATAUP?.GM_9,
        "Giảm Nhẹ": DATAUP?.GN_9,
        "Giảm Vừa": DATAUP?.GV_9,
        "Tham Chiếu": DATAUP?.TC_9,
        "Tăng Nhẹ": DATAUP?.TN_9,
        "Tăng Vừa": DATAUP?.TV_9,
        "Tăng Mạnh": DATAUP?.TM_9
        },{
        "day": "8",
        "Giảm Mạnh": DATAUP?.GM_8,
        "Giảm Nhẹ": DATAUP?.GN_8,
        "Giảm Vừa": DATAUP?.GV_8,
        "Tham Chiếu": DATAUP?.TC_8,
        "Tăng Nhẹ": DATAUP?.TN_8,
        "Tăng Vừa": DATAUP?.TV_8,
        "Tăng Mạnh": DATAUP?.TM_8
        },{
        "day": "7",
        "Giảm Mạnh": DATAUP?.GM_7,
        "Giảm Nhẹ": DATAUP?.GN_7,
        "Giảm Vừa": DATAUP?.GV_7,
        "Tham Chiếu": DATAUP?.TC_7,
        "Tăng Nhẹ": DATAUP?.TN_7,
        "Tăng Vừa": DATAUP?.TV_7,
        "Tăng Mạnh": DATAUP?.TM_7
        },{
        "day": "6",
        "Giảm Mạnh": DATAUP?.GM_6,
        "Giảm Nhẹ": DATAUP?.GN_6,
        "Giảm Vừa": DATAUP?.GV_6,
        "Tham Chiếu": DATAUP?.TC_6,
        "Tăng Nhẹ": DATAUP?.TN_6,
        "Tăng Vừa": DATAUP?.TV_6,
        "Tăng Mạnh": DATAUP?.TM_6
        },{
        "day": "5",
        "Giảm Mạnh": DATAUP?.GM_5,
        "Giảm Nhẹ": DATAUP?.GN_5,
        "Giảm Vừa": DATAUP?.GV_5,
        "Tham Chiếu": DATAUP?.TC_5,
        "Tăng Nhẹ": DATAUP?.TN_5,
        "Tăng Vừa": DATAUP?.TV_5,
        "Tăng Mạnh": DATAUP?.TM_5
        },{
        "day": "4",
        "Giảm Mạnh": DATAUP?.GM_4,
        "Giảm Nhẹ": DATAUP?.GN_4,
        "Giảm Vừa": DATAUP?.GV_4,
        "Tham Chiếu": DATAUP?.TC_4,
        "Tăng Nhẹ": DATAUP?.TN_4,
        "Tăng Vừa": DATAUP?.TV_4,
        "Tăng Mạnh": DATAUP?.TM_4
        },{
        "day": "3",
        "Giảm Mạnh": DATAUP?.GM_3,
        "Giảm Nhẹ": DATAUP?.GN_3,
        "Giảm Vừa": DATAUP?.GV_3,
        "Tham Chiếu": DATAUP?.TC_3,
        "Tăng Nhẹ": DATAUP?.TN_3,
        "Tăng Vừa": DATAUP?.TV_3,
        "Tăng Mạnh": DATAUP?.TM_3
        },{
    "day": "2",
    "Giảm Mạnh": DATAUP?.GM_2,
    "Giảm Nhẹ": DATAUP?.GN_2,
    "Giảm Vừa": DATAUP?.GV_2,
    "Tham Chiếu": DATAUP?.TC_2,
    "Tăng Nhẹ": DATAUP?.TN_2,
    "Tăng Vừa": DATAUP?.TV_2,
    "Tăng Mạnh": DATAUP?.TM_2
    },{
    "day": "1",
    "Giảm Mạnh": DATAUP?.GM_1,
    "Giảm Nhẹ": DATAUP?.GN_1,
    "Giảm Vừa": DATAUP?.GV_1,
    "Tham Chiếu": DATAUP?.TC_1,
    "Tăng Nhẹ": DATAUP?.TN_1,
    "Tăng Vừa": DATAUP?.TV_1,
    "Tăng Mạnh": DATAUP?.TM_1
    },{
    "day": "0",
    "Giảm Mạnh": DATAUP?.GM_0,
    "Giảm Nhẹ": DATAUP?.GN_0,
    "Giảm Vừa": DATAUP?.GV_0,
    "Tham Chiếu": DATAUP?.TC_0,
    "Tăng Nhẹ": DATAUP?.TN_0,
    "Tăng Vừa": DATAUP?.TV_0,
    "Tăng Mạnh": DATAUP?.TM_0
    }];
    
    var sum = DATAUP?.GM_0+DATAUP?.GN_0+DATAUP?.GV_0+DATAUP?.TC_0+DATAUP?.TN_0+DATAUP?.TV_0+DATAUP?.TM_0

    function sumT(x){
        return Number(DATAUP?.[`TN_${x}`]+DATAUP?.[`TV_${x}`]+DATAUP?.[`TM_${x}`])/sum*100
    }

    
    function sumG(x){
        return Number(DATAUP?.[`GN_${x}`]+DATAUP?.[`GV_${x}`]+DATAUP?.[`GM_${x}`])/sum*100
    }
    this.setState({
        PctUp: (DATAUP?.TN_0+DATAUP?.TV_0+DATAUP?.TM_0)/sum,
        PctConst: (DATAUP?.TC_0)/sum,
        PctDn: (DATAUP?.GN_0+DATAUP?.GV_0+DATAUP?.GM_0)/sum,
        Up: DATAUP?.TN_0+DATAUP?.TV_0+DATAUP?.TM_0,
        Const: DATAUP?.TC_0,
        Dn: DATAUP?.GN_0+DATAUP?.GV_0+DATAUP?.GM_0,

        UpChgAvg5: (Math.abs(sumT(0)-sumT(1))+Math.abs(sumT(1)-sumT(2))+Math.abs(sumT(2)-sumT(3))+Math.abs(sumT(3)-sumT(4))+Math.abs(sumT(4)-sumT(5)))/5,
        UpAvg5: (sumT(0)+sumT(1)+sumT(2)+sumT(3)+sumT(4))/5,

        
        DnChgAvg5: (Math.abs(sumG(0)-sumG(1))+Math.abs(sumG(1)-sumG(2))+Math.abs(sumG(2)-sumG(3))+Math.abs(sumG(3)-sumG(4))+Math.abs(sumG(4)-sumG(5)))/5,
        DnAvg5: (sumG(0)+sumG(1)+sumG(2)+sumG(3)+sumG(4))/5
    })


                    
            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            function createSeries(name, field) {
                let series = chart.series.push(am5xy.LineSeries.new(root, {
                    name: name,
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: field,
                    categoryXField: "day",
                    valueYShow: "valueYTotalPercent",
                    legendValueText: "{valueY}",
                    tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "[fontSize: 12px ]{name}: {valueYTotalPercent.formatNumber('#.0')}% ({valueY})",
                    fontSize: 2
                    
                    })
                }));
            
                series.fills.template.setAll({
                    fillOpacity: 0.5,
                    visible: true
                });

                
              
            
                series.data.setAll(data_);
                series.appear(1000);
                }
            
            createSeries("Giảm Mạnh", "Giảm Mạnh");
            createSeries("Giảm Vừa", "Giảm Vừa");
            createSeries("Giảm Nhẹ", "Giảm Nhẹ");
            createSeries("Tham Chiếu", "Tham Chiếu");
            createSeries("Tăng Nhẹ", "Tăng Nhẹ");
            createSeries("Tăng Vừa", "Tăng Vừa");
            createSeries("Tăng Mạnh", "Tăng Mạnh");
            
            
            xAxis.data.setAll(data_)
        




// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/

        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
    }


    
        
    


    render() {
        return (
            <div style={{backgroundColor: '#202020', borderRadius: 10, boxShadow: '5px 5px #101010', alignItems: 'center'}}>
              <AppBar position="static" sx={{backgroundColor: '#202020' }}>
                <Toolbar variant="dense" sx={{display: 'flex', flexDirection: 'column', padding: '10px'}}>

                    
              <Typography
                    fontWeight = 'bold'
                    color = '#FAEBD7'
                    variant = 'h6'
                    textAlign = 'center'
                    
                  >
                    Tương Đối Các Biên Độ Giá Thị Trường
                  </Typography>
                  <div style={{width: '90%', borderBottom: '1px dashed white'}}>

                  </div>
                    <span style={{display: 'flex', justifyContent: 'space-between', marginTop: 5}}>
                        <Typography variant = "body2" fontWeight='bold'>
                            Trạng Thái: 
                        </Typography>&nbsp;
                        <span style={{display: 'flex', color: 'limegreen'}}>
                            
                            <ArrowDropUpIcon/>
                            <Typography variant = "subtitle2">{this.state.Up}&nbsp;({Math.ceil(this.state.PctUp*100)}%)
                            </Typography>
                        </span>&nbsp;
                        <span style={{display: 'flex', color: 'goldenrod'}}>
                            <ArrowRightAltIcon/>
                            <Typography variant = "subtitle2">{this.state.Const}&nbsp;({Math.ceil(this.state.PctConst*100)}%)</Typography>
                        </span>&nbsp;
                        <span style={{display: 'flex', color: 'red'}}>
                            
                            <ArrowDropDownIcon/>
                            <Typography variant = "subtitle2">{this.state.Dn}&nbsp;({Math.ceil(this.state.PctDn*100)}%)
                            </Typography>
                        </span>
                    </span>
                    <div style={{display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <span style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography variant = "body2" fontWeight='bold' color={'lightgreen'} marginTop = {0.8}>
                                UpAvg5:
                            </Typography>&nbsp;
                            
                            <Typography variant = "body2" marginTop = {0.8}>
                                {this.state.UpAvg5?.toFixed(0)}%
                                {' ('}
                                <span style={{color: this.state.UpChgAvg5>=10?'purple':'lightgray'}}>{this.state.UpChgAvg5?.toFixed(0)}%</span>
                                /P{')'}
                            </Typography>
                        </span>
                        &nbsp;
                        <Typography 
                        variant = {'h6'}
                        sx={{
                            fontWeight: 'bold',
                        }}>
                            {this.state.UpChgAvg5 > this.state.DnChgAvg5 
                                ? 
                                '|>|':
                                (
                                    this.state.UpChgAvg5 < this.state.DnChgAvg5 
                                    ?
                                    '|<|'
                                    :
                                    '|=|'
                                )
                            }
                        </Typography>
                        &nbsp;
                        

                        <span style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography variant = "body2" fontWeight='bold' color={'pink'} marginTop = {0.8}>
                                DnAvg5:
                            </Typography>&nbsp;
                            
                            <Typography variant = "body2" marginTop = {0.8}>
                                {this.state.DnAvg5?.toFixed(0)}%
                                {' ('}
                                <span style={{color: this.state.DnChgAvg5>=10?'purple':'lightgray'}}>{this.state.DnChgAvg5?.toFixed(0)}%</span>
                                /P{')'}
                            </Typography>
                        </span>
                    </div>
                </Toolbar>
                </AppBar>
                
                <div id={this.props.id} style={{height: 400, width: '100%'}}>
                </div>
            </div>
        );
    }

    


}

export default BienDoCPDaily;



