import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {Component} from "react";
import React from "react";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { Button, FormControl, InputLabel, makeStyles, MenuItem, NativeSelect, Select, Typography } from "@mui/material";
import BulletChart from "./BulletChart.dashboard";
import ChartBubbleSector from "./BubbleChartSector.dashboard";
import { MdOutlineShowChart,MdOutlineStackedLineChart  } from "react-icons/md";




class BubbleChart extends Component {

    constructor(props) {
      super(props);
      this.state = {
        series: null,
        VolumeAverage: 50000,
        Close: 5,
        Sector: 'ALL',
        Chart: 'Stock',
        data: null
      }
    }


    

    componentDidMount(){


        let root = am5.Root.new(this.props.id);
        root.setThemes([
            am5themes_Dark.new(root)
          ]);
          
    root._logo.dispose();
          
          // Create chart
          // https://www.amcharts.com/docs/v5/charts/xy-chart/
          let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelY: "zoomXY",
            pinchZoomX:true,
            pinchZoomY:true
          }));
          
          
          // Create axes
          // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
          let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            strictMinMax: true,
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {}),
            max: 70,
            
          }));
          
          
          let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            
            strictMinMaxSelection: true,
            renderer: am5xy.AxisRendererY.new(root, {
              inversed: false
            }),
            tooltip: am5.Tooltip.new(root, {}),
            max: 45
          }));
          
          
          
          
          
          // Create series
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
          let series = chart.series.push(am5xy.LineSeries.new(root, {
            calculateAggregates: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "SB3MK0A",
            valueXField: "AllAspect",
            valueField: "vol0",
            seriesTooltipTarget:"bullet",
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText: "[fontSize: 13px ][bold]TICKER: {TICKERVN} | {S2Col}{S2No}[/]\n[fontSize: 12px]SCORE Động Lượng: {AllAspect.formatNumber('#.0')}\nĐội Lái Trung Bình 5 Phiên: {AvgDoiLai5.formatNumber('#.0')}\nKhối Lượng Trung Bình: {VolumeAverage}\n%Change Weekly: {Weekly}%\n%Change Monthly: {Monthly}%\nChu Kì Tăng: {ChuKiTangGiam}%"
            })
            
          }));
          series.bullets.push(function(root, series, dataItem) {
            return am5.Bullet.new(root, {
              sprite: am5.Label.new(root, {
                text: "{Ticker}",
                fill: am5.color(0xffffff),
                populateText: true,
                centerX: am5.p50,
                centerY: am5.p50,
                textAlign: "center",
                fontSize: 11,
                fillOpacity: 4,
                fontWeight: 600
              }),
              dynamic: true
            });
          });
          
          series.strokes.template.set("visible", false);
          
          
          // Add bullet
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
          let circleTemplate = am5.Template.new({});
          circleTemplate.adapters.add("fill", function(fill, target) {
            let dataItem = target.dataItem;
            if (dataItem) {
              return am5.Color.fromString(dataItem.dataContext.color);
            }
            return fill
          });
          series.bullets.push(function() {
            let bulletCircle = am5.Circle.new(root, {
              fill: series.get("fill"),
              radius: 5,
              fillOpacity: 0.45
            }, circleTemplate);
            return am5.Bullet.new(root, {
              sprite: bulletCircle
            });
          });
          
          
          // Add heat rule
          // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
          series.set("heatRules", [{
            target: circleTemplate,
            min: 0.3,
            max: 36,
            dataField: "value",
            key: "radius"
          }]);

          chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            snapToSeries: [series]
          }));
          
          
          // Add scrollbars
          // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
          // chart.set("scrollbarX", am5.Scrollbar.new(root, {
          //   orientation: "horizontal"
          // }));
          
          // chart.set("scrollbarY", am5.Scrollbar.new(root, {
          //   orientation: "vertical"
          // }));

          xAxis.labelsContainer.set("tooltip", am5.Tooltip.new(root, {
            pointerOrientation: "down"
          }));
          
          let xRenderer = xAxis.get("renderer");
          
          xRenderer.labels.template.setAll({
            tooltipText: "TICKERVN",
            oversizedBehavior: "truncate",
            maxWidth: 100
          });

          series.data.processor = am5.DataProcessor.new(root, {
            numericFields: ["Close","vol0","AllAspect","AvgDoiLai5",'SB3MK0A'],
            
          });  
          
          xRenderer.labels.template.setup = function(target) {
            target.set("background", am5.Rectangle.new(root, {
              fill: am5.color('#999933'),
              fillOpacity: 0
            }));
          };

          var series3 = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339966")
          }));
          series3.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series3.strokes.template.set("forceHidden", true);
          series3.data.setAll([
            { ax: 60, ay: 10 },
            { ax: 10, ay: 10 },
            { ax: 10, ay: 25 },
            { ax: 60, ay: 25 }
          ]);

          var series3a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339966")
          }));
          series3a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series3a.strokes.template.set("forceHidden", true);
          series3a.data.setAll([
            { ax: 10, ay: 25 },
            { ax: 70, ay: 25 },
            { ax: 70, ay: 45 },
            { ax: 10, ay: 45 }
          ]);

          var series0a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339999")
          }));
          series0a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series0a.strokes.template.set("forceHidden", true);
          series0a.data.setAll([
            { ax: 0, ay: 25 },
            { ax: 10, ay: 25 },
            { ax: 10, ay: 0 },
            { ax: 0, ay: 0 }
          ]);

          var series0b = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339999")
          }));
          series0b.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series0b.strokes.template.set("forceHidden", true);
          series0b.data.setAll([
            { ax: 10, ay: 10 },
            { ax: 50, ay: 10 },
            { ax: 50, ay: 0 },
            { ax: 10, ay: 0 }
          ]);
          
          var series1 = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#993333")
          }));
          series1.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series1.strokes.template.set("forceHidden", true);
          series1.data.setAll([
            { ax: -3, ay: -3 },
            { ax: -30, ay: -3 },
            { ax: -30, ay: 25 },
            { ax: -3, ay: 25 }
          ]);


          var series1a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#993333")
          }));
          series1a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series1a.strokes.template.set("forceHidden", true);
          series1a.data.setAll([
            { ax: 50, ay: -10 },
            { ax: -30, ay: -10 },
            { ax: -30, ay: -3 },
            { ax: 50, ay: -3 }
          ]);

          
          
          var series2a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#999933")
          }));
          series2a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series2a.strokes.template.set("forceHidden", true);
          series2a.data.setAll([
            { ax: 0, ay: -3 },
            { ax: -3, ay: -3 },
            { ax: -3, ay: 25 },
            { ax: 0, ay: 25 }
          ]);

          var series2b = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#999933")
          }));
          series2b.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series2b.strokes.template.set("forceHidden", true);
          series2b.data.setAll([
            { ax: 50, ay: -3 },
            { ax: -3, ay: -3 },
            { ax: -3, ay: 0 },
            { ax: 50, ay: 0 }
          ]);

   

    this.setState({series: series})

    fetch(`${process.env.REACT_APP_BASE_URL}/data/stockdatashort`)
    .then(res => res.json())
    .then(data => {
      if(this.state.Sector==="ALL") 
        {
          series?.data?.setAll(am5.JSONParser.parse(data)?.filter(datax => {return Number(datax.VolumeAverage)>this.state.VolumeAverage && Number(datax.Close)>this.state.Close}))
          this.setState({data: am5.JSONParser.parse(data)?.filter(datax => {return Number(datax.VolumeAverage)>this.state.VolumeAverage && Number(datax.Close)>this.state.Close})})
          
        }
        else 
        {
          series?.data?.setAll(am5.JSONParser.parse(data)?.filter(datax => {return Number(datax.VolumeAverage)>this.state.VolumeAverage && Number(datax.Close)>this.state.Close && datax.Sector?.includes(this.state.Sector)}))
        
        this.setState({data: am5.JSONParser.parse(data)?.filter(datax => {return Number(datax.VolumeAverage)>this.state.VolumeAverage && Number(datax.Close)>this.state.Close && datax.Sector?.includes(this.state.Sector)})})
        }
    })
        
      

    

    

    


// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/

        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
    }


    
        
    


    render() {

      var series = this.state.series;
      var data = this?.props?.data
      
      


      const handleChange = (event) => {
        this.setState({VolumeAverage: event.target.value});
        fetch(`${process.env.REACT_APP_BASE_URL}/data/stockdatashort`)?.then(x => x.json())
        .then((data) => {
          if(this.state.Sector==="ALL") 
            {
              series?.data?.setAll(am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close}))
              this.setState({data: am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close})})
            }
            else 
            {
              series?.data?.setAll(am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close && data.Sector?.includes(this.state.Sector)}))
            
            this.setState({data: am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close && data.Sector?.includes(this.state.Sector)})})
            }
        })
          
      };

      
      const handleChange2 = (event) => {
        this.setState({Sector: event.target.value});
        fetch(`${process.env.REACT_APP_BASE_URL}/data/stockdatashort`)?.then(x => x.json())
        .then((data) => {
          if(this.state.Sector==="ALL") 
            {
              series?.data?.setAll(am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close}))
              this.setState({data: am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close})})
            }
            else 
            {
              series?.data?.setAll(am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close && data.Sector?.includes(this.state.Sector)}))
            
            this.setState({data: am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close && data.Sector?.includes(this.state.Sector)})})
            }
        })
          
      };

      
      const handleChange3 = (event) => {
        this.setState({Close: event.target.value});
        fetch(`${process.env.REACT_APP_BASE_URL}/data/stockdatashort`)?.then(x => x.json())
        .then((data) => {
          if(this.state.Sector==="ALL") 
            {
              series?.data?.setAll(am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close}))
              this.setState({data: am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close})})
            }
            else 
            {
              series?.data?.setAll(am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close && data.Sector?.includes(this.state.Sector)}))
            
            this.setState({data: am5.JSONParser.parse(data)?.filter(data => {return Number(data.VolumeAverage)>this.state.VolumeAverage && Number(data.Close)>this.state.Close && data.Sector?.includes(this.state.Sector)})})
            }    
        })
          
      };

      function BubbleChartParam(data_){
       
        var STOCK_FAIR = data_?.filter(x => {
          return Number(x.AllAspect)>-2 && Number(x.SB3MK0A)>-2
        })?.length / data_?.length
        
        var STOCK_STRONGPARTIAL = data_?.filter(x => {
          return Number(x.AllAspect)>0 || Number(x.SB3MK0A)>0
        })?.length / data_?.length
    
        var STOCK_STRONGFULL = data_?.filter(x => {
          return Number(x.AllAspect)>0 && Number(x.SB3MK0A)>0
        })?.length / data_?.length
        function calculateDensityScore(array, param) {
          const benchmark = array?.reduce((a,b) => {return a+b})/array?.length

          
          const totalCount = array?.length;
      
          if (totalCount === 0) return 0; // Handle empty array
      
          // Calculate the sum of absolute distances from the benchmark
          const totalDistance = array?.reduce((acc, num) => acc + Math.abs(num - benchmark), 0);
      
          // Calculate average distance from the benchmark
          const averageDistance = totalDistance / totalCount;
      
          // Normalize the score to a range of -1 to +1
          // Assuming maxDistance is the furthest distance from the benchmark you want to consider
          var maxDistance = array?Math.max(...(array?.map(num => Math.abs(num - benchmark)))):null;

         
      
          // Calculate the density score
          const densityScore = 1 - (averageDistance / maxDistance);
    
          return densityScore*10;
        }
    
        var array1 = data_?.map(x => Number(x.SB3MK0A))
        var array1_Q1 = array1?.[Math.round((array1?.length+1)/4)]
        var array1_Q2 = array1?.[Math.round((array1?.length+1)/2)]
        var array1_Q3 = array1?.[Math.round(3*(array1?.length+1)/4)]
        var array1_IQR = array1_Q3-array1_Q1;
        var array1_Outliner = data_?.filter(x => {
          return x.SB3MK0A >= (array1_Q3 + array1_IQR*1.5)
        })

        var array2 = data_?.map(x => Number(x.AllAspect))
        var array2_Q1 = array2?.[Math.round((array2?.length+1)/4)]
        var array2_Q2 = array2?.[Math.round((array2?.length+1)/2)]
        var array2_Q3 = array2?.[Math.round(3*((array2?.length+1)/4))]
        var array2_IQR = array2_Q3-array2_Q1;
        var array2_Outliner = data_?.filter(x => {
          return x.AllAspect >= (array2_Q3 + array2_IQR*1.5)
        })
    
    
    
        
        
    
        return {
          Benchmark: ((STOCK_FAIR+STOCK_STRONGPARTIAL*0.7+STOCK_STRONGFULL*0.3)/2*10)?.toFixed(2),
          Density: ((calculateDensityScore(data_?.map(x => Number(x.SB3MK0A)),'SB3MK0A')+calculateDensityScore(data_?.map(x => Number(x.AllAspect)), 'AllAspect'))/2)?.toFixed(2),
          Outliner: {
            length: array1_Outliner?.length,
            pct: ((array1_Outliner?.length / data_?.length + array2_Outliner?.length / data_?.length)/2*10)?.toFixed(2)
          }
         
        }
      }

      function commentOverall(data_){
        if(data_?.Benchmark>=7){
          if(data_?.Outliner?.pct>3){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & PH MẠNH (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & PH MẠNH (BAL {'<'} 1/2)
              </span>
            }
            
          }
          else if(data_?.Outliner?.pct>=1){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & PH KHÁ (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & PH KHÁ (BAL {'<'} 1/2)
              </span>
            }
          }
          
          else{
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & ĐỀU (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & ĐỀU(BAL {'<'} 1/2)
              </span>
            }
          }
        }


        else if (data_?.Benchmark>=5){
          if(data_?.Outliner?.pct>3){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & PH MẠNH (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & PH MẠNH (BAL {'<'} 1/2)
              </span>
            }
            
          }
          else if(data_?.Outliner?.pct>=1){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & PH KHÁ (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & PH KHÁ (BAL {'<'} 1/2)
              </span>
            }
          }
          
          else{
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & ĐỀU(BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & ĐỀU (BAL {'<'} 1/2)
              </span>
            }
          }
        }

        
        else{
          if(data_?.Outliner?.pct>3){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & PH MẠNH (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & PH MẠNH (BAL {'<'} 1/2)
              </span>
            }
            
          }
          else if(data_?.Outliner?.pct>=1){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & PH KHÁ (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & PH KHÁ (BAL {'<'} 1/2)
              </span>
            }
          }
          
          else{
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & ĐỀU (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & ĐỀU (BAL {'<'} 1/2)
              </span>
            }
          }
        }
        
      }



      
        return (

            
            <React.Fragment>
              {this?.state?.data?<div style={{
                backgroundColor: '#101010',
                padding: 10,
                display: 'flex',
                flexDirection: 'row',
                margin: 'auto',
                width: '100%',
                justifyContent: 'space-around',
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}>
                  <Typography sx = {{
                    color: 'beige'
                  }}
                    variant={'h6'}
                    fontWeight={600}
                  >
                    BENCHMARK(10)
                  </Typography>
                  <div>
                  {/*Benchmark*/}
                  {
                    BubbleChartParam(this?.state?.data)?.Benchmark>=7?
                      <span style={{color: 'forestgreen', fontWeight: 'bold'}}>
                        {BubbleChartParam(this?.state?.data)?.Benchmark} {'-'} {'CAO'}
                      </span>
                      
                    :
                      (
                        BubbleChartParam(this?.state?.data)?.Benchmark>=5?
                          <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                            {BubbleChartParam(this?.state?.data)?.Benchmark} {'-'} {'KHÁ'}
                          </span>
                          :
                          (
                            BubbleChartParam(this?.state?.data)?.Benchmark>=3?
                            <span style={{color: 'darkorange', fontWeight: 'bold'}}>
                              {BubbleChartParam(this?.state?.data)?.Benchmark} {'-'} {'KÉM'}
                            </span>
                            :
                            <span style={{color: 'red', fontWeight: 'bold'}}>
                              {BubbleChartParam(this?.state?.data)?.Benchmark} {'-'} {'THẤP'}
                            </span>
                          )
                      )
                      
                  }
                  
                  
                </div>

          
          
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  }}>
                    <Typography sx = {{
                      color: 'beige'
                    }}
                      variant={'h6'}
                      fontWeight={600}
                    >
                      DENSITY(10)
                    </Typography>
                    {/*Density*/}
                    {
                      BubbleChartParam(this?.state?.data)?.Density>=7?
                        <span style={{color: 'forestgreen', fontWeight: 'bold'}}>
                          {BubbleChartParam(this?.state?.data)?.Density} {'-'} {'CAO'}
                        </span>
                        
                      :
                        (
                          BubbleChartParam(this?.state?.data)?.Density>=5?
                            <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                              {BubbleChartParam(this?.state?.data)?.Density} {'-'} {'KHÁ'}
                            </span>
                            :
                            (
                              BubbleChartParam(this?.state?.data)?.Density>=3?
                              <span style={{color: 'darkorange', fontWeight: 'bold'}}>
                                {BubbleChartParam(this?.state?.data)?.Density} {'-'} {'KÉM'}
                              </span>
                              :
                              <span style={{color: 'red', fontWeight: 'bold'}}>
                                {BubbleChartParam(this?.state?.data)?.Density} {'-'} {'THẤP'}
                              </span>
                            )
                        )
                        
                    }
                  </div>
                  <div style={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    textAlign: 'center',
                  }}>
                    <Typography sx = {{
                      color: 'beige'
                    }}
                      variant={'h6'}
                      fontWeight={600}
                    >
                      OUTLINER(10)
                    </Typography>
                    {
                      Number(BubbleChartParam(this?.state?.data)?.Outliner?.pct)>=5?
                        <span style={{color: 'forestgreen', fontWeight: 'bold'}}>
                          {BubbleChartParam(this?.state?.data)?.Outliner?.pct} {'-'} {'CAO'}
                        </span>
                        
                      :
                        (
                          Number(BubbleChartParam(this?.state?.data)?.Outliner?.pct)>=2?
                            <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                              {BubbleChartParam(this?.state?.data)?.Outliner?.pct} {'-'} {'VỪA'}
                            </span>
                            :
                            (
                              Number(BubbleChartParam(this?.state?.data)?.Outliner?.pct)<=0.5?
                              <span style={{color: 'red', fontWeight: 'bold'}}>
                                {BubbleChartParam(this?.state?.data)?.Outliner?.pct} {'-'} {'THẤP'}
                              </span>
                              :
                              <span style={{color: 'darkorange', fontWeight: 'bold'}}>
                                {BubbleChartParam(this?.state?.data)?.Outliner?.pct} {'-'} {'KÉM'}
                              </span>
                            )
                            
                        )
                        
                    }

                  </div>

                  <div style={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    textAlign: 'center',
                  }}>
                    <Typography sx = {{
                      color: 'beige'
                    }}
                      variant={'h6'}
                      fontWeight={600}
                    >
                      OVERALL
                    </Typography>
                    {
                      commentOverall(BubbleChartParam(this?.state?.data))
                    }
                  </div>
        
        
              </div>
              
              
              
              :<></>}
              <div style={{display: 'flex', width: '100%', margin: 'auto', justifyContent: 'center', backgroundColor: '#151515', padding: 10, }}>

                <div style={{
                  borderRight: '2px solid white',
                  borderLeft: '2px solid white',
                  width: '100%',
                  display: 'flex',
                  flexDirection:'row',
                  justifyContent: 'left',
                }}>
                  <div  style={{
                        width: '45%',}}
                  >
                    <InputLabel  variant="standard" sx={{color: 'white', textAlign: 'center', textDecorationLine: 'underline'}}>Sector</InputLabel>
                    <NativeSelect
                      defaultValue={this.state.Sector}
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Sector"
                      onChange={handleChange2}
                      sx={{
                        backgroundColor: '#202020',
                        boxShadow: '2px black',
                        color: '#808080',
                        width: '100%',
                        textAlign: 'center'
                      }}
                    >
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'ALL'}>Tất Cả Ngành & Dòng</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'darkred', fontWeight: 'bold'}} value={'B_'}>DÒNG - BlueChip</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'darkred', fontWeight: 'bold'}} value={'M_'}>DÒNG - MidCap</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'darkred', fontWeight: 'bold'}} value={'P_'}>DÒNG - Penny</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'goldenrod', fontWeight: 'bold'}} value={'BDS'}>NGÀNH BIG 3 - Bất Động Sản</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'goldenrod', fontWeight: 'bold'}} value={'NGAN'}>NGÀNH BIG 3 - Ngân Hàng </option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center', color: 'goldenrod', fontWeight: 'bold'}} value={'CHUNG'}>NGÀNH BIG 3 - Chứng Khoán</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'BAN'}>Bán Lẻ</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'XAY'}>Xây Dựng</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'THEP'}>Thép (HH)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'DAU'}>Dầu Khí (HH)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'DAU'}>Dệt May (HH)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'DIEN'}>Điện</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'THUC'}>Thực Phẩm</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'THUY'}>Thủy Sản (TP)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'NONG'}>Nông Sản (TP)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'DTC'}>Đầu Tư Công</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'HOA CHAT'}>Hóa Chất</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'PHAN'}>Phân Bón (HC)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'CAO SU'}>Cao Su (HC)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'NHUA'}>Nhựa (HC)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'KK'}>Than (HC)</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'VAN TAI'}>Vận Tải</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'DUOC'}>Dược Phẩm</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={'BKCN'}>Bất Động Sản - Khu Công Nghiệp</option>
                    </NativeSelect>
                  </div>

                  <div  style={{
                        width: '30%', marginLeft: '2%'}}
                  >
                    <InputLabel  variant="standard" sx={{color: 'white', textAlign: 'center', textDecorationLine: 'underline'}}>Min Volume</InputLabel>
                    <NativeSelect
                      defaultValue={this.state.VolumeAverage}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                      label="Min Volume"
                      onChange={handleChange}
                      sx={{
                        backgroundColor: '#202020',
                        boxShadow: '2px black',
                        color: '#808080',
                        width: '100%'
                      }}
                    >
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={10000000}>10,000,000</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={5000000}>5,000,000</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={1000000}>1,000,000</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={500000}>500,000</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={200000}>200,000</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={100000}>100,000</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={50000}>50,000</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={10000}>10,000</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={0}>0</option>
                    </NativeSelect>
                  </div>

                  <div  style={{
                        width: '15%', marginLeft: '2%'}}
                  >
                    <InputLabel  variant="standard" sx={{color: 'white', textAlign: 'center', textDecorationLine: 'underline'}}>Min Close</InputLabel>
                    <NativeSelect
                      defaultValue={this.state.Close}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                      label="Min Close"
                      onChange={handleChange3}
                      sx={{
                        backgroundColor: '#202020',
                        boxShadow: '2px black',
                        color: '#808080',
                        width: '100%'
                      }}
                    >
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={200}>200</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={100}>100</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={50}>50</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={20}>20</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={10}>10</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={5}>5</option>
                      <option style={{backgroundColor: '#202020', textAlign: 'center'}} value={0}>0</option>
                    </NativeSelect>
                  </div>

                </div>

                
                

                
              </div>

              
              
                

            
            <>
            
              <div id={this.props.id} style={{height: this.props.height, width: this.props.width, backgroundColor: '#070812', border: '1px solid gray', borderRadius: 5, marginTop: 5 }}>
              </div>
              <BulletChart data = {this.state.data} id={'BulletChart'} height={100}/>
            </>
              
            <div style={{marginTop: 50}}>
            <Typography variant="h5" fontWeight="bold" paddingBottom = {0.3} color={'#00BFFF'}  sx={{textDecorationLine: 'underline'}}>Định Lượng Tổng Quan Ngành</Typography>
              <ChartBubbleSector datax = {this.props.data} data={this.props.dataSector} id={'ChartBubbleSector'} />
            </div>

            

              
            
            
          </React.Fragment>

          
        );
    }

    


}

export default BubbleChart;



