import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5stock from '@amcharts/amcharts5/stock'
import { useEffect, Component} from "react";
import React from "react";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";




export class StockChartVNINDEX extends Component {

    constructor(props){
        super(props);
        this.state = {
            TickerName: null,
            valueSeries: null,
            volumeSeries: null,
            sbSeries: null,
            name: this.props.name
            
        }
    }

    

    componentDidMount(){

      
        let root1 = am5.Root.new(this.props.id);


    
        root1._logo.dispose();

        root1.setThemes([
            am5themes_Dark.new(root1)
        ]);

    let stockChart = root1.container.children.push(am5stock.StockChart.new(root1, {
    }));

    root1.numberFormatter.set("numberFormat", "#,###.00");


    // Create a main stock panel (chart)
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Adding_panels
    let mainPanel = stockChart.panels.push(am5stock.StockPanel.new(root1, {
    wheelY: "zoomX",
    panX: true,
    panY: false,
    height: am5.percent(70)
    }));


    // Create value axis
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let valueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(root1, {
    renderer: am5xy.AxisRendererY.new(root1, {
        pan: "zoom"
    }),
    extraMin: 0.1, // adds some space for for main series
    tooltip: am5.Tooltip.new(root1, {}),
    numberFormat: "#,###.00",
    extraTooltipPrecision: 2
    }));

    let dateAxis = mainPanel.xAxes.push(am5xy.GaplessDateAxis.new(root1, {
    baseInterval: {
        timeUnit: "day",
        count: 1
    },
    renderer: am5xy.AxisRendererX.new(root1, {}),
    tooltip: am5.Tooltip.new(root1, {})
    }));


    // Add series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var valueSeries = mainPanel.series.push(am5xy.LineSeries.new(root1, {
    name: 'VNINDEX',
    clustered: false,
    valueXField: "date",
    valueYField: "close",
    highValueYField: "high",
    lowValueYField: "low",
    openValueYField: "open",
    calculateAggregates: true,
    xAxis: dateAxis,
    yAxis: valueAxis,
    legendValueText: "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
    legendRangeValueText: ""
    }));

    valueSeries.data.processor = am5.DataProcessor.new(root1, {
        numericFields: ["SB3CL0","SB3H0","SB3L0","SB3O0"],
        
      });  

    let valueTooltip = valueSeries.set("tooltip", am5.Tooltip.new(root1, {
        getFillFromSprite: false,
        getStrokeFromSprite: true,
        getLabelFillFromSprite: true,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        labelText: "{valueY} {valueYChangePreviousPercent.formatNumber('[#00ff00]+#,###.##|[#ff0000]#,###.##|[#999999]0')}%"
    }));
    valueTooltip.get("background") .set("fill", root1.interfaceColors.get("background"));


    // Set main value series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Setting_main_series
    stockChart.set("stockSeries", valueSeries);


    // Add a stock legend
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/stock-legend/
    let valueLegend = mainPanel.plotContainer.children.push(am5stock.StockLegend.new(root1, {
    stockChart: stockChart
    }));


    // Create a volume panel (chart)
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Adding_panels
    let volumePanel = stockChart.panels.push(am5stock.StockPanel.new(root1, {  
    panX: true,
    panY: true,
    height: am5.percent(0),
    paddingTop: 6
    }));

    // hide close button as we don't want this panel to be closed
    volumePanel.panelControls.closeButton.set("forceHidden", true);

    let volumeDateAxis = volumePanel.xAxes.push(am5xy.GaplessDateAxis.new(root1, {
    baseInterval: {
        timeUnit: "day",
        count: 1
    },
    renderer: am5xy.AxisRendererX.new(root1, {}),
    tooltip: am5.Tooltip.new(root1, {
        forceHidden: true
    }),
    height: 0
    }));

    // we don't need it to be visible
    volumeDateAxis.get("renderer").labels.template.set("forceHidden", true);

    // Create volume axis
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let volumeAxisRenderer = am5xy.AxisRendererY.new(root1, {});

    let volumeValueAxis = volumePanel.yAxes.push(am5xy.ValueAxis.new(root1, {
    numberFormat: "#.#a",
    renderer: volumeAxisRenderer
    }));


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let volumeSeries = volumePanel.series.push(am5xy.ColumnSeries.new(root1, {
    name: "Volume",
    clustered: false,
    valueXField: "date",
    valueYField: "volume",
    xAxis: volumeDateAxis,
    yAxis: volumeValueAxis,
    legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]"
    }));

    volumeSeries.columns.template.setAll({
    strokeOpacity: 0,
    fillOpacity: 0.5
    });

    // color columns by stock rules
    volumeSeries.columns.template.adapters.add("fill", function(fill, target) {
    let dataItem = target.dataItem;
    if (dataItem) {
        return stockChart.getVolumeColor(dataItem);
    }
    return fill;
    })

    // Add a stock legend
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/stock-legend/
    let volumeLegend = volumePanel.plotContainer.children.push(am5stock.StockLegend.new(root1, {
    stockChart: stockChart
    }));


    // Set main series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Setting_main_series
    
    valueLegend.data.setAll([valueSeries]);
    


    // Add cursor(s)
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    mainPanel.set("cursor", am5xy.XYCursor.new(root1, {
    yAxis: valueAxis,
    xAxis: dateAxis,
    snapToSeries: [valueSeries],
    snapToSeriesBy: "y!"
    }));


    


    // Add scrollbar
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    let scrollbar = mainPanel.set("scrollbarX", am5xy.XYChartScrollbar.new(root1, {
    orientation: "horizontal",
    height: 50
    }));
    stockChart.toolsContainer.children.push(scrollbar);
    // to move scrollbar to the bottom of the Stock chart, uncomment this line
    //stockChart.children.moveValue(stockChart.toolsContainer, stockChart.children.length - 1);

    let sbDateAxis = scrollbar.chart.xAxes.push(am5xy.GaplessDateAxis.new(root1, {
    baseInterval: {
        timeUnit: "day",
        count: 1
    },
    renderer: am5xy.AxisRendererX.new(root1, {})
    }));

    let sbValueAxis = scrollbar.chart.yAxes.push(am5xy.ValueAxis.new(root1, {
    renderer: am5xy.AxisRendererY.new(root1, {})
    }));

    let sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(root1, {
    valueYField: "close",
    valueXField: "date",
    xAxis: sbDateAxis,
    yAxis: sbValueAxis
    }));

    sbSeries.fills.template.setAll({
    visible: true,
    fillOpacity: 0.3
    });

    // Set up series type switcher
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/toolbar/series-type-control/
    let seriesSwitcher = am5stock.SeriesTypeControl.new(root1, {
    stockChart: stockChart
    });

    seriesSwitcher.events.on("selected", function(ev) {
    setSeriesType(ev.item.id);
    });

    function getNewSettings(series) {
    let newSettings = [];
    am5.array.each(["name", "valueYField", "highValueYField", "lowValueYField", "openValueYField", "calculateAggregates", "valueXField", "xAxis", "yAxis", "legendValueText", "stroke", "fill"], function(setting) {
        newSettings[setting] = series.get(setting);
    });
    return newSettings;
    }

    function setSeriesType(seriesType) {
    // Get current series and its settings
    let currentSeries = stockChart.get("stockSeries");
    let newSettings = getNewSettings(currentSeries);

    // Remove previous series
    let data = currentSeries.data.values;
    mainPanel.series.removeValue(currentSeries);

    // Create new series
    let series;
    switch (seriesType) {
        case "line":
        series = mainPanel.series.push(am5xy.LineSeries.new(root1, newSettings));
        break;
        case "candlestick":
        case "procandlestick":
        newSettings.clustered = false;
        series = mainPanel.series.push(am5xy.CandlestickSeries.new(root1, newSettings));
        if (seriesType == "procandlestick") {
            series.columns.template.get("themeTags").push("pro");
        }
        break;
        case "ohlc":
        newSettings.clustered = false;
        series = mainPanel.series.push(am5xy.OHLCSeries.new(root1, newSettings));
        break;
    }

    // Set new series as stockSeries
    if (series) {
        valueLegend.data.removeValue(currentSeries);
        series.data.setAll(data);
        stockChart.set("stockSeries", series);
        let cursor = mainPanel.get("cursor");
        if (cursor) {
        cursor.set("snapToSeries", [series]);
        }
        valueLegend.data.insertIndex(0, series);
    }
    }

    


    this.setState({
        valueSeries: valueSeries,
        volumeSeries: volumeSeries,
        sbSeries: sbSeries
    })

//     fetch(`${process.env.REACT_APP_BASE_URL}/data/dataYahoo`)
//         .then((res) => res.json())
//         .then((data) => { 
        

//         var dataup = Object?.values(data?.filter((x) => {return x?.TickerName===this.props.TickerName})||{})[0]?.['HistoryDataDaily']

//         console.log(dataup)

//         let processor = am5.DataProcessor.new(root1, {
//             dateFields: ["date"]
//         });
        
//         processor.processMany(dataup);
            
//         // Set data
//         valueSeries.data.setAll(dataup);
//         volumeSeries.data.setAll(dataup);
//         sbSeries.data.setAll(dataup);
//     }



// )
        


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/

            this.root1 = root1;

        }

        componentWillUnmount() {
            if (this.root1) {
            this.root1.dispose();
            }
        }




    render() {
        var valueSeries = this?.state?.valueSeries
        var volumeSeries = this?.state?.volumeSeries
        var sbSeries = this?.state?.sbSeries
        var TickerName = this?.props?.TickerName
        var Name = this?.props?.name
        function dataLoaded(result) {
          // Set data on all series of the chart
          const data = am5.JSONParser.parse(result.response);

          
        var date = new Date(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
        let setDate = new Date(date)
        date.setHours(0, 0, 0, 0);
        am5.time.add(date, "day", 0);
          
          var dataup = []
          for(let i2=0; i2<=40;i2++){
            setDate.setDate(setDate.getDate()-1)
            dataup.push({
                date: setDate.getTime(),
                close: Number(data?.[0]?.[`SB3CL${i2}`]),
                high: Number(data?.[0]?.[`SB3H${i2}`]),
                low: Number(data?.[0]?.[`SB3L${i2}`]),
                open: Number(data?.[0]?.[`SB3O${i2}`]),
                volume: Number(data?.[0]?.[`SB3V${i2}`])
            })
            }
          valueSeries?.data?.setAll(dataup);
          valueSeries?.data?.setAll(dataup);
          volumeSeries?.data?.setAll(dataup);
          sbSeries?.data?.setAll(dataup);
          this?.setState({name: Name})
        }
        am5.net.load(`${process.env.REACT_APP_BASE_URL}/data/datavnindex`)?.then(dataLoaded)
       

        
        
        return (

            <React.Fragment>
                
                <div id={this.props.id} style={{ height: 300, backgroundColor: '#070812', borderRadius: 5, marginLeft: 7.5, marginTop: 5 }}>

            </div>
            
            
          </React.Fragment>

          
        );
    }

    


}

