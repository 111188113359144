import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5stock from '@amcharts/amcharts5/stock'
import { useEffect, Component} from "react";
import React from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { StockChartVNINDEX } from "./StockChart.deri.dashboard";


class InvestorType extends Component {

    constructor(props){
      super(props); //
      this.state = {
        Maker0: 0,
        Score0: 0,
        ScoreL0: 0,
        Delta: 0,
        isMidTDOn: false,
        isDeltaOn: false,
        isMakerChg: false
      }
    }
    componentDidMount(){

            
          
        let root = am5.Root.new(this.props.id);
        
        root._logo.dispose();


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
        am5themes_Dark.new(root)
        ]);

       
        
    
// Create chart
// https://www.amcharts.com/docs/v5/charts/xy-chart/
let chart = root.container.children.push(
  am5xy.XYChart.new(root, {
    panX: true,
    panY: true,
    wheelX: "panX",
    wheelY: "zoomX"
  })
);
chart.get("colors").set("colors", [
  am5.color('#DE3163'),
  am5.color('#FF7F50'),
  am5.color('#FFBF00'),
  am5.color('#FFFAF0'),
]);

// Create axes
let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
    maxDeviation: 0.2,
    baseInterval: {
    timeUnit: "day",
    count: 1
    },
    renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 70,
        cellStartLocation: 0.025,
        cellEndLocation: 0.975,
        fontSize: 20
    }),
    tooltip: am5.Tooltip.new(root, {}),

}));


let yAxis = chart.yAxes.push(
  am5xy.ValueAxis.new(root, {
    renderer: am5xy.AxisRendererY.new(root, {}),
    max: 100
  })
);

xAxis.children.push(am5.Label.new(root, {
  text: 'Date (MM-DD)',
  textAlign: 'center',
  x: am5.p50,
  fontWeight: '500',
  fontSize: 14,
  textDecoration: "underline"
}));

yAxis.children.unshift(am5.Label.new(root, {
  text: 'Percentage Of Position (%)',
  textAlign: 'center',
  y: am5.p50,
  rotation: -90,
  fontWeight: '500',
  fontSize: 14,
  textDecoration: "underline"
}));

xAxis.get("renderer").labels.template.setAll({
    fontSize: 15
});

yAxis.get("renderer").labels.template.setAll({
    fontSize: 15
});



// Add series
// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
let series = chart.series.push(
  am5xy.SmoothedXLineSeries.new(root, {
    name: 'Maker',
    minBulletDistance: 10,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "value",
    valueXField: "date",
    tooltip: am5.Tooltip.new(root, {
      pointerOrientation: "horizontal",
      labelText: "{valueY}"
    }),
    
    
  })

);


series.strokes.template.setAll({
  strokeWidth: 3
});






let series2 = chart.series.push(
  am5xy.SmoothedXLineSeries.new(root, {
    name: 'Mid.Inv',
    minBulletDistance: 10,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "value2",
    valueXField: "date",
    tooltip: am5.Tooltip.new(root, {
      pointerOrientation: "horizontal",
      labelText: "{valueY}"
    })
  })
);

series2.strokes.template.setAll({
  strokeWidth: 3
});



let series3 = chart.series.push(
  am5xy.SmoothedXLineSeries.new(root, {
    name: 'Retailer',
    minBulletDistance: 10,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "value3",
    valueXField: "date",
    tooltip: am5.Tooltip.new(root, {
      pointerOrientation: "horizontal",
      labelText: "{valueY}"
    })
  })
);

series3.strokes.template.setAll({
  strokeWidth: 3
});


let series4 = chart.series.push(
  am5xy.SmoothedXLineSeries.new(root, {
    name: 'Delta M-R',
    minBulletDistance: 10,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "value4",
    valueXField: "date",
    tooltip: am5.Tooltip.new(root, {
      pointerOrientation: "horizontal",
      labelText: "{valueY}"
    })
  })
);

series4.strokes.template.setAll({
  strokeWidth: 1
});

let series5 = chart.series.push(
    am5xy.SmoothedXLineSeries.new(root, {
      name: 'MKChg',
      minBulletDistance: 10,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value5",
      valueXField: "date",
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: "{valueY}"
      })
    })
  );
  
  series5.strokes.template.setAll({
    strokeWidth: 1
  });




let legend = chart.rightAxesContainer.children.push(am5.Legend.new(root, {
  width: 75,
  paddingLeft: 5,
  height: am5.percent(100)
}));

// When legend item container is hovered, dim all the series except the hovered one
legend.itemContainers.template.events.on("pointerover", function(e) {
  let itemContainer = e.target;

  // As series list is data of a legend, dataContext is series
  let series = itemContainer.dataItem.dataContext;

  chart.series.each(function(chartSeries) {
    if (chartSeries != series) {
      chartSeries.strokes.template.setAll({
        strokeOpacity: 0.15,
        stroke: am5.color(0x000000)
      });
    } else {
      chartSeries.strokes.template.setAll({
        strokeWidth: 3
      });
    }
  })
})

legend.itemContainers.template.events.on("pointerout", function(e) {
  let itemContainer = e.target;
  let series = itemContainer.dataItem.dataContext;

  chart.series.each(function(chartSeries) {
    chartSeries.strokes.template.setAll({
      strokeOpacity: 1,
      stroke: chartSeries.get("fill")
    });
  });
})

legend.labels.template.setAll({
    fontSize: 12,
    fontWeight: "500",
    height: 15
  });

legend.itemContainers.template.set("width", am5.p100);
legend.valueLabels.template.setAll({
  width: am5.p100,
  textAlign: "right"
});

// It's is important to set legend data after all the events are set on template, otherwise events won't be copied
legend.data.setAll(chart.series.values);


// Add cursor
// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
  xAxis: xAxis
}));
cursor.lineY.set("visible", false);

// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/
series.appear(1000, 100);
chart.appear(1000, 100);

// Function to add process control ranges


function createRange(value, endValue, label, color, dashed, labelname) {
  let rangeDataItem = yAxis.makeDataItem({
    value: value,
    endValue: endValue
  });
  
  let range = yAxis.createAxisRange(rangeDataItem);
  
  if (endValue) {
    range?.get("axisFill")?.setAll({
      fill: color,
      fillOpacity: 0.2,
      visible: true
    });
  }
  else {
    range?.get("grid")?.setAll({
      stroke: color,
      strokeOpacity: 1,
      strokeWidth: 2,
      location: 1
    });
    
    if (dashed) {
      range?.get("grid")?.set("strokeDasharray", [5, 3]);
    }
  }

  switch(labelname){
    case 'up': 
      if (label) {
        range?.get("label")?.setAll({
          text: label,
          location: 1,
          fontSize: 15,
          inside: true,
          centerX: am5.p0,
          centerY: am5.p100
        });
      }
      break;
    case 'dn': 
      if (label) {
        range?.get("label")?.setAll({
          text: label,
          location: 1,
          fontSize: 15,
          inside: true,
          centerX: am5.p0,
          centerY: am5.p0
        });
      }
      break;
    default: if (label) {
      range?.get("label")?.setAll({
        text: label,
        location: 1,
        fontSize: 15,
        inside: true,
        centerX: am5.p0,
        centerY: am5.p100
      });
    }
  }
}

    var data = this?.props?.data

                
            var date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            let setDate = new Date(date)
            let setDate2 = new Date(date)
            let setDate3 = new Date(date)
            date.setHours(0, 0, 0, 0);
            am5.time.add(date, "day", 0);

            

            const dataOri = data[0]
            var data_ = []
            for(let i=0; i<=39; i++){
                
                setDate.setDate(setDate.getDate()-1)
                data_.push({date: setDate.getTime(),x: 40-i, value: (dataOri[`MK${i}`]), value2: (dataOri[`SH${i}`]), value3:  (dataOri[`SC${i}`]), value4:  (dataOri[`MK${i}`])-(dataOri[`SC${i}`]), value5:  (dataOri[`MK${i}`])-(dataOri[`MK${i+1}`])})
            }

            var data2_ = []
            for(let i=0; i<=19; i++){
                setDate2.setDate(setDate2.getDate()-1)
                data2_.push({date: setDate2.getTime(),x: 20-i, value: (dataOri[`MK${i}`]), value2: (dataOri[`SH${i}`]), value3:  (dataOri[`SC${i}`]), value4:  (dataOri[`MK${i}`])-(dataOri[`SC${i}`]), value5:  (dataOri[`MK${i}`])-(dataOri[`MK${i+1}`])})
            }

            
            var data3_ = []
            for(let i=0; i<=9; i++){
                setDate3.setDate(setDate2.getDate()-1)
                data3_.push({date: setDate3.getTime(),x: 10-i, value: (dataOri[`MK${i}`]), value2: (dataOri[`SH${i}`]), value3:  (dataOri[`SC${i}`]), value4:  (dataOri[`MK${i}`])-(dataOri[`SC${i}`]), value5:  (dataOri[`MK${i}`])-(dataOri[`MK${i+1}`])})
            }


            function addLimits(lower, upper, middle, zoneCol,midName) {
              // Add range fill
              createRange(lower, upper, undefined, am5.color(zoneCol));
              
              
              createRange(middle, undefined, midName, am5.color('#FFFFFF'), true);
              createRange(100, undefined, "MAX", am5.color('#D0D3D4'), false, 'dn')
              createRange(0, undefined, "MIN", am5.color('#D0D3D4'), false, 'up')
              
            }

            var sortedArray = data_.map(x => (x.value+x.value2+x.value3)/3).sort((a,b) => {return a-b})
            var median = (1+data_?.length)/2
            var firstQuartile = (1+data_?.length)/4
            var thirdQuartile = 3*(1+Math.ceil(data_?.length))/4

            
            var sortedArray2 = data2_.map(x => (x.value+x.value2+x.value3)/3).sort((a,b) => {return a-b})
            var median2 = (1+data2_?.length)/2
            var firstQuartile2 = (1+data2_?.length)/4
            var thirdQuartile2 = 3*(1+Math.ceil(data2_?.length))/4

            
            var sortedArray3 = data3_.map(x => (x.value+x.value2+x.value3)/3).sort((a,b) => {return a-b})
            var median3 = (1+data3_?.length)/2
            var firstQuartile3 = (1+data3_?.length)/4
            var thirdQuartile3 = 3*(1+Math.ceil(data3_?.length))/4


            
            addLimits(
              sortedArray[Math.round(firstQuartile)],     
              sortedArray[Math.round(thirdQuartile)], 
              sortedArray[Math.round(median)],
              '#303030',
              "Median40"
            )
            addLimits(
                sortedArray2[Math.round(firstQuartile2)],     
                sortedArray2[Math.round(thirdQuartile2)], 
                sortedArray2[Math.round(median2)],
                '#606060',
                "Median20"
            )
            
            addLimits(
                sortedArray3[Math.round(firstQuartile3)],     
                sortedArray3[Math.round(thirdQuartile3)], 
                sortedArray3[Math.round(median3)],
                '#909090',
                "Median10"
            )
              
        
        
            series.data?.setAll(data_);
            series2.data?.setAll(data_);
            series3.data?.setAll(data_)
            series4.data?.setAll(data_);
            series5.data?.setAll(data_);

            this.setState({ScoreL0: data_.map(x => x.value2)[0]})
            
            this.setState({Maker0: data_.map(x => x.value)[0]})
            
            this.setState({Score0: data_.map(x => x.value3)[0]})
            this.setState({Delta: data_.map(x => x.value4)[0]})


        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
            this.root.dispose();
        }
    }


    
    render() {
      function colorByData(v){
        if(v>=50) return 'limegreen'
        if(v<=25) return 'crimson'
        else return 'goldenrod'
      }
      function colorByDataDelta(v){
        if(v>30) return '#FF00FF'
        if(v>20) return '#00CED1'
        else return '#DAA520'
      }
        return (
            <div style={{backgroundColor: '#202020', borderRadius: 10, boxShadow: '5px 5px #101010', alignItems: 'center'}}>
              <AppBar position="static" sx={{backgroundColor: '#202020' }}>
                <Toolbar variant="dense">
                  <Typography sx={{textAlign: 'left'}} variant="body1" padding={1.25} fontWeight={600} color={'#DCDCDC'} backgroundColor={'#353535'}>
                      Sự Tương Quan Giữa Nhà Đầu Tư và Tạo Lập
                  </Typography>
                  
                </Toolbar>
              </AppBar>
                
                <div style={{paddingLeft: 40, display: 'flex', justifyContent: 'space-around', alignItems: 'center', border: '3px solid #303030', padding: 7}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '120px', height: '65px', alignItems: 'center', padding: 0.5, borderRadius: 3, backgroundColor: '#151515'}}>
                      <Typography variant="h6" fontWeight="bold" >
                        Maker
                      </Typography>
                      <Typography variant="body1" color={colorByData(this.state.Maker0)}>
                        {Number(this.state.Maker0.toFixed(1))}%
                      </Typography>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '120px', height: '65px', alignItems: 'center', padding: 0.5, borderRadius: 3, backgroundColor: '#151515'}}>
                      <Typography variant="h6" fontWeight="bold" >
                        MidTD
                      </Typography>
                      <Typography variant="body1" color={colorByData(this.state.ScoreL0)}>
                        {Number(this.state.ScoreL0.toFixed(1))}%
                      </Typography>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '120px', height: '65px', alignItems: 'center', padding: 0.5, borderRadius: 3, backgroundColor: '#151515'}}>
                      <Typography variant="h6" fontWeight="bold" >
                        Retailer
                      </Typography>
                      <Typography variant="body1" color={colorByData(this.state.Score0)}>
                        {Number(this.state.Score0.toFixed(1))}%
                      </Typography>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '120px', height: '65px', alignItems: 'center', padding: 0.5, borderRadius: 3, backgroundColor: '#151515'}}>
                      <Typography variant="h6" fontWeight="bold" >
                        Delta
                      </Typography>
                      <Typography variant="body1" color={colorByDataDelta(this.state.Delta)}>
                        {Number(this.state.Delta.toFixed(1))}%
                      </Typography>
                    </Box>
                  </div>
                  
          <StockChartVNINDEX chartControlID={'rgjrig'} id={'fbifnbfibn'}/>
                <div id={this.props.id} style={{height: 350, width: '100%', border: '3px solid #303030', padding: 5}}>
                  
                </div>
                
            </div>
        );
    }
}

export default InvestorType;