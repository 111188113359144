import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import {Component} from "react";
import React from "react";


export class LienTangCompare extends Component {
    constructor(props){
        super(props); //
        this.state = {
            TotalUp: 0,
            TotalDn: 0,
            MaxRBuy: '',
            MaxRSell: 0,
        }
      }
    componentDidMount(){

            
          
        let root = am5.Root.new(this.props.id);
        
        root._logo.dispose();


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
        am5themes_Dark.new(root)
        ]);

       
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            paddingLeft: 0,
            paddingRight: 0,
            layout: root.verticalLayout
        })
        );
        chart.getNumberFormatter().set("numberFormat", "#.##");

        // Create axes and their renderers
        let yRenderer = am5xy.AxisRendererY.new(root, {
        visible: false,
        minGridDistance: 20,
        inversed: true,
        minorGridEnabled: true
        });

        yRenderer.grid.template.set("visible", false);

        let yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            renderer: yRenderer,
            categoryField: "category"
        })
        );

        let xRenderer = am5xy.AxisRendererX.new(root, {
        visible: false,
        minGridDistance: 30,
        inversed: true,
        minorGridEnabled: true
        });

        xRenderer.grid.template.set("visible", false);

        let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: "category"
        })
        );

        xAxis.children.push(am5.Label.new(root, {
            text: 'Sector | Group',
            textAlign: 'center',
            x: am5.p50,
            fontWeight: '500',
            fontSize: 14,
            textDecoration: "underline"
          }));
          
          yAxis.children.unshift(am5.Label.new(root, {
            text: 'Future | Index | MarketCap Type',
            textAlign: 'center',
            y: am5.p50,
            rotation: -90,
            fontWeight: '500',
            fontSize: 14,
            textDecoration: "underline"
          }));

        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
        let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            calculateAggregates: true,
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: "x",
            categoryYField: "y",
            valueField: "value"
        })
        );

        series.columns.template.setAll({
        tooltipText: "{value}",
        strokeOpacity: 1,
        strokeWidth: 2,
        cornerRadiusTL: 5,
        cornerRadiusTR: 5,
        cornerRadiusBL: 5,
        cornerRadiusBR: 5,
        width: am5.percent(100),
        height: am5.percent(100),
        templateField: "columnSettings"
        });

        let circleTemplate = am5.Template.new({});

        // Add heat rule
        // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
        series.set("heatRules", [{
        target: circleTemplate,
        min: 10,
        max: 35,
        dataField: "value",
        key: "radius"
        }]);

        series.bullets.push(function () {
        return am5.Bullet.new(root, {
            sprite: am5.Circle.new(
            root,
            {
                fill: am5.color(0x000000),
                fillOpacity: 0.6,
                strokeOpacity: 0
            },
            circleTemplate
            )
        });
        });

        series.bullets.push(function () {
        return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
            fill: am5.color(0xffffff),
            populateText: true,
            centerX: am5.p50,
            centerY: am5.p50,
            fontSize: 12,
            text: "{value}"
            })
        });
        });


        xAxis.get("renderer").labels.template.setAll({
            fontSize: 10
        });
    
        yAxis.get("renderer").labels.template.setAll({
            fontSize: 10
        });
        

        

        
        var data = this?.props?.data
                function filterby(industry){
                    return data?.filter(x => {
                        return x.Sector===industry
                    })?.[0]
                }

                function addvalue(industry){
                    return `${industry}(${filterby(industry)?.MakerAverage<0?'-':'+'})`
                }

                

                const industryListName = ['MASAN','VIN','DUOC PHAM', "DET MAY", "DTC", 'PHAN BON', 'VAN TAI BIEN', 'DAU KHI', 'THEP', 'BAN LE','NGAN HANG', 'CHUNG KHOAN', 'BDS']
                const highLevListName = ['Future', 'Index', 'B_', 'M_', 'P_']


                function chgNameIndustry(x){
                    return x.replace('VAN TAI BIEN', 'VẬN TẢI')
                            .replace('DAU KHI', 'DẦU')
                            .replace('THEP', 'THÉP')
                            .replace('BAN LE', 'BÁN LẺ')
                            .replace('NGAN HANG', 'BANK')
                            .replace('CHUNG KHOAN', 'CHỨNG KH')
                            .replace('BDS', 'BDS')
                            .replace('PHAN BON', 'PHÂN')
                            .replace('DTC', 'ĐTCÔNG')
                            .replace('DUOC PHAM', 'DƯỢC')
                            .replace('VIN', 'GVIN')
                            .replace('MASAN', 'GMASAN')
                            .replace('DET MAY', 'DETMAY')
                }
                
                function chgNameHighLev(x){
                    return x.replace('Future', 'PHÁI SINH')
                            .replace('Index', 'CHỈ SỐ')
                            .replace('B_', 'BLUECHIP')
                            .replace('M_', 'MIDCAP')
                            .replace('P_', 'PENNY')
                }
                
                
                
                const industryListByV = industryListName?.map(x => {
                    return addvalue(x)
                })

                
                const highLevListByV = highLevListName?.map(x => {
                    return addvalue(x)
                })


                var data__ = []

                function dataCol(x1,x2,x){

                    if(x<2 && x2>0 && x1>0) return am5.color(0x5dbe24)
                    if(x>=2 && x2>0 && x1>0) return am5.color(0x0b7d03)
                    if(x2<0 && x1<0) return am5.color(0xca0101)
                    if((x1>0 && Math.abs(x)<2) || (x2>0 && Math.abs(x)<2)) return am5.color(0xe1d92d)
                    if((x1>0 && Math.abs(x)>=2) || (x2>0 && Math.abs(x)>=2)) return am5.color(0xe17a2d)
                }

                for(let i = 0; i<industryListName.length;i++){
                    for(let j = 0; j<highLevListName.length; j++){
                        data__.push({
                            x: chgNameIndustry(industryListByV[i]),
                            y: chgNameHighLev(highLevListByV[j]),
                            columnSettings: {
                            fill: dataCol(
                                filterby(industryListName[i])?.MakerAverage,
                                filterby(highLevListName[j])?.MakerAverage,
                                filterby(industryListName[i])?.MakerAverage/filterby(highLevListName[j])?.MakerAverage)
                            },
                            value: Math.abs(Number(filterby(industryListName[i])?.MakerAverage/filterby(highLevListName[j])?.MakerAverage))
                        })
                    }
                    
                }

                console.log(data__)

                xAxis.data.setAll(industryListByV.map(x => {
                    return {
                        category: chgNameIndustry(x)
                    }
                }));

                yAxis.data.setAll(highLevListByV.map(x => {
                    return {
                        category: chgNameHighLev(x)
                    }
                }));
                    series.data.setAll(data__ );

                    
          
        
        
        
        
        
        
        
        // Add series
        
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomY"
        }));
        cursor.lineY.set("forceHidden", true);
        cursor.lineX.set("forceHidden", true);

        
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);
  
        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
    }

    render() {
        return (
            <><div style={{backgroundColor: '#202020', boxShadow: '5px 5px #101010', alignItems: 'center', width: this.props.width, height: this.props.height}}>
            
                <div id={this.props.id} style={{width: '95%', height: 380, padding: 10}}>
                </div>
            </div>
            </>
        );
    }
}

export default LienTangCompare;