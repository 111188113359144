import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, TickerNo, TickerList, Statistic) {
  return { name, TickerNo, TickerList, Statistic };
}

const styleHeader = {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 20
}

const styleBody = {
    color: 'white'
}




export default function MatrixRecom(props) {
    const {data} = props
    const STRATEGY1 = data?.filter(
        x => {
            return (
                Number(x.SB3MK0A)>=0.5 &&
                Number(x.SB3SC0A)>=0 &&
                Number(x.SB3SH0)>=0 &&
                Number(x.ChuKiTangGiam)>=2&&
                ((String(x.S2Col)?.includes('=>G')) || 
                 (String(x.S2Col)==='GREEN' && Number(x.S2No)<=10)
                )&&
                Number(x.VolumeAverage)>=100000 && 
                Number (x.Close)>=20 &&
                Number(x.S3ScoreStrengthDelta)>=0.1 &&
                Number(x.S4MakerStrengthDelta)>=0.1 &&
                Number(x.S1Rating?.includes('A5'))
            )
        }
    )?.sort((a,b) => {
        return Number(b.SB3MK0A)-Number(a.SB3MK0A)
    })

    const STRATEGY2 = data?.filter(
        x => {
            return (
                Number(x.SB3MK0A)>=0 &&
                Number(x.ChuKiTangGiam)>=0&&
                ((String(x.S2Col)?.includes('=>G'))  || 
                (String(x.S2Col)==='GREEN' && Number(x.S2No)<=2)
                )&&
                Number(x.VolumeAverage)>=100000 && 
                Number(x.S1Rating?.includes('A5'))
            )
        }
    )?.sort((a,b) => {
        return Number(b.SB3MK0A)-Number(a.SB3MK0A)
    })
    
    const STRATEGY3 = data?.filter(
        x => {
            return (
                Number(x.SB3MK0A)>=0 &&
                (
                (String(x.S2Col)==='GREEN')
                )&&
                Number(x.VolumeAverage)>=100000
            )
        }
    )?.sort((a,b) => {
        return Number(b.SB3MK0A)-Number(a.SB3MK0A)
    })
    
    
    const rows = [
        createData(
          'FULLCOND', 
          STRATEGY1?.length,
          STRATEGY1?.slice(0,Math.min(15,STRATEGY1?.length)).map(
            x => {
                return (
                    <span>{x.Ticker},</span>
                )
            }
        ), 
          <div>
            <div>
                ScoreAverage: {Math.ceil(STRATEGY1?.map(x => Number(x.SB3SC0A))?.reduce((a,b) => a + b)/STRATEGY1?.length)}.0
            </div>
            <div>
                MakerAverage: {Math.ceil(STRATEGY1?.map(x => Number(x.SB3MK0A))?.reduce((a,b) => a + b)/STRATEGY1?.length)}.0
            </div>
            
            <div>
                TrendAverage: {Math.ceil(STRATEGY1?.map(x => Number(x.ChuKiTangGiam))?.reduce((a,b) => a + b)/STRATEGY1?.length)}%
            </div>
          </div>
        ),
        createData(
            'NEWGREEN', 
            STRATEGY2?.length,
            STRATEGY2?.slice(0,Math.min(15,STRATEGY2?.length))?.map(
              x => {
                  return (
                      <span>{x.Ticker},</span>
                  )
              }
          ), 
            <div>
              <div>
                  ScoreAverage: {Math.ceil(STRATEGY2?.map(x => Number(x.SB3SC0A))?.reduce((a,b) => a + b)/STRATEGY2?.length)}.0
              </div>
              <div>
                  MakerAverage: {Math.ceil(STRATEGY2?.map(x => Number(x.SB3MK0A))?.reduce((a,b) => a + b)/STRATEGY2?.length)}.0
              </div>
            <div>
                TrendAverage: {Math.ceil(STRATEGY2?.map(x => Number(x.ChuKiTangGiam))?.reduce((a,b) => a + b)/STRATEGY2?.length)}%
            </div>
            </div>
          ),
        createData(
            'ALLGREEN', 
            STRATEGY3?.length,
            STRATEGY3?.slice(0,Math.min(15,STRATEGY3?.length))?.map(
              x => {
                  return (
                      <span>{x.Ticker},</span>
                  )
              }
          ), 
            <div>
              <div>
                  ScoreAverage: {Math.ceil(STRATEGY3?.map(x => Number(x.SB3SC0A))?.reduce((a,b) => a + b)/STRATEGY3?.length)}.0
              </div>
              <div>
                  MakerAverage: {Math.ceil(STRATEGY3?.map(x => Number(x.SB3MK0A))?.reduce((a,b) => a + b)/STRATEGY3?.length)}.0
              </div>
            <div>
                TrendAverage: {Math.ceil(STRATEGY3?.map(x => Number(x.ChuKiTangGiam))?.reduce((a,b) => a + b)/STRATEGY3?.length)}%
            </div>
            </div>
          ),
    ];
  return (
    <TableContainer sx={{color: 'white'}}>
      <Table sx={{ minWidth: 800 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={styleHeader}>Strategy Name</TableCell>
            <TableCell sx={styleHeader} align="center">No of Ticker</TableCell>
            <TableCell sx={styleHeader} align="center">Ticker List</TableCell>
            <TableCell sx={styleHeader} align="center">Statistics</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={styleBody} component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell sx={styleBody} align="center">{row.TickerNo}</TableCell>
              <TableCell sx={{color: 'white', maxWidth: 500, textOverFlow: 'ellipsis', whiteSpace: 'nowrap', overFlow: 'hidden'}} align="center">{row.TickerList}</TableCell>
              <TableCell sx={styleBody} align="center">{row.Statistic}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
