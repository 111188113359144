
import React, {useMemo, useState, useRef, useEffect} from 'react'
import { Typography } from '@mui/material'
import { AgGridReact } from 'ag-grid-react';
import MKStructureDistBoxPlot from './MKStructureDistBoxPlot.DeepA';

export default function MarketStructureDistribution (props)  {

    const {data} = props
    const gridRef = useRef();

    console.log(data)

    


        const TICKERVNCOL = {
            'px25font': (params) => 1>0
          };
        
    
    function getSectorDataB(SectorName) {
        var SectorScore = 
        ( 
            (data?.filter(x => x.Sector===SectorName)[0]?.LastDaysData?.Maker[39])
        )       
                        return SectorScore
    }

    const data_MatrixFutureForceB = 
      [
        {
            "BDS": {name: "BDS", sectorScore: getSectorDataB("BDS")},
            "BANLE": {name: "BANLE", sectorScore: getSectorDataB("BAN LE")},
            "BAOHIEM":  {name: "BAOHIEM", sectorScore: getSectorDataB("BAO HIEM")},
            "CHUNGKHOAN":  {name: "CHUNGKHOAN", sectorScore: getSectorDataB("CHUNG KHOAN")},
            "CONGNGHE":  {name: "CONGNGHE", sectorScore: getSectorDataB("CONG NGHE")},
            "DETMAY":  {name: "DETMAY", sectorScore: getSectorDataB("DET MAY")},
            "DIEN":  {name: "DIEN", sectorScore: getSectorDataB("DIEN")},
            "DUOCPHAM":  {name: "DUOCPHAM", sectorScore: getSectorDataB("DUOC PHAM")},
            "DAU":  {name: "DAU", sectorScore: getSectorDataB("DAU KHI")},
            "DUONG":  {name: "DUONG", sectorScore: getSectorDataB("DUONG")},
            "H_TIEUDUNG":  {name: "H_TIEUDUNG"},
            "HOACHAT":  {name: "HOACHAT", sectorScore: getSectorDataB("PHAN BON")},
            "KKTHAN":  {name: "KKTHAN", sectorScore: getSectorDataB("THAN")},
            "NGANHANG":  {name: "NGANHANG", sectorScore: getSectorDataB("NGAN HANG")},
            "NHUA":  {name: "NHUA", sectorScore: getSectorDataB("NHUA")},
            "NONGSAN":  {name: "NONGSAN", sectorScore: getSectorDataB("NONG SAN")},
            "THEP":  {name: "THEP", sectorScore:getSectorDataB("THEP")},
            "THUCPHAM":  {name: "THUCPHAM", sectorScore:getSectorDataB("THUC PHAM")},
            "THUYSAN":  {name: "THUYSAN", sectorScore: getSectorDataB("THUY SAN")},
            "VANTAI":  {name: "VANTAI", sectorScore: getSectorDataB("VAN TAI BIEN")},
            "VLXD":  {name: "VLXD", sectorScore: getSectorDataB("VLXD")},
            "XAYDUNG":  {name: "XAYDUNG", sectorScore: getSectorDataB("XAY DUNG")},
            "BDSKCN":  {name: "BDSKCN", sectorScore: getSectorDataB("BKCN")},
            "DTC":  {name: "DTC", sectorScore: getSectorDataB("DTC")},
            "Penny":  {name: "PENNY*", sectorScore: getSectorDataB("P_")},
            "Midcap":  {name: "MIDCAP*", sectorScore: getSectorDataB("M_")},
            "BlueChip":  {name: "BLUECHIP*", sectorScore: getSectorDataB("B_")},
            "Index":  {name: "INDEX**", sectorScore: getSectorDataB("Index")},
            "Future":  {name: "FUTURE**", sectorScore: getSectorDataB("Future")},

        },
      ]

    const data_MatrixFutureForceB_X = 
    [
      
        {name: "BDS", sectorScore: getSectorDataB("BDS")},
        {name: "BANLE", sectorScore: getSectorDataB("BAN LE")},
        {name: "BAOHIEM", sectorScore: getSectorDataB("BAO HIEM")},
        {name: "CHUNGKHOAN", sectorScore: getSectorDataB("CHUNG KHOAN")},
        {name: "CONGNGHE", sectorScore: getSectorDataB("CONG NGHE")},
        {name: "DETMAY", sectorScore: getSectorDataB("DET MAY")},
        {name: "DIEN", sectorScore: getSectorDataB("DIEN")},
        {name: "DUOCPHAM", sectorScore: getSectorDataB("DUOC PHAM")},
        {name: "DAU", sectorScore: getSectorDataB("DAU KHI")},
        {name: "DUONG", sectorScore: getSectorDataB("DUONG")},
        {name: "H_TIEUDUNG"},
        {name: "HOACHAT", sectorScore: getSectorDataB("PHAN BON")},
        {name: "KKTHAN", sectorScore: getSectorDataB("THAN")},
        {name: "NGANHANG", sectorScore: getSectorDataB("NGAN HANG")},
        {name: "NHUA", sectorScore: getSectorDataB("NHUA")},
        {name: "NONGSAN", sectorScore: getSectorDataB("NONG SAN")},
        {name: "THEP", sectorScore:getSectorDataB("THEP")},
        {name: "THUCPHAM", sectorScore:getSectorDataB("THUC PHAM")},
        {name: "THUYSAN", sectorScore: getSectorDataB("THUY SAN")},
        {name: "VANTAI", sectorScore: getSectorDataB("VAN TAI BIEN")},
        {name: "VLXD", sectorScore: getSectorDataB("VLXD")},
        {name: "XAYDUNG", sectorScore: getSectorDataB("XAY DUNG")},
        {name: "BDSKCN", sectorScore: getSectorDataB("BKCN")},
        {name: "DTC", sectorScore: getSectorDataB("DTC")},
        {name: "PENNY*", sectorScore: getSectorDataB("P_")},
        {name: "MIDCAP*", sectorScore: getSectorDataB("M_")},
        {name: "BLUECHIP*", sectorScore: getSectorDataB("B_")},
        {name: "INDEX**", sectorScore: getSectorDataB("Index")},
        {name: "FUTURE**", sectorScore: getSectorDataB("Future")},
  ]

  const data_MatrixFutureForceB_X_ = 
    [
      
        {x: "BDS", y: getSectorDataB("BDS")},
        {x: "BANLE", y: getSectorDataB("BAN LE")},
        {x: "BAOHIEM", y: getSectorDataB("BAO HIEM")},
        {x: "CHUNGKHOAN", y: getSectorDataB("CHUNG KHOAN")},
        {x: "CONGNGHE", y: getSectorDataB("CONG NGHE")},
        {x: "DETMAY", y: getSectorDataB("DET MAY")},
        {x: "DIEN", y: getSectorDataB("DIEN")},
        {x: "DUOCPHAM", y: getSectorDataB("DUOC PHAM")},
        {x: "DAU", y: getSectorDataB("DAU KHI")},
        {x: "DUONG", y: getSectorDataB("DUONG")},
        {x: "H_TIEUDUNG"},
        {x: "HOACHAT", y: getSectorDataB("PHAN BON")},
        {x: "KKTHAN", y: getSectorDataB("THAN")},
        {x: "NGANHANG", y: getSectorDataB("NGAN HANG")},
        {x: "NHUA", y: getSectorDataB("NHUA")},
        {x: "NONGSAN", y: getSectorDataB("NONG SAN")},
        {x: "THEP", y:getSectorDataB("THEP")},
        {x: "THUCPHAM", y:getSectorDataB("THUC PHAM")},
        {x: "THUYSAN", y: getSectorDataB("THUY SAN")},
        {x: "VANTAI", y: getSectorDataB("VAN TAI BIEN")},
        {x: "VLXD", y: getSectorDataB("VLXD")},
        {x: "XAYDUNG", y: getSectorDataB("XAY DUNG")},
        {x: "BDSKCN", y: getSectorDataB("BKCN")},
        {x: "DTC", y: getSectorDataB("DTC")},
        {x: "PENNY*", y: getSectorDataB("P_")},
        {x: "MIDCAP*", y: getSectorDataB("M_")},
        {x: "BLUECHIP*", y: getSectorDataB("B_")},
        {x: "INDEX**", y: getSectorDataB("Index")},
        {x: "FUTURE**", y: getSectorDataB("Future")},
  ]


    const SORTEDSectorData = data_MatrixFutureForceB_X?.sort((a,b) => a.sectorScore-b.sectorScore)?.map(x => x.sectorScore)
    console.log(SORTEDSectorData)
    var Q1 = SORTEDSectorData[Math.round((SORTEDSectorData?.length+1)/4)]
    var Q2 = SORTEDSectorData[Math.round((SORTEDSectorData?.length+1)/2)]
    var Q3 = SORTEDSectorData[Math.round(3*(SORTEDSectorData?.length+1)/4)]
    var IQR = Q3-Q1
    var OutLLo = (IQR<0)?(Q1+IQR*1.5):(Q1-IQR*1.5)
    var OutLHi = (IQR<0)?(Q3-IQR*1.5):(Q3+IQR*1.5)
    var Low = SORTEDSectorData[0]
    var High = SORTEDSectorData[SORTEDSectorData?.length-1]

    




    const scoreArray = [getSectorDataB("BDS"),getSectorDataB("BAN LE"),getSectorDataB("BAO HIEM"),getSectorDataB("CHUNG KHOAN"),getSectorDataB("CONG NGHE"),getSectorDataB("DET MAY"),getSectorDataB("DIEN"),getSectorDataB("DUOC PHAM"),getSectorDataB("DAU KHI"),getSectorDataB("DUONG"),getSectorDataB("BKCN"),getSectorDataB("NGAN HANG"),getSectorDataB("NHUA"),getSectorDataB("NONG SAN"),getSectorDataB("THEP"),getSectorDataB("THUC PHAM"),getSectorDataB("THUY SAN"),getSectorDataB("VAN TAI BIEN"),getSectorDataB("XAY DUNG"),getSectorDataB("BKCN"),getSectorDataB("DTC"),getSectorDataB("P_"),getSectorDataB("M_"),getSectorDataB("B_"),getSectorDataB("Index"),getSectorDataB("Future")].sort((a,b) => a-b)

    const DataAnalysis = () => {

    
        return <div style = {{
          backgroundColor: '#151515',
          borderRadius: 5,
          width: '100%',
          height: 200
        }}>
          <div>
            <Typography
              variant = {'h5'}
              padding = {1}
              fontWeight={'bold'}
            >
              ĐỘ PHÂN HÓA:
            {OutLLo?.toFixed(2)}|{Q1?.toFixed(2)}|{Q2?.toFixed(2)}|{Q3?.toFixed(2)}|{OutLHi?.toFixed(2)}
            </Typography>
          </div>
        </div>
      }
    


    var Biendo1 = 0.5;
    var Biendo2 = 2;
    var Biendo3 = 4;
    var Biendo4 = 8;
    var Biendo5 = 10000;
    
    var styleImportance2 = {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'crimson'
    }
    var styleImportance1 = {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'darkorange'
    }
    var styleImportance0 = {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'gold'
    }
    var styleNormal = {
        fontSize: '12px',
        color: 'white'
    }

    function SectorUIGMM(params) {
        function SectorGetter(Sector, Key){
            if(params?.data[Sector]?.sectorScore >= Key[0] && params?.data[Sector]?.sectorScore <= Key[1]){if(params?.data[Sector]?.name.includes("BDS")||params?.data[Sector]?.name.includes("NGAN")||params?.data[Sector]?.name.includes("CHUNG")){
                    return <span style = {params?.data[Sector]?.name==='BDSKCN'?styleNormal:styleImportance0}>{params?.data[Sector]?.name+",\n"}</span>
                }
                if(params?.data[Sector]?.name.includes("**")){
                    return <span style = {styleImportance2}>{params?.data[Sector]?.name+",\n"}</span>
                }
                if(params?.data[Sector]?.name.includes("*")){
                    return <span style = {styleImportance1}>{params?.data[Sector]?.name+",\n"}</span>
                }
                else{
                    return <span style = {styleNormal}>{params?.data[Sector]?.name+',\n'}</span>
                }
            }
            else{
                return <></>
            }
        }

    
        return <React.Fragment>

            {SectorGetter("BDS", [-Biendo5,-Biendo4])}
            {SectorGetter("BANLE", [-Biendo5,-Biendo4])}
            {SectorGetter("BAOHIEM", [-Biendo5,-Biendo4])}
            {SectorGetter("CHUNGKHOAN", [-Biendo5,-Biendo4])}
            {SectorGetter("CONGNGHE", [-Biendo5,-Biendo4])}
            {SectorGetter("DAU", [-Biendo5,-Biendo4])}
            {SectorGetter("DETMAY", [-Biendo5,-Biendo4])}
            {SectorGetter("DIEN", [-Biendo5,-Biendo4])}
            {SectorGetter("DUOCPHAM", [-Biendo5,-Biendo4])}
            {SectorGetter("DUONG", [-Biendo5,-Biendo4])}
            {SectorGetter("H_TIEUDUNG", [-Biendo5,-Biendo4])}
            {SectorGetter("HOACHAT", [-Biendo5,-Biendo4])}
            {SectorGetter("KKTHAN", [-Biendo5,-Biendo4])}
            {SectorGetter("NGANHANG", [-Biendo5,-Biendo4])}
            {SectorGetter("NHUA", [-Biendo5,-Biendo4])}
            {SectorGetter("NONGSAN", [-Biendo5,-Biendo4])}
            {SectorGetter("THEP", [-Biendo5,-Biendo4])}
            {SectorGetter("THUCPHAM", [-Biendo5,-Biendo4])}
            {SectorGetter("THUYSAN", [-Biendo5,-Biendo4])}
            {SectorGetter("VLXD", [-Biendo5,-Biendo4])}
            {SectorGetter("VANTAI", [-Biendo5,-Biendo4])}
            {SectorGetter("XAYDUNG", [-Biendo5,-Biendo4])}
            {SectorGetter("BDSKCN", [-Biendo5,-Biendo4])}
            {SectorGetter("DTC", [-Biendo5,-Biendo4])}
            {SectorGetter("Penny", [-Biendo5,-Biendo4])}
            {SectorGetter("Midcap", [-Biendo5,-Biendo4])}
            {SectorGetter("BlueChip", [-Biendo5,-Biendo4])}
            {SectorGetter("Index", [-Biendo5,-Biendo4])}
            {SectorGetter("Future", [-Biendo5,-Biendo4])}
        </React.Fragment>
        
        }

      function SectorUIGM(params) {
        function SectorGetter(Sector, Key){
            if(params?.data[Sector]?.sectorScore >= Key[0] && params?.data[Sector]?.sectorScore <= Key[1]){if(params?.data[Sector]?.name.includes("BDS")||params?.data[Sector]?.name.includes("NGAN")||params?.data[Sector]?.name.includes("CHUNG")){
                    return <span style = {params?.data[Sector]?.name==='BDSKCN'?styleNormal:styleImportance0}>{params?.data[Sector]?.name+",\n"}</span>
                }
                if(params?.data[Sector]?.name.includes("**")){
                    return <span style = {styleImportance2}>{params?.data[Sector]?.name+",\n"}</span>
                }
                if(params?.data[Sector]?.name.includes("*")){
                    return <span style = {styleImportance1}>{params?.data[Sector]?.name+",\n"}</span>
                }
                else{
                    return <span style = {styleNormal}>{params?.data[Sector]?.name+',\n'}</span>
                }
            }
            else{
                return <></>
            }
        }

    
        return <React.Fragment>
            {SectorGetter("BDS", [-Biendo4,-Biendo3])}
            {SectorGetter("BANLE", [-Biendo4,-Biendo3])}
            {SectorGetter("BAOHIEM", [-Biendo4,-Biendo3])}
            {SectorGetter("CHUNGKHOAN", [-Biendo4,-Biendo3])}
            {SectorGetter("CONGNGHE", [-Biendo4,-Biendo3])}
            {SectorGetter("DAU", [-Biendo4,-Biendo3])}
            {SectorGetter("DETMAY", [-Biendo4,-Biendo3])}
            {SectorGetter("DIEN", [-Biendo4,-Biendo3])}
            {SectorGetter("DUOCPHAM", [-Biendo4,-Biendo3])}
            {SectorGetter("DUONG", [-Biendo4,-Biendo3])}
            {SectorGetter("H_TIEUDUNG", [-Biendo4,-Biendo3])}
            {SectorGetter("HOACHAT", [-Biendo4,-Biendo3])}
            {SectorGetter("KKTHAN", [-Biendo4,-Biendo3])}
            {SectorGetter("NGANHANG", [-Biendo4,-Biendo3])}
            {SectorGetter("NHUA", [-Biendo4,-Biendo3])}
            {SectorGetter("NONGSAN", [-Biendo4,-Biendo3])}
            {SectorGetter("THEP", [-Biendo4,-Biendo3])}
            {SectorGetter("THUCPHAM", [-Biendo4,-Biendo3])}
            {SectorGetter("THUYSAN", [-Biendo4,-Biendo3])}
            {SectorGetter("VLXD", [-Biendo4,-Biendo3])}
            {SectorGetter("VANTAI", [-Biendo4,-Biendo3])}
            {SectorGetter("XAYDUNG", [-Biendo4,-Biendo3])}
            {SectorGetter("BDSKCN", [-Biendo4,-Biendo3])}
            {SectorGetter("DTC", [-Biendo4,-Biendo3])}
            {SectorGetter("Penny", [-Biendo4,-Biendo3])}
            {SectorGetter("Midcap", [-Biendo4,-Biendo3])}
            {SectorGetter("BlueChip", [-Biendo4,-Biendo3])}
            {SectorGetter("Index", [-Biendo4,-Biendo3])}
            {SectorGetter("Future", [-Biendo4,-Biendo3])}
        </React.Fragment>
        
        }

        function SectorUIGV(params) {
            function SectorGetter(Sector, Key){
                if(params?.data[Sector]?.sectorScore >= Key[0] && params?.data[Sector]?.sectorScore <= Key[1]){if(params?.data[Sector]?.name.includes("BDS")||params?.data[Sector]?.name.includes("NGAN")||params?.data[Sector]?.name.includes("CHUNG")){
                    return <span style = {params?.data[Sector]?.name==='BDSKCN'?styleNormal:styleImportance0}>{params?.data[Sector]?.name+",\n"}</span>
                }
                    if(params?.data[Sector]?.name.includes("**")){
                        return <span style = {styleImportance2}>{params?.data[Sector]?.name+",\n"}</span>
                    }
                    if(params?.data[Sector]?.name.includes("*")){
                        return <span style = {styleImportance1}>{params?.data[Sector]?.name+",\n"}</span>
                    }
                    else{
                        return <span style = {styleNormal}>{params?.data[Sector]?.name+',\n'}</span>
                    }
                }
                else{
                    return <></>
                }
            }
    
            return <React.Fragment>
            {SectorGetter("BDS", [-Biendo3,-Biendo2])}
                {SectorGetter("BANLE", [-Biendo3,-Biendo2])}
                {SectorGetter("BAOHIEM", [-Biendo3,-Biendo2])}
                {SectorGetter("CHUNGKHOAN", [-Biendo3,-Biendo2])}
                {SectorGetter("CONGNGHE", [-Biendo3,-Biendo2])}
                {SectorGetter("DAU", [-Biendo3,-Biendo2])}
                {SectorGetter("DETMAY", [-Biendo3,-Biendo2])}
                {SectorGetter("DIEN", [-Biendo3,-Biendo2])}
                {SectorGetter("DUOCPHAM", [-Biendo3,-Biendo2])}
                {SectorGetter("DUONG", [-Biendo3,-Biendo2])}
                {SectorGetter("H_TIEUDUNG", [-Biendo3,-Biendo2])}
                {SectorGetter("HOACHAT", [-Biendo3,-Biendo2])}
                {SectorGetter("KKTHAN", [-Biendo3,-Biendo2])}
                {SectorGetter("NGANHANG", [-Biendo3,-Biendo2])}
                {SectorGetter("NHUA", [-Biendo3,-Biendo2])}
                {SectorGetter("NONGSAN", [-Biendo3,-Biendo2])}
                {SectorGetter("THEP", [-Biendo3,-Biendo2])}
                {SectorGetter("THUCPHAM", [-Biendo3,-Biendo2])}
                {SectorGetter("THUYSAN", [-Biendo3,-Biendo2])}
                {SectorGetter("VLXD", [-Biendo3,-Biendo2])}
                {SectorGetter("VANTAI", [-Biendo3,-Biendo2])}
                {SectorGetter("XAYDUNG", [-Biendo3,-Biendo2])}
                {SectorGetter("BDSKCN", [-Biendo3,-Biendo2])}
                {SectorGetter("DTC", [-Biendo3,-Biendo2])}
                {SectorGetter("Penny", [-Biendo3,-Biendo2])}
                {SectorGetter("Midcap", [-Biendo3,-Biendo2])}
                {SectorGetter("BlueChip", [-Biendo3,-Biendo2])}
                {SectorGetter("Index", [-Biendo3,-Biendo2])}
                {SectorGetter("Future", [-Biendo3,-Biendo2])}
            </React.Fragment>
            
            }

        
            function SectorUIGN(params) {
                function SectorGetter(Sector, Key){
                    if(params?.data[Sector]?.sectorScore >= Key[0] && params?.data[Sector]?.sectorScore <= Key[1]){if(params?.data[Sector]?.name.includes("BDS")||params?.data[Sector]?.name.includes("NGAN")||params?.data[Sector]?.name.includes("CHUNG")){
                        return <span style = {params?.data[Sector]?.name==='BDSKCN'?styleNormal:styleImportance0}>{params?.data[Sector]?.name+",\n"}</span>
                    }
                        if(params?.data[Sector]?.name.includes("**")){
                            return <span style = {styleImportance2}>{params?.data[Sector]?.name+",\n"}</span>
                        }
                        if(params?.data[Sector]?.name.includes("*")){
                            return <span style = {styleImportance1}>{params?.data[Sector]?.name+",\n"}</span>
                        }
                        else{
                            return <span style = {styleNormal}>{params?.data[Sector]?.name+',\n'}</span>
                        }
                    }
                    else{
                        return <></>
                    }
                }
    
                return <React.Fragment>
                {SectorGetter("BDS", [-Biendo2,-Biendo1])}
                    {SectorGetter("BANLE", [-Biendo2,-Biendo1])}
                    {SectorGetter("BAOHIEM", [-Biendo2,-Biendo1])}
                    {SectorGetter("CHUNGKHOAN", [-Biendo2,-Biendo1])}
                    {SectorGetter("CONGNGHE", [-Biendo2,-Biendo1])}
                    {SectorGetter("DAU", [-Biendo2,-Biendo1])}
                    {SectorGetter("DETMAY", [-Biendo2,-Biendo1])}
                    {SectorGetter("DIEN", [-Biendo2,-Biendo1])}
                    {SectorGetter("DUOCPHAM", [-Biendo2,-Biendo1])}
                    {SectorGetter("DUONG", [-Biendo2,-Biendo1])}
                    {SectorGetter("H_TIEUDUNG", [-Biendo2,-Biendo1])}
                    {SectorGetter("HOACHAT", [-Biendo2,-Biendo1])}
                    {SectorGetter("KKTHAN", [-Biendo2,-Biendo1])}
                    {SectorGetter("NGANHANG", [-Biendo2,-Biendo1])}
                    {SectorGetter("NHUA", [-Biendo2,-Biendo1])}
                    {SectorGetter("NONGSAN", [-Biendo2,-Biendo1])}
                    {SectorGetter("THEP", [-Biendo2,-Biendo1])}
                    {SectorGetter("THUCPHAM", [-Biendo2,-Biendo1])}
                    {SectorGetter("THUYSAN", [-Biendo2,-Biendo1])}
                    {SectorGetter("VLXD", [-Biendo2,-Biendo1])}
                    {SectorGetter("VANTAI", [-Biendo2,-Biendo1])}
                    {SectorGetter("XAYDUNG", [-Biendo2,-Biendo1])}
                    {SectorGetter("BDSKCN", [-Biendo2,-Biendo1])}
                    {SectorGetter("DTC", [-Biendo2,-Biendo1])}
                    {SectorGetter("Penny", [-Biendo2,-Biendo1])}
                    {SectorGetter("Midcap", [-Biendo2,-Biendo1])}
                    {SectorGetter("BlueChip", [-Biendo2,-Biendo1])}
                    {SectorGetter("Index", [-Biendo2,-Biendo1])}
                    {SectorGetter("Future", [-Biendo2,-Biendo1])}
                </React.Fragment>
                
                }

            
        function SectorUITC(params) {
            function SectorGetter(Sector, Key){
                if(params?.data[Sector]?.sectorScore >= Key[0] && params?.data[Sector]?.sectorScore <= Key[1]){if(params?.data[Sector]?.name.includes("BDS")||params?.data[Sector]?.name.includes("NGAN")||params?.data[Sector]?.name.includes("CHUNG")){
                    return <span style = {params?.data[Sector]?.name==='BDSKCN'?styleNormal:styleImportance0}>{params?.data[Sector]?.name+",\n"}</span>
                }
                    if(params?.data[Sector]?.name.includes("**")){
                        return <span style = {styleImportance2}>{params?.data[Sector]?.name+",\n"}</span>
                    }
                    if(params?.data[Sector]?.name.includes("*")){
                        return <span style = {styleImportance1}>{params?.data[Sector]?.name+",\n"}</span>
                    }
                    else{
                        return <span style = {styleNormal}>{params?.data[Sector]?.name+',\n'}</span>
                    }
                }
                else{
                    return <></>
                }
            }
    
            return <React.Fragment>
            {SectorGetter("BDS", [-Biendo1,Biendo1])}
                {SectorGetter("BANLE", [-Biendo1,Biendo1])}
                {SectorGetter("BAOHIEM", [-Biendo1,Biendo1])}
                {SectorGetter("CHUNGKHOAN", [-Biendo1,Biendo1])}
                {SectorGetter("CONGNGHE", [-Biendo1,Biendo1])}
                {SectorGetter("DAU", [-Biendo1,Biendo1])}
                {SectorGetter("DETMAY", [-Biendo1,Biendo1])}
                {SectorGetter("DIEN", [-Biendo1,Biendo1])}
                {SectorGetter("DUOCPHAM", [-Biendo1,Biendo1])}
                {SectorGetter("DUONG", [-Biendo1,Biendo1])}
                {SectorGetter("H_TIEUDUNG", [-Biendo1,Biendo1])}
                {SectorGetter("HOACHAT", [-Biendo1,Biendo1])}
                {SectorGetter("KKTHAN", [-Biendo1,Biendo1])}
                {SectorGetter("NGANHANG", [-Biendo1,Biendo1])}
                {SectorGetter("NHUA", [-Biendo1,Biendo1])}
                {SectorGetter("NONGSAN", [-Biendo1,Biendo1])}
                {SectorGetter("THEP", [-Biendo1,Biendo1])}
                {SectorGetter("THUCPHAM", [-Biendo1,Biendo1])}
                {SectorGetter("THUYSAN", [-Biendo1,Biendo1])}
                {SectorGetter("VLXD", [-Biendo1,Biendo1])}
                {SectorGetter("VANTAI", [-Biendo1,Biendo1])}
                {SectorGetter("XAYDUNG", [-Biendo1,Biendo1])}
                {SectorGetter("BDSKCN", [-Biendo1,Biendo1])}
                {SectorGetter("DTC", [-Biendo1,Biendo1])}
                {SectorGetter("Penny", [-Biendo1,Biendo1])}
                {SectorGetter("Midcap", [-Biendo1,Biendo1])}
                {SectorGetter("BlueChip", [-Biendo1,Biendo1])}
                {SectorGetter("Index", [-Biendo1,Biendo1])}
                {SectorGetter("Future", [-Biendo1,Biendo1])}
            </React.Fragment>
            
            }
        
            function SectorUITN(params) {
                function SectorGetter(Sector, Key){
                    if(params?.data[Sector]?.sectorScore >= Key[0] && params?.data[Sector]?.sectorScore <= Key[1]){if(params?.data[Sector]?.name.includes("BDS")||params?.data[Sector]?.name.includes("NGAN")||params?.data[Sector]?.name.includes("CHUNG")){
                        return <span style = {params?.data[Sector]?.name==='BDSKCN'?styleNormal:styleImportance0}>{params?.data[Sector]?.name+",\n"}</span>
                    }
                        if(params?.data[Sector]?.name.includes("**")){
                            return <span style = {styleImportance2}>{params?.data[Sector]?.name+",\n"}</span>
                        }
                        if(params?.data[Sector]?.name.includes("*")){
                            return <span style = {styleImportance1}>{params?.data[Sector]?.name+",\n"}</span>
                        }
                        else{
                            return <span style = {styleNormal}>{params?.data[Sector]?.name+',\n'}</span>
                        }
                    }
                    else{
                        return <></>
                    }
                }
    
                return <React.Fragment>
                {SectorGetter("BDS", [Biendo1,Biendo2])}
                    {SectorGetter("BANLE", [Biendo1,Biendo2])}
                    {SectorGetter("BAOHIEM", [Biendo1,Biendo2])}
                    {SectorGetter("CHUNGKHOAN", [Biendo1,Biendo2])}
                    {SectorGetter("CONGNGHE", [Biendo1,Biendo2])}
                    {SectorGetter("DAU", [Biendo1,Biendo2])}
                    {SectorGetter("DETMAY", [Biendo1,Biendo2])}
                    {SectorGetter("DIEN", [Biendo1,Biendo2])}
                    {SectorGetter("DUOCPHAM", [Biendo1,Biendo2])}
                    {SectorGetter("DUONG", [Biendo1,Biendo2])}
                    {SectorGetter("H_TIEUDUNG", [Biendo1,Biendo2])}
                    {SectorGetter("HOACHAT", [Biendo1,Biendo2])}
                    {SectorGetter("KKTHAN", [Biendo1,Biendo2])}
                    {SectorGetter("NGANHANG", [Biendo1,Biendo2])}
                    {SectorGetter("NHUA", [Biendo1,Biendo2])}
                    {SectorGetter("NONGSAN", [Biendo1,Biendo2])}
                    {SectorGetter("THEP", [Biendo1,Biendo2])}
                    {SectorGetter("THUCPHAM", [Biendo1,Biendo2])}
                    {SectorGetter("THUYSAN", [Biendo1,Biendo2])}
                    {SectorGetter("VLXD", [Biendo1,Biendo2])}
                    {SectorGetter("VANTAI", [Biendo1,Biendo2])}
                    {SectorGetter("XAYDUNG", [Biendo1,Biendo2])}
                    {SectorGetter("BDSKCN", [Biendo1,Biendo2])}
                    {SectorGetter("DTC", [Biendo1,Biendo2])}
                    {SectorGetter("Penny", [Biendo1,Biendo2])}
                    {SectorGetter("Midcap", [Biendo1,Biendo2])}
                    {SectorGetter("BlueChip", [Biendo1,Biendo2])}
                    {SectorGetter("Index", [Biendo1,Biendo2])}
                    {SectorGetter("Future", [Biendo1,Biendo2])}
                </React.Fragment>
                
                }

                
            function SectorUITV(params) {
                function SectorGetter(Sector, Key){
                    if(params?.data[Sector]?.sectorScore >= Key[0] && params?.data[Sector]?.sectorScore <= Key[1]){if(params?.data[Sector]?.name.includes("BDS")||params?.data[Sector]?.name.includes("NGAN")||params?.data[Sector]?.name.includes("CHUNG")){
                        return <span style = {params?.data[Sector]?.name==='BDSKCN'?styleNormal:styleImportance0}>{params?.data[Sector]?.name+",\n"}</span>
                    }
                        if(params?.data[Sector]?.name.includes("**")){
                            return <span style = {styleImportance2}>{params?.data[Sector]?.name+",\n"}</span>
                        }
                        if(params?.data[Sector]?.name.includes("*")){
                            return <span style = {styleImportance1}>{params?.data[Sector]?.name+",\n"}</span>
                        }
                        else{
                            return <span style = {styleNormal}>{params?.data[Sector]?.name+',\n'}</span>
                        }
                    }
                    else{
                        return <></>
                    }
                }
                return <React.Fragment>
                {SectorGetter("BDS", [Biendo2,Biendo3])}
                    {SectorGetter("BANLE", [Biendo2,Biendo3])}
                    {SectorGetter("BAOHIEM", [Biendo2,Biendo3])}
                    {SectorGetter("CHUNGKHOAN", [Biendo2,Biendo3])}
                    {SectorGetter("CONGNGHE", [Biendo2,Biendo3])}
                    {SectorGetter("DAU", [Biendo2,Biendo3])}
                    {SectorGetter("DETMAY", [Biendo2,Biendo3])}
                    {SectorGetter("DIEN", [Biendo2,Biendo3])}
                    {SectorGetter("DUOCPHAM", [Biendo2,Biendo3])}
                    {SectorGetter("DUONG", [Biendo2,Biendo3])}
                    {SectorGetter("H_TIEUDUNG", [Biendo2,Biendo3])}
                    {SectorGetter("HOACHAT", [Biendo2,Biendo3])}
                    {SectorGetter("KKTHAN", [Biendo2,Biendo3])}
                    {SectorGetter("NGANHANG", [Biendo2,Biendo3])}
                    {SectorGetter("NHUA", [Biendo2,Biendo3])}
                    {SectorGetter("NONGSAN", [Biendo2,Biendo3])}
                    {SectorGetter("THEP", [Biendo2,Biendo3])}
                    {SectorGetter("THUCPHAM", [Biendo2,Biendo3])}
                    {SectorGetter("THUYSAN", [Biendo2,Biendo3])}
                    {SectorGetter("VLXD", [Biendo2,Biendo3])}
                    {SectorGetter("VANTAI", [Biendo2,Biendo3])}
                    {SectorGetter("XAYDUNG", [Biendo2,Biendo3])}
                    {SectorGetter("BDSKCN", [Biendo2,Biendo3])}
                    {SectorGetter("DTC", [Biendo2,Biendo3])}
                    {SectorGetter("Penny", [Biendo2,Biendo3])}
                    {SectorGetter("Midcap", [Biendo2,Biendo3])}
                    {SectorGetter("BlueChip", [Biendo2,Biendo3])}
                    {SectorGetter("Index", [Biendo2,Biendo3])}
                    {SectorGetter("Future", [Biendo2,Biendo3])}
                </React.Fragment>
                
                }
        
                function SectorUITM(params) {
                    function SectorGetter(Sector, Key){
                        if(params?.data[Sector]?.sectorScore >= Key[0] && params?.data[Sector]?.sectorScore <= Key[1]){if(params?.data[Sector]?.name.includes("BDS")||params?.data[Sector]?.name.includes("NGAN")||params?.data[Sector]?.name.includes("CHUNG")){
                            return <span style = {params?.data[Sector]?.name==='BDSKCN'?styleNormal:styleImportance0}>{params?.data[Sector]?.name+",\n"}</span>
                        }
                            if(params?.data[Sector]?.name.includes("**")){
                                return <span style = {styleImportance2}>{params?.data[Sector]?.name+",\n"}</span>
                            }
                            if(params?.data[Sector]?.name.includes("*")){
                                return <span style = {styleImportance1}>{params?.data[Sector]?.name+",\n"}</span>
                            }
                            else{
                                return <span style = {styleNormal}>{params?.data[Sector]?.name+',\n'}</span>
                            }
                        }
                        else{
                            return <></>
                        }
                    }
                    return <React.Fragment>
                    {SectorGetter("BDS", [Biendo3,Biendo4])}
                        {SectorGetter("BANLE", [Biendo3,Biendo4])}
                        {SectorGetter("BAOHIEM", [Biendo3,Biendo4])}
                        {SectorGetter("CHUNGKHOAN", [Biendo3,Biendo4])}
                        {SectorGetter("CONGNGHE", [Biendo3,Biendo4])}
                        {SectorGetter("DAU", [Biendo3,Biendo4])}
                        {SectorGetter("DETMAY", [Biendo3,Biendo4])}
                        {SectorGetter("DIEN", [Biendo3,Biendo4])}
                        {SectorGetter("DUOCPHAM", [Biendo3,Biendo4])}
                        {SectorGetter("DUONG", [Biendo3,Biendo4])}
                        {SectorGetter("H_TIEUDUNG", [Biendo3,Biendo4])}
                        {SectorGetter("HOACHAT", [Biendo3,Biendo4])}
                        {SectorGetter("KKTHAN", [Biendo3,Biendo4])}
                        {SectorGetter("NGANHANG", [Biendo3,Biendo4])}
                        {SectorGetter("NHUA", [Biendo3,Biendo4])}
                        {SectorGetter("NONGSAN", [Biendo3,Biendo4])}
                        {SectorGetter("THEP", [Biendo3,Biendo4])}
                        {SectorGetter("THUCPHAM", [Biendo3,Biendo4])}
                        {SectorGetter("THUYSAN", [Biendo3,Biendo4])}
                        {SectorGetter("VLXD", [Biendo3,Biendo4])}
                        {SectorGetter("VANTAI", [Biendo3,Biendo4])}
                        {SectorGetter("XAYDUNG", [Biendo3,Biendo4])}
                        {SectorGetter("BDSKCN", [Biendo3,Biendo4])}
                        {SectorGetter("DTC", [Biendo3,Biendo4])}
                        {SectorGetter("Penny", [Biendo3,Biendo4])}
                        {SectorGetter("Midcap", [Biendo3,Biendo4])}
                        {SectorGetter("BlueChip", [Biendo3,Biendo4])}
                        {SectorGetter("Index", [Biendo3,Biendo4])}
                        {SectorGetter("Future", [Biendo3,Biendo4])}
                    </React.Fragment>
                    
                    }

                function AspectX(params){
                    return <span>Aspect</span>
                }
        
                function SectorUITMM(params) {
                    function SectorGetter(Sector, Key){
                        if(params?.data[Sector]?.sectorScore >= Key[0] && params?.data[Sector]?.sectorScore <= Key[1]){if(params?.data[Sector]?.name.includes("BDS")||params?.data[Sector]?.name.includes("NGAN")||params?.data[Sector]?.name.includes("CHUNG")){
                            return <span style = {params?.data[Sector]?.name==='BDSKCN'?styleNormal:styleImportance0}>{params?.data[Sector]?.name+",\n"}</span>
                        }
                            if(params?.data[Sector]?.name.includes("**")){
                                return <span style = {styleImportance2}>{params?.data[Sector]?.name+",\n"}</span>
                            }
                            if(params?.data[Sector]?.name.includes("*")){
                                return <span style = {styleImportance1}>{params?.data[Sector]?.name+",\n"}</span>
                            }
                            else{
                                return <span style = {styleNormal}>{params?.data[Sector]?.name+',\n'}</span>
                            }
                        }
                        else{
                            return <></>
                        }
                    }
                    return <React.Fragment>
                    {SectorGetter("BDS", [Biendo4,Biendo5])}
                        {SectorGetter("BANLE", [Biendo4,Biendo5])}
                        {SectorGetter("BAOHIEM", [Biendo4,Biendo5])}
                        {SectorGetter("CHUNGKHOAN", [Biendo4,Biendo5])}
                        {SectorGetter("CONGNGHE", [Biendo4,Biendo5])}
                        {SectorGetter("DAU", [Biendo4,Biendo5])}
                        {SectorGetter("DETMAY", [Biendo4,Biendo5])}
                        {SectorGetter("DIEN", [Biendo4,Biendo5])}
                        {SectorGetter("DUOCPHAM", [Biendo4,Biendo5])}
                        {SectorGetter("DUONG", [Biendo4,Biendo5])}
                        {SectorGetter("H_TIEUDUNG", [Biendo4,Biendo5])}
                        {SectorGetter("HOACHAT", [Biendo4,Biendo5])}
                        {SectorGetter("KKTHAN", [Biendo4,Biendo5])}
                        {SectorGetter("NGANHANG", [Biendo4,Biendo5])}
                        {SectorGetter("NHUA", [Biendo4,Biendo5])}
                        {SectorGetter("NONGSAN", [Biendo4,Biendo5])}
                        {SectorGetter("THEP", [Biendo4,Biendo5])}
                        {SectorGetter("THUCPHAM", [Biendo4,Biendo5])}
                        {SectorGetter("THUYSAN", [Biendo4,Biendo5])}
                        {SectorGetter("VLXD", [Biendo4,Biendo5])}
                        {SectorGetter("VANTAI", [Biendo4,Biendo5])}
                        {SectorGetter("XAYDUNG", [Biendo4,Biendo5])}
                        {SectorGetter("BDSKCN", [Biendo4,Biendo5])}
                        {SectorGetter("DTC", [Biendo4,Biendo5])}
                        {SectorGetter("Penny", [Biendo4,Biendo5])}
                        {SectorGetter("Midcap", [Biendo4,Biendo5])}
                        {SectorGetter("BlueChip", [Biendo4,Biendo5])}
                        {SectorGetter("Index", [Biendo4,Biendo5])}
                        {SectorGetter("Future", [Biendo4,Biendo5])}
                    </React.Fragment>
                    
                    }

                function AspectX(params){
                    return <div>Aspect</div>
                }



      const defaultColDef2 = useMemo(() => {
        return {
          flex: 1,
          resizable: true,
          floatingFilter: false,
          editable: false,
          sortable: false,
          filter: false,
          minWidth: 100,
          headerComponentParams: {
            template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                '  </div>' +
                '</div>',
          },
          cellStyle: {border: '1px solid black',textalign: 'center', fontSize:'12px',fontFamily:'Arial', lineHeight: 1.5},
          autoHeight: true,
          filterParams: {
            filterOptions: ["contains"],
            textCustomComparator: function(filter, value, filterText) {
              // get array of comma separated values
              const filterValues = filterText.split(',') || filterText.split(', ') || filterText.split(',  ') || filterText.split(',   ') || filterText.split(',    ')|| filterText.split(',     ')|| filterText.split(',      ');
              // loop through filterValues and see if the value contains any of them
              return filterValues.some((item) => {
                return value.indexOf(item) >= 0;
              });
            }
          }
        };
      }, []);

      const gridStyle2 = useMemo(() => ({fontSize: 10, height: 350, width: '100%', lineHeight: 1.5}), []);

      


  const [columnDefs2, setColumnDefs2] = useState([
    {
      field: 'DANGEROUS',
      headerName: 'DANGEROUS',
        filter: false,
        sortable: false,
        
      cellStyle: {'white-space': 'normal',border: '1px solid black', backgroundColor: '#003333'},
      
      cellClassRules: TICKERVNCOL,
      cellRenderer: SectorUIGMM
 
    },
    {
      field: 'SUPERWEAK',
        filter: false,
        sortable: false,
        
      cellStyle: {'white-space': 'normal',border: '1px solid black', backgroundColor: 'rgb(55, 0, 0)'},
      
      cellClassRules: TICKERVNCOL,
      cellRenderer: SectorUIGM
 
    },
    {
      field: 'WEAK',
      filter: false,
      sortable: false,
      
        
      cellStyle: {'white-space': 'normal',border: '1px solid black', backgroundColor: 'rgb(65, 0, 0)'},
      autoHeight: true,
      chartDataType: 'category',
      
      cellClassRules: TICKERVNCOL,
      cellRenderer: SectorUIGV
 
    },
    {
      field: 'MODERATE',
      filter: false,
      sortable: false,
      
        
      cellStyle: {'white-space': 'normal',border: '1px solid black', backgroundColor: '#331900'},
      autoHeight: true,
      chartDataType: 'category',
      
      cellClassRules: TICKERVNCOL,
      cellRenderer: SectorUIGN
    },
    {
      field: 'NORMAL',
      filter: false,
      sortable: false,
      
        
      cellStyle: {'white-space': 'normal',border: '1px solid black', backgroundColor: '#333300'},
      autoHeight: true,
      chartDataType: 'category',
      
      cellClassRules: TICKERVNCOL,
      cellRenderer: SectorUITC
 
    },
    {
      field: 'GOOD',
      filter: false,
      sortable: false,
      
      cellStyle: {'white-space': 'normal',border: '1px solid black', backgroundColor: '#193300'},
      autoHeight: true,
      chartDataType: 'category',
      
      cellClassRules: TICKERVNCOL,
      cellRenderer: SectorUITN
 
    },
    {
      field: 'STRONG',
      filter: false,
      sortable: false,
      
        
      cellStyle: {'white-space': 'normal',border: '1px solid black', backgroundColor: '#003300'},
      autoHeight: true,
      chartDataType: 'category',
      
      cellClassRules: TICKERVNCOL,
      cellRenderer: SectorUITV
 
    },
    {
      field: 'SUPERSTRONG',
      filter: false,
      sortable: false,
      
        
      cellStyle: {'white-space': 'normal',border: '1px solid black', backgroundColor: '#004000'},
      autoHeight: true,
      chartDataType: 'category',
      
      cellClassRules: TICKERVNCOL,
      cellRenderer: SectorUITM
 
    },
    
    {
        field: 'LEADING',
        filter: false,
        sortable: false,
        
          
        cellStyle: {'white-space': 'normal',border: '1px solid black', backgroundColor: '#330033'},
        autoHeight: true,
        chartDataType: 'category',
        
        cellClassRules: TICKERVNCOL,
        cellRenderer: SectorUITMM
   
      },
  ])


      return <React.Fragment>
      

      <div className="ag-theme-quartz-dark" style={gridStyle2}>

          <AgGridReact
            ref={gridRef}
            rowData={data_MatrixFutureForceB}
            columnDefs={columnDefs2}
            defaultColDef={defaultColDef2}
 
            //Header
            rowHeight={300}
        

            //Sort
            multiSortKey = {'ctrl'}

            />
        </div>
        <div style = {{marginTop: 5}}>
            {/* <DataAnalysis/> */}
            <div style={{backgroundColor: '#151515'}}>
                <MKStructureDistBoxPlot 
                    data = {[
                        {x: 'Market', y: [Low,Q1,Q2,Q3,High]},
                        {x: 'Standard', y: [-5, 0, 0, 0, 5]},
                    ]}
                />
            </div>
        </div>
      </React.Fragment>

    
}
