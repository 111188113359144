import { useGetSectorQuery, useGetStockDataQuery } from 'features/dashboard/api/apiSlice.dashboard'
import useTitle from 'hooks/useTitle'
import React, { useState, useEffect } from 'react'
import MarketStructureDistribution from './components/MarketStructureDistribution.DeepA'
import MKStructureDistByDay from './components/MKStructureDistByDay.DeepA'
import { PulseLoader } from 'react-spinners'
import {Typography} from '@mui/material'
import MKStructureDistBoxPlot from './components/MKStructureDistBoxPlot.DeepA'

const DeepAnalysis = () => {

  const {data,isSuccess} = useGetStockDataQuery()
  const {data: dataSector, isSuccess: isSuccessSector} = useGetSectorQuery()
  const [Data, setData] = useState([])
  const [DataSector, setDataSector] = useState([])
  useEffect(() => {
    setData(data)
  }, [isSuccess])
  useEffect(() => {
    setDataSector(dataSector)
  }, [isSuccessSector])
  useTitle("Finlens DeepAnalysis")

  
  return DataSector?.length>0?(
    <div style={{margin: 'auto', width: '90%'}}>
      
      <div style={{border: '1px solid white'}}>
        <MarketStructureDistribution data={dataSector}/>
      </div>
      
      <div style={{backgroundColor: '#151515', padding: 15, marginTop: 20, border: '1px solid white', width: '100%', borderRadius: 10}}>
        <MKStructureDistByDay id={'MKStructureDistByDay'} data={DataSector}/>
      </div>
      
    </div>
  ): <PulseLoader  color={"#FFF"}/>
}

export default DeepAnalysis