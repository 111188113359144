import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { Component} from "react";
import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";


export class BienDoCPIntraday extends Component {
    constructor(props){
        super(props); //
        this.state = {
            TotalUp: 0,
            TotalDn: 0,
            MaxRBuy: '',
            MaxRSell: 0,
            AbsValue: [
              null,null,null,null,null
            ],
            MaxAbsValue: null
        }
      }
    componentDidMount(){

            
          
        let root = am5.Root.new(this.props.id);
        
        root._logo.dispose();


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
        am5themes_Dark.new(root)
        ]);

       
        
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout,
            arrangeTooltips: false
            })
        );
        chart.get("colors").set("colors", [
            am5.color('#FF4500'),
            am5.color('#32CD32'),
        ]);
    
        
        // Use only absolute numbers
        chart.getNumberFormatter().set("numberFormat", "#.#s");
        
        

        var data = this?.props?.data
        var totalup = data[0].C0_M0+data[0].C0_M1+data[0].C0_M2+data[0].C0_M3+data[0].C0_M4+data[0].C0_M5+data[0].C0_M6+data[0].C0_M7+data[0].C0_M8+data[0].C0_M9+data[0].C0_M10+data[0].C0_M12
        var totaldn = data[0].C0_L0+data[0].C0_L1+data[0].C0_L2+data[0].C0_L3+data[0].C0_L4+data[0].C0_L5+data[0].C0_L6+data[0].C0_L7+data[0].C0_L8+data[0].C0_L9+data[0].C0_L10+data[0].C0_L12
       
        var total = totalup+totaldn
                // Data
                let data_ = [
                    {
                    Level: ">12",
                    Down: -data[0].C0_L12/total*100,
                    Up: data[0].C0_M12/total*100
                    },
                    {
                    Level: "10-12",
                    Down: -data[0].C0_L10/total*100,
                    Up: data[0].C0_M10/total*100
                    },
                    {
                    Level: "9-10",
                    Down: -data[0].C0_L9/total*100,
                    Up: data[0].C0_M9/total*100
                    },
                    {
                    Level: "8-9",
                    Down: -data[0].C0_L8/total*100,
                    Up: data[0].C0_M8/total*100
                    },
                    {
                    Level: "7-8",
                    Down: -data[0].C0_L7/total*100,
                    Up: data[0].C0_M7/total*100
                    },
                    {
                    Level: "6-7",
                    Down: -data[0].C0_L6/total*100,
                    Up: data[0].C0_M6/total*100
                    },
                    {
                    Level: "5-6",
                    Down: -data[0].C0_L5/total*100,
                    Up: data[0].C0_M5/total*100
                    },
                    {
                    Level: "4-5",
                    Down: -data[0].C0_L4/total*100,
                    Up: data[0].C0_M4/total*100
                    },
                    {
                    Level: "3-4",
                    Down: -data[0].C0_L3/total*100,
                    Up: data[0].C0_M3/total*100
                    },
                    {
                    Level: "2-3",
                    Down: -data[0].C0_L2/total*100,
                    Up: data[0].C0_M2/total*100
                    },
                    {
                    Level: "1-2",
                    Down: -data[0].C0_L1/total*100,
                    Up: data[0].C0_M1/total*100
                    },
                    {
                    Level: "0-1",
                    Down: -data[0].C0_L0/total*100,
                    Up: data[0].C0_M0/total*100
                    },
                    
                ];
                var upArr = data_?.slice(10,12).map(x => {return x.Up})
                var dnArr = data_?.slice(10,12).map(x => {return x.Down})
                this.setState({
                    TotalUp: totalup,
                    TotalDn: totaldn,
                    MaxRBuy: upArr.reduce((a,b) => {return a+b})/upArr.length,
                    MaxRSell: dnArr.reduce((a,b) => {return Math.abs(a)+Math.abs(b)})/dnArr.length,
                    AbsValue: [
                      Math.round(data[0].C0_L0/total*100+data[0].C0_M0/total*100),
                      Math.round(data[0].C0_L1/total*100+data[0].C0_M1/total*100),
                      Math.round(data[0].C0_L2/total*100+data[0].C0_M2/total*100),
                      Math.round(data[0].C0_L3/total*100+data[0].C0_M3/total*100),
                      100-Math.round(data[0].C0_L0/total*100+data[0].C0_M0/total*100)-Math.round(data[0].C0_L1/total*100+data[0].C0_M1/total*100)-Math.round(data[0].C0_L2/total*100+data[0].C0_M2/total*100)-Math.round(data[0].C0_L3/total*100+data[0].C0_M3/total*100)-Math.round(data[0].C0_L4/total*100+data[0].C0_M4/total*100),

                    ],
                    
                   MaxAbsValue: Math.max(...[
                    Math.round(data[0].C0_L0/total*100+data[0].C0_M0/total*100),
                    Math.round(data[0].C0_L1/total*100+data[0].C0_M1/total*100),
                    Math.round(data[0].C0_L2/total*100+data[0].C0_M2/total*100),
                    Math.round(data[0].C0_L3/total*100+data[0].C0_M3/total*100),
                    100-Math.round(data[0].C0_L0/total*100+data[0].C0_M0/total*100)-Math.round(data[0].C0_L1/total*100+data[0].C0_M1/total*100)-Math.round(data[0].C0_L2/total*100+data[0].C0_M2/total*100)-Math.round(data[0].C0_L3/total*100+data[0].C0_M3/total*100)-Math.round(data[0].C0_L4/total*100+data[0].C0_M4/total*100),

                   ])
                    
                
                })

                this.setState({
                })


                // Create axes
                // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
                let yAxis = chart.yAxes.push(
                    am5xy.CategoryAxis.new(root, {
                    categoryField: "Level",
                    renderer: am5xy.AxisRendererY.new(root, {
                        inversed: true,
                        cellStartLocation: 0.1,
                        cellEndLocation: 0.9
                    })
                    })
                );

                yAxis.data.setAll(data_);

                        
                let xAxis = chart.xAxes.push(
                    am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererX.new(root, {
                        strokeOpacity: 0.1
                    }),
                    max: 30,
                    min: -30
                    })
                );

                xAxis.get("renderer").labels.template.setAll({
                    fontSize: 15
                });
            
                yAxis.get("renderer").labels.template.setAll({
                    fontSize: 15
                });
                

                
                // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
                function createSeries(field, labelCenterX, pointerOrientation, rangeValue) {
                    let series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueXField: field,
                        categoryYField: "Level",
                        sequencedInterpolation: true,
                        clustered: false,
                        tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: pointerOrientation,
                        labelText: "{categoryY}: {valueX}"
                        })
                    })
                    );
                
                    series.columns.template.setAll({
                    height: am5.p100,
                    strokeOpacity: 0,
                    fillOpacity: 0.8
                    });
                
                    series.bullets.push(function() {
                    return am5.Bullet.new(root, {
                        locationX: 1,
                        locationY: 0.5,
                        sprite: am5.Label.new(root, {
                        centerY: am5.p50,
                        text: "{valueX}",
                        populateText: true,
                        centerX: labelCenterX,
                        fontSize: 10
                        })
                    });
                    });
                
                    series.data.setAll(data_);
                    series.appear();
                
                    let rangeDataItem = xAxis.makeDataItem({
                    value: rangeValue
                    });
                    xAxis.createAxisRange(rangeDataItem);
                    rangeDataItem.get("grid").setAll({
                    strokeOpacity: 1,
                    stroke: series.get("stroke")
                    });
                
                    let label = rangeDataItem.get("label");
                    label.setAll({
                    text: field.toUpperCase(),
                    fontSize: "1.1em",
                    fill: series.get("stroke"),
                    paddingTop: 10,
                    isMeasured: false,
                    centerX: labelCenterX
                    });
                    label.adapters.add("dy", function() {
                    return -chart.plotContainer.height();
                    });

                
                    return series;
                }
                
                createSeries("Down", am5.p100, "right", -20);
                createSeries("Up", 0, "left", 20);
        
  
        
        
        
        
        
        // Add series
        
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomY"
        }));
        cursor.lineY.set("forceHidden", true);
        cursor.lineX.set("forceHidden", true);

        
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);
  
        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
    }

    render() {
        const skewness = (this.state.MaxRBuy/this.state.MaxRSell)?.toFixed(2)
        return (
            <><div style={{backgroundColor: '#202020', borderRadius: 10, boxShadow: '5px 5px #101010', alignItems: 'center', width: this.props.width, height: this.props.height}}>
            <AppBar position="static" sx={{backgroundColor: '#202020' }}>
              <Toolbar variant="dense" sx={{display: 'flex', flexDirection: 'column', padding: '14px', justifyContent: 'space-between'}}>

              <Typography
                    fontWeight = 'bold'
                    color = '#FAEBD7'
                    variant = 'h6'
                    textAlign = 'center'
                    borderBottom = '1px dashed white'
                    
                  >
                    Biên Độ Chi Tiết Thị Trường Phiên Gần Nhất
                  </Typography>
                   
              <span style={{
                display: 'flex', flexDirection: 'row',
                lineHeight: '20%',marginTop: 5
              }}>
                  
                
                  <Typography variant="body2" fontWeight="bold" color="lightgrey">
                      Lợi Thế 
                    &nbsp;
                    </Typography>
                    
                    <Typography variant="body2" fontWeight={500}>
                      {skewness<1?
                        <span style={{display: 'flex'}}>
                        <Typography 
                          variant = {'body2'}
                          color = {'red'}
                          sx={{
                          fontWeight: 'bold',
                          textDecorationLine: 'underline'
                        }}>
                          Bên Bán
                        </Typography>
                        &nbsp;
                        <Typography
                          variant = {'body2'}
                          color={'darkgrey'}
                        >
                          ({(this.state.MaxRSell/this.state.MaxRBuy)?.toFixed(2)} lần Bên Mua)
                        </Typography>
                      </span>
                        
                        :
                        <span style={{display: 'flex'}}>
                          <Typography 
                            variant = {'body2'}
                            color = {'limegreen'}
                            sx={{
                            fontWeight: 'bold',
                            textDecorationLine: 'underline'
                          }}>
                            Bên Mua
                          </Typography>
                          &nbsp;
                          <Typography
                            variant = {'body2'}
                            color={'darkgrey'}
                          >
                            ({(this.state.MaxRBuy/this.state.MaxRSell)?.toFixed(2)} lần Bên Bán)
                          </Typography>
                        </span>
                      }

                    
                      
                    </Typography>
              </span>
                  
                  <span style={{display: 'flex', marginTop: 5}}>
                    <Typography variant="body2" fontWeight="bold"
                      color = {'lightgrey'}
                    >
                        Trạng Thái 5 Biên Độ (0|1|2|3|4): 
                    </Typography>
                    &nbsp;
                    <span>
                      <Typography
                        variant = "body2"
                        style={{
                          display: 'flex'
                        }}
                      >
                        <span style={{
                          color: this.state.AbsValue[0] === this.state.MaxAbsValue ? 'dodgerblue' : 'white'
                        }}>
                          {this.state.AbsValue[0]}%
                        </span>
                        |
                        <span>
                          {this.state.AbsValue[1]}%
                        </span>
                        |
                        <span>
                          {this.state.AbsValue[2]}%
                        </span>
                        |
                        <span>
                          {this.state.AbsValue[3]}%
                        </span>
                        |
                        <span>
                          {this.state.AbsValue[4]}%
                        </span>
                      </Typography>
                    </span>
                    
                  </span>
              </Toolbar>
              </AppBar>
                <div id={this.props.id} style={{width: '100%', height: 400}}>
                </div>
            </div>
            </>
        );
    }
}

export default BienDoCPIntraday;