import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { Component} from "react";
import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5index from "@amcharts/amcharts5/index";


export class BienDoCPIntraday5Day extends Component {
    constructor(props){
        super(props); //
        this.state = {
            TotalUp: 0,
            TotalDn: 0,
            MaxRBuy: '',
            MaxRSell: 0,
            AbsValue: [
              null,null,null,null,null
            ],
            MaxAbsValue: null
        }
      }
    componentDidMount(){

            
          
        let root = am5.Root.new(this.props.id);
        
        root._logo.dispose();


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
        am5themes_Dark.new(root)
        ]);

       
        
       

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            paddingLeft: 0,
            layout: root.verticalLayout
        }));
        
        
        // Create axes and their renderers
        let yRenderer = am5xy.AxisRendererY.new(root, {
            visible: false,
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true
        });
        
        yRenderer.grid.template.set("visible", false);
        
        let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            renderer: yRenderer,
            categoryField: "weekday"
        }));
        
        let xRenderer = am5xy.AxisRendererX.new(root, {
            visible: false,
            minGridDistance: 30,
            opposite:true,
            minorGridEnabled: true
        });
        
        xRenderer.grid.template.set("visible", false);
        
        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: "hour"
        }));
        
        
        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
            calculateAggregates: true,
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: "hour",
            categoryYField: "weekday",
            valueField: "value"
        }));
        
        series.columns.template.setAll({
            tooltipText: "{value}",
            strokeOpacity: 1,
            strokeWidth: 2,
            width: am5.percent(100),
            height: am5.percent(100)
        });
        
        series.columns.template.events.on("pointerover", function(event) {
            let di = event.target.dataItem;
            if (di) {
            heatLegend.showValue(di.get("value", 0));
            }
        });
        
        series.events.on("datavalidated", function() {
            heatLegend.set("startValue", series.getPrivate("valueHigh"));
            heatLegend.set("endValue", series.getPrivate("valueLow"));
        });
        
        
        // Set up heat rules
        // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
        series.set("heatRules", [{
            target: series.columns.template,
            min: am5.color('#66CDAA'),
            max: am5.color('#663399'),
            dataField: "value",
            key: "fill"
        }]);
        
        
        // Add heat legend
        // https://www.amcharts.com/docs/v5/concepts/legend/heat-legend/
        let heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
            orientation: "horizontal",
            endColor: am5.color('#66CDAA'),
            startColor: am5.color('#663399')
        }));

        
        var data = this?.props?.data
        var totalup = data[0].C0_M0+data[0].C0_M1+data[0].C0_M2+data[0].C0_M3+data[0].C0_M4+data[0].C0_M5+data[0].C0_M6+data[0].C0_M7+data[0].C0_M8+data[0].C0_M9+data[0].C0_M10+data[0].C0_M12
        var totaldn = data[0].C0_L0+data[0].C0_L1+data[0].C0_L2+data[0].C0_L3+data[0].C0_L4+data[0].C0_L5+data[0].C0_L6+data[0].C0_L7+data[0].C0_L8+data[0].C0_L9+data[0].C0_L10+data[0].C0_L12
       
        var total = totalup+totaldn
        
        
        // Set data
        // https://www.amcharts.com/docs/v5/charts/xy-chart/#Setting_data
        let data_ = []
        for (let i =0; i<10; i++){
            data_.push(
                {
                    weekday: `T${i}`,
                    hour: '<-5',
                    value: (data[0][`C${i}_L5`]/total*100)+(data[0][`C${i}_L6`]/total*100)+(data[0][`C${i}_L7`]/total*100)+(data[0][`C${i}_L8`]/total*100)+(data[0][`C${i}_L9`]/total*100)+(data[0][`C${i}_L10`]/total*100)+(data[0][`C${i}_L12`]/total*100)
                },{
                    weekday: `T${i}`,
                    hour: '-5',
                    value: (data[0][`C${i}_L4`]/total*100)
                },{
                    weekday: `T${i}`,
                    hour: '-4',
                    value: (data[0][`C${i}_L3`]/total*100)
                },{
                    weekday: `T${i}`,
                    hour: '-3',
                    value: (data[0][`C${i}_L2`]/total*100)
                },{
                    weekday: `T${i}`,
                    hour: '-2',
                    value: (data[0][`C${i}_L1`]/total*100)
                },
                {
                    weekday: `T${i}`,
                    hour: '-1',
                    value: (data[0][`C${i}_L0`]/total*100)
                },
                {
                    weekday: `T${i}`,
                    hour: '1',
                    value: (data[0][`C${i}_M0`]/total*100)
                },
                {
                    weekday: `T${i}`,
                    hour: '2',
                    value: (data[0][`C${i}_M1`]/total*100)
                },
                {
                    weekday: `T${i}`,
                    hour: '3',
                    value: (data[0][`C${i}_M2`]/total*100)
                },
                {
                    weekday: `T${i}`,
                    hour: '4',
                    value: (data[0][`C${i}_M3`]/total*100)
                },
                {
                    weekday: `T${i}`,
                    hour: '5',
                    value: (data[0][`C${i}_M4`]/total*100)
                },
                {
                    weekday: `T${i}`,
                    hour: '>5',
                    value: (data[0][`C${i}_M5`]/total*100)+(data[0][`C${i}_M6`]/total*100)+(data[0][`C${i}_M7`]/total*100)+(data[0][`C${i}_M8`]/total*100)+(data[0][`C${i}_M9`]/total*100)+(data[0][`C${i}_M10`]/total*100)+(data[0][`C${i}_M12`]/total*100)
                }
            )
        }
        
        
        series.data.setAll(data_);
        
        // Auto-populate X and Y axis category data
        let weekdays = [];
        let hours = [];
        am5.array.each(data_, function(row) {
            if (weekdays.indexOf(row.weekday) == -1) {
            weekdays.push(row.weekday);
            }
            if (hours.indexOf(row.hour) == -1) {
            hours.push(row.hour);
            }
        });
        
        
        yAxis.data.setAll(weekdays.map(function(item) {
            return { weekday: item }
        }));
        
        
        xAxis.data.setAll(hours.map(function(item) {
            return { hour: item }
        }));
        
        
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
        chart.appear(1000, 100);
  
  
        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
    }

    render() {
        const skewness = (this.state.MaxRBuy/this.state.MaxRSell)?.toFixed(2)
        return (
            <><div style={{backgroundColor: '#202020', borderRadius: 10, boxShadow: '5px 5px #101010', alignItems: 'center', width: this.props.width, height: this.props.height, padding: 20}}>
            <AppBar position="static" sx={{backgroundColor: '#202020' }}>
              <Toolbar variant="dense" sx={{display: 'flex', flexDirection: 'column', padding: '14px', justifyContent: 'space-between'}}>
                   <Typography
                        fontWeight = 'bold'
                        color = 'beige'
                        variant = 'h6'
                        textAlign = 'center'

                    >
                        Biên Độ Tăng Giảm Chi Tiết 10 Ngày Gần Nhất
                   </Typography>
                   <Typography>
                        (Đơn Vị: %)
                   </Typography>
            
              </Toolbar>
              </AppBar>
                <div id={this.props.id} style={{width: '100%', height: 400}}>
                </div>
            </div>
            </>
        );
    }
}

export default BienDoCPIntraday5Day;