import React from 'react'
import { useGetYahooDataQuery } from '../../api/apiSlice.dashboard';
import { AiFillGold } from 'react-icons/ai';
import {DataGrid, GridToolbar, bgBG } from "@mui/x-data-grid"
import { OilBarrel } from '@mui/icons-material';
import { Button } from '@mui/material';
import {ChartIndex} from './CategoryChart_Index';
import Flags from "country-flag-icons/react/3x2";




const IndexDataBoard = () => {

    

    
    //setChart
    const [chart, setChart] = React.useState(<><ChartIndex chartControlID={'chartControlID'} id={'ChartIndex'} height={620} TickerName={'I_VNINDEX'} name={'VNINDEX'}/></>)


    const x = useGetYahooDataQuery().data;
    const {isSuccess} = useGetYahooDataQuery()
    console.log(x)


  

    const columns = [
        
        {
            field: 'Country',
            width: 105,
            renderCell: (params) => {
                function getFlag(code){
                    const Flag = Flags[code]
                    return <Flag/>
                }
                function FlagDiv(){
                    
                if(params.row.Country?.includes('Australia')){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('AU')}
                    </div>
                  }
                  if(params.row.Country?.includes("Japan")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('JP')}
                    </div>
                  }
                  if(params.row.Country?.includes("Korea")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('KR')}
                    </div>
                  }
                  if(params.row.Country?.includes("China")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('CN')}
                    </div>
                  }
                  if(params.row.Country?.includes("Hong Kong")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('HK')}
                    </div>
                  }
                  if(params.row.Country?.includes("Thailand")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('TH')}
                    </div>
                  }
                  if(params.row.Country?.includes("South Africa")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('ZA')}
                    </div>
                  }
                  if(params.row.Country?.includes("Mexico")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('MX')}
                    </div>
                  }
                  if(params.row.Country?.includes("Vietnam")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('VN')}
                    </div>
                  }
                  if(params.row.Country?.includes("Belgium")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('BE')}
                    </div>
                  }
                  if(params.row.Country?.includes("Denmark")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('DK')}
                    </div>
                  }
                  if(params.row.Country?.includes("Indonesia")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('ID')}
                    </div>
                  }
                  if(params.row.Country?.includes("Egypt")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('EG')}
                    </div>
                  }
                  if(params.row.Country?.includes("UK")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('GB')}
                    </div>
                  }
                  if(params.row.Country?.includes("Egypt")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('EG')}
                    </div>
                  }
                  if(params.row.Country?.includes("Austria")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('AT')}
                    </div>
                  }
                  if(params.row.Country?.includes("Spain")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('ES')}
                    </div>
                  }
                  if(params.row.Country?.includes("Germany")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('DE')}
                    </div>
                  }
                  if(params.row.Country?.includes("Finland")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('FI')}
                    </div>
                  }
                  if(params.row.Country?.includes("Euro")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('EU')}
                    </div>
                  }
                  if(params.row.Country?.includes("Hungary")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('HU')}
                    </div>
                  }
                  if(params.row.Country?.includes("Netherland")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('NL')}
                    </div>
                  }
                  if(params.row.Country?.includes("Singapore")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('SG')}
                    </div>
                  }
                  if(params.row.Country?.includes("France")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('FR')}
                    </div>
                  }
                  if(params.row.Country?.includes("Philippines")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('PH')}
                    </div>
                  }
                  if(params.row.Country?.includes("Newzealand")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('NZ')}
                    </div>
                  }
                  if(params.row.Country?.includes("US")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('US')}
                    </div>
                  }
                  if(params.row.Country?.includes("Malaysia")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('MY')}
                    </div>
                  }
                  if(params.row.Country?.includes("Taiwan")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('TW')}
                    </div>
                  }
                  if(params.row.Country?.includes("Brazil")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('BR')}
                    </div>
                  }
                  if(params.row.Country?.includes("Czech")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('CZ')}
                    </div>
                  }
                  if(params.row.Country?.includes("India")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('IN')}
                    </div>
                  }
                  if(params.row.Country?.includes("Russia")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('RU')}
                    </div>
                  }
                  if(params.row.Country?.includes("Canada")){
                    return <div style={{color: "royalblue",width: 30,}}>
                      {getFlag('CA')}
                    </div>
                  }
                }
                return <div style={{display: 'flex', fontSize: 10}}>
                    {FlagDiv()}
                    <div style={{padding: 3, color: '#909090'}}>
                        {params.row.Country}
                    </div>
                </div>
                
                
              
        }
        },{
            field: 'Ticker',
            width: 120,
            renderCell: (params) => {
               return <div style={{display: 'flex', flexDirection: 'column', cursor: 'pointer'}} onClick={() => setChart(<ChartIndex chartControlID={`chartControlID${params.row.symbol}`} id={`ChartIndex${params.row.symbol}`} height={620} TickerName={params.row.Ticker} name={params.row.Ticker.replace('I_','')+' - '+params.row.Country}/>)}>
                <div style={{fontSize: 14, fontWeight: 600, color: 'white'}}>
                    {params.row.Ticker?.replace('I_','')}
                </div>
              </div>
                
                  
                  
              }
                
            
            
        },
        
        
        {
            field: 'Close',
            width: 70,
            renderCell: (params) => {
                return <div style={{fontSize: 12.95, color: 'white'}}>
                    {params.row.Close}
                </div>
            }
        },
        {
            field: 'Change',
            width: 75,
            renderCell: (params) => {
                function col(v){
                    if(v>0) return 'lime'
                    if(v<0) return 'red'
                    else return 'gold'
                }
                return <div style={{fontSize: 12.95, color: col(params.row.Change)}}>
                    {params.row.Change>0?`+${params.row.Change?.toFixed(2)}`:params.row.Change?.toFixed(2)}%
                </div>
            }
        },
        
        {
            field: 'weeklyChange',
            headerName: 'W',
            width: 80,
            renderCell: (params) => {
                function volAvg(v,v1,v2){
                  if(v>v2) return '#BA55D3'
                  else if(v<=v2 && v>v1) return 'royalblue'
                  else if(v<=v1 && v>0.5) return 'green'
                  else if(v<=0.5 && v>-0.5) return 'darkgoldenrod'
                  else if(v<=-0.5 && v>-v1) return '#CD5C5C'
                  else if(v<=-v1 && v>-v2) return '#DC143C'
                  else if(v<-v2) return 'darkred'
                }
                const weeklyChange = params.row.weeklyChange
                return <div style={{display: 'flex', flexDirection: 'column', fontSize: 13, backgroundColor: volAvg(weeklyChange,3,7), color: 'white', width: 50, height: 30, textAlign: 'center', borderRadius: 10}}>
                    
                    <div>{weeklyChange?.toFixed(2)}%</div>
                    
                </div>
            },
        },
        {
            field: 'monthlyChange',
            headerName: 'M',
            width: 80,
            renderCell: (params) => {
                function volAvg(v,v1,v2){
                  if(v>v2) return '#BA55D3'
                  else if(v<=v2 && v>v1) return 'royalblue'
                  else if(v<=v1 && v>2) return 'green'
                  else if(v<=2 && v>-2) return 'darkgoldenrod'
                  else if(v<=-2 && v>-v1) return '#CD5C5C'
                  else if(v<=-v1 && v>-v2) return '#DC143C'
                  else if(v<-v2) return 'darkred'
                }
                const monthlyChange = params.row.monthlyChange
                return <div style={{display: 'flex', flexDirection: 'column', fontSize: 13, backgroundColor: volAvg(monthlyChange,3,7), color: 'white', width: 50, height: 30, textAlign: 'center', borderRadius: 10}}>
                    
                    <div>{monthlyChange?.toFixed(2)}%</div>
                    
                </div>
            },
        },
        {
            field: 'quarterlyChange',
            headerName: 'Q',
            width: 80,
            renderCell: (params) => {
                function volAvg(v,v1,v2){
                  if(v>v2) return '#BA55D3'
                  else if(v<=v2 && v>v1) return 'royalblue'
                  else if(v<=v1 && v>3) return 'green'
                  else if(v<=3 && v>-3) return 'darkgoldenrod'
                  else if(v<=-3 && v>-v1) return '#CD5C5C'
                  else if(v<=-v1 && v>-v2) return '#DC143C'
                  else if(v<-v2) return 'darkred'
                }
                const quarterlyChange = params.row.quarterlyChange
                return <div style={{display: 'flex', flexDirection: 'column', fontSize: 13, backgroundColor: volAvg(quarterlyChange,3,7), color: 'white', width: 50, height: 30, textAlign: 'center', borderRadius: 10}}>
                    
                    <div>{quarterlyChange?.toFixed(2)}%</div>
                    
                </div>
            },
        },


    ]

    
    

    //Data
    //Data
    var rows = [];

    for(let i=0; i<x?.length; i++){
        function createData(TickerName,Close,High,Low,Change,weeklyChange,monthlyChange,quarterlyChange, Country) {
            return { 
                Ticker: TickerName,
                Close: Close,
                High: High,
                Low: Low,
                Change: Change,
                weeklyChange: weeklyChange,
                monthlyChange: monthlyChange,
                quarterlyChange: quarterlyChange,
                Country: Country
            };
        }

        rows.push(
            createData(
                String(x[i]?.TickerName),
                Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_close'],
                Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_high'],
                Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_low'],
                ((Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_close']-Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[1]?.['price_close'])/Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[1]?.['price_close']*100),
                ((Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_close']-Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[4]?.['price_close'])/Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[4]?.['price_close']*100),
                ((Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_close']-Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[19]?.['price_close'])/Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[19]?.['price_close']*100),
                ((Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[0]?.['price_close']-Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[56]?.['price_close'])/Object(Object?.values(x?.[i]?.['HistoryDataDaily']||{}).reverse())[56]?.['price_close']*100),
                Object(Object?.values(x?.[i]?.['DataSummary']||{}).reverse())[0],

            )
        )
    }


        
   
    return <div style={{ width: '100%', display: 'flex'}}>
    <div style={{border: '3px solid #4d547d', backgroundColor: '#202020', color: 'white', height: 700, minWidth: 500, width: '60%'}}>
      {chart}
    </div>
    <div style = {{  backgroundColor: '#101010', color: 'white', height: 700, width: '40%'}}>
      
    {isSuccess?<DataGrid
            rows = {rows?.filter((x) => {return x?.Ticker?.includes('I_')})?.sort((a,b) => {return b.Change-a.Change})}
            rowsHeight = {5}
            columns = {columns}
            getRowId={(row) =>  row.Ticker}
            sx={{
                '--DataGrid-containerBackground': '#404040',
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                    color: 'primary.light',
                },
                'css-t89xny-MuiDataGrid-columnHeaderTitle':{
                  color: 'green'
                },
                '.MuiDataGrid-footerContainer':{
                  backgroundColor: '#404040',
                  color: 'white'
                },
                '& .super-app.negative': {
                    backgroundColor: '#d47483',
                    color: '#1a3e72',
                    fontWeight: '600',
                  },
                  '& .super-app.positive': {
                    backgroundColor: 'MediumSeaGreen',
                    color: '#1a3e72',
                    fontWeight: '600',
                  },
                  '& .super-app.base': {
                    backgroundColor: 'SandyBrown',
                    color: '#1a3e72',
                    fontWeight: '600',
                  },
                  '& .super-app.negative1': {
                      color: '#d47483',
                    },
                    '& .super-app.positive1': {
                      color: 'MediumSeaGreen',
                    },
                    '& .super-app.base1': {
                      color: 'SandyBrown',
                    },
                  '& .super-app.bold': {
                    fontWeight: 'bold'
                  },
                  '& .super-app.fontup': {
                    fontSize: '18px'
                  },
            }}
            localeText={{
                toolbarDensity: 'Size',
                toolbarDensityLabel: 'Size',
                toolbarDensityCompact: 'Small',
                toolbarDensityStandard: 'Medium',
                toolbarDensityComfortable: 'Large',
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            rowHeight={35} 
        />:<></>}
        </div>
        </div>
}

export default IndexDataBoard