import React, {useEffect, useState}  from 'react'
import {DataGrid, GridToolbar, bgBG } from "@mui/x-data-grid"
import { Typography,Button } from '@mui/material'
import { useGetSectorQuery } from '../api/apiSlice.dashboard'
import { ChartScoreMakerSector } from './BySector/TopScore.bysector.dashboard'
import { TopByCriteria } from './BySector/TopByCriteria.bysector'

const SectorBoard = (props) => {
    
    const dataOri = props.data
    function colorPCT(x){
        if(x>60){return '#32CD32'}
        else if(x<=60 && x>=35){return '#DAA520'}
        else if(x<35){return '#FF0000'}
    }
    const [sector, setSector] = useState('BDS')
    const data = dataOri?.map((x) => {
        return {
            Sector: x.Sector,
            Score: x.ScoreAverage,
            Maker: x.MakerAverage,
            VolR: x.VolRAverage,
            CKTG: x.ChuKiTangGiamAverage,
            Volume: x.Volume,
            Change: ((x.LastDaysData.Close[19]-x.LastDaysData.Close[18])/x.LastDaysData.Close[18])*100,
            BienDo_GM: x.BienDoCoPhieu[0].GM_,
            BienDo_GV: x.BienDoCoPhieu[0].GV_,
            BienDo_GN: x.BienDoCoPhieu[0].GN_,
            BienDo_TC: x.BienDoCoPhieu[0].TC_,
            BienDo_TN: x.BienDoCoPhieu[0].TN_,
            BienDo_TV: x.BienDoCoPhieu[0].TV_,
            BienDo_TM: x.BienDoCoPhieu[0].TM_,
            P_GREEN: x.Percentage.Green,
            P_CKTG0: x.Percentage.ChuKiTang,
            P_S0: x.Percentage.Score0,
            P_M0: x.Percentage.Maker0,
            MaTangManh: x.SortedTickerBy.Maker[0],
            

        }
    })

    const dataMax = {
        ScoreMax: dataOri?.map(x => Math.abs(x.ScoreAverage))
    }
    const columns = [
        {
            field: 'Sector',
            width: 120,
            renderCell: (params) => {
                var SectorName="";
                if(params.row.Sector==="B_") {SectorName="BlueChip*"}
                else if(params.row.Sector==="M_") {SectorName="Midcap*"}
                else if(params.row.Sector==="P_") {SectorName="Penny*"}
                else if(params.row.Sector==="BAN LE") {SectorName="Bán Lẻ"}
                else if(params.row.Sector==="BAO HIEM") {SectorName="Bảo Hiểm"}
                else if(params.row.Sector==="BDS") {SectorName="BDS"}
                else if(params.row.Sector==="BKCN") {SectorName="BDS-KCN"}
                else if(params.row.Sector==="CAO SU") {SectorName="Cao Su"}
                else if(params.row.Sector==="CHUNG KHOAN") {SectorName="Chứng Khoán"}
                else if(params.row.Sector==="CONG NGHE") {SectorName="Công Nghệ"}
                else if(params.row.Sector==="DAU KHI") {SectorName="Dầu Khí"}
                else if(params.row.Sector==="DET MAY") {SectorName="Dệt May"}
                else if(params.row.Sector==="DTC") {SectorName="Đầu Tư Công"}
                else if(params.row.Sector==="DUOC PHAM") {SectorName="Dược Phẩm"}
                else if(params.row.Sector==="DIEN") {SectorName="Điện"}
                else if(params.row.Sector==="DUONG") {SectorName="Đường"}
                else if(params.row.Sector==="Future") {SectorName="FUTURE**"}
                else if(params.row.Sector==="Index") {SectorName="INDEX**"}
                else if(params.row.Sector==="NGAN HANG") {SectorName="Ngân Hàng"}
                else if(params.row.Sector==="NHUA") {SectorName="Nhựa"}
                else if(params.row.Sector==="NONG SAN") {SectorName="Nông Sản"}
                else if(params.row.Sector==="PHAN BON") {SectorName="Phân Bón"}
                else if(params.row.Sector==="THAN") {SectorName="Than"}
                else if(params.row.Sector==="THEP") {SectorName="Thép"}
                else if(params.row.Sector==="THUC PHAM") {SectorName="Thực Phẩm"}
                else if(params.row.Sector==="THUY SAN") {SectorName="Thủy Sản"}
                else if(params.row.Sector==="VAN TAI BIEN") {SectorName="Vận Tải Biển"}
                else if(params.row.Sector==="VLXD") {SectorName="VLXD"}
                else if(params.row.Sector==="XAY DUNG") {SectorName="Xây Dựng"}
                else if(params.row.Sector==="VIN") {SectorName="G_VINGROUP"}
                else if(params.row.Sector==="MASAN") {SectorName="G_MASAN"}
                else if(params.row.Sector==="FPT") {SectorName="G_FPT"}
                else if(params.row.Sector==="GELEX") {SectorName="G_GELEX"}
                else if(params.row.Sector==="HAGL") {SectorName="G_HAGL"}
                else if(params.row.Sector==="SH?") {SectorName="G_SH"}
                else if(params.row.Sector==="VIETTEL") {SectorName="G_VIETTEL"}
                else if(params.row.Sector==="APEC!") {SectorName="G_APEC"}
                else if(params.row.Sector==="HANGKHONG") {SectorName="G_HANGKHONG"}
                return <Button onClick = {() => props.setParams({Sector: params.row.Sector})}
                variant={'contained'}
                 sx={{fontSize: 12,
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: 'darkslategray'
                }}
                >
                    
                    {SectorName?.includes('G_') ?
                    <div style={{textDecoration: 'italic', color: 'beige'}}>
                        {SectorName}
                    </div>:
                    SectorName?.includes('**') ? <>
                        <div style={{fontWeight: 'bold', color: 'red'}}>
                            {SectorName}
                        </div>
                    </>:(SectorName?.includes('*') ?  <>
                        <div style={{fontWeight: 'bold', color: 'darkorange'}}>
                            {SectorName}
                        </div>
                    </>:(SectorName?.includes('BDS')||SectorName?.includes('Chứng')||SectorName?.includes('Ngân')||SectorName?.includes('Thép')) ?  <>
                        <div style={{fontWeight: 'bold', color: 'goldenrod'}}>
                            {SectorName}
                        </div>
                    </>:<><div style={{fontWeight: 600}}>
                            {SectorName}
                        </div></>)}
                </Button>
            }
        },{
        
        field: 'Change',
        width: 55,
        renderCell: (params) => {
            function color(x){
                if(x>0.5){return '#32CD32'}
                else if(x<=0.5 && x>=-0.5){return '#DAA520'}
                else if(x<-0.5){return '#FF0000'}
            }
            
            return <div style={{fontSize: 12,
                width: "100%",
                textAlign: "center",
                fontWeight: 'bold',
                color: color(params.row.Change)
            }}
            >
                {params.row.Change>0?`+${params.row.Change?.toFixed(1)}`:params.row.Change?.toFixed(1)}%
            </div>
        }
    },
    {
            field: 'Score',
            width: 60,
            renderCell: (params) => {
                return <div style={{fontSize: 12,
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: params.row.Score>0.5?'#134d00':params.row.Score<-0.5?'#801a00':'#785807'
                    , color: 'white'
                }}
                >
                    {params.row.Score?.toFixed(2)}
                </div>
                    
            }
        },{
            field: 'Maker',
            width: 60,
            renderCell: (params) => {
                return <div style={{fontSize: 12,
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: params.row.Maker>0.5?'#134d00':params.row.Maker<-0.5?'#801a00':'#785807'
                    , color: 'white'
                }}
                >
                    {params.row.Maker?.toFixed(2)}
                </div>
            }
        },{
            field: 'VolR',
            width: 60,
            renderCell: (params) => {
                return <div style={{fontSize: 12,
                    width: "100%",
                    textAlign: "center",color: params.row.VolR>=1.3?'violet':params.row.VolR>=1.1?'dodgerblue':params.row.VolR<0.8?'red':'white'
                }}
                >
                    {params.row.VolR?.toFixed(2)}
                </div>
            }
        },{
            field: 'CKTG',
            width: 60,
            renderCell: (params) => {
                return <div style={{fontSize: 12,
                    width: "100%",
                    textAlign: "center"}}
                >
                    {params.row.CKTG?.toFixed(2)}
                </div>
            }
        },{
            field: 'Volume',
            width: 100,
            renderCell: (params) => {
                return <div style={{fontSize: 12,
                    width: "100%",
                    textAlign: "center"}}
                >
                    {Math.round(params.row.Volume?.toFixed(0))}
                </div>
            }
        },
        {
            field: 'MaTangManh',
            headerName: 'Distribution',
            width: 150,
            renderCell: (params) => {
                return <span style={{width: 150, marginTop: 5}}>
                    <canvas style={{width: `${params.row.BienDo_GM}%`, height: 20, backgroundColor: '#00CED1', marginTop: 5}}>&nbsp;</canvas>
                    <canvas style={{width: `${params.row.BienDo_GV}%`, height: 20, backgroundColor: '#FF0000', marginTop: 5}}>&nbsp;</canvas>
                    <canvas style={{width: `${params.row.BienDo_GN}%`, height: 20, backgroundColor: '#FF4500', marginTop: 5}}>&nbsp;</canvas>
                    <canvas style={{width: `${params.row.BienDo_TC}%`, height: 20, backgroundColor: '#DAA520', marginTop: 5}}>&nbsp;</canvas>
                    <canvas style={{width: `${params.row.BienDo_TN}%`, height: 20, backgroundColor: 'lightgreen', marginTop: 5}}>&nbsp;</canvas>
                    <canvas style={{width: `${params.row.BienDo_TV}%`, height: 20, backgroundColor: '#32CD32', marginTop: 5}}>&nbsp;</canvas>
                    <canvas style={{width: `${params.row.BienDo_TM}%`, height: 20, backgroundColor: '#BA55D3', marginTop: 5}}>&nbsp;</canvas>

                </span>
            }
        },
        {
            field: 'P_GREEN',
            headerName: '%GREEN',
            width: 80,
            renderCell: (params) => {
                return <div style={{fontSize: 12,
                    width: "100%",
                    textAlign: "center",
                    color: colorPCT(params.row.P_GREEN)}}
                >
                    {params.row.P_GREEN?.toFixed(2)}%
                </div>
            }
        },
        
        {
            field: 'P_CKTG0',
            headerName: '%TREND>0',
            width: 80,
            renderCell: (params) => {
                return <div style={{fontSize: 12,
                    width: "100%",
                    textAlign: "center",
                    color: colorPCT(params.row.P_CKTG0)}}
                >
                    {params.row.P_CKTG0?.toFixed(2)}%
                </div>
            }
        },
        {
            field: 'P_M0',
            headerName: '%MAKER>0',
            width: 80,
            renderCell: (params) => {
                return <div style={{fontSize: 12,
                    width: "100%",
                    textAlign: "center",
                    color: colorPCT(params.row.P_M0)}}
                >
                    {params.row.P_M0?.toFixed(2)}%
                </div>
            }
        },
        {
            field: 'P_S0',
            headerName: '%SCORE>0',
            width: 80,
            renderCell: (params) => {
                return <div style={{fontSize: 12,
                    width: "100%",
                    textAlign: "center",
                    color: colorPCT(params.row.P_S0)}}
                >
                    {params.row.P_S0?.toFixed(2)}%
                </div>
            }
        },
        {
            field: 'BienDo_GN',
            headerName: 'TOP MAKER',
            width: 450,
            renderCell: (params) => {

                var data = params.row.MaTangManh

                function TickerTag(x){
                    function color(v){
                        if(v<=0) return 'red'
                        else if(v>0 && v<=2) return 'lightgreen'
                        else if(v>2 && v<=10) return '#32CD32'
                        else if(v>10) return '#BA55D3'
                    }
                    return <span style={{color: color(data.Value[x]), fontSize: 9}}>
                        {data.Ticker[x]?`${data.Ticker[x]}(${Number(data.Value[x])?.toFixed(0)}),`:``}
                    </span>

                }

                return <div>
                        <span>{TickerTag(0)}{TickerTag(1)}{TickerTag(2)}{TickerTag(3)}{TickerTag(4)}{TickerTag(5)}{TickerTag(6)}{TickerTag(7)}{TickerTag(8)}{TickerTag(9)}{TickerTag(10)}</span>
                        <span>{TickerTag(11)}{TickerTag(12)}{TickerTag(13)}{TickerTag(14)}{TickerTag(15)}{TickerTag(16)}{TickerTag(17)}{TickerTag(18)}{TickerTag(19)}{TickerTag(20)}</span>
                </div>
            }
        }
    ]
    return <div style = {{   backgroundColor: '#202020', color: 'red', minWidth: 900, height: 700, display: 'flex'}}>
        
        {<DataGrid
                rows = {data}
                rowsHeight = {15}
                columns = {columns}
                getRowId={(row) =>  row.Maker}
                sx={{
                    boxShadow: 4,
                    color: 'white',
                    border: 3,
                    borderColor: 'primary.light',
                    '& .MuiDataGrid-cell:hover': {
                        color: 'primary.light',
                    },
                    '--DataGrid-containerBackground': '#404040',
                    '& .super-app.negative': {
                        backgroundColor: '#d47483',
                        color: '#1a3e72',
                        fontWeight: '600',
                      },
                      '& .super-app.positive': {
                        backgroundColor: 'MediumSeaGreen',
                        color: '#1a3e72',
                        fontWeight: '600',
                      },
                      '& .super-app.base': {
                        backgroundColor: 'SandyBrown',
                        color: '#1a3e72',
                        fontWeight: '600',
                      },
                      '& .super-app.negative1': {
                          color: '#d47483',
                        },
                        '& .super-app.positive1': {
                          color: 'MediumSeaGreen',
                        },
                        '& .super-app.base1': {
                          color: 'SandyBrown',
                        },
                      '& .super-app.bold': {
                        fontWeight: 'bold'
                      },
                      '& .super-app.fontup': {
                        fontSize: '18px'
                      },
                }}
                localeText={{
                    toolbarDensity: 'Size',
                    toolbarDensityLabel: 'Size',
                    toolbarDensityCompact: 'Small',
                    toolbarDensityStandard: 'Medium',
                    toolbarDensityComfortable: 'Large',
                }}
                slots={{
                    toolbar: GridToolbar,
                  }}
                rowHeight={35} 
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'Maker', sort: 'desc' }],
                    },
                }}
                
                
            />}
        
            
    </div>;
}

export default SectorBoard