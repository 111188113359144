import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5stock from '@amcharts/amcharts5/stock'
import { useEffect, Component} from "react";
import React from "react";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5radar from "@amcharts/amcharts5/radar";



export class ChartCategory extends Component {

    constructor(props){
        super(props);
        this.state = {
            series: null,
            series2: null,
            series3: null
            
        }
    }

    componentDidMount(){
        let root = am5.Root.new(this.props.id);
        root._logo.dispose();
        root.setThemes([
            am5themes_Dark.new(root)
        ]);

        let chart = root.container.children.push(am5radar.RadarChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX"
          }));
        chart.get("colors").set("colors", [
            am5.color('#F0FFFF'),
            am5.color('#FFD700'),
            am5.color('#FF8C00'),
            am5.color('#FF0000'),
        ]);
          
          // Add cursor
          // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
          let cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
            behavior: "zoomX"
          }));
          
          cursor.lineY.set("visible", false);
          
          
          // Create axes and their renderers
          // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
          let xRenderer = am5radar.AxisRendererCircular.new(root, {});
          xRenderer.labels.template.setAll({
            radius: 10
          });
          
          let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            categoryField: "name",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
          }));
          
          let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5radar.AxisRendererRadial.new(root, {})
          }));

          
            xAxis.get("renderer").labels.template.setAll({
                fontSize: 10
            });

            yAxis.get("renderer").labels.template.setAll({
                fontSize: 10
            });
            
          
          // Create series
          // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
          let series = chart.series.push(am5radar.RadarLineSeries.new(root, {
            name: "Stock",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value1",
            categoryXField: "name",
            tooltip:am5.Tooltip.new(root, {
              labelText:"{name}: {valueY}",
            })
          }));
          
          series.strokes.template.setAll({
            strokeWidth: 2
          });
          
          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                radius: 5,
                fill: series.get("fill")
              })
            });
          });

          // Create series
          // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
          let series1 = chart.series.push(am5radar.RadarLineSeries.new(root, {
            name: "Sector",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value2",
            categoryXField: "name",
            tooltip:am5.Tooltip.new(root, {
              labelText:"{valueY}"
            })
          }));
          
          series1.strokes.template.setAll({
            strokeWidth: 2
          });
          
          series1.bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                radius: 5,
                fill: series1.get("fill")
              })
            });
          });

          // Create series
          // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
          let series2 = chart.series.push(am5radar.RadarLineSeries.new(root, {
            name: "Index",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value3",
            categoryXField: "name",
            tooltip:am5.Tooltip.new(root, {
              labelText:"{valueY}"
            })
          }));
          
          series2.strokes.template.setAll({
            strokeWidth: 2
          });
          
          series2.bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                radius: 5,
                fill: series2.get("fill")
              })
            });
          });

          // Create series
          // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
          let series3 = chart.series.push(am5radar.RadarLineSeries.new(root, {
            name: "Future",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value4",
            categoryXField: "name",
            tooltip:am5.Tooltip.new(root, {
              labelText:"{valueY}"
            })
          }));
          
          series3.strokes.template.setAll({
            strokeWidth: 2
          });
          
          series3.bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                radius: 5,
                fill: series3.get("fill")
              })
            });
          });

          let legend = chart.children.push(am5.Legend.new(root, {
            nameField: "name",
            fillField: "color",
            strokeField: "color",
            centerX: am5.percent(40),
            x: am5.percent(50),
            y: am5.percent(95),
          }));
          legend.labels.template.setAll({
            fontSize: 9,
            fontWeight: "600",
            height: 15
          });
          
          legend.data.setAll([{
            name: "CỔ PHIẾU",
            color: am5.color('#F0FFFF')
          }, {
            name: "NGÀNH",
            color: am5.color('#FFD700')
          }, {
            name: "INDEX",
            color: am5.color('#FF8C00')
          }, {
            name: "PHÁI SINH",
            color: am5.color('#FF0000')
          }]);
    

          this.setState({
            xAxis: xAxis,
            series: series,
            series1: series1,
            series2: series2,
            series3: series3
          })
          
          
          
          
          
          // Animate chart and series in
          // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
          series.appear(1000);
          series1.appear(1000);
          series2.appear(1000);
          series3.appear(1000);
          chart.appear(1000, 100);

          this.root = root;

        }

        componentWillUnmount() {
            if (this.root) {
            this.root.dispose();
            }
        }
    fetchApi(){

    }
    render() {
        let xAxis = this?.state?.xAxis
        let xAxis1 = this?.state?.xAxis1
        let series = this?.state?.series
        let series1 = this?.state?.series1
        let series2 = this?.state?.series2
        let series3 = this?.state?.series3
        let ticker = this?.props?.Ticker
        // let range0 = xAxis?.createAxisRange(xAxis?.makeDataItem({ category: "NW", endCategory: "NW",value: 2 }));
        // range0?.get("axisFill")?.setAll({
        // visible: true,
        // fill: am5?.color(0x0000ff),
        // fillOpacity: 0.2,

        // })

        


            
          const data = this?.props?.data
          const sector = this?.props?.dataSector
          const future = this?.props?.dataFuture
          const index = this?.props?.dataIndex

          var sectorapi
          



          if(data?.[0]?.Sector?.includes('BKCN')) sectorapi = 'BKCN'
          if(data?.[0]?.Sector?.includes('DTC')) sectorapi = 'DTC'
          if(data?.[0]?.Sector?.includes('DAU')) sectorapi = 'DAU KHI'
          if(data?.[0]?.Sector?.includes('NHUA')) sectorapi = 'NHUA'
          if(data?.[0]?.Sector?.includes('CAO')) sectorapi = 'CAO SU'
          if(data?.[0]?.Sector?.includes('PHAN')) sectorapi = 'PHAN BON'
          if(data?.[0]?.Sector?.includes('THEP')) sectorapi = 'THEP'
          if(data?.[0]?.Sector?.includes('THAN')) sectorapi = 'THAN'
          if(data?.[0]?.Sector?.includes('VANTAI')) sectorapi = 'VAN TAI BIEN'
          if(data?.[0]?.Sector?.includes('VLXD')) sectorapi = 'VLXD'
          if(data?.[0]?.Sector?.includes('XAY')) sectorapi = 'XAY DUNG'
          if(data?.[0]?.Sector?.includes('DIEN')) sectorapi = 'DIEN'
          if(data?.[0]?.Sector?.includes('THUY')) sectorapi = 'THUY SAN'
          if(data?.[0]?.Sector?.includes('NONG')) sectorapi = 'NONG SAN'
          if(data?.[0]?.Sector?.includes('THUC')) sectorapi = 'THUC PHAM'
          if(data?.[0]?.Sector?.includes('DET')) sectorapi = 'DET MAY'
          if(data?.[0]?.Sector?.includes('DUOC')) sectorapi = 'DUOC PHAM'
          if(data?.[0]?.Sector?.includes('CHUNG')) sectorapi = 'CHUNG KHOAN'
          if(data?.[0]?.Sector?.includes('NGAN')) sectorapi = 'NGAN HANG'
          if(data?.[0]?.Sector?.includes('BDS')) sectorapi = 'BDS'
          if(data?.[0]?.Sector?.includes('BAO')) sectorapi = 'BAO HIEM'
          if(data?.[0]?.Sector?.includes('CONG')) sectorapi = 'CONG NGHE'
          if(data?.[0]?.Sector?.includes('BAN')) sectorapi = 'BAN LE'
          if(data?.[0]?.Sector?.includes('DUOC')) sectorapi = 'DUOC PHAM'



              var sector_ = sector?.filter(x => {
                return x?.Sector===sectorapi
              })

              var index_ = sector?.filter(x => {
                return x?.Sector==="Index"
              })
              
              var future_ = sector?.filter(x => {
                return x?.Sector==="Future"
              })
            
                let data_ = [{
                    "name": "SCORE (Cá Nhân)",
                    "value1": Number(data?.[0]?.SB3SC0A),
                    "value2": Number(sector?.[0]?.ScoreAverage),
                    "value3": Number(index?.[0]?.ScoreAverage),
                    "value4": Number(future?.[0]?.ScoreAverage)
                    }, {
                    "name": "MAKER (Tạo Lập)",
                    "value1": Number(data?.[0]?.SB3MK0A),
                    "value2": Number(sector?.[0]?.MakerAverage),
                    "value3": Number(index?.[0]?.MakerAverage),
                    "value4": Number(future?.[0]?.MakerAverage)
                    }, {
                    "name": "Chu Kì Tăng Giảm",
                    "value1": Number(data?.[0]?.['ChuKiTangGiam']),
                    "value2": Number(sector?.[0]?.ChuKiTangGiamAverage),
                    "value3": Number(index?.[0]?.ChuKiTangGiamAverage),
                    "value4": Number(future?.[0]?.ChuKiTangGiamAverage)
                    },
                    {
                        "name": "VOLR (Hệ số Dòng Tiền Tạo Lập)",
                        "value1": Number(data?.[0]?.['VolRAvg5']),
                        "value2": Number(sector?.[0]?.VolRAverage),
                        "value3": Number(index?.[0]?.VolRAverage),
                        "value4": Number(future?.[0]?.VolRAverage)
                        },
                    ]
                
                series?.data?.setAll(data_);
                xAxis?.data?.setAll(data_);
                series1?.data?.setAll(data_);
                series2?.data?.setAll(data_);
                series3?.data?.setAll(data_);
    
    


        
        return (
        <spa>
            
            <div id={this.props.id} style={{ height: 300, backgroundColor: '#202020',marginLeft: 20, padding: -5, borderRadius: 5, border: '1px solid white'}}>
            </div>
        </spa>

          
        );
    }

    


}

