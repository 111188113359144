export function SectorBenchmark(dataOri, sectorRequire){
    const data = dataOri?.map((x) => {
        return {
            Sector: x.Sector,
            Score: x.ScoreAverage,
            Maker: x.MakerAverage,
            VolR: x.VolRAverage,
            CKTG: x.ChuKiTangGiamAverage,
            Volume: x.Volume,
            Change: ((x.LastDaysData.Close[19]-x.LastDaysData.Close[18])/x.LastDaysData.Close[18])*100,
            BienDo_GM: x.BienDoCoPhieu[0].GM_,
            BienDo_GV: x.BienDoCoPhieu[0].GV_,
            BienDo_GN: x.BienDoCoPhieu[0].GN_,
            BienDo_TC: x.BienDoCoPhieu[0].TC_,
            BienDo_TN: x.BienDoCoPhieu[0].TN_,
            BienDo_TV: x.BienDoCoPhieu[0].TV_,
            BienDo_TM: x.BienDoCoPhieu[0].TM_,
            P_GREEN: x.Percentage.Green,
            P_CKTG0: x.Percentage.ChuKiTang,
            P_S0: x.Percentage.Score0,
            P_M0: x.Percentage.Maker0,
            MaTangManh: x.SortedTickerBy.Maker[0],
            
            BienDo_TM5: (x.LastDaysData.BienDoCoPhieu[0].TM_+x.LastDaysData.BienDoCoPhieu[1].TM_+x.LastDaysData.BienDoCoPhieu[2].TM_+x.LastDaysData.BienDoCoPhieu[3].TM_+x.LastDaysData.BienDoCoPhieu[4].TM_)/5,
            BienDo_TV5: (x.LastDaysData.BienDoCoPhieu[0].TV_+x.LastDaysData.BienDoCoPhieu[1].TV_+x.LastDaysData.BienDoCoPhieu[2].TV_+x.LastDaysData.BienDoCoPhieu[3].TV_+x.LastDaysData.BienDoCoPhieu[4].TV_)/5,
            BienDo_TN5: (x.LastDaysData.BienDoCoPhieu[0].TN_+x.LastDaysData.BienDoCoPhieu[1].TN_+x.LastDaysData.BienDoCoPhieu[2].TN_+x.LastDaysData.BienDoCoPhieu[3].TN_+x.LastDaysData.BienDoCoPhieu[4].TN_)/5,
            BienDo_TC5: (x.LastDaysData.BienDoCoPhieu[0].TC_+x.LastDaysData.BienDoCoPhieu[1].TC_+x.LastDaysData.BienDoCoPhieu[2].TC_+x.LastDaysData.BienDoCoPhieu[3].TC_+x.LastDaysData.BienDoCoPhieu[4].TC_)/5,
            BienDo_GN5: (x.LastDaysData.BienDoCoPhieu[0].GN_+x.LastDaysData.BienDoCoPhieu[1].GN_+x.LastDaysData.BienDoCoPhieu[2].GN_+x.LastDaysData.BienDoCoPhieu[3].GN_+x.LastDaysData.BienDoCoPhieu[4].GN_)/5,
            BienDo_GV5: (x.LastDaysData.BienDoCoPhieu[0].GV_+x.LastDaysData.BienDoCoPhieu[1].GV_+x.LastDaysData.BienDoCoPhieu[2].GV_+x.LastDaysData.BienDoCoPhieu[3].GV_+x.LastDaysData.BienDoCoPhieu[4].GV_)/5,
            BienDo_GM5: (x.LastDaysData.BienDoCoPhieu[0].GM_+x.LastDaysData.BienDoCoPhieu[1].GM_+x.LastDaysData.BienDoCoPhieu[2].GM_+x.LastDaysData.BienDoCoPhieu[3].GM_+x.LastDaysData.BienDoCoPhieu[4].GM_)/5,

        }
    })

    const dataBySector = data?.filter(x => x.Sector === sectorRequire)?.[0]

    function RankScore(x){
        if(x<=3) return 10
        if(x>3 && x<=5) return 9
        if(x>5 && x<=7) return 8
        if(x>7 && x<=10) return 6
        if(x>10 && x<=12) return 5
        if(x>12 && x<=14) return 4
        if(x>14 && x<=16) return 3
        if(x>16 && x<=18) return 2
        if(x>18 && x<=20) return 1
        if(x>20) return 0
    }

    
    function PctScore(x){
        if(x>75) return 10
        if(x>60 && x<=75) return 8
        if(x>50 && x<=60) return 6
        if(x>40 && x<=50) return 4
        if(x>30 && x<=40) return 2
        if(x>20 && x<=30) return 1
        if(x<=20) return 0
    }

    function MakerScore(x){
        if(x>5) return 10
        if(x>2 && x<=5) return 9
        if(x>0 && x<=2) return 8
        if(x>-0.5 && x<=0) return 5
        if(x>-1 && x<=-0.5) return 3
        if(x>2 && x<=-1) return 1
        if(x<=-2) return 0
    }

    
    function CKTGScore(x){
        if(x>10) return 7
        if(x>5 && x<=10) return 8.5
        if(x>0 && x<=5) return 10
        if(x>-2 && x<=0) return 6
        if(x>-5 && x<=-2) return 3
        if(x>-10 && x<=-5) return 1
        if(x<=-10) return 0
    }

    
    function VolRScore(x){
        if(x>1.5) return 10
        if(x>1.25 && x<=1.5) return 9
        if(x>1 && x<=1.25) return 8
        if(x>0.9 && x<=1) return 7
        if(x>0.85 && x<=0.9) return 5
        if(x>0.75 && x<=0.85) return 3
        if(x>0.65 && x<=0.75) return 2
        if(x>0.5 && x<=0.65) return 1
        if(x<=0.5) return 0
    }



    const MakerRank = data?.sort(
        (a,b) => Number(b.Maker) - Number(a.Maker)
    )?.map((value, index) => ({ value, index }))?.filter(
        x => {
           return x.value.Sector === sectorRequire
        }
    )?.[0]?.index+1

    
    const VolRRank = data?.sort(
        (a,b) => Number(b.VolR) - Number(a.VolR)
    )?.map((value, index) => ({ value, index }))?.filter(
        x => {
           return x.value.Sector === sectorRequire
        }
    )?.[0]?.index+1

    
    const CKTGRank = data?.sort(
        (a,b) => Number(b.CKTG) - Number(a.CKTG)
    )?.map((value, index) => ({ value, index }))?.filter(
        x => {
           return x.value.Sector === sectorRequire
        }
    )?.[0]?.index+1

    const overallScore = RankScore(MakerRank)*0.15 + RankScore(VolRRank)*0.1 + RankScore(CKTGRank)*0.1 
    + MakerScore(dataBySector.Maker)*0.15 + CKTGScore(dataBySector.CKTG)*0.1 
    + VolRScore(dataBySector.VolR) * 0.1 + PctScore(dataBySector.P_GREEN)*0.1 + PctScore(dataBySector.P_M0)*0.1
    + PctScore(dataBySector.P_CKTG0)*0.1

    return {
        RANKING: RankScore(MakerRank)*0.5 + RankScore(VolRRank)*0.25 + RankScore(CKTGRank)*0.25,
        UNIFORMITY: PctScore(dataBySector.P_GREEN)*0.33+ PctScore(dataBySector.P_M0)*0.33 + PctScore(dataBySector.P_CKTG0)*0.33,
        STABILITY: MakerScore(dataBySector.Maker)*0.33 + CKTGScore(dataBySector.CKTG)*0.33 + VolRScore(dataBySector.VolR) * 0.33,
        RANGE: Math.min((dataBySector.BienDo_TC5+dataBySector.BienDo_TN5+dataBySector.BienDo_TV5+dataBySector.BienDo_TM5)/10*1.5,10)
        
    }







}