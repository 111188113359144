import useTitle from 'hooks/useTitle'
import React, { useState, useEffect } from 'react'
import MatrixBoard from './components/MatrixBoard.matrix'
import { useGetFundamentalQuery, useGetStockDataQuery } from './api/apiSlice.matrix'
import { ChartScoreMaker } from './components/ByTicker/ChartScoreMaker.byticker.matrix'
import { ChartCategory } from './components/ByTicker/ChartCategory.byticker.matrix'
import { useGetSectorQuery } from 'features/dashboard/api/apiSlice.dashboard'
import { PulseLoader } from 'react-spinners'
import { TickerVNSearch } from './components/filter/Ticker.filter.matrix'
import { MakerFilter, RangeSelect, ScoreFilter, Status5DSelect, VolumeSearch, SectorFilter, CKTGFilter } from './components/filter/Params.filter.matrix'
import { Button, Typography } from '@mui/material'
import MatrixRecom from './components/MatrixRecom.matrix'
import { MdFilterAlt, MdFilterAltOff  } from "react-icons/md";
import { TbChartBar, TbChartBarOff } from "react-icons/tb";
import { FcAcceptDatabase, FcDeleteDatabase  } from "react-icons/fc";
import CompanyNameList from './components/data/CompanyNameList'
import {OrderFlow} from './components/MatrixComponents.matrix.jsx'

const Matrix = () => {
  useTitle("Finlens Matrix")
  const {data,isSuccess} = useGetStockDataQuery()
  const {data: dataFunda, isSuccess: isSuccessFunda} = useGetFundamentalQuery()
  const {data: dataSector, isSuccess: isSuccessSector} = useGetSectorQuery()
  const [Data, setData] = useState([])
  const [DataSector, setDataSector] = useState([])
  const [FilterToggle, setFilterToggle] = useState(false)
  const [ChartToggle, setChartToggle] = useState(false)
  const [BoardToggle, setBoardToggle] = useState(true)
  useEffect(() => {
    setData(data)
    setTickerVN(data)
  }, [isSuccess])
  useEffect(() => {
    setDataSector(dataSector)
  }, [isSuccessSector])
  const [params,setParams] = useState({
    TickerName: 'FRT',
    TickerList: ['ACB','VNM','IDC']
  })
  const [TickerVN, setTickerVN] = useState(Data)
  const [searchAll, setSearchAll] = useState({
    Ticker: data,
    Volume: 100000,
    Sector: 'ALL',
    Score: {
      Value: [-100,100],
      Delta: -100
    },
    Maker: {
      Value: [-100,100],
      Delta: -100
    },
    TrendCycle: [-100,500],
    Delta25: [[-1e9],[-1e9]],
    VolRate: [0,0,0,0,0,0,0,0,0,0],
    DChange: [-15,15],
    Range: 'ALL',
    Status: {
      Type: 'ALL',
      No: 0
    }

  })
  


  function changeSector(x){
    
    if(x?.includes('BKCN')) return 'BKCN'
    if(x?.includes('DTC')) return 'DTC'
    if(x?.includes('DAU')) return 'DAU KHI'
    if(x?.includes('NHUA')) return 'NHUA'
    if(x?.includes('CAO')) return 'CAO SU'
    if(x?.includes('PHAN')) return 'PHAN BON'
    if(x?.includes('THEP')) return 'THEP'
    if(x?.includes('THAN')) return 'THAN'
    if(x?.includes('VANTAI')) return 'VAN TAI BIEN'
    if(x?.includes('VLXD')) return 'VLXD'
    if(x?.includes('XAY')) return 'XAY DUNG'
    if(x?.includes('DIEN')) return 'DIEN'
    if(x?.includes('THUY')) return 'THUY SAN'
    if(x?.includes('NONG')) return 'NONG SAN'
    if(x?.includes('THUC')) return 'THUC PHAM'
    if(x?.includes('DET')) return 'DET MAY'
    if(x?.includes('DUOC')) return 'DUOC PHAM'
    if(x?.includes('CHUNG')) return 'CHUNG KHOAN'
    if(x?.includes('NGAN')) return 'NGAN HANG'
    if(x?.includes('BDS')) return 'BDS'
    if(x?.includes('BAO')) return 'BAO HIEM'
    if(x?.includes('CONG')) return 'CONG NGHE'
    if(x?.includes('BAN')) return 'BAN LE'
    if(x?.includes('DUOC')) return 'DUOC PHAM'
  }
  console.log(searchAll)


  const Chart = () => {

    const list = CompanyNameList();
    function getChgCol(v){
      if(Number(v)>=6) return 'violet'
      else if(Number(v)<6 && Number(v)>0) return 'green'
      else if(Number(v)<0 && Number(v)>-6) return 'darkred'
      else if(Number(v)<=-6) return 'aqua'
    }
    return <div style={{backgroundColor: '#101010', height: 400, padding: 15, minWidth: 1000}}>
      <div style={{textAlign: 'center'}}>
        <span style={{
          textDecoration: 'underline',
          fontWeight: 'bold', fontSize: 20
        }}>
          Current Ticker
        </span>:&nbsp;
        <span style={{
          fontSize: 20,
          color: Data?.filter(x => x.Ticker === params.TickerName)?.[0]?.color
        }}>{params.TickerName} ({list?.filter(x => {return x.stockname===params.TickerName})?.[0]?.companyname})
        </span>
      </div>
      
      
      <div style={{ width: '100%', display: 'flex', minWidth: 1000, justifyContent: 'center', marginTop: 15}}>
      <span style={{ width: '46%'}}>
        <Typography
          textAlign={'center'}
          fontStyle={'italic'}
          color={'#808080'}
        >
          Tương Đối Nhà Đầu Tư Cá Nhân & Tạo Lập
        </Typography>
        <ChartScoreMaker data={Data?.filter(x => {return x.Ticker === params.TickerName})} id={`ChartScoreMaker_`} Ticker={params.TickerName} />
      </span>
      <span style={{ width: '46%'}}>
        <Typography
          textAlign={'center'}
          fontStyle={'italic'}
          color={'#808080'}
        >
          Định Lượng Liên Thị Trường (Ngành, Phái Sinh & Index)
        </Typography>
        <ChartCategory 
        data={Data?.filter(x => {return x.Ticker === params.TickerName})} 
        dataSector={DataSector?.filter(x2 => {return x2?.Sector === changeSector(Data?.filter(x => {return x.Ticker === params.TickerName})?.[0]?.Sector)})} id={`ChartCategory_`} Ticker={params.TickerName} 
        dataIndex={DataSector?.filter(x2 => {return x2?.Sector === 'Index'})}
        dataFuture={DataSector?.filter(x2 => {return x2?.Sector === 'Future'})}
        
        />
          
      </span>

    </div> 
  </div>
  }

  const handleChangeFilterToggle = () => {
    setFilterToggle(!FilterToggle)
  }

  
  const handleChangeChartToggle = () => {
    setChartToggle(!ChartToggle)
  }

  
  const handleChangeBoardToggle = () => {
    setBoardToggle(!BoardToggle)
  }
  const STRATEGY1 = data?.filter(
    x => {
        return (
            String(x.Ticker)?.length===3&&
            Number(x.SB3MK0A)>=0 &&
            Number(x.ChuKiTangGiam)>=0&&
            ((String(x.S2Col)?.includes('=>G')) || 
             (String(x.S2Col)==='GREEN' && Number(x.S2No)<=10)
            )&&
            Number(x.VolumeAverage)>=500000 && 
            Number(x.S4MakerStrengthDelta)>0 &&
            Number(x.S1Rating?.includes('A5'))
        )
    }
)?.sort((a,b) => {
    return Number(b.SB3MK0A)-Number(a.SB3MK0A)
})?.slice(0,20)

const STRATEGY2 = data?.filter(
  x => {
      return (
          String(x.Ticker)?.length===3&&
          Number(x.VolumeAverage)>=100000&&
          Number(x.SB3MK0A)>=0 &&
          ((String(x.S2Col)?.includes('=>G')) || 
             (String(x.S2Col)==='GREEN' && Number(x.S2No)<=2)
            )
          )&&
          Number(x.S1Rating?.includes('A5')||Number(x.S1Rating?.includes('B5'))
      )
  }
)?.sort((a,b) => {
  return Number(b.SB3MK0A)-Number(a.SB3MK0A)
})
  return (isSuccessSector&&isSuccess?
    
    <section className="welcome" style={{
      margin: 'auto',
      width: '96%',
      minWidth: 800
    }}>
      

    <div style={{backgroundColor: '#151515', padding: 10, display: 'flex'}}>
      <div style={{display: 'flex', flexDirection: 'column', padding:  10,borderLeft: '2px solid white', borderRight: '2px solid white', width: '50%', }}>
          
          <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
          }}>
            <Button onClick = {handleChangeFilterToggle} sx = {{
              border: '2px solid white',
              backgroundColor: '#151515',
              display: 'flex',
              flexDirection: 'column',
              color: 'beige'
            }}>
                {FilterToggle===true?<MdFilterAlt fontSize={30}/>:<MdFilterAltOff fontSize={30}/>}
                <Typography
                  fontSize={10}
                  fontWeight={600}
                  color={'royalblue'}
                >
                  AdvFilter
                </Typography>
            </Button>
            
            
            <Button onClick = {handleChangeChartToggle} sx = {{
              border: '2px solid white',
              color: 'royalblue',
              backgroundColor: '#151515',
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 5,
              color: 'beige'
            }}>
              {ChartToggle===true?<TbChartBar fontSize={30}/>:<TbChartBarOff fontSize={30}/>}
              <Typography
                  fontSize={10}
                  fontWeight={600}
                  color={'royalblue'}
                >
                  Chart
                </Typography>
            </Button>
            <Button onClick = {handleChangeBoardToggle} sx = {{
              border: '2px solid white',
              color: 'royalblue',
              backgroundColor: '#151515',
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 5
            }}>
              {BoardToggle===true?<FcAcceptDatabase fontSize={30}/>:<FcDeleteDatabase fontSize={30}/>}
              <Typography
                  fontSize={10}
                  fontWeight={600}
                >
                  Board
                </Typography>
            </Button>
          </div>
          <div style={{fontSize: 10, color: 'lightgrey', textAlign: 'center', marginTop: 10}}>
            Thời Gian Cập Nhật Data Mới Nhất: {Data?.filter(x => x.Ticker==="AAA")?.[0]?.DateNow} 
            &nbsp;
            {Data?.filter(x => x.Ticker==="AAA")?.[0]?.['Date/Time']} 
          </div>


        </div>

        <div style={{textAlign: 'center', width: '40%', paddingTop: '0.5%', paddingBottom: '0.5%', paddingRight: '5%', paddingLeft: '5%', fontSize: 20, borderRight: '2px solid white', overflowWrap: 'break-word', color: '#FFFAF0'}}>
            
            <div style={{fontSize: 12}}>
              <div style={{fontWeight: 'bold', textDecorationLine: 'underline'}}
                onClick = {() => {
                  setSearchAll()
                }}
                >
                STRONG FROM T-3 ({STRATEGY1?.length}): 
              </div>&nbsp;
              {STRATEGY1?.slice(0,30)?.map(x => {
                return <span
                  style={{
                    fontSize: 10
                  }}

                  >
                    {x.Ticker},
                </span>
              })}
            </div>
            <br/>

            <div style={{fontSize: 12}}>
              <a style={{fontWeight: 'bold', textDecorationLine: 'underline'}}
                onClick = {() => {
                  setSearchAll()
                }}
                >
                NEW GREEN ({STRATEGY2?.length}): 
              </a>&nbsp;
              {STRATEGY2?.slice(0,50)?.map(x => {
                return <span
                  style={{
                    fontSize: 10
                  }}

                  >
                    {x.Ticker},
                </span>
              })}
            </div>

        </div>

        
        <div style={{textAlign: 'center', width: '25%', paddingTop: '0.5%', paddingBottom: '0.5%', paddingRight: '5%', paddingLeft: '5%', fontSize: 20, borderRight: '2px solid white', color: '#FFFAF0'}}>
              <div >
                <span style={{fontSize: 12, fontWeight: 'bold', textDecorationLine: 'underline'}}>
                  Tickers
                </span>&nbsp;
                {
                    <span style={{
                      fontSize: 12
                    }}>
                     {Data?.length + ' Mã'}
                    </span>
                }
              </div>
              <div>
                <span style={{fontSize: 12, fontWeight: 'bold', textDecorationLine: 'underline'}}>
                  Average Volume
                </span>&nbsp;
                <span style={{fontSize: 12}}>
                  {
                    '>='
                  }{
                    searchAll?.Volume
                  }
                </span>
              </div>
              <div>
                <span style={{fontSize: 12, fontWeight: 'bold', textDecorationLine: 'underline'}}>
                  Score 
                </span>&nbsp;
                <span style={{fontSize: 12}}>
                  {
                    searchAll?.Score?.Value[0] 
                  }&nbsp;
                  {
                    '=>'
                  }&nbsp;
                  {
                    searchAll?.Score?.Value[1]
                  }
                </span>&nbsp;|&nbsp;
                <span style={{fontSize: 12, fontWeight: 'bold', textDecorationLine: 'underline'}}>
                  Maker 
                </span>&nbsp;
                <span style={{fontSize: 12}}>
                  {
                    searchAll?.Maker?.Value[0] 
                  }&nbsp;
                  {
                    '=>'
                  }&nbsp;
                  {
                    searchAll?.Maker?.Value[1]
                  }
                </span>
              </div>
        </div>
        
        
        
        

        

       

        </div>

        {
            FilterToggle?<div style={{
              backgroundColor: '#101010',
              display: 'flex',
              padding: '1%'
            }}>
              <div style={{}}>
                <TickerVNSearch s data = {data}  setSearchAll={setSearchAll} TickerVN={TickerVN} setTickerVN={setTickerVN} isSuccess={isSuccess}/>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', width: '30%', marginLeft: 20, minWidth: 250}}>
                <VolumeSearch setSearchAll={setSearchAll} volume={searchAll.Volume||undefined} />
                <ScoreFilter setSearchAll={setSearchAll} score={searchAll.Score} />
                <MakerFilter setSearchAll={setSearchAll} maker={searchAll.Maker} />
                <CKTGFilter setSearchAll={setSearchAll} cktg={searchAll.TrendCycle}/>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', width: '20%', marginLeft: 20, minWidth: 350}}>
                <RangeSelect setSearchAll = {setSearchAll} range={searchAll.Range}/>
                <Status5DSelect setSearchAll = {setSearchAll}/>
                <SectorFilter setSearchAll = {setSearchAll} sector={searchAll.Sector} />
              </div>
              <Button onClick = {() => {
                if(searchAll.Ticker.length > 0){
                  setTickerVN(searchAll.Ticker?.filter(
                    x => {
                      return Number(x.SB3SC0A) >= (searchAll.Score.Value[0] )
                      && Number(x.SB3SC0A) <= (searchAll.Score.Value[1])
                      && Number(x.S3DeltaScore) >= (searchAll.Score.Delta )
                      &&Number(x.SB3MK0A) >= (searchAll.Maker.Value[0]  )
                      && Number(x.SB3MK0A) <= (searchAll.Maker.Value[1])
                      && Number(x.S4DeltaMaker) >= (searchAll.Maker.Delta)
                      && Number(x.VolumeAverage) >= Number(searchAll.Volume)
                      && Number(x.ChuKiTangGiam) >= searchAll.TrendCycle[0]
                      && Number(x.ChuKiTangGiam) <= searchAll.TrendCycle[1]
                      && (searchAll.Status.Type === "ALL"?
                        x.S2Col!=='':
                        (x.S2Col === searchAll.Status.Type && Number(x.S2No)<= searchAll.Status.No)
                      )
                      && (
                        searchAll.Range === "ALL"?
                        String(x.S1Rating)!=="":
                        String(x.S1Rating)?.includes(searchAll.Range)
                      )
                      && (
                        searchAll.Sector === 'ALL'?
                        String(x.Ticker)!=="":
                        String(x.Sector)?.includes(searchAll.Sector)
                      )
                    }
                  ))
                }
                else{
                  setTickerVN(data?.filter(
                    x => {
                      return Number(x.SB3SC0A) >= (searchAll.Score.Value[0] )
                      && Number(x.SB3SC0A) <= (searchAll.Score.Value[1])
                      && Number(x.S3DeltaScore) >= (searchAll.Score.Delta )
                      &&Number(x.SB3MK0A) >= (searchAll.Maker.Value[0]  )
                      && Number(x.SB3MK0A) <= (searchAll.Maker.Value[1])
                      && Number(x.S4DeltaMaker) >= (searchAll.Maker.Delta)
                      && Number(x.VolumeAverage) >= Number(searchAll.Volume)
                      && Number(x.ChuKiTangGiam) >= searchAll.TrendCycle[0]
                      && Number(x.ChuKiTangGiam) <= searchAll.TrendCycle[1]
                      && (searchAll.Status.Type === "ALL"?
                        x.S2Col!=='':
                        (x.S2Col === searchAll.Status.Type && Number(x.S2No)<= searchAll.Status.No)
                      )
                      && (
                        searchAll.Range === "ALL"?
                        String(x.S1Rating)!=="":
                        String(x.S1Rating)?.includes(searchAll.Range)
                      )
                      && (
                        searchAll.Sector === 'ALL'?
                        String(x.Ticker)!=="":
                        String(x.Sector)?.includes(searchAll.Sector)
                      )
                    }
                  ))
                }
                
                
              }}
                variant='contained'
                color='success'
                sx = {{
                  marginLeft: '20px'
                }}
              >
                Submit
              </Button>
              <Button onClick = {() => {
                setTickerVN(data)
                setSearchAll({
                  Ticker: data,
                  Volume: 100000,
                  Sector: 'ALL',
                  Score: {
                    Value: [-100,100],
                    Delta: -100
                  },
                  Maker: {
                    Value: [-100,100],
                    Delta: -100
                  },
                  TrendCycle: [-100,500],
                  Delta25: [[-1e9],[-1e9]],
                  VolRate: [0,0,0,0,0,0,0,0,0,0],
                  DChange: [-15,15],
                  Range: 'ALL',
                  Status: {
                    Type: 'ALL',
                    No: 0
                  }
              
                })
                
              }}
              variant={'contained'}
              color={'warning'}
              >
                Reset
              </Button>
            </div>: <></>
        }

        {
            ChartToggle?<Chart/>: <></>
        }

        {
          BoardToggle?<div style={{
            height: '100%',
          }}>
            <MatrixBoard data={TickerVN} isSuccess={isSuccess} dataFunda={dataFunda} isSuccessFunda={isSuccessFunda} setParams={setParams} />
          </div>: <></>
        }
     
      
      
          </section>
    : <PulseLoader  color={"#FFF"}/>
  )
}

export default Matrix