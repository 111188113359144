export default function CompanyNameList() {
    return [{stockname: '000001.SS', companyname: 'China ShangHai Composite Index'},
    {stockname: 'A32', companyname: 'CTCP 32'},
    {stockname: 'AAA', companyname: 'CTCP Nhựa An Phát Xanh'},
    {stockname: 'AAM', companyname: 'CTCP Thủy sản MeKong'},
    {stockname: 'AAS', companyname: 'CTCP Chứng khoán SmartInvest'},
    {stockname: 'AAT', companyname: 'Công ty Cổ phần Tiên Sơn Thanh Hóa'},
    {stockname: 'AAV', companyname: 'CTCP Việt Tiên Sơn Địa ốc'},
    {stockname: 'ABB', companyname: 'Ngân hàng Thương mại cổ phần An Bình'},
    {stockname: 'ABC', companyname: 'CTCP Truyền thông VMG'},
    {stockname: 'ABI', companyname: 'CTCP Bảo hiểm Ngân hàng Nông nghiệp Việt Nam'},
    {stockname: 'ABR', companyname: 'CTCP Đầu tư Nhãn hiệu Việt'},
    {stockname: 'ABS', companyname: 'CTCP Dịch vụ Nông nghiệp Bình Thuận'},
    {stockname: 'ABT', companyname: 'CTCP Xuất nhập khẩu Thủy sản Bến Tre'},
    {stockname: 'ACB', companyname: 'Ngân hàng TMCP Á Châu'},
    {stockname: 'ACC', companyname: 'CTCP Đầu tư và Xây dựng Bình Dương ACC'},
    {stockname: 'ACE', companyname: 'CTCP Bê tông Ly tâm An Giang'},
    {stockname: 'ACL', companyname: 'CTCP Xuất nhập khẩu Thủy sản Cửu Long An Giang'},
    {stockname: 'ACM', companyname: 'CTCP Tập đoàn Khoáng sản Á Cường'},
    {stockname: 'ACS', companyname: 'CTCP Xây lắp Thương mại 2'},
    {stockname: 'ACV', companyname: 'Tổng Công ty Cảng Hàng không Việt Nam - CTCP'},
    {stockname: 'ADC', companyname: 'CTCP Mỹ thuật và Truyền thông'},
    {stockname: 'ADG', companyname: 'CTCP Clever Group'},
    {stockname: 'ADP', companyname: 'CTCP Sơn Á Đông'},
    {stockname: 'ADS', companyname: 'CTCP Damsan'},
    {stockname: 'AFX', companyname: 'CTCP Xuất nhập khẩu Nông sản Thực phẩm An Giang'},
    {stockname: 'AG1', companyname: 'CTCP 28.1'},
    {stockname: 'AGE', companyname: 'Công ty cổ phần Môi trường Đô thị An Giang'},
    {stockname: 'AGF', companyname: 'CTCP Xuất nhập khẩu Thủy sản An Giang'},
    {stockname: 'AGG', companyname: 'CTCP Đầu tư và Phát triển Bất động sản An Gia'},
    {stockname: 'AGM', companyname: 'CTCP Xuất nhập khẩu An Giang'},
    {stockname: 'AGP', companyname: 'CTCP Dược phẩm Agimexpharm'},
    {stockname: 'AGR', companyname: 'CTCP Chứng khoán Agribank'},
    {stockname: 'AGX', companyname: 'CTCP Thực phẩm Nông Sản xuất khẩu Sài Gòn'},
    {stockname: 'AIC', companyname: 'Công ty cổ phần Bảo hiểm Hàng không'},
    {stockname: 'ALT', companyname: 'CTCP Văn hóa Tân Bình'},
    {stockname: 'ALV', companyname: 'CTCP Xây dựng ALVICO'},
    {stockname: 'AMC', companyname: 'CTCP Khoáng sản Á Châu'},
    {stockname: 'AMD', companyname: 'CTCP Đầu tư và Khoáng sản FLC AMD'},
    {stockname: 'AME', companyname: 'CTCP Alphanam E&C'},
    {stockname: 'AMP', companyname: 'CTCP Armephaco'},
    {stockname: 'AMS', companyname: 'CTCP Cơ khí Xây dựng AMECC'},
    {stockname: 'AMV', companyname: 'CTCP Sản xuất Kinh doanh Dược và Trang thiết bị Y tế Việt Mỹ'},
    {stockname: 'ANT', companyname: 'CTCP Rau quả Thực phẩm An Giang'},
    {stockname: 'ANV', companyname: 'CTCP Nam Việt'},
    {stockname: 'APC', companyname: 'CTCP Chiếu xạ An Phú'},
    {stockname: 'APF', companyname: 'CTCP Nông sản Thực phẩm Quảng Ngãi'},
    {stockname: 'APG', companyname: 'CTCP Chứng khoán APG'},
    {stockname: 'APH', companyname: 'CTCP Tập đoàn An Phát Holdings'},
    {stockname: 'API', companyname: 'CTCP Đầu tư Châu Á - Thái Bình Dương'},
    {stockname: 'APL', companyname: 'CTCP Cơ khí và Thiết bị áp lực - VVMI'},
    {stockname: 'APP', companyname: 'CTCP Phát triển Phụ gia và Sản phẩm Dầu Mỏ'},
    {stockname: 'APS', companyname: 'CTCP Chứng khoán Châu Á Thái Bình Dương'},
    {stockname: 'APT', companyname: 'CTCP Kinh doanh Thủy Hải Sản Sài Gòn'},
    {stockname: 'ARM', companyname: 'CTCP Xuất nhập khẩu Hàng không'},
    {stockname: 'ART', companyname: 'CTCP Chứng khoán BOS'},
    {stockname: 'ASA', companyname: 'CTCP ASA'},
    {stockname: 'ASG', companyname: 'Công ty Cổ phần Tập đoàn ASG'},
    {stockname: 'ASM', companyname: 'CTCP Tập đoàn Sao Mai'},
    {stockname: 'ASP', companyname: 'CTCP Tập đoàn Dầu khí An Pha'},
    {stockname: 'AST', companyname: 'CTCP Dịch vụ Hàng không Taseco'},
    {stockname: 'ATA', companyname: 'CTCP Ntaco'},
    {stockname: 'ATB', companyname: 'CTCP An Thịnh'},
    {stockname: 'ATG', companyname: 'CTCP An Trường An'},
    {stockname: 'ATS', companyname: 'CTCP Suất ăn Công nghiệp Atesco'},
    {stockname: 'AUD-X', companyname: 'USD/AUD (Australian Dollar)'},
    {stockname: 'AUDUSD', companyname: 'AUD/USD (Đô la Úc / Mỹ)'},
    {stockname: 'AUM', companyname: 'CTCP Vinacafe Sơn Thành'},
    {stockname: 'AVC', companyname: 'CTCP Thủy điện A Vương'},
    {stockname: 'AVF', companyname: 'CTCP Việt An'},
    {stockname: 'B82', companyname: 'CTCP 482'},
    {stockname: 'BAB', companyname: 'Ngân hàng TMCP Bắc Á'},
    {stockname: 'BAF', companyname: 'Công ty Cổ phần Nông nghiệp BaF Việt Nam'},
    {stockname: 'BAL', companyname: 'CTCP Bao bì Bia - Rượu - Nước giải khát'},
    {stockname: 'BAX', companyname: 'CTCP Thống Nhất'},
    {stockname: 'BBC', companyname: 'CTCP Bibica'},
    {stockname: 'BBH', companyname: 'CTCP Bao bì Hoàng Thạch'},
    {stockname: 'BBM', companyname: 'CTCP Bia Hà Nội - Nam Định'},
    {stockname: 'BBS', companyname: 'CTCP VICEM Bao bì Bút Sơn'},
    {stockname: 'BBT', companyname: 'CTCP Bông Bạch Tuyết'},
    {stockname: 'BCA', companyname: 'Công ty cổ phần B.C.H'},
    {stockname: 'BCB', companyname: 'CTCP 397'},
    {stockname: 'BCC', companyname: 'CTCP Xi măng Bỉm Sơn'},
    {stockname: 'BCE', companyname: 'CTCP Xây dựng và Giao thông Bình Dương'},
    {stockname: 'BCF', companyname: 'CTCP Thực phẩm Bích Chi'},
    {stockname: 'BCG', companyname: 'CTCP Bamboo Capital'},
    {stockname: 'BCM', companyname: 'Tổng Công ty Đầu tư và Phát triển Công nghiệp – CTCP'},
    {stockname: 'BCP', companyname: 'CTCP Dược ENLIE'},
    {stockname: 'BCV', companyname: 'CTCP Du lịch và Thương mại Bằng Giang Cao Bằng - Vimico'},
    {stockname: 'BDB', companyname: 'CTCP Sách và Thiết bị Bình Định'},
    {stockname: 'BDG', companyname: 'CTCP May mặc Bình Dương'},
    {stockname: 'BDT', companyname: 'CTCP Xây lắp và Vật liệu Xây dựng Đồng Tháp'},
    {stockname: 'BDW', companyname: 'CTCP Cấp thoát nước Bình Định'},
    {stockname: 'BED', companyname: 'CTCP Sách và Thiết bị Trường học Đà Nẵng'},
    {stockname: 'BEL', companyname: 'CTCP Điện tử Biên Hòa'},
    {stockname: 'BFC', companyname: 'CTCP Phân bón Bình Điền'},
    {stockname: 'BGW', companyname: 'CTCP Nước sạch Bắc Giang'},
    {stockname: 'BHA', companyname: 'CTCP Thủy điện Bắc Hà'},
    {stockname: 'BHC', companyname: 'CTCP Bê tông Biên Hòa'},
    {stockname: 'BHG', companyname: 'CTCP Chè Biển Hồ'},
    {stockname: 'BHK', companyname: 'CTCP Bia Hà Nội - Kim Bài'},
    {stockname: 'BHN', companyname: 'Tổng Công ty cổ phần Bia - Rượu - Nước giải khát Hà Nội'},
    {stockname: 'BHP', companyname: 'CTCP Bia Hà Nội - Hải Phòng'},
    {stockname: 'BHT', companyname: 'CTCP Đầu tư Xây dựng Bạch Đằng TMC'},
    {stockname: 'BIC', companyname: 'Tổng Công ty cổ phần Bảo hiểm Ngân hàng Đầu tư và Phát triển Việt Nam'},
    {stockname: 'BID', companyname: 'Ngân hàng TMCP Đầu tư và Phát triển Việt Nam'},
    {stockname: 'BIG', companyname: 'CTCP Big Invest Group'},
    {stockname: 'BII', companyname: 'Công ty cổ phần Louis Land'},
    {stockname: 'BIO', companyname: 'CTCP Vắc xin và Sinh phẩm Nha Trang'},
    {stockname: 'BKC', companyname: 'CTCP khoáng sản Bắc Kạn'},
    {stockname: 'BKG', companyname: 'Công ty Cổ phần Đầu tư BKG Việt Nam'},
    {stockname: 'BKH', companyname: 'CTCP Bánh mứt kẹo Hà Nội'},
    {stockname: 'BLF', companyname: 'CTCP Thủy sản Bạc Liêu'},
    {stockname: 'BLI', companyname: 'Tổng Công ty cổ phần Bảo hiểm Bảo Long'},
    {stockname: 'BLN', companyname: 'CTCP Vận tải và Dịch vụ Liên Ninh'},
    {stockname: 'BLT', companyname: 'CTCP Lương thực Bình Định'},
    {stockname: 'BLW', companyname: 'CTCP Cấp nước Bạc Liêu'},
    {stockname: 'BMC', companyname: 'CTCP Khoáng sản Bình Định'},
    {stockname: 'BMD', companyname: 'CTCP Môi trường và Dịch vụ Đô thị Bình Thuận'},
    {stockname: 'BMF', companyname: 'CTCP Vật liệu Xây dựng và Chất đốt Đồng Nai'},
    {stockname: 'BMG', companyname: 'CTCP May Bình Minh'},
    {stockname: 'BMI', companyname: 'Tổng Công ty cổ phần Bảo Minh'},
    {stockname: 'BMJ', companyname: 'CTCP Khoáng sản Miền Đông AHP'},
    {stockname: 'BMN', companyname: 'CTCP 715'},
    {stockname: 'BMP', companyname: 'CTCP Nhựa Bình Minh'},
    {stockname: 'BMS', companyname: 'CTCP Chứng khoán Bảo Minh'},
    {stockname: 'BMV', companyname: 'CTCP Bột mỳ Vinafood 1'},
    {stockname: 'BNA', companyname: 'Công ty Cổ phần Đầu tư Sản xuất Bảo Ngọc'},
    {stockname: 'BNW', companyname: 'CTCP Nước sạch Bắc Ninh'},
    {stockname: 'BOT', companyname: 'CTCP BOT Cầu Thái Hà'},
    {stockname: 'BPC', companyname: 'CTCP VICEM Bao bì Bỉm Sơn'},
    {stockname: 'BQB', companyname: 'CTCP Bia Hà Nội - Quảng Bình'},
    {stockname: 'BRC', companyname: 'CTCP Cao su Bến Thành'},
    {stockname: 'BRL-X', companyname: 'USD/BRL (Brazilian Real)'},
    {stockname: 'BRR', companyname: 'CTCP Cao su Bà Rịa'},
    {stockname: 'BRS', companyname: 'CTCP Dịch vụ Đô thị Bà Rịa'},
    {stockname: 'BSA', companyname: 'CTCP Thủy điện Buôn Đôn'},
    {stockname: 'BSC', companyname: 'CTCP Dịch vụ Bến Thành'},
    {stockname: 'BSD', companyname: 'CTCP Bia, Rượu Sài Gòn - Đồng Xuân'},
    {stockname: 'BSG', companyname: 'CTCP Xe khách Sài Gòn'},
    {stockname: 'BSH', companyname: 'CTCP Bia Sài Gòn - Hà Nội'},
    {stockname: 'BSI', companyname: 'CTCP Chứng khoán Ngân hàng Đầu tư và Phát triển Việt Nam'},
    {stockname: 'BSL', companyname: 'CTCP Bia Sài Gòn - Sông Lam'},
    {stockname: 'BSP', companyname: 'CTCP Bia Sài Gòn - Phú Thọ'},
    {stockname: 'BSQ', companyname: 'CTCP Bia Sài Gòn - Quảng Ngãi'},
    {stockname: 'BSR', companyname: 'CTCP Lọc hóa Dầu Bình Sơn'},
    {stockname: 'BST', companyname: 'CTCP Sách và Thiết bị Bình Thuận'},
    {stockname: 'BT1', companyname: 'CTCP Bảo vệ Thực vật 1 Trung Ương'},
    {stockname: 'BT6', companyname: 'CTCP Beton 6'},
    {stockname: 'BTB', companyname: 'CTCP Bia Hà Nội - Thái Bình'},
    {stockname: 'BTCUSD', companyname: 'BITCON'},
    {stockname: 'BTCUSD-X', companyname: 'Bitcoin Price Index'},
    {stockname: 'BTD', companyname: 'CTCP Bê tông Ly tâm Thủ Đức'},
    {stockname: 'BTG', companyname: 'CTCP Bao bì Tiền Giang'},
    {stockname: 'BTH', companyname: 'CTCP Chế tạo Biến thế và Vật liệu Điện Hà Nội'},
    {stockname: 'BTN', companyname: 'CTCP Gạch Tuy Nen Bình Định'},
    {stockname: 'BTP', companyname: 'CTCP Nhiệt điện Bà Rịa'},
    {stockname: 'BTS', companyname: 'CTCP Xi măng VICEM Bút Sơn'},
    {stockname: 'BTT', companyname: 'CTCP Thương mại Dịch vụ Bến Thành'},
    {stockname: 'BTU', companyname: 'CTCP Công trình Đô thị Bến Tre'},
    {stockname: 'BTV', companyname: 'CTCP Dịch vụ Du lịch Bến Thành'},
    {stockname: 'BTW', companyname: 'CTCP Cấp nước Bến Thành'},
    {stockname: 'BVB', companyname: 'Ngân hàng TMCP Bản Việt'},
    {stockname: 'BVG', companyname: 'CTCP GROUP BẮC VIỆT'},
    {stockname: 'BVH', companyname: 'Tập đoàn Bảo Việt'},
    {stockname: 'BVL', companyname: 'Công ty cổ phần BV Land'},
    {stockname: 'BVN', companyname: 'CTCP Bông Việt Nam'},
    {stockname: 'BVS', companyname: 'CTCP Chứng khoán Bảo Việt'},
    {stockname: 'BWA', companyname: 'CTCP Cấp thoát nước và Xây dựng Bảo Lộc'},
    {stockname: 'BWE', companyname: 'CTCP Nước - Môi trường Bình Dương'},
    {stockname: 'BWS', companyname: 'CTCP Cấp nước Bà Rịa - Vũng Tàu'},
    {stockname: 'BXH', companyname: 'CTCP VICEM Bao bì Hải Phòng'},
    {stockname: 'C12', companyname: 'CTCP Cầu 12'},
    {stockname: 'C21', companyname: 'CTCP Thế Kỷ 21'},
    {stockname: 'C22', companyname: 'CTCP 22'},
    {stockname: 'C32', companyname: 'CTCP CIC39'},
    {stockname: 'C47', companyname: 'CTCP Xây dựng 47'},
    {stockname: 'C4G', companyname: 'CTCP Tập đoàn CIENCO4'},
    {stockname: 'C69', companyname: 'CTCP Xây dựng 1369'},
    {stockname: 'C92', companyname: 'CTCP Xây dựng và Đầu tư 492'},
    {stockname: 'CAB', companyname: 'CTCP Tổng Công ty Truyền hình Cáp Việt Nam'},
    {stockname: 'CAD', companyname: 'CTCP Chế biến và Xuất nhập khẩu Thủy sản Cadovimex'},
    {stockname: 'CAD-X', companyname: 'USD/CAD (Canadian Dollar)'},
    {stockname: 'CAG', companyname: 'CTCP Cảng An Giang'},
    {stockname: 'CAN', companyname: 'CTCP Đồ hộp Hạ Long'},
    {stockname: 'CAP', companyname: 'CTCP Lâm Nông sản Thực phẩm Yên Bái'},
    {stockname: 'CAR', companyname: 'Công ty Cổ phần Tập đoàn Giáo dục Trí Việt'},
    {stockname: 'CAT', companyname: 'CTCP Thủy sản Cà Mau'},
    {stockname: 'CAV', companyname: 'CTCP Dây Cáp điện Việt Nam'},
    {stockname: 'CBI', companyname: 'CTCP Gang thép Cao Bằng'},
    {stockname: 'CBS', companyname: 'CTCP Mía Đường Cao Bằng'},
    {stockname: 'CC1', companyname: 'Tổng Công ty Xây dựng Số 1 - CTCP'},
    {stockname: 'CC4', companyname: 'CTCP Đầu tư và Xây dựng Số 4'},
    {stockname: 'CCA', companyname: 'CTCP Xuất nhập khẩu Thuỷ sản Cần Thơ'},
    {stockname: 'CCI', companyname: 'CTCP Đầu tư Phát triển Công nghiệp Thương mại Củ Chi'},
    {stockname: 'CCL', companyname: 'CTCP Đầu Tư và Phát triển Đô Thị Dầu khí Cửu Long'},
    {stockname: 'CCM', companyname: 'CTCP khoáng sản và Xi măng Cần Thơ'},
    {stockname: 'CCP', companyname: 'CTCP Cảng Cửa Cấm Hải Phòng'},
    {stockname: 'CCR', companyname: 'CTCP Cảng Cam Ranh'},
    {stockname: 'CCT', companyname: 'CTCP Cảng Cần Thơ'},
    {stockname: 'CCV', companyname: 'CTCP Tư vấn Xây dựng Công nghiệp và Đô thị Việt Nam'},
    {stockname: 'CDC', companyname: 'CTCP Chương Dương'},
    {stockname: 'CDG', companyname: 'CTCP Cầu Đuống'},
    {stockname: 'CDH', companyname: 'CTCP Công trình Công cộng và Dịch vụ Du lịch Hải Phòng'},
    {stockname: 'CDN', companyname: 'CTCP Cảng Đà Nẵng'},
    {stockname: 'CDO', companyname: 'CTCP Tư vấn Thiết kế và Phát triển Đô thị'},
    {stockname: 'CDP', companyname: 'CTCP Dược phẩm Trung ương Codupha'},
    {stockname: 'CDR', companyname: 'CTCP Xây dựng Cao su Đồng Nai'},
    {stockname: 'CE1', companyname: 'CTCP Xây dựng và Thiết bị Công nghiệp CIE1'},
    {stockname: 'CEG', companyname: 'CTCP Tập đoàn Xây dựng và Thiết bị Công nghiệp'},
    {stockname: 'CEN', companyname: 'CTCP CENCON Việt Nam'},
    {stockname: 'CEO', companyname: 'CTCP Tập đoàn C.E.O'},
    {stockname: 'CET', companyname: 'CTCP Tech – Vina'},
    {stockname: 'CFM', companyname: 'CTCP Đầu tư CFM'},
    {stockname: 'CFPT2012', companyname: 'Chứng quyền FPT-HSC-MET06'},
    {stockname: 'CFPT2016', companyname: 'Chứng quyền FPT/8M/SSI/C/EU/Cash-08'},
    {stockname: 'CFPT2017', companyname: 'Chứng quyền CFPT03MBS20CE'},
    {stockname: 'CFPT2101', companyname: 'Chứng quyền CFPT04MBS20CE'},
    {stockname: 'CFPT2102', companyname: 'Chứng quyền FPT/VCSC/M/Au/T/A3'},
    {stockname: 'CFPT2103', companyname: 'Chứng quyền FPT-HSC-MET07'},
    {stockname: 'CFPT2104', companyname: 'Chứng quyền FPT/ACBS/Call/EU/Cash/4M/10'},
    {stockname: 'CFPT2105', companyname: 'Chứng quyền FPT/5M/SSI/C/EU/Cash-10'},
    {stockname: 'CFPT2106', companyname: 'Chứng quyền CFPT02MBS21CE'},
    {stockname: 'CFV', companyname: 'CTCP Cà phê Thắng Lợi'},
    {stockname: 'CGV', companyname: 'CTCP Sành sứ Thủy tinh Việt Nam'},
    {stockname: 'CH5', companyname: 'CTCP Xây dựng Số 5 Hà Nội'},
    {stockname: 'CHC', companyname: 'CTCP Cẩm Hà'},
    {stockname: 'CHDB2007', companyname: 'Chứng quyền.HDB.KIS.M.CA.T.04'},
    {stockname: 'CHDB2008', companyname: 'Chứng quyền.HDB.KIS.M.CA.T.05'},
    {stockname: 'CHDB2101', companyname: 'Chứng quyền.HDB.KIS.M.CA.T.06'},
    {stockname: 'CHDB2102', companyname: 'Chứng quyền.HDB.KIS.M.CA.T.07'},
    {stockname: 'CHP', companyname: 'CTCP Thủy điện Miền Trung'},
    {stockname: 'CHPG2014', companyname: 'Chứng quyền HPG/9M/SSI/C/EU/Cash-06'},
    {stockname: 'CHPG2018', companyname: 'Chứng quyền.HPG.KIS.M.CA.T.09'},
    {stockname: 'CHPG2020', companyname: 'Chứng quyền.HPG.VND.M.CA.T.2020.02'},
    {stockname: 'CHPG2022', companyname: 'Chứng quyền HPG-HSC-MET05'},
    {stockname: 'CHPG2026', companyname: 'Chứng quyền CHPG03MBS20CE'},
    {stockname: 'CHPG2101', companyname: 'Chứng quyền.HPG.KIS.M.CA.T.10'},
    {stockname: 'CHPG2102', companyname: 'Chứng quyền HPG/ACBS/Call/EU/Cash/6M/02'},
    {stockname: 'CHPG2103', companyname: 'Chứng quyền.HPG.VND.M.CA.T.2020.03'},
    {stockname: 'CHPG2104', companyname: 'Chứng quyền CHPG04MBS20CE'},
    {stockname: 'CHPG2105', companyname: 'Chứng quyền HPG-HSC-MET06'},
    {stockname: 'CHPG2106', companyname: 'Chứng quyền CHPG01MBS21CE'},
    {stockname: 'CHPG2107', companyname: 'Chứng quyền.HPG.KIS.M.CA.T.11'},
    {stockname: 'CHPG2108', companyname: 'Chứng quyền.HPG.KIS.M.CA.T.12'},
    {stockname: 'CHPG2109', companyname: 'Chứng quyền HPG/VCSC/M/Au/T/A2'},
    {stockname: 'CHPG2110', companyname: 'Chứng quyền HPG/ACBS/Call/EU/Cash/4M/05'},
    {stockname: 'CHPG2111', companyname: 'Chứng quyền HPG/5M/SSI/C/EU/Cash-10'},
    {stockname: 'CHPG2112', companyname: 'Chứng quyền CHPG02MBS21CE'},
    {stockname: 'CHS', companyname: 'CTCP Chiếu sáng Công cộng Thành phố Hồ Chí Minh'},
    {stockname: 'CI5', companyname: 'CTCP Đầu Tư Xây dựng Số 5'},
    {stockname: 'CIA', companyname: 'CTCP Dịch vụ Sân bay Quốc tế Cam Ranh'},
    {stockname: 'CID', companyname: 'CTCP Xây dựng và Phát triển Cơ sở Hạ tầng'},
    {stockname: 'CIG', companyname: 'CTCP COMA 18'},
    {stockname: 'CII', companyname: 'CTCP Đầu tư Hạ tầng Kỹ thuật Thành phố Hồ Chí Minh'},
    {stockname: 'CIP', companyname: 'CTCP Xây lắp và Sản xuất Công nghiệp'},
    {stockname: 'CJC', companyname: 'CTCP Cơ điện Miền Trung'},
    {stockname: 'CKA', companyname: 'CTCP Cơ Khí An Giang'},
    {stockname: 'CKD', companyname: 'CTCP Cơ khí Đông Anh LICOGI'},
    {stockname: 'CKDH2001', companyname: 'Chứng quyền.KDH.KIS.M.CA.T.02'},
    {stockname: 'CKDH2002', companyname: 'Chứng quyền.KDH.KIS.M.CA.T.03'},
    {stockname: 'CKDH2101', companyname: 'Chứng quyền.KDH.KIS.M.CA.T.04'},
    {stockname: 'CKDH2102', companyname: 'Chứng quyền CKDH01MBS21CE'},
    {stockname: 'CKDH2103', companyname: 'Chứng quyền.KDH.KIS.M.CA.T.05'},
    {stockname: 'CKDH2104', companyname: 'Chứng quyền KDH/5M/SSI/C/EU/Cash-11'},
    {stockname: 'CKDH2105', companyname: 'Chứng quyền CKDH02MBS21CE'},
    {stockname: 'CKDH2106', companyname: 'Chứng quyền KDH/VCSC/M/Au/T/A1'},
    {stockname: 'CKG', companyname: 'CTCP Tập đoàn Tư vấn Đầu tư và Xây dựng Kiên Giang'},
    {stockname: 'CKV', companyname: 'CTCP COKYVINA'},
    {stockname: 'CL-F', companyname: 'Crude Oil (Giá Dầu)'},
    {stockname: 'CLC', companyname: 'CTCP Cát Lợi'},
    {stockname: 'CLG', companyname: 'CTCP Đầu tư và Phát triển Nhà đất COTEC'},
    {stockname: 'CLH', companyname: 'CTCP Xi măng La Hiên VVMI'},
    {stockname: 'CLL', companyname: 'CTCP Cảng Cát Lái'},
    {stockname: 'CLM', companyname: 'CTCP Xuất nhập khẩu Than - Vinacomin'},
    {stockname: 'CLW', companyname: 'CTCP Cấp nước Chợ Lớn'},
    {stockname: 'CLX', companyname: 'CTCP Xuất nhập khẩu và Đầu tư Chợ Lớn (CHOLIMEX)'},
    {stockname: 'CMBB2010', companyname: 'Chứng quyền MBB/8M/SSI/C/EU/Cash-08'},
    {stockname: 'CMBB2101', companyname: 'Chứng quyền.MBB.VND.M.CA.T.2020.03'},
    {stockname: 'CMBB2102', companyname: 'Chứng quyền.MBB.KIS.M.CA.T.01'},
    {stockname: 'CMBB2103', companyname: 'Chứng quyền MBB-HSC-MET07'},
    {stockname: 'CMBB2104', companyname: 'Chứng quyền MBB/5M/SSI/C/EU/Cash-11'},
    {stockname: 'CMBB2105', companyname: 'Chứng quyền MBB/VCSC/M/Au/T/A2'},
    {stockname: 'CMC', companyname: 'CTCP Đầu tư CMC'},
    {stockname: 'CMD', companyname: 'CTCP Vật liệu Xây dựng và Trang trí nội thất Thành phố Hồ Chí Minh'},
    {stockname: 'CMF', companyname: 'CTCP Thực phẩm Cholimex'},
    {stockname: 'CMG', companyname: 'CTCP Tập đoàn Công nghệ CMC'},
    {stockname: 'CMI', companyname: 'CTCP CMISTONE Việt Nam'},
    {stockname: 'CMK', companyname: 'CTCP Cơ khí Mạo Khê - Vinacomin'},
    {stockname: 'CMN', companyname: 'CTCP Lương thực Thực phẩm Colusa - Miliket'},
    {stockname: 'CMP', companyname: 'CTCP Cảng Chân Mây'},
    {stockname: 'CMS', companyname: 'CTCP CMVIETNAM'},
    {stockname: 'CMSN2007', companyname: 'Chứng quyền.MSN.KIS.M.CA.T.06'},
    {stockname: 'CMSN2009', companyname: 'Chứng quyền.MSN.KIS.M.CA.T.07'},
    {stockname: 'CMSN2101', companyname: 'Chứng quyền.MSN.KIS.M.CA.T.09'},
    {stockname: 'CMSN2102', companyname: 'Chứng quyền.MSN.KIS.M.CA.T.10'},
    {stockname: 'CMSN2103', companyname: 'Chứng quyền CMSN01MBS21CE'},
    {stockname: 'CMSN2104', companyname: 'Chứng quyền MSN/ACBS/Call/EU/Cash/9M/09'},
    {stockname: 'CMSN2105', companyname: 'Chứng quyền MSN/5M/SSI/C/EU/Cash-10'},
    {stockname: 'CMSN2106', companyname: 'Chứng quyền.MSN.KIS.M.CA.T.11'},
    {stockname: 'CMT', companyname: 'CTCP Công nghệ Mạng và Truyền thông'},
    {stockname: 'CMV', companyname: 'CTCP Thương nghiệp Cà Mau'},
    {stockname: 'CMW', companyname: 'CTCP Cấp nước Cà Mau'},
    {stockname: 'CMWG2013', companyname: 'Chứng quyền.MWG.VND.M.CA.T.2020.02'},
    {stockname: 'CMWG2015', companyname: 'Chứng quyền MWG-HSC-MET06'},
    {stockname: 'CMWG2016', companyname: 'Chứng quyền MWG-HSC-MET07'},
    {stockname: 'CMWG2017', companyname: 'Chứng quyền CMWG03MBS20CE'},
    {stockname: 'CMWG2101', companyname: 'Chứng quyền.MWG.VND.M.CA.T.2020.03'},
    {stockname: 'CMWG2102', companyname: 'Chứng quyền MWG/VCSC/M/Au/T/A3'},
    {stockname: 'CMWG2103', companyname: 'Chứng quyền CMWG04MBS20CE'},
    {stockname: 'CMWG2104', companyname: 'Chứng quyền MWG/ACBS/Call/EU/Cash/12M/04'},
    {stockname: 'CMWG2105', companyname: 'Chứng quyền CMWG01MBS21CE'},
    {stockname: 'CMWG2106', companyname: 'Chứng quyền MWG-HSC-MET08'},
    {stockname: 'CMWG2107', companyname: 'Chứng quyền MWG/5M/SSI/C/EU/Cash-10'},
    {stockname: 'CMX', companyname: 'CTCP Camimex Group'},
    {stockname: 'CNA', companyname: 'Công ty TNHH MTV Đầu tư phát triển Chè Nghệ An'},
    {stockname: 'CNC', companyname: 'CTCP Công nghệ Cao Traphaco'},
    {stockname: 'CNG', companyname: 'CTCP CNG Việt Nam'},
    {stockname: 'CNN', companyname: 'CTCP Tư vấn Công nghệ Thiết bị và Kiểm Định Xây dựng - Coninco'},
    {stockname: 'CNT', companyname: 'CTCP Xây dựng và Kinh doanh Vật tư'},
    {stockname: 'CNVL2003', companyname: 'Chứng quyền.NVL.KIS.M.CA.T.04'},
    {stockname: 'CNVL2101', companyname: 'Chứng quyền.NVL.KIS.M.CA.T.05'},
    {stockname: 'CNVL2102', companyname: 'Chứng quyền.NVL.KIS.M.CA.T.06'},
    {stockname: 'CNVL2103', companyname: 'Chứng quyền NVL/5M/SSI/C/EU/Cash-11'},
    {stockname: 'CNY-X', companyname: 'USD/CNY (Chinese Yuan)'},
    {stockname: 'COM', companyname: 'CTCP Vật tư Xăng Dầu'},
    {stockname: 'CPA', companyname: 'CTCP Cà phê Phước An'},
    {stockname: 'CPC', companyname: 'CTCP Thuốc sát trùng Cần Thơ'},
    {stockname: 'CPDR2101', companyname: 'Chứng quyền.PDR.KIS.M.CA.T.01'},
    {stockname: 'CPDR2102', companyname: 'Chứng quyền.PDR.KIS.M.CA.T.02'},
    {stockname: 'CPH', companyname: 'CTCP Phục vụ Mai táng Hải Phòng'},
    {stockname: 'CPI', companyname: 'CTCP Đầu tư Cảng Cái Lân'},
    {stockname: 'CPNJ2101', companyname: 'Chứng quyền.PNJ.VND.M.CA.T.2020.02'},
    {stockname: 'CPNJ2102', companyname: 'Chứng quyền PNJ/VCSC/M/Au/T/A2'},
    {stockname: 'CPNJ2103', companyname: 'Chứng quyền CPNJ04MBS20CE'},
    {stockname: 'CPNJ2104', companyname: 'Chứng quyền PNJ-HSC-MET04'},
    {stockname: 'CPNJ2105', companyname: 'Chứng quyền PNJ/5M/SSI/C/EU/Cash-10'},
    {stockname: 'CQN', companyname: 'CTCP Cảng Quảng Ninh'},
    {stockname: 'CQT', companyname: 'CTCP Xi măng Quán Triều VVMI'},
    {stockname: 'CRC', companyname: 'CTCP Create Capital Việt Nam'},
    {stockname: 'CRE', companyname: 'CTCP Bất động sản Thế Kỷ'},
    {stockname: 'CREE2006', companyname: 'Chứng quyền REE-HSC-MET04'},
    {stockname: 'CREE2101', companyname: 'Chứng quyền.REE.VND.M.CA.T.2020.04'},
    {stockname: 'CSBT2007', companyname: 'Chứng quyền.SBT.KIS.M.CA.T.03'},
    {stockname: 'CSBT2101', companyname: 'Chứng quyền.SBT.KIS.M.CA.T.04'},
    {stockname: 'CSC', companyname: 'CTCP Tập đoàn COTANA'},
    {stockname: 'CSI', companyname: 'CTCP Chứng khoán Kiến thiết Việt Nam'},
    {stockname: 'CSM', companyname: 'CTCP Công nghiệp Cao su Miền Nam'},
    {stockname: 'CST', companyname: 'Công ty Cổ phần Than Cao Sơn - TKV'},
    {stockname: 'CSTB2007', companyname: 'Chứng quyền.STB.KIS.M.CA.T.07'},
    {stockname: 'CSTB2010', companyname: 'Chứng quyền.STB.KIS.M.CA.T.08'},
    {stockname: 'CSTB2014', companyname: 'Chứng quyền STB/8M/SSI/C/EU/Cash-08'},
    {stockname: 'CSTB2016', companyname: 'Chứng quyền.STB.KIS.M.CA.T.10'},
    {stockname: 'CSTB2017', companyname: 'Chứng quyền CSTB03MBS20CE'},
    {stockname: 'CSTB2101', companyname: 'Chứng quyền.STB.KIS.M.CA.T.11'},
    {stockname: 'CSTB2102', companyname: 'Chứng quyền CSTB04MBS20CE'},
    {stockname: 'CSTB2103', companyname: 'Chứng quyền STB-HSC-MET02'},
    {stockname: 'CSTB2104', companyname: 'Chứng quyền CSTB01MBS21CE'},
    {stockname: 'CSTB2105', companyname: 'Chứng quyền STB/VCSC/M/Au/T/A1'},
    {stockname: 'CSTB2106', companyname: 'Chứng quyền.STB.KIS.M.CA.T.12'},
    {stockname: 'CSTB2107', companyname: 'Chứng quyền STB/5M/SSI/C/EU/Cash-11'},
    {stockname: 'CSTB2108', companyname: 'Chứng quyền CSTB02MBS21CE'},
    {stockname: 'CSV', companyname: 'CTCP Hóa chất Cơ bản Miền Nam'},
    {stockname: 'CT3', companyname: 'CTCP Đầu tư và Xây dựng Công trình 3'},
    {stockname: 'CT6', companyname: 'CTCP Công trình 6'},
    {stockname: 'CTA', companyname: 'CTCP Vinavico'},
    {stockname: 'CTB', companyname: 'CTCP Chế tạo Bơm Hải Dương'},
    {stockname: 'CTC', companyname: 'CTCP Gia Lai CTC'},
    {stockname: 'CTCB2012', companyname: 'Chứng quyền TCB/8M/SSI/C/EU/Cash-09'},
    {stockname: 'CTCB2013', companyname: 'Chứng quyền TCB/5M/SSI/C/EU/Cash-09'},
    {stockname: 'CTCB2101', companyname: 'Chứng quyền TCB/ACBS/Call/EU/Cash/9M/05'},
    {stockname: 'CTCB2102', companyname: 'Chứng quyền.TCB.VND.M.CA.T.2020.02'},
    {stockname: 'CTCB2103', companyname: 'Chứng quyền TCB-HSC-MET05'},
    {stockname: 'CTCB2104', companyname: 'Chứng quyền CTCB01MBS21CE'},
    {stockname: 'CTCB2105', companyname: 'Chứng quyền TCB/ACBS/Call/EU/Cash/9M/06'},
    {stockname: 'CTCB2106', companyname: 'Chứng quyền TCB/5M/SSI/C/EU/Cash-11'},
    {stockname: 'CTCB2107', companyname: 'Chứng quyền CTCB02MBS21CE'},
    {stockname: 'CTCH2001', companyname: 'Chứng quyền.TCH.KIS.M.CA.T.02'},
    {stockname: 'CTCH2003', companyname: 'Chứng quyền.TCH.KIS.M.CA.T.03'},
    {stockname: 'CTCH2101', companyname: 'Chứng quyền.TCH.KIS.M.CA.T.04'},
    {stockname: 'CTCH2102', companyname: 'Chứng quyền CTCH01MBS21CE'},
    {stockname: 'CTCH2103', companyname: 'Chứng quyền.TCH.KIS.M.CA.T.05'},
    {stockname: 'CTD', companyname: 'CTCP Xây dựng Coteccons'},
    {stockname: 'CTF', companyname: 'CTCP City Auto'},
    {stockname: 'CTG', companyname: 'Ngân hàng TMCP Công Thương Việt Nam'},
    {stockname: 'CTI', companyname: 'CTCP Đầu tư Phát triển Cường Thuận IDICO'},
    {stockname: 'CTN', companyname: 'CTCP Xây dựng Công trình ngầm'},
    {stockname: 'CTP', companyname: 'CTCP Minh Khanh Capital Trading Public'},
    {stockname: 'CTR', companyname: 'Tổng Công ty cổ phần Công trình Viettel'},
    {stockname: 'CTS', companyname: 'CTCP Chứng khoán Ngân hàng Công thương Việt Nam'},
    {stockname: 'CTT', companyname: 'CTCP Chế tạo Máy - Vinacomin'},
    {stockname: 'CTW', companyname: 'CTCP Cấp thoát nước Cần Thơ'},
    {stockname: 'CTX', companyname: 'Tổng Công ty cổ phần Đầu tư Xây dựng và Thương mại Việt Nam'},
    {stockname: 'CVHM2006', companyname: 'Chứng quyền.VHM.KIS.M.CA.T.04'},
    {stockname: 'CVHM2008', companyname: 'Chứng quyền.VHM.KIS.M.CA.T.05'},
    {stockname: 'CVHM2010', companyname: 'Chứng quyền VHM-HSC-MET04'},
    {stockname: 'CVHM2101', companyname: 'Chứng quyền.VHM.KIS.M.CA.T.06'},
    {stockname: 'CVHM2102', companyname: 'Chứng quyền.VHM.VND.M.CA.T.2020.01'},
    {stockname: 'CVHM2103', companyname: 'Chứng quyền CVHM01MBS20CE'},
    {stockname: 'CVHM2104', companyname: 'Chứng quyền VHM-HSC-MET05'},
    {stockname: 'CVHM2105', companyname: 'Chứng quyền CVHM01MBS21CE'},
    {stockname: 'CVHM2106', companyname: 'Chứng quyền.VHM.KIS.M.CA.T.07'},
    {stockname: 'CVHM2107', companyname: 'Chứng quyền VHM/5M/SSI/C/EU/Cash-10'},
    {stockname: 'CVHM2108', companyname: 'Chứng quyền CVHM02MBS21CE'},
    {stockname: 'CVHM2109', companyname: 'Chứng quyền VHM/VCSC/M/Au/T/A1'},
    {stockname: 'CVIC2004', companyname: 'Chứng quyền.VIC.KIS.M.CA.T.06'},
    {stockname: 'CVIC2005', companyname: 'Chứng quyền.VIC.KIS.M.CA.T.07'},
    {stockname: 'CVIC2101', companyname: 'Chứng quyền.VIC.KIS.M.CA.T.09'},
    {stockname: 'CVIC2102', companyname: 'Chứng quyền CVIC01MBS20CE'},
    {stockname: 'CVIC2103', companyname: 'Chứng quyền.VIC.KIS.M.CA.T.10'},
    {stockname: 'CVIC2104', companyname: 'Chứng quyền VIC-HSC-MET02'},
    {stockname: 'CVIC2105', companyname: 'Chứng quyền VIC/5M/SSI/C/EU/Cash-10'},
    {stockname: 'CVJC2004', companyname: 'Chứng quyền.VJC.KIS.M.CA.T.04'},
    {stockname: 'CVJC2006', companyname: 'Chứng quyền.VJC.KIS.M.CA.T.05'},
    {stockname: 'CVJC2101', companyname: 'Chứng quyền VJC/5M/SSI/C/EU/Cash-10'},
    {stockname: 'CVJC2102', companyname: 'Chứng quyền.VJC.KIS.M.CA.T.06'},
    {stockname: 'CVN', companyname: 'CTCP Vinam'},
    {stockname: 'CVNM2011', companyname: 'Chứng quyền.VNM.KIS.M.CA.T.06'},
    {stockname: 'CVNM2101', companyname: 'Chứng quyền.VNM.KIS.M.CA.T.08'},
    {stockname: 'CVNM2102', companyname: 'Chứng quyền.VNM.VND.M.CA.T.2020.02'},
    {stockname: 'CVNM2103', companyname: 'Chứng quyền CVNM04MBS20CE'},
    {stockname: 'CVNM2104', companyname: 'Chứng quyền CVNM01MBS21CE'},
    {stockname: 'CVNM2105', companyname: 'Chứng quyền.VNM.KIS.M.CA.T.09'},
    {stockname: 'CVNM2106', companyname: 'Chứng quyền VNM/VCSC/M/Au/T/A2'},
    {stockname: 'CVNM2107', companyname: 'Chứng quyền VNM-HSC-MET06'},
    {stockname: 'CVNM2108', companyname: 'Chứng quyền VNM/ACBS/Call/EU/Cash/4M/07'},
    {stockname: 'CVNM2109', companyname: 'Chứng quyền VNM/5M/SSI/C/EU/Cash-10'},
    {stockname: 'CVNM2110', companyname: 'Chứng quyền CVNM02MBS21CE'},
    {stockname: 'CVP', companyname: 'Công ty cổ phần Cảng Cửa Việt'},
    {stockname: 'CVPB2011', companyname: 'Chứng quyền VPB-HSC-MET05'},
    {stockname: 'CVPB2015', companyname: 'Chứng quyền VPB/8M/SSI/C/EU/Cash-09'},
    {stockname: 'CVPB2016', companyname: 'Chứng quyền VPB/5M/SSI/C/EU/Cash-09'},
    {stockname: 'CVPB2101', companyname: 'Chứng quyền.VPB.VND.M.CA.T.2020.02'},
    {stockname: 'CVPB2102', companyname: 'Chứng quyền CVPB04MBS20CE'},
    {stockname: 'CVPB2103', companyname: 'Chứng quyền VPB-HSC-MET06'},
    {stockname: 'CVPB2104', companyname: 'Chứng quyền CVPB01MBS21CE'},
    {stockname: 'CVPB2105', companyname: 'Chứng quyền.VPB.KIS.M.CA.T.02'},
    {stockname: 'CVPB2106', companyname: 'Chứng quyền VPB/5M/SSI/C/EU/Cash-11'},
    {stockname: 'CVRE2009', companyname: 'Chứng quyền.VRE.KIS.M.CA.T.07'},
    {stockname: 'CVRE2011', companyname: 'Chứng quyền.VRE.KIS.M.CA.T.09'},
    {stockname: 'CVRE2013', companyname: 'Chứng quyền VRE/8M/SSI/C/EU/Cash-09'},
    {stockname: 'CVRE2014', companyname: 'Chứng quyền VRE/5M/SSI/C/EU/Cash-09'},
    {stockname: 'CVRE2015', companyname: 'Chứng quyền CVRE01MBS20CE'},
    {stockname: 'CVRE2101', companyname: 'Chứng quyền.VRE.KIS.M.CA.T.10'},
    {stockname: 'CVRE2102', companyname: 'Chứng quyền.VRE.VND.M.CA.T.2020.01'},
    {stockname: 'CVRE2103', companyname: 'Chứng quyền VRE-HSC-MET06'},
    {stockname: 'CVRE2104', companyname: 'Chứng quyền CVRE01MBS21CE'},
    {stockname: 'CVRE2105', companyname: 'Chứng quyền VRE/ACBS/Call/EU/Cash/9M/08'},
    {stockname: 'CVRE2106', companyname: 'Chứng quyền VRE/5M/SSI/C/EU/Cash-10'},
    {stockname: 'CVRE2107', companyname: 'Chứng quyền.VRE.KIS.M.CA.T.11'},
    {stockname: 'CVRE2108', companyname: 'Chứng quyền CVRE02MBS21CE'},
    {stockname: 'CVT', companyname: 'CTCP CMC'},
    {stockname: 'CX8', companyname: 'CTCP Đầu tư và Xây lắp Constrexim Số 8'},
    {stockname: 'CYC', companyname: 'CTCP Gạch men Chang Yih'},
    {stockname: 'D11', companyname: 'CTCP Địa ốc 11'},
    {stockname: 'D2D', companyname: 'CTCP Phát triển Đô thị Công nghiệp số 2'},
    {stockname: 'DAC', companyname: 'CTCP Viglacera Đông Anh'},
    {stockname: 'DAD', companyname: 'CTCP Đầu tư và Phát triển Giáo dục Đà Nẵng'},
    {stockname: 'DAE', companyname: 'CTCP Sách Giáo dục tại Thành phố Đà Nẵng'},
    {stockname: 'DAG', companyname: 'CTCP Tập đoàn Nhựa Đông Á'},
    {stockname: 'DAH', companyname: 'CTCP Tập đoàn Khách sạn Đông Á'},
    {stockname: 'DAN', companyname: 'CTCP Dược Danapha'},
    {stockname: 'DAS', companyname: 'CTCP Máy - Thiết bị Dầu khí Đà Nẵng'},
    {stockname: 'DAT', companyname: 'CTCP Đầu tư du lịch và Phát triển Thủy sản'},
    {stockname: 'DBC', companyname: 'CTCP Tập đoàn Dabaco Việt Nam'},
    {stockname: 'DBD', companyname: 'CTCP Dược - Trang thiết bị Y tế Bình Định'},
    {stockname: 'DBM', companyname: 'CTCP Dược - Vật tư Y Tế Đăk Lăk'},
    {stockname: 'DBT', companyname: 'CTCP Dược phẩm Bến Tre'},
    {stockname: 'DBW', companyname: 'CTCP Cấp nước Điện Biên'},
    {stockname: 'DC1', companyname: 'CTCP Đầu tư Phát triển Xây dựng Số 1'},
    {stockname: 'DC2', companyname: 'CTCP Đầu tư - Phát triển - Xây dựng (DIC) Số 2'},
    {stockname: 'DC4', companyname: 'CTCP Xây dựng DIC Holdings'},
    {stockname: 'DCF', companyname: 'CTCP Xây dựng và Thiết kế Số 1'},
    {stockname: 'DCG', companyname: 'CTCP Tổng Công ty May Đáp Cầu'},
    {stockname: 'DCH', companyname: 'CTCP Địa chính Hà Nội'},
    {stockname: 'DCL', companyname: 'CTCP Dược phẩm Cửu Long'},
    {stockname: 'DCM', companyname: 'CTCP Phân bón Dầu khí Cà Mau'},
    {stockname: 'DCR', companyname: 'CTCP Gạch men Cosevco'},
    {stockname: 'DCS', companyname: 'CTCP Tập đoàn Đại Châu'},
    {stockname: 'DCT', companyname: 'CTCP Tấm lợp Vật liệu Xây dựng Đồng Nai'},
    {stockname: 'DDG', companyname: 'CTCP Đầu tư Công nghiệp Xuất nhập khẩu Đông Dương'},
    {stockname: 'DDH', companyname: 'CTCP Đảm bảo Giao thông Đường thủy Hải Phòng'},
    {stockname: 'DDM', companyname: 'CTCP Hàng Hải Đông Đô'},
    {stockname: 'DDN', companyname: 'CTCP Dược và Thiết bị Y tế Đà Nẵng'},
    {stockname: 'DDV', companyname: 'CTCP DAP - VINACHEM'},
    {stockname: 'DFC', companyname: 'CTCP Xích líp Đông Anh'},
    {stockname: 'DFF', companyname: 'CTCP Tập đoàn Đua Fat'},
    {stockname: 'DGC', companyname: 'CTCP Tập đoàn Hóa chất Đức Giang'},
    {stockname: 'DGT', companyname: 'CTCP Công trình Giao thông Đồng Nai'},
    {stockname: 'DGW', companyname: 'CTCP Thế Giới Số'},
    {stockname: 'DHA', companyname: 'CTCP Hóa An'},
    {stockname: 'DHB', companyname: 'CTCP Phân đạm và Hóa chất Hà Bắc'},
    {stockname: 'DHC', companyname: 'CTCP Đông Hải Bến Tre'},
    {stockname: 'DHD', companyname: 'CTCP Dược Vật tư Y tế Hải Dương'},
    {stockname: 'DHG', companyname: 'CTCP Dược Hậu Giang'},
    {stockname: 'DHM', companyname: 'CTCP Thương mại và Khai thác Khoáng sản Dương Hiếu'},
    {stockname: 'DHN', companyname: 'CTCP Dược phẩm Hà Nội'},
    {stockname: 'DHP', companyname: 'CTCP Điện cơ Hải Phòng'},
    {stockname: 'DHT', companyname: 'CTCP Dược phẩm Hà Tây'},
    {stockname: 'DIC', companyname: 'CTCP Đầu tư và Thương mại DIC'},
    {stockname: 'DID', companyname: 'CTCP DIC - Đồng Tiến'},
    {stockname: 'DIG', companyname: 'Tổng Công ty cổ phần Đầu tư Phát triển Xây dựng'},
    {stockname: 'DIH', companyname: 'CTCP Đầu tư Phát triển Xây dựng - Hội An'},
    {stockname: 'DKC', companyname: 'CTCP Chợ Lạng Sơn'},
    {stockname: 'DL1', companyname: 'CTCP Tập đoàn Năng lượng Tái tạo Việt Nam'},
    {stockname: 'DLD', companyname: 'CTCP Du lịch Đắk Lắk'},
    {stockname: 'DLG', companyname: 'CTCP Tập đoàn Đức Long Gia Lai'},
    {stockname: 'DLR', companyname: 'CTCP Địa ốc Đà Lạt'},
    {stockname: 'DLT', companyname: 'CTCP Du lịch và Thương mại - Vinacomin'},
    {stockname: 'DM7', companyname: 'CTCP Dệt May 7'},
    {stockname: 'DMC', companyname: 'CTCP Xuất nhập khẩu Y Tế Domesco'},
    {stockname: 'DMN', companyname: 'Công ty cổ phần Domenal'},
    {stockname: 'DNA', companyname: 'CTCP Điện Nước An Giang'},
    {stockname: 'DNC', companyname: 'CTCP Điện Nước Lắp máy Hải Phòng'},
    {stockname: 'DND', companyname: 'CTCP Đầu tư Xây dựng và Vật liệu Đồng Nai'},
    {stockname: 'DNE', companyname: 'CTCP Môi trường Đô thị Đà Nẵng'},
    {stockname: 'DNH', companyname: 'CTCP Thủy điện Đa Nhim - Hàm Thuận - Đa Mi'},
    {stockname: 'DNL', companyname: 'CTCP Logistics Cảng Đà Nẵng'},
    {stockname: 'DNM', companyname: 'Tổng Công ty cổ phần Y tế DANAMECO'},
    {stockname: 'DNN', companyname: 'CTCP Cấp nước Đà Nẵng'},
    {stockname: 'DNP', companyname: 'CTCP Nhựa Đồng Nai'},
    {stockname: 'DNT', companyname: 'CTCP Du lịch Đồng Nai'},
    {stockname: 'DNW', companyname: 'CTCP Cấp nước Đồng Nai'},
    {stockname: 'DOC', companyname: 'CTCP Vật tư Nông Nghiệp Đồng Nai'},
    {stockname: 'DOGE-USD', companyname: 'DOGE-USD'},
    {stockname: 'DOP', companyname: 'CTCP Vận tải Xăng dầu Đồng Tháp'},
    {stockname: 'DP1', companyname: 'CTCP Dược phẩm Trung ương CPC1'},
    {stockname: 'DP2', companyname: 'CTCP Dược phẩm Trung ương 2'},
    {stockname: 'DP3', companyname: 'CTCP Dược phẩm Trung ương 3'},
    {stockname: 'DPC', companyname: 'CTCP Nhựa Đà Nẵng'},
    {stockname: 'DPD', companyname: 'CTCP Cao su Đồng Phú - Đắk Nông'},
    {stockname: 'DPG', companyname: 'CTCP Đạt Phương'},
    {stockname: 'DPH', companyname: 'CTCP Dược phẩm Hải Phòng'},
    {stockname: 'DPM', companyname: 'Tổng Công ty Phân bón và Hóa chất Dầu khí - CTCP'},
    {stockname: 'DPP', companyname: 'CTCP Dược Đồng Nai'},
    {stockname: 'DPR', companyname: 'CTCP Cao su Đồng Phú'},
    {stockname: 'DPS', companyname: 'CTCP Đầu tư Phát triển Sóc Sơn'},
    {stockname: 'DQC', companyname: 'CTCP Bóng đèn Điện Quang'},
    {stockname: 'DRC', companyname: 'CTCP Cao su Đà Nẵng'},
    {stockname: 'DRG', companyname: 'CTCP Cao su Đắk Lắk'},
    {stockname: 'DRH', companyname: 'CTCP DRH Holdings'},
    {stockname: 'DRI', companyname: 'CTCP Đầu tư Cao su Đắk Lắk'},
    {stockname: 'DRL', companyname: 'CTCP Thủy điện - Điện Lực 3'},
    {stockname: 'DS3', companyname: 'CTCP Quản lý Đường sông Số 3'},
    {stockname: 'DSC', companyname: 'CTCP Chứng khoán Đà Nẵng'},
    {stockname: 'DSD', companyname: 'Công ty cổ phần DHC Suối Đôi'},
    {stockname: 'DSG', companyname: 'CTCP Kính Viglacera Đáp Cầu'},
    {stockname: 'DSN', companyname: 'CTCP Công viên nước Đầm Sen'},
    {stockname: 'DSP', companyname: 'CTCP Dịch vụ Du lịch Phú Thọ'},
    {stockname: 'DST', companyname: 'CTCP Đầu tư Sao Thăng Long'},
    {stockname: 'DSV', companyname: 'CTCP Đường sắt Vĩnh Phú'},
    {stockname: 'DTA', companyname: 'CTCP Đệ Tam'},
    {stockname: 'DTB', companyname: 'CTCP Công trình Đô thị Bảo Lộc'},
    {stockname: 'DTC', companyname: 'CTCP Viglacera Đông Triều'},
    {stockname: 'DTD', companyname: 'CTCP Đầu tư Phát triển Thành Đạt'},
    {stockname: 'DTE', companyname: 'CTCP Đầu tư Năng lượng Đại Trường Thành Holdings'},
    {stockname: 'DTG', companyname: 'CTCP Dược phẩm Tipharco'},
    {stockname: 'DTH', companyname: 'Công ty Cổ phần Dược - Vật tư Y tế Thanh Hóa'},
    {stockname: 'DTI', companyname: 'CTCP Đầu tư Đức Trung'},
    {stockname: 'DTK', companyname: 'Tổng Công ty Điện lực TKV - CTCP'},
    {stockname: 'DTL', companyname: 'CTCP Đại Thiên Lộc'},
    {stockname: 'DTP', companyname: 'CTCP Dược phẩm CPC1 Hà Nội'},
    {stockname: 'DTT', companyname: 'CTCP Kỹ nghệ Đô Thành'},
    {stockname: 'DTV', companyname: 'CTCP Phát triển Điện Nông thôn Trà Vinh'},
    {stockname: 'DUS', companyname: 'CTCP Dịch vụ Đô thị Đà Lạt'},
    {stockname: 'DVC', companyname: 'CTCP Thương mại Dịch vụ Tổng hợp Cảng Hải Phòng'},
    {stockname: 'DVG', companyname: 'Công ty Cổ phần Tập đoàn Sơn Đại Việt'},
    {stockname: 'DVM', companyname: 'Công ty Cổ phần Dược liệu Việt Nam'},
    {stockname: 'DVN', companyname: 'Tổng Công ty Dược Việt Nam - CTCP'},
    {stockname: 'DVP', companyname: 'CTCP Đầu tư và Phát triển Cảng Đình Vũ'},
    {stockname: 'DVW', companyname: 'CTCP Dịch vụ và Xây dựng Cấp nước Đồng Nai'},
    {stockname: 'DWC', companyname: 'CTCP Cấp nước Đắk Lắk'},
    {stockname: 'DWS', companyname: 'CTCP Cấp nước và Môi trường đô thị Đồng Tháp'},
    {stockname: 'DXG', companyname: 'CTCP Tập đoàn Đất Xanh'},
    {stockname: 'DXL', companyname: 'CTCP Du Lịch và Xuất nhập khẩu Lạng Sơn'},
    {stockname: 'DXP', companyname: 'CTCP Cảng Đoạn Xá'},
    {stockname: 'DXS', companyname: 'Công ty Cổ phần Dịch vụ Bất động sản Đất Xanh'},
    {stockname: 'DXV', companyname: 'CTCP VICEM Vật liệu Xây dựng Đà Nẵng'},
    {stockname: 'DZM', companyname: 'CTCP Cơ điện Dzĩ An'},
    {stockname: 'E12', companyname: 'CTCP Xây dựng Điện VNECO12'},
    {stockname: 'E1VFVN30', companyname: 'Quỹ ETF VFMVN30'},
    {stockname: 'E29', companyname: 'CTCP Đầu tư Xây dựng và Kỹ thuật 29'},
    {stockname: 'EBS', companyname: 'CTCP Sách Giáo dục tại Thành phố Hà Nội'},
    {stockname: 'ECI', companyname: 'CTCP Bản đồ và Tranh ảnh Giáo dục'},
    {stockname: 'EFI', companyname: 'CTCP Đầu tư Tài chính Giáo dục'},
    {stockname: 'EIB', companyname: 'Ngân hàng TMCP Xuất nhập khẩu Việt Nam'},
    {stockname: 'EIC', companyname: 'CTCP EVN Quốc tế'},
    {stockname: 'EID', companyname: 'CTCP Đầu tư và Phát triển Giáo dục Hà Nội'},
    {stockname: 'EIN', companyname: 'CTCP Đầu tư - Thương mại - Dịch vụ Điện Lực'},
    {stockname: 'ELC', companyname: 'CTCP Đầu tư Phát triển Công nghệ Điện tử - Viễn thông'},
    {stockname: 'EMC', companyname: 'CTCP Cơ điện Thủ Đức'},
    {stockname: 'EME', companyname: 'CTCP Điện cơ'},
    {stockname: 'EMG', companyname: 'CTCP Thiết bị Phụ Tùng Cơ Điện'},
    {stockname: 'EMS', companyname: 'Tổng Công ty Chuyển phát nhanh Bưu Điện - CTCP'},
    {stockname: 'EPC', companyname: 'CTCP Cà phê Ea Pốk'},
    {stockname: 'EPH', companyname: 'CTCP Dịch vụ Xuất bản Giáo dục Hà Nội'},
    {stockname: 'ETHUSD-X', companyname: 'Ethereum Price Index'},
    {stockname: 'EUR-X', companyname: 'USD/EUR (Euro)'},
    {stockname: 'EURUSD', companyname: 'EUR/USD'},
    {stockname: 'EVE', companyname: 'CTCP Everpia'},
    {stockname: 'EVF', companyname: 'Công ty Tài chính Cổ phần Điện lực'},
    {stockname: 'EVG', companyname: 'CTCP Tập đoàn EverLand'},
    {stockname: 'EVS', companyname: 'CTCP Chứng khoán Everest'},
    {stockname: 'FBA', companyname: 'CTCP Tập đoàn Quốc tế FBA'},
    {stockname: 'FBC', companyname: 'CTCP Cơ khí Phổ Yên'},
    {stockname: 'FCC', companyname: 'CTCP Liên hợp Thực phẩm'},
    {stockname: 'FCM', companyname: 'CTCP Khoáng sản FECON'},
    {stockname: 'FCN', companyname: 'CTCP FECON'},
    {stockname: 'FCS', companyname: 'CTCP Lương thực Thành phố Hồ Chí Minh'},
    {stockname: 'FDC', companyname: 'CTCP Ngoại thương và Phát triển Đầu tư Thành phố Hồ Chí Minh'},
    {stockname: 'FGL', companyname: 'CTCP Cà phê Gia Lai'},
    {stockname: 'FHN', companyname: 'CTCP Xuất nhập khẩu Lương thực Thực phẩm Hà Nội'},
    {stockname: 'FHS', companyname: 'CTCP Phát hành Sách Thành phố Hồ Chí Minh'},
    {stockname: 'FIC', companyname: 'Tổng Công ty Vật liệu Xây dựng số 1 - CTCP'},
    {stockname: 'FID', companyname: 'CTCP Đầu tư và Phát triển Doanh nghiệp Việt Nam'},
    {stockname: 'FIR', companyname: 'CTCP Địa ốc First Real'},
    {stockname: 'FIT', companyname: 'CTCP Tập đoàn F.I.T'},
    {stockname: 'FLC', companyname: 'CTCP Tập đoàn FLC'},
    {stockname: 'FMC', companyname: 'CTCP Thực phẩm Sao Ta'},
    {stockname: 'FOC', companyname: 'CTCP Dịch vụ Trực tuyến FPT'},
    {stockname: 'FOX', companyname: 'CTCP Viễn thông FPT'},
    {stockname: 'FPT', companyname: 'CTCP FPT'},
    {stockname: 'FRC', companyname: 'CTCP Lâm đặc sản Xuất khẩu Quảng Nam'},
    {stockname: 'FRM', companyname: 'CTCP Lâm Nghiệp Sài Gòn'},
    {stockname: 'FRT', companyname: 'CTCP Bán lẻ Kỹ thuật số FPT'},
    {stockname: 'FSO', companyname: 'CTCP Cơ khí đóng tàu Thủy sản Việt Nam'},
    {stockname: 'FT1', companyname: 'CTCP Phụ tùng Máy số 1'},
    {stockname: 'FTI', companyname: 'CTCP Công nghiệp - Thương mại Hữu Nghị'},
    {stockname: 'FTM', companyname: 'CTCP Đầu tư và Phát triển Đức Quân'},
    {stockname: 'FTS', companyname: 'CTCP Chứng khoán FPT'},
    {stockname: 'FUCTVGF1', companyname: 'Quỹ đầu tư tăng trưởng TVAM'},
    {stockname: 'FUCTVGF2', companyname: 'Quỹ Đầu tư Tăng trưởng Thiên Việt 2'},
    {stockname: 'FUCVREIT', companyname: 'Quỹ đầu tư bất động sản Techcom Việt Nam'},
    {stockname: 'FUEMAV30', companyname: 'Quỹ ETF MAFM VN30'},
    {stockname: 'FUESSV30', companyname: 'Quỹ ETF SSIAM VN30'},
    {stockname: 'FUESSV50', companyname: 'QUỸ ETF SSIAM VNX50'},
    {stockname: 'FUESSVFL', companyname: 'Quỹ ETF SSIAM VNFIN LEAD'},
    {stockname: 'FUEVFVND', companyname: 'Quỹ ETF VFMVN DIAMOND'},
    {stockname: 'FUEVN100', companyname: 'Quỹ ETF VINACAPITAL VN100'},
    {stockname: 'G20', companyname: 'CTCP Đầu tư Dệt may Vĩnh Phúc'},
    {stockname: 'G36', companyname: 'Tổng Công ty 36 - CTCP'},
    {stockname: 'GAB', companyname: 'CTCP Đầu tư Khai Khoáng và Quản lý Tài sản FLC'},
    {stockname: 'GAS', companyname: 'Tổng Công ty Khí Việt Nam - CTCP'},
    {stockname: 'GBP-X', companyname: 'USD/GBP (British Pound)'},
    {stockname: 'GBPJPY', companyname: 'GBP/JPY'},
    {stockname: 'GBPUSD', companyname: 'GBP/USD'},
    {stockname: 'GC-F', companyname: 'GOLD (Giá Vàng)'},
    {stockname: 'GCB', companyname: 'CTCP Petec Bình Định'},
    {stockname: 'GDT', companyname: 'CTCP Chế biến Gỗ Đức Thành'},
    {stockname: 'GDW', companyname: 'CTCP Cấp nước Gia Định'},
    {stockname: 'GE2', companyname: 'Tổng công ty Phát điện 2 - Công ty TNHH MTV'},
    {stockname: 'GEE', companyname: 'CTCP Thiết bị Điện Gelex'},
    {stockname: 'GEG', companyname: 'CTCP Điện Gia Lai'},
    {stockname: 'GER', companyname: 'CTCP Thể thao Ngôi sao Geru'},
    {stockname: 'GEX', companyname: 'Tổng Công ty cổ phần Thiết bị Điện Việt Nam'},
    {stockname: 'GGG', companyname: 'CTCP Ô tô Giải Phóng'},
    {stockname: 'GH3', companyname: 'CTCP Công trình Giao thông Hà Nội'},
    {stockname: 'GHC', companyname: 'CTCP Thủy điện Gia Lai'},
    {stockname: 'GIC', companyname: 'Công ty Cổ phần Đầu tư dịch vụ và Phát triển Xanh'},
    {stockname: 'GIL', companyname: 'CTCP Sản xuất Kinh doanh và Xuất nhập khẩu Bình Thạnh'},
    {stockname: 'GKM', companyname: 'CTCP Khang Minh Group'},
    {stockname: 'GLC', companyname: 'CTCP Vàng Lào Cai'},
    {stockname: 'GLT', companyname: 'CTCP Kỹ thuật Điện Toàn cầu'},
    {stockname: 'GLW', companyname: 'CTCP Cấp thoát nước Gia Lai'},
    {stockname: 'GMA', companyname: 'Công ty Cổ phần Enteco Việt Nam'},
    {stockname: 'GMC', companyname: 'CTCP Garmex Sài Gòn'},
    {stockname: 'GMD', companyname: 'CTCP Gemadept'},
    {stockname: 'GMH', companyname: 'Công ty Cổ phần Minh Hưng Quảng Trị'},
    {stockname: 'GMX', companyname: 'CTCP Gạch ngói Gốm Xây dựng Mỹ Xuân'},
    {stockname: 'GND', companyname: 'CTCP Gạch ngói Đồng Nai'},
    {stockname: 'GSM', companyname: 'CTCP Thủy điện Hương Sơn'},
    {stockname: 'GSP', companyname: 'CTCP Vận tải Sản Phẩm Khí Quốc tế'},
    {stockname: 'GTA', companyname: 'CTCP Chế biến gỗ Thuận An'},
    {stockname: 'GTD', companyname: 'CTCP Giầy Thượng Đình'},
    {stockname: 'GTH', companyname: 'CTCP Xây dựng - Giao thông Thừa Thiên Huế'},
    {stockname: 'GTS', companyname: 'CTCP Công trình Giao thông Sài Gòn'},
    {stockname: 'GTT', companyname: 'CTCP Thuận Thảo'},
    {stockname: 'GVR', companyname: 'Tập đoàn Công nghiệp Cao su Việt Nam - CTCP'},
    {stockname: 'GVT', companyname: 'CTCP Giấy Việt Trì'},
    {stockname: 'H11', companyname: 'CTCP Xây dựng HUD101'},
    {stockname: 'HAC', companyname: 'CTCP Chứng khoán Hải Phòng'},
    {stockname: 'HAD', companyname: 'CTCP Bia Hà Nội - Hải Dương'},
    {stockname: 'HAF', companyname: 'CTCP Thực phẩm Hà Nội'},
    {stockname: 'HAG', companyname: 'CTCP Hoàng Anh Gia Lai'},
    {stockname: 'HAH', companyname: 'CTCP Vận tải và Xếp dỡ Hải An'},
    {stockname: 'HAI', companyname: 'CTCP Nông dược H.A.I'},
    {stockname: 'HAM', companyname: 'CTCP Vật tư Hậu Giang'},
    {stockname: 'HAN', companyname: 'Tổng Công ty Xây dựng Hà Nội - CTCP'},
    {stockname: 'HAP', companyname: 'CTCP Tập đoàn Hapaco'},
    {stockname: 'HAR', companyname: 'CTCP Đầu tư Thương mại Bất động sản An Dương Thảo Điền'},
    {stockname: 'HAS', companyname: 'CTCP Hacisco'},
    {stockname: 'HAT', companyname: 'Công ty Cổ phần Thương mại Bia Hà Nội'},
    {stockname: 'HAV', companyname: 'Công ty Cổ phần Rượu Hapro'},
    {stockname: 'HAX', companyname: 'CTCP Dịch vụ Ô tô Hàng Xanh'},
    {stockname: 'HBC', companyname: 'CTCP Tập đoàn Xây dựng Hòa Bình'},
    {stockname: 'HBD', companyname: 'CTCP Bao Bì PP Bình Dương'},
    {stockname: 'HBH', companyname: 'CTCP Habeco - Hải Phòng'},
    {stockname: 'HBS', companyname: 'CTCP Chứng khoán Hòa Bình'},
    {stockname: 'HC1', companyname: 'CTCP Xây dựng Số 1 Hà Nội'},
    {stockname: 'HC3', companyname: 'CTCP Xây dựng Số 3 Hải Phòng'},
    {stockname: 'HCB', companyname: 'CTCP Dệt may 29/3'},
    {stockname: 'HCC', companyname: 'CTCP Bê tông Hòa Cầm - Intimex'},
    {stockname: 'HCD', companyname: 'CTCP Đầu tư Sản xuất và Thương mại HCD'},
    {stockname: 'HCI', companyname: 'CTCP Đầu tư Xây dựng Hà Nội'},
    {stockname: 'HCM', companyname: 'CTCP Chứng khoán Thành phố Hồ Chí Minh'},
    {stockname: 'HCT', companyname: 'CTCP Thương mại Dịch vụ Vận tải Xi măng Hải Phòng'},
    {stockname: 'HD2', companyname: 'CTCP Đầu tư Phát triển Nhà HUD2'},
    {stockname: 'HD6', companyname: 'CTCP Đầu tư và Phát triển Nhà số 6 Hà Nội'},
    {stockname: 'HD8', companyname: 'CTCP Đầu tư Phát triển Nhà và Đô thị HUD8'},
    {stockname: 'HDA', companyname: 'CTCP Hãng sơn Đông Á'},
    {stockname: 'HDB', companyname: 'Ngân hàng TMCP Phát triển TPHCM'},
    {stockname: 'HDC', companyname: 'CTCP Phát triển Nhà Bà Rịa - Vũng Tàu'},
    {stockname: 'HDG', companyname: 'CTCP Tập đoàn Hà Đô'},
    {stockname: 'HDM', companyname: 'CTCP Dệt may Huế'},
    {stockname: 'HDO', companyname: 'CTCP Hưng Đạo Container'},
    {stockname: 'HDP', companyname: 'CTCP Dược Hà Tĩnh'},
    {stockname: 'HDW', companyname: 'CTCP Kinh doanh Nước sạch Hải Dương'},
    {stockname: 'HEC', companyname: 'CTCP Tư vấn Xây dựng Thủy Lợi II'},
    {stockname: 'HEJ', companyname: 'Tổng Công ty Tư vấn Xây dựng Thủy lợi Việt Nam - CTCP'},
    {stockname: 'HEM', companyname: 'CTCP Chế tạo Điện Cơ Hà Nội'},
    {stockname: 'HEP', companyname: 'CTCP Môi trường và Công trình Đô thị Huế'},
    {stockname: 'HES', companyname: 'CTCP Dịch vụ Giải trí Hà Nội'},
    {stockname: 'HEV', companyname: 'CTCP Sách Đại học Dạy nghề'},
    {stockname: 'HFB', companyname: 'CTCP Công trình Cầu phà Thành phố Hồ Chí Minh'},
    {stockname: 'HFC', companyname: 'CTCP Xăng dầu HFC'},
    {stockname: 'HFX', companyname: 'CTCP Sản xuất - Xuất nhập khẩu Thanh Hà'},
    {stockname: 'HGM', companyname: 'CTCP Cơ khí và Khoáng sản Hà Giang'},
    {stockname: 'HGT', companyname: 'Công ty cổ phần Du lịch Hương Giang'},
    {stockname: 'HGW', companyname: 'CTCP Cấp thoát nước - Công trình Đô thị Hậu Giang'},
    {stockname: 'HHC', companyname: 'CTCP Bánh kẹo Hải Hà'},
    {stockname: 'HHG', companyname: 'CTCP Hoàng Hà'},
    {stockname: 'HHN', companyname: 'CTCP Vận tải và Dịch vụ Hàng hóa Hà Nội'},
    {stockname: 'HHP', companyname: 'CTCP Giấy Hoàng Hà Hải Phòng'},
    {stockname: 'HHR', companyname: 'CTCP Đường sắt Hà Hải'},
    {stockname: 'HHS', companyname: 'CTCP Đầu tư Dịch vụ Hoàng Huy'},
    {stockname: 'HHV', companyname: 'CTCP Đầu tư Hạ tầng Giao thông Đèo Cả'},
    {stockname: 'HID', companyname: 'CTCP Halcom Việt Nam'},
    {stockname: 'HIG', companyname: 'CTCP Tập đoàn HIPT'},
    {stockname: 'HII', companyname: 'CTCP An Tiến Industries'},
    {stockname: 'HJC', companyname: 'CTCP Hòa Việt'},
    {stockname: 'HJS', companyname: 'CTCP Thủy điện Nậm Mu'},
    {stockname: 'HKB', companyname: 'CTCP Nông nghiệp và Thực phẩm Hà Nội - Kinh Bắc'},
    {stockname: 'HKP', companyname: 'CTCP Bao bì Hà Tiên'},
    {stockname: 'HKT', companyname: 'CTCP Đầu tư Ego Việt Nam'},
    {stockname: 'HLA', companyname: 'CTCP Hữu Liên Á Châu'},
    {stockname: 'HLB', companyname: 'CTCP Bia và Nước giải khát Hạ Long'},
    {stockname: 'HLC', companyname: 'CTCP Than Hà Lầm - Vinacomin'},
    {stockname: 'HLD', companyname: 'CTCP Đầu tư và Phát triển Bất động sản HUDLAND'},
    {stockname: 'HLG', companyname: 'CTCP Tập đoàn Hoàng Long'},
    {stockname: 'HLR', companyname: 'CTCP Đường sắt Hà Lạng'},
    {stockname: 'HLS', companyname: 'CTCP Sứ Kỹ thuật Hoàng Liên Sơn'},
    {stockname: 'HLT', companyname: 'CTCP Dệt may Hoàng Thị Loan'},
    {stockname: 'HLY', companyname: 'CTCP Viglacera Hạ Long I'},
    {stockname: 'HMC', companyname: 'CTCP Kim khí Thành phố Hồ Chí Minh - Vnsteel'},
    {stockname: 'HMG', companyname: 'CTCP Kim khí Hà Nội - VNSTEEL'},
    {stockname: 'HMH', companyname: 'CTCP Hải Minh'},
    {stockname: 'HMR', companyname: 'Công ty Cổ phần Đá Hoàng Mai'},
    {stockname: 'HMS', companyname: 'CTCP Xây dựng Bảo tàng Hồ Chí Minh'},
    {stockname: 'HNA', companyname: 'CTCP Thủy điện Hủa Na'},
    {stockname: 'HNB', companyname: 'CTCP Bến xe Hà Nội'},
    {stockname: 'HND', companyname: 'CTCP Nhiệt điện Hải Phòng'},
    {stockname: 'HNF', companyname: 'CTCP Thực phẩm Hữu Nghị'},
    {stockname: 'HNG', companyname: 'CTCP Nông nghiệp Quốc tế Hoàng Anh Gia Lai'},
    {stockname: 'HNI', companyname: 'CTCP May Hữu Nghị'},
    {stockname: 'HNM', companyname: 'CTCP Sữa Hà Nội'},
    {stockname: 'HNP', companyname: 'CTCP Hanel Xốp nhựa'},
    {stockname: 'HNR', companyname: 'CTCP Rượu và Nước giải khát Hà Nội'},
    {stockname: 'HOM', companyname: 'CTCP Xi măng VICEM Hoàng Mai'},
    {stockname: 'HOT', companyname: 'CTCP Du lịch Dịch vụ Hội An'},
    {stockname: 'HPB', companyname: 'CTCP Bao bì PP'},
    {stockname: 'HPD', companyname: 'CTCP Thủy điện ĐăK Đoa'},
    {stockname: 'HPG', companyname: 'CTCP Tập đoàn Hòa Phát'},
    {stockname: 'HPH', companyname: 'CTCP Hóa Chất Hưng Phát Hà Bắc'},
    {stockname: 'HPI', companyname: 'CTCP Khu công nghiệp Hiệp Phước'},
    {stockname: 'HPM', companyname: 'CTCP Xây dựng Thương mại và Khoáng Sản Hoàng Phúc'},
    {stockname: 'HPP', companyname: 'CTCP Sơn Hải Phòng'},
    {stockname: 'HPT', companyname: 'CTCP Dịch vụ Công nghệ Tin học HPT'},
    {stockname: 'HPW', companyname: 'CTCP Cấp nước Hải Phòng'},
    {stockname: 'HPX', companyname: 'CTCP Đầu tư Hải Phát'},
    {stockname: 'HQC', companyname: 'CTCP Tư vấn Thương mại Dịch vụ Địa Ốc Hoàng Quân'},
    {stockname: 'HRB', companyname: 'CTCP Harec Đầu tư và Thương mại'},
    {stockname: 'HRC', companyname: 'CTCP Cao su Hòa Bình'},
    {stockname: 'HRT', companyname: 'CTCP Vận tải Đường sắt Hà Nội'},
    {stockname: 'HSA', companyname: 'CTCP Hestia'},
    {stockname: 'HSG', companyname: 'CTCP Tập đoàn Hoa Sen'},
    {stockname: 'HSI', companyname: 'CTCP Vật tư Tổng hợp và Phân bón Hóa Sinh'},
    {stockname: 'HSL', companyname: 'CTCP Đầu tư Phát triển Thực phẩm Hồng Hà'},
    {stockname: 'HSM', companyname: 'Tổng Công ty cổ phần Dệt may Hà Nội'},
    {stockname: 'HSP', companyname: 'CTCP Sơn Tổng hợp Hà Nội'},
    {stockname: 'HSV', companyname: 'CTCP Gang thép Hà Nội'},
    {stockname: 'HT1', companyname: 'CTCP Xi măng Hà Tiên 1'},
    {stockname: 'HTC', companyname: 'CTCP Thương mại Hóc Môn'},
    {stockname: 'HTE', companyname: 'CTCP Đầu tư Kinh doanh Điện lực Thành phố Hồ Chí Minh'},
    {stockname: 'HTH', companyname: 'Công ty TNHH MTV Hoa tiêu Hàng hải - TKV'},
    {stockname: 'HTI', companyname: 'CTCP Đầu tư Phát triển Hạ tầng IDICO'},
    {stockname: 'HTL', companyname: 'CTCP Kỹ thuật và Ôtô Trường Long'},
    {stockname: 'HTM', companyname: 'Tổng Công ty Thương mại Hà Nội - CTCP'},
    {stockname: 'HTN', companyname: 'CTCP Hưng Thịnh Incons'},
    {stockname: 'HTP', companyname: 'CTCP In Sách Giáo khoa Hòa Phát'},
    {stockname: 'HTR', companyname: 'CTCP Đường sắt Hà Thái'},
    {stockname: 'HTT', companyname: 'CTCP Thương mại Hà Tây'},
    {stockname: 'HTV', companyname: 'CTCP Vận tải Hà Tiên'},
    {stockname: 'HTW', companyname: 'CTCP Cấp nước Hà Tĩnh'},
    {stockname: 'HU1', companyname: 'CTCP Đầu tư và Xây dựng HUD1'},
    {stockname: 'HU3', companyname: 'CTCP Đầu tư và Xây dựng HUD3'},
    {stockname: 'HU4', companyname: 'CTCP Đầu tư và Xây dựng HUD4'},
    {stockname: 'HU6', companyname: 'CTCP Đầu tư Phát triển Nhà và Đô thị HUD6'},
    {stockname: 'HUB', companyname: 'CTCP Xây lắp Thừa Thiên Huế'},
    {stockname: 'HUG', companyname: 'Tổng Công ty May Hưng Yên - CTCP'},
    {stockname: 'HUT', companyname: 'CTCP Tasco'},
    {stockname: 'HVA', companyname: 'CTCP Đầu tư HVA'},
    {stockname: 'HVG', companyname: 'CTCP Hùng Vương'},
    {stockname: 'HVH', companyname: 'CTCP Đầu tư và Công nghệ HVC'},
    {stockname: 'HVN', companyname: 'Tổng Công ty Hàng không Việt Nam - CTCP'},
    {stockname: 'HVT', companyname: 'CTCP Hóa chất Việt Trì'},
    {stockname: 'HVX', companyname: 'CTCP Xi măng VICEM Hải Vân'},
    {stockname: 'HWS', companyname: 'CTCP Cấp nước Thừa Thiên Huế'},
    {stockname: 'IBC', companyname: 'CTCP Đầu tư Apax Holdings'},
    {stockname: 'IBD', companyname: 'CTCP In Tổng hợp Bình Dương'},
    {stockname: 'IBN', companyname: 'Công ty TNHH MTV In báo Nghệ An'},
    {stockname: 'ICC', companyname: 'CTCP Xây dựng Công nghiệp (ICC)'},
    {stockname: 'ICF', companyname: 'CTCP Đầu tư Thương mại Thủy sản'},
    {stockname: 'ICG', companyname: 'CTCP Xây dựng Sông Hồng'},
    {stockname: 'ICI', companyname: 'CTCP Đầu tư và Xây dựng Công nghiệp'},
    {stockname: 'ICN', companyname: 'CTCP Đầu tư Xây dựng Dầu khí IDICO'},
    {stockname: 'ICT', companyname: 'CTCP Viễn thông - Tin học Bưu điện'},
    {stockname: 'IDC', companyname: 'Tổng Công ty IDICO – CTCP'},
    {stockname: 'IDI', companyname: 'CTCP Đầu tư và Phát triển Đa Quốc Gia - IDI'},
    {stockname: 'IDJ', companyname: 'CTCP Đầu tư IDJ Việt Nam'},
    {stockname: 'IDP', companyname: 'Công ty cổ phần Sữa Quốc tế'},
    {stockname: 'IDV', companyname: 'CTCP Phát triển Hạ tầng Vĩnh Phúc'},
    {stockname: 'IFS', companyname: 'CTCP Thực phẩm Quốc tế'},
    {stockname: 'IHK', companyname: 'CTCP In Hàng Không'},
    {stockname: 'IJC', companyname: 'CTCP Phát triển Hạ tầng Kỹ thuật'},
    {stockname: 'ILA', companyname: 'CTCP ILA'},
    {stockname: 'ILB', companyname: 'CTCP ICD Tân Cảng - Long Bình'},
    {stockname: 'ILC', companyname: 'CTCP Hợp tác Lao động với nước ngoài'},
    {stockname: 'ILS', companyname: 'CTCP Đầu tư Thương mại và Dịch vụ Quốc tế'},
    {stockname: 'IME', companyname: 'CTCP Cơ khí và Xây lắp Công nghiệp'},
    {stockname: 'IMP', companyname: 'CTCP Dược phẩm Imexpharm'},
    {stockname: 'IN4', companyname: 'CTCP In Số 4'},
    {stockname: 'INC', companyname: 'CTCP Tư vấn Đầu tư IDICO'},
    {stockname: 'INN', companyname: 'CTCP Bao bì và In Nông nghiệp'},
    {stockname: 'INR-X', companyname: 'USD/INR (Indian Rupee)'},
    {stockname: 'IPA', companyname: 'CTCP Tập đoàn Đầu tư I.P.A'},
    {stockname: 'IRC', companyname: 'CTCP Cao su Công nghiệp'},
    {stockname: 'ISG', companyname: 'CTCP Vận tải biển và Hợp tác Lao động Quốc tế'},
    {stockname: 'ISH', companyname: 'CTCP Thủy điện Srok Phu Miêng IDICO'},
    {stockname: 'IST', companyname: 'CTCP ICD Tân Cảng Sóng Thần'},
    {stockname: 'ITA', companyname: 'CTCP Đầu tư và Công nghiệp Tân Tạo'},
    {stockname: 'ITC', companyname: 'CTCP Đầu tư và Kinh doanh Nhà'},
    {stockname: 'ITD', companyname: 'CTCP Công nghệ Tiên Phong'},
    {stockname: 'ITQ', companyname: 'CTCP Tập đoàn Thiên Quang'},
    {stockname: 'ITS', companyname: 'CTCP Đầu tư Thương mại và Dịch vụ - Vinacomin'},
    {stockname: 'IVS', companyname: 'CTCP Chứng khoán Đầu tư Việt Nam'},
    {stockname: 'JOS', companyname: 'CTCP Chế biến Thủy sản xuất khẩu Minh Hải'},
    {stockname: 'JPY-X', companyname: 'USD/JPY (Japanese Yen)'},
    {stockname: 'JVC', companyname: 'CTCP Thiết bị Y tế Việt Nhật'},
    {stockname: 'KAC', companyname: 'CTCP Đầu tư Địa ốc Khang An'},
    {stockname: 'KBC', companyname: 'Tổng Công ty Phát triển Đô thị Kinh Bắc - CTCP'},
    {stockname: 'KCB', companyname: 'CTCP khoáng Sản và Luyện Kim Cao Bằng'},
    {stockname: 'KCE', companyname: 'CTCP Bê tông Ly tâm Điện Lực Khánh Hòa'},
    {stockname: 'KDC', companyname: 'CTCP Tập đoàn KIDO'},
    {stockname: 'KDH', companyname: 'CTCP Đầu tư và Kinh doanh Nhà Khang Điền'},
    {stockname: 'KDM', companyname: 'CTCP Tập đoàn Đầu Tư Lê Gia'},
    {stockname: 'KGM', companyname: 'CTCP Xuất nhập khẩu Kiên Giang'},
    {stockname: 'KHA', companyname: 'CTCP Đầu tư và Dịch vụ Khánh Hội'},
    {stockname: 'KHD', companyname: 'CTCP Khai thác Chế biến Khoáng sản Hải Dương'},
    {stockname: 'KHG', companyname: 'Công ty Cổ phần Bất động sản Khải Hoàn Land'},
    {stockname: 'KHL', companyname: 'CTCP khoáng Sản và Vật liệu Xây dựng Hưng Long'},
    {stockname: 'KHP', companyname: 'CTCP Điện lực Khánh Hòa'},
    {stockname: 'KHS', companyname: 'CTCP Kiên Hùng'},
    {stockname: 'KHW', companyname: 'CTCP Cấp thoát nước Khánh Hòa'},
    {stockname: 'KIP', companyname: 'CTCP K.I.P Việt Nam'},
    {stockname: 'KKC', companyname: 'CTCP Kim Khí KKC'},
    {stockname: 'KLB', companyname: 'Ngân hàng TMCP Kiên Long'},
    {stockname: 'KLF', companyname: 'CTCP Đầu tư Thương mại và Xuất nhập khẩu CFS'},
    {stockname: 'KLM', companyname: 'CTCP Kim loại màu Nghệ Tĩnh'},
    {stockname: 'KMR', companyname: 'CTCP Mirae'},
    {stockname: 'KMT', companyname: 'CTCP Kim khí Miền Trung'},
    {stockname: 'KOS', companyname: 'CTCP KOSY'},
    {stockname: 'KPF', companyname: 'CTCP Đầu tư Tài chính Hoàng Minh'},
    {stockname: 'KRW-X', companyname: 'USD/KRW (South Korean won)'},
    {stockname: 'KSB', companyname: 'CTCP Khoáng sản và Xây dựng Bình Dương'},
    {stockname: 'KSD', companyname: 'CTCP Đầu tư DNA'},
    {stockname: 'KSF', companyname: 'Công ty Cổ phần Tập đoàn KSFinance'},
    {stockname: 'KSH', companyname: 'CTCP DAMAC GLS'},
    {stockname: 'KSQ', companyname: 'CTCP CNC Capital Việt Nam'},
    {stockname: 'KST', companyname: 'CTCP KASATI'},
    {stockname: 'KSV', companyname: 'Tổng Công ty Khoáng sản TKV - CTCP'},
    {stockname: 'KTC', companyname: 'CTCP Thương mại Kiên Giang'},
    {stockname: 'KTL', companyname: 'CTCP Kim Khí Thăng Long'},
    {stockname: 'KTS', companyname: 'CTCP Đường Kon Tum'},
    {stockname: 'KTT', companyname: 'CTCP Đầu tư Thiết bị và Xây lắp Điện Thiên Trường'},
    {stockname: 'KVC', companyname: 'CTCP Sản xuất Xuất nhập khẩu Inox Kim Vĩ'},
    {stockname: 'L10', companyname: 'CTCP Lilama 10'},
    {stockname: 'L12', companyname: 'CTCP Licogi 12'},
    {stockname: 'L14', companyname: 'CTCP Licogi 14'},
    {stockname: 'L18', companyname: 'CTCP Đầu tư và Xây dựng Số 18'},
    {stockname: 'L35', companyname: 'CTCP Cơ khí Lắp máy Lilama'},
    {stockname: 'L40', companyname: 'Công ty Cổ phần Đầu tư và Xây dựng 40'},
    {stockname: 'L43', companyname: 'CTCP Lilama 45.3'},
    {stockname: 'L44', companyname: 'CTCP Lilama 45.4'},
    {stockname: 'L45', companyname: 'CTCP Lilama 45.1'},
    {stockname: 'L61', companyname: 'CTCP Lilama 69-1'},
    {stockname: 'L62', companyname: 'CTCP Lilama 69-2'},
    {stockname: 'L63', companyname: 'CTCP Lilama 69-3'},
    {stockname: 'LAF', companyname: 'CTCP Chế biến hàng Xuất khẩu Long An'},
    {stockname: 'LAI', companyname: 'CTCP Đầu Tư Xây dựng Long An IDICO'},
    {stockname: 'LAS', companyname: 'CTCP Supe Phốt phát và Hóa chất Lâm Thao'},
    {stockname: 'LAW', companyname: 'CTCP Cấp thoát nước Long An'},
    {stockname: 'LBC', companyname: 'CTCP Thương mại - Đầu tư Long Biên'},
    {stockname: 'LBE', companyname: 'CTCP Sách và Thiết bị Trường học Long An'},
    {stockname: 'LBM', companyname: 'CTCP Khoáng sản và Vật liệu Xây dựng Lâm Đồng'},
    {stockname: 'LCC', companyname: 'CTCP Xi măng Hồng Phong'},
    {stockname: 'LCD', companyname: 'CTCP Lắp máy - Thí nghiệm Cơ điện'},
    {stockname: 'LCG', companyname: 'CTCP Licogi 16'},
    {stockname: 'LCM', companyname: 'CTCP Khai thác và Chế biến khoáng sản Lào Cai'},
    {stockname: 'LCS', companyname: 'CTCP Licogi 166'},
    {stockname: 'LCW', companyname: 'CTCP Nước sạch Lai Châu'},
    {stockname: 'LDG', companyname: 'CTCP Đầu tư LDG'},
    {stockname: 'LDP', companyname: 'CTCP Dược Lâm Đồng - Ladophar'},
    {stockname: 'LDW', companyname: 'CTCP Cấp thoát nước Lâm Đồng'},
    {stockname: 'LEC', companyname: 'CTCP Bất động sản Điện lực Miền Trung'},
    {stockname: 'LG9', companyname: 'CTCP Cơ giới và Xây lắp Số 9'},
    {stockname: 'LGC', companyname: 'CTCP Đầu tư Cầu đường CII'},
    {stockname: 'LGL', companyname: 'CTCP Đầu tư và Phát triển Đô thị Long Giang'},
    {stockname: 'LGM', companyname: 'CTCP Giày da và May mặc Xuất khẩu'},
    {stockname: 'LHC', companyname: 'CTCP Đầu tư và Xây dựng Thủy lợi Lâm Đồng'},
    {stockname: 'LHG', companyname: 'CTCP Long Hậu'},
    {stockname: 'LIC', companyname: 'Tổng Công ty LICOGI - CTCP'},
    {stockname: 'LIG', companyname: 'CTCP Licogi 13'},
    {stockname: 'LINK-USD', companyname: 'Chainlink USD'},
    {stockname: 'LIX', companyname: 'CTCP Bột Giặt Lix'},
    {stockname: 'LKW', companyname: 'CTCP Cấp nước Long Khánh'},
    {stockname: 'LLM', companyname: 'Tổng Công ty Lắp máy Việt Nam - CTCP'},
    {stockname: 'LM3', companyname: 'CTCP Lilama 3'},
    {stockname: 'LM7', companyname: 'CTCP Lilama 7'},
    {stockname: 'LM8', companyname: 'CTCP Lilama 18'},
    {stockname: 'LMC', companyname: 'CTCP Khoáng sản Latca'},
    {stockname: 'LMH', companyname: 'CTCP Landmark Holding'},
    {stockname: 'LMI', companyname: 'CTCP Đầu tư Xây dựng Lắp máy IDICO'},
    {stockname: 'LNC', companyname: 'CTCP Lệ Ninh'},
    {stockname: 'LO5', companyname: 'CTCP Lilama 5'},
    {stockname: 'LPB', companyname: 'Ngân hàng TMCP Bưu điện Liên Việt'},
    {stockname: 'LPT', companyname: 'CTCP Thương mại và Sản xuất Lập Phương Thành'},
    {stockname: 'LQN', companyname: 'CTCP Licogi Quảng Ngãi'},
    {stockname: 'LSG', companyname: 'Công ty cổ phần Bất động sản Sài Gòn VINA'},
    {stockname: 'LSS', companyname: 'CTCP Mía Đường Lam Sơn'},
    {stockname: 'LTC', companyname: 'CTCP Điện nhẹ Viễn Thông'},
    {stockname: 'LTG', companyname: 'CTCP Tập đoàn Lộc Trời'},
    {stockname: 'LUT', companyname: 'CTCP Đầu tư Xây dựng Lương Tài'},
    {stockname: 'LWS', companyname: 'CTCP Cấp nước Tỉnh Lào Cai'},
    {stockname: 'LYF', companyname: 'CTCP Lương thực Lương Yên'},
    {stockname: 'M10', companyname: 'Tổng Công ty May 10 - CTCP'},
    {stockname: 'MA1', companyname: 'CTCP Thiết bị'},
    {stockname: 'MAC', companyname: 'CTCP Cung ứng và Dịch vụ Kỹ thuật Hàng Hải'},
    {stockname: 'MAS', companyname: 'CTCP Dịch vụ Hàng không Sân bay Đà Nẵng'},
    {stockname: 'MBB', companyname: 'Ngân hàng TMCP Quân Đội'},
    {stockname: 'MBG', companyname: 'CTCP Tập đoàn MBG'},
    {stockname: 'MBN', companyname: 'CTCP Môi trường và Công trình đô thị Bắc Ninh'},
    {stockname: 'MBS', companyname: 'CTCP Chứng khoán MB'},
    {stockname: 'MCC', companyname: 'CTCP Gạch ngói Cao cấp'},
    {stockname: 'MCF', companyname: 'CTCP Xây lắp Cơ khí và Lương thực Thực phẩm'},
    {stockname: 'MCG', companyname: 'CTCP Cơ điện và Xây dựng Việt Nam'},
    {stockname: 'MCH', companyname: 'CTCP Hàng tiêu dùng Masan'},
    {stockname: 'MCI', companyname: 'CTCP Đầu tư Xây dựng và Phát triển Vật liệu IDICO'},
    {stockname: 'MCM', companyname: 'Công ty cổ phần Giống bò sữa Mộc Châu'},
    {stockname: 'MCO', companyname: 'CTCP Đầu tư và Xây dựng BDC Việt Nam'},
    {stockname: 'MCP', companyname: 'CTCP In và Bao bì Mỹ Châu'},
    {stockname: 'MDA', companyname: 'CTCP Môi trường Đô thị Đông Anh'},
    {stockname: 'MDC', companyname: 'CTCP Than Mông Dương - Vinacomin'},
    {stockname: 'MDF', companyname: 'CTCP Gỗ MDF VRG - Quảng Trị'},
    {stockname: 'MDG', companyname: 'CTCP Miền Đông'},
    {stockname: 'MEC', companyname: 'CTCP Cơ khí - Lắp máy Sông Đà'},
    {stockname: 'MED', companyname: 'CTCP Dược Trung ương Mediplantex'},
    {stockname: 'MEF', companyname: 'CTCP MEINFA'},
    {stockname: 'MEL', companyname: 'CTCP Thép Mê Lin'},
    {stockname: 'MES', companyname: 'CTCP Cơ điện Công trình'},
    {stockname: 'MFS', companyname: 'CTCP Dịch vụ Kỹ thuật Mobifone'},
    {stockname: 'MGC', companyname: 'CTCP Địa chất mỏ - TKV'},
    {stockname: 'MGG', companyname: 'Tổng Công ty Đức Giang - CTCP'},
    {stockname: 'MGR', companyname: 'Công ty cổ phần Tập đoàn Mgroup'},
    {stockname: 'MH3', companyname: 'CTCP Khu Công nghiệp Cao su Bình Long'},
    {stockname: 'MHC', companyname: 'CTCP MHC'},
    {stockname: 'MHL', companyname: 'CTCP Minh Hữu Liên'},
    {stockname: 'MIC', companyname: 'CTCP Kỹ nghệ Khoáng sản Quảng Nam'},
    {stockname: 'MIE', companyname: 'Tổng Công ty Máy và Thiết bị Công nghiệp - CTCP'},
    {stockname: 'MIG', companyname: 'Tổng Công ty cổ phần Bảo hiểm Quân Đội'},
    {stockname: 'MIM', companyname: 'CTCP Khoáng sản và Cơ khí'},
    {stockname: 'MKP', companyname: 'CTCP Hoá - Dược phẩm Mekophar'},
    {stockname: 'MKV', companyname: 'CTCP Dược thú Y Cai Lậy'},
    {stockname: 'MLC', companyname: 'CTCP Môi trường Đô thị Tỉnh Lào Cai'},
    {stockname: 'MLS', companyname: 'CTCP Chăn nuôi - Mitraco'},
    {stockname: 'MML', companyname: 'CTCP Masan MeatLife'},
    {stockname: 'MNB', companyname: 'Tổng Công ty May Nhà Bè - CTCP'},
    {stockname: 'MND', companyname: 'CTCP Môi trường Nam Định'},
    {stockname: 'MPC', companyname: 'CTCP Tập đoàn Thủy sản Minh Phú'},
    {stockname: 'MPT', companyname: 'CTCP Tập đoàn Trường Tiền'},
    {stockname: 'MPY', companyname: 'CTCP Môi trường Đô thị Phú Yên'},
    {stockname: 'MQB', companyname: 'CTCP Môi trường và Phát triển Đô thị Quảng Bình'},
    {stockname: 'MQN', companyname: 'CTCP Môi trường đô thị Quảng Ngãi'},
    {stockname: 'MRF', companyname: 'CTCP Merufa'},
    {stockname: 'MSB', companyname: 'Ngân hàng Thương mại cổ phần Hàng hải Việt Nam'},
    {stockname: 'MSH', companyname: 'CTCP May Sông Hồng'},
    {stockname: 'MSN', companyname: 'CTCP Tập đoàn Masan'},
    {stockname: 'MSR', companyname: 'CTCP Masan High-Tech Materials'},
    {stockname: 'MST', companyname: 'CTCP Đầu tư MST'},
    {stockname: 'MTA', companyname: 'Tổng Công ty Khoáng sản và Thương mại Hà Tĩnh - CTCP'},
    {stockname: 'MTB', companyname: 'CTCP Môi trường và Công trình Đô thị tỉnh Thái Bình'},
    {stockname: 'MTC', companyname: 'CTCP Dịch vụ Du lịch Mỹ Trà'},
    {stockname: 'MTG', companyname: 'CTCP MT Gas'},
    {stockname: 'MTH', companyname: 'CTCP Môi trường Đô thị Hà Đông'},
    {stockname: 'MTL', companyname: 'CTCP Dịch vụ Môi trường Đô thị Từ Liêm'},
    {stockname: 'MTP', companyname: 'CTCP Dược Medipharco'},
    {stockname: 'MTS', companyname: 'CTCP Vật tư - TKV'},
    {stockname: 'MTV', companyname: 'CTCP Dịch vụ Môi trường và Công trình Đô thị Vũng Tàu'},
    {stockname: 'MVB', companyname: 'Tổng Công ty Công nghiệp mỏ Việt Bắc TKV - CTCP'},
    {stockname: 'MVC', companyname: 'CTCP Vật liệu và Xây dựng Bình Dương'},
    {stockname: 'MVN', companyname: 'Tổng Công ty Hàng hải Việt Nam - CTCP'},
    {stockname: 'MWG', companyname: 'CTCP Đầu tư Thế giới Di động'},
    {stockname: 'MXX-X', companyname: 'USD/MXX (Mexican Peso)'},
    {stockname: 'NAB', companyname: 'Ngân hàng TMCP Nam Á'},
    {stockname: 'NAC', companyname: 'CTCP Tư vấn Xây dựng Tổng hợp'},
    {stockname: 'NAF', companyname: 'CTCP Nafoods Group'},
    {stockname: 'NAG', companyname: 'CTCP Tập đoàn Nagakawa'},
    {stockname: 'NAP', companyname: 'CTCP Cảng Nghệ Tĩnh'},
    {stockname: 'NAS', companyname: 'CTCP Dịch vụ Hàng không Sân bay Nội Bài'},
    {stockname: 'NAU', companyname: 'CTCP Môi trường Đô thị Nghệ An'},
    {stockname: 'NAV', companyname: 'CTCP Nam Việt'},
    {stockname: 'NAW', companyname: 'CTCP Cấp nước Nghệ An'},
    {stockname: 'NBB', companyname: 'CTCP Đầu tư Năm Bảy Bảy'},
    {stockname: 'NBE', companyname: 'CTCP Sách và Thiết bị Giáo dục Miền Bắc'},
    {stockname: 'NBP', companyname: 'CTCP Nhiệt điện Ninh Bình'},
    {stockname: 'NBT', companyname: 'CTCP Cấp thoát nước Bến Tre'},
    {stockname: 'NBW', companyname: 'CTCP Cấp nước Nhà Bè'},
    {stockname: 'NCS', companyname: 'CTCP Suất ăn Hàng không Nội Bài'},
    {stockname: 'NCT', companyname: 'CTCP Dịch vụ Hàng hóa Nội Bài'},
    {stockname: 'ND2', companyname: 'CTCP Đầu tư và Phát triển Điện Miền Bắc 2'},
    {stockname: 'NDC', companyname: 'CTCP Nam Dược'},
    {stockname: 'NDF', companyname: 'CTCP Chế biến Thực phẩm Nông sản xuất khẩu Nam Định'},
    {stockname: 'NDN', companyname: 'CTCP Đầu tư Phát triển Nhà Đà Nẵng'},
    {stockname: 'NDP', companyname: 'CTCP Dược phẩm 2/9'},
    {stockname: 'NDT', companyname: 'Tổng Công ty cổ phần Dệt May Nam Định'},
    {stockname: 'NDW', companyname: 'CTCP Cấp nước Nam Định'},
    {stockname: 'NDX', companyname: 'CTCP Xây lắp Phát triển Nhà Đà Nẵng'},
    {stockname: 'NED', companyname: 'CTCP Đầu tư và Phát triển Điện Tây Bắc'},
    {stockname: 'NET', companyname: 'CTCP Bột giặt NET'},
    {stockname: 'NFC', companyname: 'CTCP Phân lân Ninh Bình'},
    {stockname: 'NGC', companyname: 'CTCP Chế biến Thủy sản xuất khẩu Ngô Quyền'},
    {stockname: 'NHA', companyname: 'Tổng Công ty Đầu tư Phát triển Nhà và Đô thị Nam Hà Nội'},
    {stockname: 'NHC', companyname: 'CTCP Gạch ngói Nhị Hiệp'},
    {stockname: 'NHH', companyname: 'CTCP Nhựa Hà Nội'},
    {stockname: 'NHP', companyname: 'CTCP Sản xuất Xuất nhập khẩu NHP'},
    {stockname: 'NHT', companyname: 'CTCP Sản xuất và Thương mại Nam Hoa'},
    {stockname: 'NHV', companyname: 'CTCP Đầu tư NHV'},
    {stockname: 'NJC', companyname: 'Công ty cổ phần May Nam Định'},
    {stockname: 'NKG', companyname: 'CTCP Thép Nam Kim'},
    {stockname: 'NLG', companyname: 'CTCP Đầu tư Nam Long'},
    {stockname: 'NLS', companyname: 'CTCP Cấp thoát nước Lạng Sơn'},
    {stockname: 'NNC', companyname: 'CTCP Đá Núi Nhỏ'},
    {stockname: 'NNG', companyname: 'CTCP Công nghiệp - Dịch vụ - Thương mại Ngọc Nghĩa'},
    {stockname: 'NNT', companyname: 'CTCP Cấp nước Ninh Thuận'},
    {stockname: 'NOS', companyname: 'CTCP Vận tải biển và Thương mại Phương Đông'},
    {stockname: 'NQB', companyname: 'CTCP Cấp nước Quảng Bình'},
    {stockname: 'NQN', companyname: 'CTCP Nước sạch Quảng Ninh'},
    {stockname: 'NQT', companyname: 'CTCP Nước sạch Quảng Trị'},
    {stockname: 'NRC', companyname: 'CTCP Bất động sản Netland'},
    {stockname: 'NS2', companyname: 'CTCP Nước sạch Số 2 Hà Nội'},
    {stockname: 'NSC', companyname: 'CTCP Tập đoàn Giống cây trồng Việt Nam'},
    {stockname: 'NSG', companyname: 'CTCP Nhựa Sài Gòn'},
    {stockname: 'NSH', companyname: 'CTCP Nhôm Sông Hồng'},
    {stockname: 'NSL', companyname: 'CTCP Cấp nước Sơn La'},
    {stockname: 'NSS', companyname: 'CTCP Nông Súc Sản Đồng Nai'},
    {stockname: 'NST', companyname: 'CTCP Ngân Sơn'},
    {stockname: 'NT2', companyname: 'CTCP Điện lực Dầu khí Nhơn Trạch 2'},
    {stockname: 'NTB', companyname: 'CTCP Đầu tư Xây dựng và Khai thác Công trình Giao thông 584'},
    {stockname: 'NTC', companyname: 'CTCP Khu Công nghiệp Nam Tân Uyên'},
    {stockname: 'NTF', companyname: 'CTCP Dược - Vật tư Y tế Nghệ An'},
    {stockname: 'NTH', companyname: 'CTCP Thủy điện Nước Trong'},
    {stockname: 'NTL', companyname: 'CTCP Phát triển Đô thị Từ Liêm'},
    {stockname: 'NTP', companyname: 'CTCP Nhựa Thiếu niên Tiền Phong'},
    {stockname: 'NTT', companyname: 'CTCP Dệt - May Nha Trang'},
    {stockname: 'NTW', companyname: 'CTCP Cấp nước Nhơn Trạch'},
    {stockname: 'NUE', companyname: 'CTCP Môi trường Đô thị Nha Trang'},
    {stockname: 'NVB', companyname: 'Ngân hàng TMCP Quốc Dân'},
    {stockname: 'NVL', companyname: 'CTCP Tập đoàn Đầu tư Địa ốc No Va'},
    {stockname: 'NVP', companyname: 'CTCP Nước sạch Vĩnh Phúc'},
    {stockname: 'NVT', companyname: 'CTCP Bất động sản Du lịch Ninh Vân Bay'},
    {stockname: 'NWT', companyname: 'CTCP Vận tải Newway'},
    {stockname: 'NXT', companyname: 'CTCP Sản xuất và Cung ứng vật liệu xây dựng Kon Tum'},
    {stockname: 'OCB', companyname: 'Ngân hàng Thương mại cổ phần Phương Đông'},
    {stockname: 'OCH', companyname: 'CTCP Khách sạn và Dịch vụ OCH'},
    {stockname: 'ODE', companyname: 'Công ty cổ phần Tập đoàn Truyền thông và Giải trí ODE'},
    {stockname: 'OGC', companyname: 'CTCP Tập đoàn Đại Dương'},
    {stockname: 'OIL', companyname: 'Tổng Công ty Dầu Việt Nam - CTCP'},
    {stockname: 'ONE', companyname: 'CTCP Truyền thông Số 1'},
    {stockname: 'ONW', companyname: 'CTCP Dịch vụ Một Thế Giới'},
    {stockname: 'OPC', companyname: 'CTCP Dược phẩm OPC'},
    {stockname: 'ORS', companyname: 'CTCP Chứng khoán Tiên Phong'},
    {stockname: 'PAC', companyname: 'CTCP Pin Ắc quy Miền Nam'},
    {stockname: 'PAI', companyname: 'CTCP Công nghệ thông tin Viễn thông và Tự động hóa Dầu Khí'},
    {stockname: 'PAN', companyname: 'CTCP Tập đoàn Pan'},
    {stockname: 'PAP', companyname: ''},
    {stockname: 'PAS', companyname: 'Công ty cổ phần Quốc tế Phương Anh'},
    {stockname: 'PAT', companyname: 'Công ty cổ phần Phốt pho Apatit Việt Nam'},
    {stockname: 'PBC', companyname: 'CTCP Dược phẩm Trung Ương 1- Pharbaco'},
    {stockname: 'PBP', companyname: 'CTCP Bao bì Dầu khí Việt Nam'},
    {stockname: 'PBT', companyname: 'CTCP Nhà và Thương mại Dầu khí'},
    {stockname: 'PC1', companyname: 'CTCP Xây lắp Điện I'},
    {stockname: 'PCC', companyname: 'CTCP Tập đoàn Xây lắp 1 - Petrolimex'},
    {stockname: 'PCE', companyname: 'CTCP Phân bón và Hóa chất Dầu khí Miền Trung'},
    {stockname: 'PCF', companyname: 'CTCP Cà phê PETEC'},
    {stockname: 'PCG', companyname: 'CTCP Đầu tư Phát triển Gas Đô thị'},
    {stockname: 'PCH', companyname: 'Công ty Cổ phần Nhựa Picomat'},
    {stockname: 'PCM', companyname: 'CTCP Vật liệu Xây dựng Bưu điện'},
    {stockname: 'PCN', companyname: 'CTCP Hóa phẩm Dầu khí DMC - Miền Bắc'},
    {stockname: 'PCT', companyname: 'CTCP Vận tải Khí và Hoá chất Việt Nam'},
    {stockname: 'PDB', companyname: 'CTCP Vật liệu Xây dựng DUFAGO'},
    {stockname: 'PDC', companyname: 'CTCP Du lịch Dầu khí Phương Đông'},
    {stockname: 'PDN', companyname: 'CTCP Cảng Đồng Nai'},
    {stockname: 'PDR', companyname: 'CTCP Phát triển Bất động sản Phát Đạt'},
    {stockname: 'PDV', companyname: 'CTCP Vận tải Dầu Phương Đông Việt'},
    {stockname: 'PEC', companyname: 'CTCP Cơ khí Điện lực'},
    {stockname: 'PEG', companyname: 'Tổng Công ty Thương mại Kỹ thuật và Đầu tư - CTCP'},
    {stockname: 'PEN', companyname: 'CTCP Xây lắp III Petrolimex'},
    {stockname: 'PEQ', companyname: 'CTCP Thiết bị Xăng dầu Petrolimex'},
    {stockname: 'PET', companyname: 'Tổng Công ty cổ phần Dịch vụ Tổng hợp Dầu khí'},
    {stockname: 'PFL', companyname: 'CTCP Dầu khí Đông Đô'},
    {stockname: 'PGB', companyname: 'Ngân hàng TMCP Xăng dầu Petrolimex'},
    {stockname: 'PGC', companyname: 'Tổng Công ty Gas Petrolimex - CTCP'},
    {stockname: 'PGD', companyname: 'CTCP Phân phối khí thấp áp Dầu khí Việt Nam'},
    {stockname: 'PGI', companyname: 'Tổng Công ty cổ phần Bảo hiểm Petrolimex'},
    {stockname: 'PGN', companyname: 'CTCP Phụ Gia Nhựa'},
    {stockname: 'PGS', companyname: 'CTCP Kinh doanh Khí Miền Nam'},
    {stockname: 'PGT', companyname: 'CTCP PGT Holdings'},
    {stockname: 'PGV', companyname: 'Tổng Công ty Phát điện 3 - CTCP'},
    {stockname: 'PHC', companyname: 'CTCP Xây dựng Phục Hưng Holdings'},
    {stockname: 'PHH', companyname: 'CTCP Hồng Hà Việt Nam'},
    {stockname: 'PHN', companyname: 'CTCP Pin Hà Nội'},
    {stockname: 'PHP', companyname: 'CTCP Cảng Hải Phòng'},
    {stockname: 'PHR', companyname: 'CTCP Cao su Phước Hòa'},
    {stockname: 'PHS', companyname: 'CTCP Chứng khoán Phú Hưng'},
    {stockname: 'PIA', companyname: 'CTCP Tin học Viễn thông Petrolimex'},
    {stockname: 'PIC', companyname: 'CTCP Đầu tư Điện lực 3'},
    {stockname: 'PID', companyname: 'CTCP Trang Trí Nội Thất Dầu khí'},
    {stockname: 'PIS', companyname: 'Tổng Công ty Pisico Bình Định - CTCP'},
    {stockname: 'PIT', companyname: 'CTCP Xuất nhập khẩu Petrolimex'},
    {stockname: 'PIV', companyname: 'CTCP PIV'},
    {stockname: 'PJC', companyname: 'CTCP Thương mại và Vận tải Petrolimex Hà Nội'},
    {stockname: 'PJS', companyname: 'CTCP Cấp nước Phú Hòa Tân'},
    {stockname: 'PJT', companyname: 'CTCP Vận tải Xăng dầu đường Thủy Petrolimex'},
    {stockname: 'PLA', companyname: 'CTCP Đầu tư và Dịch vụ Hạ tầng Xăng dầu'},
    {stockname: 'PLC', companyname: 'Tổng Công ty Hóa dầu Petrolimex - CTCP'},
    {stockname: 'PLE', companyname: 'CTCP Tư vấn Xây dựng Petrolimex'},
    {stockname: 'PLO', companyname: 'CTCP Kho Vận Petec'},
    {stockname: 'PLP', companyname: 'CTCP Sản xuất và Công nghệ Nhựa Pha Lê'},
    {stockname: 'PLX', companyname: 'Tập đoàn Xăng Dầu Việt Nam'},
    {stockname: 'PMB', companyname: 'CTCP Phân bón và Hóa chất Dầu khí Miền Bắc'},
    {stockname: 'PMC', companyname: 'CTCP Dược phẩm Dược liệu Pharmedic'},
    {stockname: 'PMG', companyname: 'CTCP Đầu tư và Sản xuất Petro Miền Trung'},
    {stockname: 'PMJ', companyname: 'CTCP Vật tư Bưu Điện'},
    {stockname: 'PMP', companyname: 'CTCP Bao bì đạm Phú Mỹ'},
    {stockname: 'PMS', companyname: 'CTCP Cơ khí Xăng dầu'},
    {stockname: 'PMT', companyname: 'CTCP Viễn thông TELVINA Việt Nam'},
    {stockname: 'PMW', companyname: 'CTCP Cấp nước Phú Mỹ'},
    {stockname: 'PNC', companyname: 'CTCP Văn hóa Phương Nam'},
    {stockname: 'PND', companyname: 'CTCP Xăng dầu Dầu khí Nam Định'},
    {stockname: 'PNG', companyname: 'CTCP Thương mại Phú Nhuận'},
    {stockname: 'PNJ', companyname: 'CTCP Vàng bạc Đá quý Phú Nhuận'},
    {stockname: 'PNP', companyname: 'CTCP Tân Cảng - Phú Hữu'},
    {stockname: 'PNT', companyname: 'CTCP Kỹ thuật Xây dựng Phú Nhuận'},
    {stockname: 'POB', companyname: 'CTCP Xăng dầu Dầu khí Thái Bình'},
    {stockname: 'POM', companyname: 'CTCP Thép Pomina'},
    {stockname: 'POS', companyname: 'CTCP Dịch vụ Lắp đặt, Vận hành và Bảo dưỡng Công trình Dầu khí Biển PTSC'},
    {stockname: 'POT', companyname: 'CTCP Thiết bị Bưu điện'},
    {stockname: 'POV', companyname: 'CTCP Xăng dầu Dầu khí Vũng Áng'},
    {stockname: 'POW', companyname: 'Tổng Công ty Điện lực Dầu khí Việt Nam - CTCP'},
    {stockname: 'PPC', companyname: 'CTCP Nhiệt điện Phả Lại'},
    {stockname: 'PPE', companyname: 'CTCP Tư vấn Điện lực Dầu khí Việt Nam'},
    {stockname: 'PPH', companyname: 'Tổng Công ty cổ phần Phong Phú'},
    {stockname: 'PPI', companyname: 'CTCP Đầu tư và Phát triển Dự án Hạ tầng Thái Bình Dương'},
    {stockname: 'PPP', companyname: 'CTCP Dược phẩm Phong Phú'},
    {stockname: 'PPS', companyname: 'CTCP Dịch vụ Kỹ thuật Điện lực Dầu khí Việt Nam'},
    {stockname: 'PPT', companyname: 'Công ty cổ phần Petro Times'},
    {stockname: 'PPY', companyname: 'CTCP Xăng dầu Dầu khí Phú Yên'},
    {stockname: 'PQN', companyname: 'CTCP Dịch vụ Dầu khí Quảng Ngãi PTSC'},
    {stockname: 'PRC', companyname: 'CTCP Logistics Portserco'},
    {stockname: 'PRE', companyname: 'Tổng Công ty Cổ phần Tái bảo hiểm PVI'},
    {stockname: 'PRO', companyname: 'CTCP Procimex Việt Nam'},
    {stockname: 'PRT', companyname: 'Tổng Công ty Sản xuất - Xuất nhập khẩu Bình Dương - CTCP'},
    {stockname: 'PSB', companyname: 'CTCP Đầu tư Dầu khí Sao Mai - Bến Đình'},
    {stockname: 'PSC', companyname: 'CTCP Vận tải và Dịch vụ Petrolimex Sài Gòn'},
    {stockname: 'PSD', companyname: 'CTCP Dịch vụ Phân phối Tổng hợp Dầu khí'},
    {stockname: 'PSE', companyname: 'CTCP Phân Bón và Hóa Chất Dầu khí Đông Nam Bộ'},
    {stockname: 'PSG', companyname: 'CTCP Đầu Tư và Xây lắp Dầu khí Sài Gòn'},
    {stockname: 'PSH', companyname: 'CTCP Thương mại Đầu tư Dầu khí Nam Sông Hậu'},
    {stockname: 'PSI', companyname: 'CTCP Chứng khoán Dầu khí'},
    {stockname: 'PSL', companyname: 'CTCP Chăn nuôi Phú Sơn'},
    {stockname: 'PSN', companyname: 'CTCP Cảng Dịch vụ Dầu khí Tổng hợp PTSC Thanh Hóa'},
    {stockname: 'PSP', companyname: 'CTCP Cảng Dịch vụ Dầu khí Đình Vũ'},
    {stockname: 'PSW', companyname: 'CTCP Phân bón và Hóa chất Dầu khí Tây Nam Bộ'},
    {stockname: 'PTB', companyname: 'CTCP Phú Tài'},
    {stockname: 'PTC', companyname: 'CTCP Đầu tư và Xây dựng Bưu điện'},
    {stockname: 'PTD', companyname: 'CTCP Thiết kế Xây dựng Thương mại Phúc Thịnh'},
    {stockname: 'PTE', companyname: 'CTCP Xi măng Phú Thọ'},
    {stockname: 'PTG', companyname: 'CTCP May Xuất khẩu Phan Thiết'},
    {stockname: 'PTH', companyname: 'CTCP Vận tải và Dịch vụ Petrolimex Hà Tây'},
    {stockname: 'PTI', companyname: 'Tổng Công ty cổ phần Bảo hiểm Bưu điện'},
    {stockname: 'PTL', companyname: 'CTCP Đầu tư Hạ tầng và Đô thị Dầu khí'},
    {stockname: 'PTO', companyname: 'CTCP Dịch vụ - Xây dựng Công trình Bưu Điện'},
    {stockname: 'PTP', companyname: 'CTCP Dịch vụ Viễn thông và In Bưu điện'},
    {stockname: 'PTS', companyname: 'CTCP Vận tải và Dịch vụ Petrolimex Hải Phòng'},
    {stockname: 'PTT', companyname: 'CTCP Vận tải Dầu khí Đông Dương'},
    {stockname: 'PTV', companyname: 'CTCP Thương mại Dầu khí'},
    {stockname: 'PTX', companyname: 'CTCP Vận tải và Dịch vụ Petrolimex Nghệ Tĩnh'},
    {stockname: 'PV2', companyname: 'CTCP Đầu tư PV2'},
    {stockname: 'PVA', companyname: 'CTCP Tổng Công ty Xây lắp Dầu khí Nghệ An'},
    {stockname: 'PVB', companyname: 'CTCP Bọc ống Dầu khí Việt Nam'},
    {stockname: 'PVC', companyname: 'Tổng Công ty Hóa chất và Dịch vụ Dầu khí - CTCP'},
    {stockname: 'PVD', companyname: 'Tổng Công ty cổ phần Khoan và Dịch vụ khoan Dầu khí'},
    {stockname: 'PVE', companyname: 'Tổng Công ty Tư vấn thiết kế Dầu khí - CTCP'},
    {stockname: 'PVG', companyname: 'CTCP Kinh doanh LPG Việt Nam'},
    {stockname: 'PVH', companyname: 'CTCP Xây lắp Dầu khí Thanh Hóa'},
    {stockname: 'PVI', companyname: 'CTCP PVI'},
    {stockname: 'PVL', companyname: 'CTCP Đầu tư Nhà Đất Việt'},
    {stockname: 'PVM', companyname: 'CTCP Máy - Thiết bị Dầu khí'},
    {stockname: 'PVO', companyname: 'CTCP Dầu nhờn PV Oil'},
    {stockname: 'PVP', companyname: 'CTCP Vận tải Dầu khí Thái Bình Dương'},
    {stockname: 'PVR', companyname: 'CTCP Đầu tư PVR Hà Nội'},
    {stockname: 'PVS', companyname: 'Tổng Công ty cổ phần Dịch vụ Kỹ thuật Dầu khí Việt Nam'},
    {stockname: 'PVT', companyname: 'Tổng Công ty cổ phần Vận tải Dầu khí'},
    {stockname: 'PVV', companyname: 'CTCP Vinaconex 39'},
    {stockname: 'PVX', companyname: 'Tổng Công ty cổ phần Xây lắp Dầu khí Việt Nam'},
    {stockname: 'PVY', companyname: 'CTCP Chế tạo Giàn khoan Dầu khí'},
    {stockname: 'PWA', companyname: 'CTCP Bất động sản Dầu khí'},
    {stockname: 'PWS', companyname: 'CTCP Cấp thoát nước Phú Yên'},
    {stockname: 'PX1', companyname: 'CTCP Xi măng Sông Lam 2'},
    {stockname: 'PXA', companyname: 'CTCP Đầu tư và Thương mại Dầu khí Nghệ An'},
    {stockname: 'PXC', companyname: 'CTCP Phát triển Đô thị Dầu khí'},
    {stockname: 'PXI', companyname: 'CTCP Xây dựng Công nghiệp và Dân dụng Dầu khí'},
    {stockname: 'PXL', companyname: 'CTCP Đầu tư Khu Công nghiệp Dầu khí Long Sơn'},
    {stockname: 'PXM', companyname: 'CTCP Xây lắp Dầu khí Miền Trung'},
    {stockname: 'PXS', companyname: 'CTCP Kết cấu Kim loại và Lắp máy Dầu khí'},
    {stockname: 'PXT', companyname: 'CTCP Xây lắp Đường ống Bể chứa Dầu khí'},
    {stockname: 'QBS', companyname: 'CTCP Xuất nhập khẩu Quảng Bình'},
    {stockname: 'QCC', companyname: 'CTCP Đầu tư Xây dựng và Phát triển Hạ tầng Viễn thông'},
    {stockname: 'QCG', companyname: 'CTCP Quốc Cường Gia Lai'},
    {stockname: 'QHD', companyname: 'CTCP Que hàn điện Việt Đức'},
    {stockname: 'QHW', companyname: 'CTCP Nước khoáng Quảng Ninh'},
    {stockname: 'QLT', companyname: 'CTCP Quản lý Bảo trì Đường thủy Nội địa Số 10'},
    {stockname: 'QNC', companyname: 'CTCP Xi măng và Xây dựng Quảng Ninh'},
    {stockname: 'QNS', companyname: 'CTCP Đường Quảng Ngãi'},
    {stockname: 'QNT', companyname: 'Trung tâm Tư vấn xây dựng thị xã Điện Bàn'},
    {stockname: 'QNU', companyname: 'CTCP Môi trường Đô thị Quảng Nam'},
    {stockname: 'QNW', companyname: 'CTCP Cấp thoát nước và Xây dựng Quảng Ngãi'},
    {stockname: 'QPH', companyname: 'CTCP Thủy điện Quế Phong'},
    {stockname: 'QSP', companyname: 'CTCP Tân Cảng Quy Nhơn'},
    {stockname: 'QST', companyname: 'CTCP Sách và Thiết bị Trường học Quảng Ninh'},
    {stockname: 'QTC', companyname: 'CTCP Công trình Giao thông Vận tải Quảng Nam'},
    {stockname: 'QTP', companyname: 'CTCP Nhiệt điện Quảng Ninh'},
    {stockname: 'RAL', companyname: 'CTCP Bóng đèn Phích nước Rạng Đông'},
    {stockname: 'RAT', companyname: 'CTCP Vận tải và Thương mại Đường sắt'},
    {stockname: 'RBC', companyname: 'CTCP Công nghiệp và Xuất nhập khẩu Cao su'},
    {stockname: 'RCC', companyname: 'CTCP Tổng Công ty Công trình Đường sắt'},
    {stockname: 'RCD', companyname: 'CTCP Xây dựng - Địa ốc Cao su'},
    {stockname: 'RCL', companyname: 'CTCP Địa ốc Chợ Lớn'},
    {stockname: 'RDP', companyname: 'CTCP Rạng Đông Holding'},
    {stockname: 'REE', companyname: 'CTCP Cơ Điện Lạnh'},
    {stockname: 'RGC', companyname: 'CTCP Đầu tư PV - Inconess'},
    {stockname: 'RIC', companyname: 'CTCP Quốc tế Hoàng Gia'},
    {stockname: 'RTB', companyname: 'CTCP Cao su Tân Biên'},
    {stockname: 'RUB-X', companyname: 'USD/RUB (Russian Ruble)'},
    {stockname: 'S12', companyname: 'CTCP Sông Đà 12'},
    {stockname: 'S27', companyname: 'CTCP Sông Đà 27'},
    {stockname: 'S4A', companyname: 'CTCP Thủy điện Sê San 4A'},
    {stockname: 'S55', companyname: 'CTCP Sông Đà 505'},
    {stockname: 'S72', companyname: 'CTCP Sông Đà 7.02'},
    {stockname: 'S74', companyname: 'CTCP Sông Đà 7.04'},
    {stockname: 'S96', companyname: 'CTCP Sông Đà 9.06'},
    {stockname: 'S99', companyname: 'CTCP SCI'},
    {stockname: 'SAB', companyname: 'Tổng Công ty cổ phần Bia - Rượu - Nước giải khát Sài Gòn'},
    {stockname: 'SAC', companyname: 'CTCP Xếp dỡ và Dịch vụ Cảng Sài Gòn'},
    {stockname: 'SAF', companyname: 'CTCP Lương thực Thực phẩm Safoco'},
    {stockname: 'SAL', companyname: 'CTCP Trục vớt Cứu hộ Việt Nam'},
    {stockname: 'SAM', companyname: 'CTCP SAM HOLDINGS'},
    {stockname: 'SAP', companyname: 'CTCP In Sách giáo khoa Thành phố Hồ Chí Minh'},
    {stockname: 'SAR-X', companyname: 'USD/SAR (Saudi Riyal)'},
    {stockname: 'SAS', companyname: 'CTCP Dịch vụ Hàng không Sân bay Tân Sơn Nhất'},
    {stockname: 'SAV', companyname: 'CTCP Hợp tác Kinh tế và Xuất nhập khẩu Savimex'},
    {stockname: 'SB1', companyname: 'CTCP Bia Sài Gòn - Nghệ Tĩnh'},
    {stockname: 'SBA', companyname: 'CTCP Sông Ba'},
    {stockname: 'SBD', companyname: 'CTCP Công nghệ Sao Bắc Đẩu'},
    {stockname: 'SBH', companyname: 'CTCP Thủy điện Sông Ba Hạ'},
    {stockname: 'SBL', companyname: 'CTCP Bia Sài Gòn - Bạc Liêu'},
    {stockname: 'SBM', companyname: 'CTCP Đầu tư Phát triển Bắc Minh'},
    {stockname: 'SBR', companyname: 'CTCP Cao su Sông Bé'},
    {stockname: 'SBS', companyname: 'CTCP Chứng khoán Ngân hàng Sài Gòn Thương Tín'},
    {stockname: 'SBT', companyname: 'CTCP Thành Thành Công - Biên Hòa'},
    {stockname: 'SBV', companyname: 'CTCP Siam Brothers Việt Nam'},
    {stockname: 'SC5', companyname: 'CTCP Xây dựng Số 5'},
    {stockname: 'SCC', companyname: 'CTCP Đầu tư Thương mại Hưng Long Tỉnh Hòa Bình'},
    {stockname: 'SCD', companyname: 'CTCP Nước giải khát Chương Dương'},
    {stockname: 'SCG', companyname: 'CTCP Xây dựng SCG'},
    {stockname: 'SCI', companyname: 'CTCP SCI E&C'},
    {stockname: 'SCJ', companyname: 'CTCP Xi măng Sài Sơn'},
    {stockname: 'SCL', companyname: 'CTCP Sông Đà Cao Cường'},
    {stockname: 'SCO', companyname: 'CTCP Công nghiệp Thủy sản'},
    {stockname: 'SCR', companyname: 'CTCP Địa ốc Sài Gòn Thương Tín'},
    {stockname: 'SCS', companyname: 'CTCP Dịch vụ Hàng hóa Sài Gòn'},
    {stockname: 'SCV', companyname: 'CTCP Muối Việt Nam'},
    {stockname: 'SCY', companyname: 'CTCP Đóng tàu Sông Cấm'},
    {stockname: 'SD1', companyname: 'CTCP Sông Đà 1'},
    {stockname: 'SD2', companyname: 'CTCP Sông Đà 2'},
    {stockname: 'SD3', companyname: 'CTCP Sông Đà 3'},
    {stockname: 'SD4', companyname: 'CTCP Sông Đà 4'},
    {stockname: 'SD5', companyname: 'CTCP Sông Đà 5'},
    {stockname: 'SD6', companyname: 'CTCP Sông Đà 6'},
    {stockname: 'SD7', companyname: 'CTCP Sông Đà 7'},
    {stockname: 'SD8', companyname: 'CTCP Sông Đà 8'},
    {stockname: 'SD9', companyname: 'CTCP Sông Đà 9'},
    {stockname: 'SDA', companyname: 'CTCP Simco Sông Đà'},
    {stockname: 'SDB', companyname: 'CTCP Sông Đà 207'},
    {stockname: 'SDC', companyname: 'CTCP Tư vấn Sông Đà'},
    {stockname: 'SDD', companyname: 'CTCP Đầu tư và Xây lắp Sông Đà'},
    {stockname: 'SDG', companyname: 'CTCP Sadico Cần Thơ'},
    {stockname: 'SDJ', companyname: 'CTCP Sông Đà 25'},
    {stockname: 'SDK', companyname: 'CTCP Cơ khí Luyện kim'},
    {stockname: 'SDN', companyname: 'CTCP Sơn Đồng Nai'},
    {stockname: 'SDP', companyname: 'CTCP SDP'},
    {stockname: 'SDT', companyname: 'CTCP Sông Đà 10'},
    {stockname: 'SDU', companyname: 'CTCP Đầu tư Xây dựng và Phát triển Đô thị Sông Đà'},
    {stockname: 'SDV', companyname: 'CTCP Dịch vụ Sonadezi'},
    {stockname: 'SDX', companyname: 'CTCP Phòng cháy Chữa cháy và Đầu tư Xây dựng Sông Đà'},
    {stockname: 'SDY', companyname: 'CTCP Xi măng Sông Đà Yaly'},
    {stockname: 'SEA', companyname: 'Tổng Công ty Thủy sản Việt Nam - CTCP'},
    {stockname: 'SEB', companyname: 'CTCP Đầu tư và Phát triển Điện Miền Trung'},
    {stockname: 'SED', companyname: 'CTCP Đầu tư và Phát triển Giáo dục Phương Nam'},
    {stockname: 'SEP', companyname: 'CTCP Tổng Công ty Thương mại Quảng Trị'},
    {stockname: 'SFC', companyname: 'CTCP Nhiên liệu Sài Gòn'},
    {stockname: 'SFG', companyname: 'CTCP Phân Bón Miền Nam'},
    {stockname: 'SFI', companyname: 'CTCP Đại lý Vận tải SAFI'},
    {stockname: 'SFN', companyname: 'CTCP Dệt lưới Sài Gòn'},
    {stockname: 'SGB', companyname: 'Ngân hàng TMCP Sài Gòn Công thương'},
    {stockname: 'SGC', companyname: 'CTCP Xuất nhập khẩu Sa Giang'},
    {stockname: 'SGD', companyname: 'CTCP Sách Giáo dục tại Thành phố Hồ Chí Minh'},
    {stockname: 'SGD-X', companyname: 'USD/SGD (Singapore Dollar)'},
    {stockname: 'SGH', companyname: 'CTCP Khách sạn Sài Gòn'},
    {stockname: 'SGI', companyname: 'CTCP Đầu tư phát triển Sài Gòn 3 Group'},
    {stockname: 'SGN', companyname: 'CTCP Phục vụ Mặt đất Sài Gòn'},
    {stockname: 'SGO', companyname: 'CTCP Dầu thực vật Sài Gòn'},
    {stockname: 'SGP', companyname: 'CTCP Cảng Sài Gòn'},
    {stockname: 'SGR', companyname: 'CTCP Địa ốc Sài Gòn'},
    {stockname: 'SGS', companyname: 'CTCP Vận tải biển Sài Gòn'},
    {stockname: 'SGT', companyname: 'CTCP Công nghệ Viễn Thông Sài Gòn'},
    {stockname: 'SHA', companyname: 'CTCP Sơn Hà Sài Gòn'},
    {stockname: 'SHB', companyname: 'Ngân hàng TMCP Sài Gòn - Hà Nội'},
    {stockname: 'SHC', companyname: 'CTCP Hàng hải Sài Gòn'},
    {stockname: 'SHE', companyname: 'CTCP Phát triển năng lượng Sơn Hà'},
    {stockname: 'SHG', companyname: 'Tổng Công ty cổ phần Sông Hồng'},
    {stockname: 'SHI', companyname: 'CTCP Quốc tế Sơn Hà'},
    {stockname: 'SHN', companyname: 'CTCP Đầu tư Tổng hợp Hà Nội'},
    {stockname: 'SHP', companyname: 'CTCP Thủy điện Miền Nam'},
    {stockname: 'SHS', companyname: 'CTCP Chứng khoán Sài Gòn - Hà Nội'},
    {stockname: 'SHX', companyname: 'CTCP Sài Gòn Hỏa xa'},
    {stockname: 'SI-F', companyname: 'Silver (Giá Bạc)'},
    {stockname: 'SIC', companyname: 'CTCP ANI'},
    {stockname: 'SID', companyname: 'CTCP Đầu tư Phát triển Sài Gòn Co.op'},
    {stockname: 'SIG', companyname: 'CTCP Đầu tư và Thương mại Sông Đà'},
    {stockname: 'SII', companyname: 'CTCP Hạ tầng Nước Sài Gòn'},
    {stockname: 'SIP', companyname: 'CTCP Đầu tư Sài Gòn VRG'},
    {stockname: 'SIV', companyname: 'CTCP SIVICO'},
    {stockname: 'SJ1', companyname: 'CTCP Nông nghiệp Hùng Hậu'},
    {stockname: 'SJC', companyname: 'CTCP Sông Đà 1.01'},
    {stockname: 'SJD', companyname: 'CTCP Thủy điện Cần Đơn'},
    {stockname: 'SJE', companyname: 'CTCP Sông Đà 11'},
    {stockname: 'SJF', companyname: 'CTCP Đầu tư Sao Thái Dương'},
    {stockname: 'SJG', companyname: 'Tổng Công ty Sông Đà - CTCP'},
    {stockname: 'SJM', companyname: 'CTCP Sông Đà 19'},
    {stockname: 'SJS', companyname: 'CTCP Đầu tư Phát triển Đô thị và Khu công nghiệp Sông Đà'},
    {stockname: 'SKG', companyname: 'CTCP Tàu cao tốc Superdong - Kiên Giang'},
    {stockname: 'SKH', companyname: 'CTCP Nước giải khát Sanest Khánh Hòa'},
    {stockname: 'SKN', companyname: 'CTCP Nước giải khát Sanna Khánh Hòa'},
    {stockname: 'SKV', companyname: 'CTCP Nước giải khát Yến sào Khánh Hòa'},
    {stockname: 'SLS', companyname: 'CTCP Mía Đường Sơn La'},
    {stockname: 'SMA', companyname: 'CTCP Thiết bị Phụ tùng Sài Gòn'},
    {stockname: 'SMB', companyname: 'CTCP Bia Sài Gòn - Miền Trung'},
    {stockname: 'SMC', companyname: 'CTCP Đầu tư Thương mại SMC'},
    {stockname: 'SMN', companyname: 'CTCP Sách và Thiết bị Giáo dục Miền Nam'},
    {stockname: 'SMT', companyname: 'CTCP Sametel'},
    {stockname: 'SNC', companyname: 'CTCP Xuất nhập khẩu Thủy sản Năm Căn'},
    {stockname: 'SNZ', companyname: 'Tổng Công ty cổ phần Phát triển Khu Công nghiệp'},
    {stockname: 'SP2', companyname: 'CTCP Thủy điện Sử Pán 2'},
    {stockname: 'SPB', companyname: 'CTCP Sợi Phú Bài'},
    {stockname: 'SPC', companyname: 'CTCP Bảo vệ Thực vật Sài Gòn'},
    {stockname: 'SPD', companyname: 'CTCP Xuất nhập khẩu Thủy sản Miền Trung'},
    {stockname: 'SPH', companyname: 'CTCP Xuất nhập khẩu Thủy sản Hà Nội'},
    {stockname: 'SPI', companyname: 'CTCP SPI'},
    {stockname: 'SPM', companyname: 'CTCP SPM'},
    {stockname: 'SPP', companyname: 'CTCP Bao bì Nhựa Sài Gòn'},
    {stockname: 'SPV', companyname: 'CTCP Thủy Đặc sản'},
    {stockname: 'SQC', companyname: 'CTCP Khoáng sản Sài Gòn - Quy Nhơn'},
    {stockname: 'SRA', companyname: 'CTCP Sara Việt Nam'},
    {stockname: 'SRB', companyname: 'CTCP Tập đoàn Sara'},
    {stockname: 'SRC', companyname: 'CTCP Cao su Sao Vàng'},
    {stockname: 'SRF', companyname: 'CTCP Kỹ nghệ Lạnh'},
    {stockname: 'SRT', companyname: 'CTCP Vận tải Đường sắt Sài Gòn'},
    {stockname: 'SSB', companyname: 'Ngân hàng TMCP Đông Nam Á'},
    {stockname: 'SSC', companyname: 'CTCP Giống cây trồng Miền Nam'},
    {stockname: 'SSF', companyname: 'CTCP Giáo dục G Sài Gòn'},
    {stockname: 'SSG', companyname: 'CTCP Vận tải biển Hải Âu'},
    {stockname: 'SSH', companyname: 'Công ty cổ phần Phát triển Sunshine Homes'},
    {stockname: 'SSI', companyname: 'CTCP Chứng khoán SSI'},
    {stockname: 'SSM', companyname: 'CTCP Chế tạo kết cấu Thép Vneco.SSM'},
    {stockname: 'SSN', companyname: 'CTCP Xuất nhập khẩu Thủy sản Sài Gòn'},
    {stockname: 'ST8', companyname: 'CTCP Siêu Thanh'},
    {stockname: 'STB', companyname: 'Ngân hàng TMCP Sài Gòn Thương Tín'},
    {stockname: 'STC', companyname: 'CTCP Sách và Thiết bị Trường học Thành phố Hồ Chí Minh'},
    {stockname: 'STG', companyname: 'CTCP Kho vận Miền Nam'},
    {stockname: 'STH', companyname: 'CTCP Phát hành Sách Thái Nguyên'},
    {stockname: 'STK', companyname: 'CTCP Sợi Thế Kỷ'},
    {stockname: 'STL', companyname: 'CTCP Sông Đà Thăng Long'},
    {stockname: 'STP', companyname: 'CTCP Công nghiệp Thương mại Sông Đà'},
    {stockname: 'STS', companyname: 'CTCP Dịch vụ Vận tải Sài Gòn'},
    {stockname: 'STT', companyname: 'CTCP Vận chuyển Sài Gòn Tourist'},
    {stockname: 'STW', companyname: 'CTCP Cấp nước Sóc Trăng'},
    {stockname: 'SVC', companyname: 'CTCP Dịch vụ Tổng hợp Sài Gòn'},
    {stockname: 'SVD', companyname: 'Công ty Cổ phần Đầu tư và Thương mại Vũ Đăng'},
    {stockname: 'SVG', companyname: 'CTCP Hơi Kỹ nghệ Que hàn'},
    {stockname: 'SVH', companyname: 'CTCP Thủy điện Sông Vàng'},
    {stockname: 'SVI', companyname: 'CTCP Bao bì Biên Hòa'},
    {stockname: 'SVN', companyname: 'CTCP Tập đoàn Vexilla Việt Nam'},
    {stockname: 'SVT', companyname: 'CTCP Công nghệ Sài Gòn Viễn Đông'},
    {stockname: 'SWC', companyname: 'Tổng Công ty cổ phần Đường sông Miền Nam'},
    {stockname: 'SZB', companyname: 'CTCP Sonadezi Long Bình'},
    {stockname: 'SZC', companyname: 'CTCP Sonadezi Châu Đức'},
    {stockname: 'SZE', companyname: 'CTCP Môi trường Sonadezi'},
    {stockname: 'SZG', companyname: 'CTCP Sonadezi Giang Điền'},
    {stockname: 'SZL', companyname: 'CTCP Sonadezi Long Thành'},
    {stockname: 'TA3', companyname: 'CTCP Đầu tư và Xây lắp Thành An 386'},
    {stockname: 'TA6', companyname: 'CTCP Đầu tư và Xây lắp Thành An 665'},
    {stockname: 'TA9', companyname: 'CTCP Xây lắp Thành An 96'},
    {stockname: 'TAG', companyname: 'CTCP Thế Giới Số Trần Anh'},
    {stockname: 'TAN', companyname: 'Công ty TNHH MTV Cà phê Thuận An'},
    {stockname: 'TAR', companyname: 'CTCP Nông nghiệp Công nghệ cao Trung An'},
    {stockname: 'TAW', companyname: 'CTCP Cấp nước Trung An'},
    {stockname: 'TB8', companyname: 'CTCP Sản xuất và Kinh doanh Vật tư Thiết bị - VVMI'},
    {stockname: 'TBC', companyname: 'CTCP Thủy điện Thác Bà'},
    {stockname: 'TBD', companyname: 'Tổng Công ty Thiết bị Điện Đông Anh - CTCP'},
    {stockname: 'TBH', companyname: 'CTCP Tổng Bách Hóa'},
    {stockname: 'TBR', companyname: 'Công ty Cổ phần Địa ốc Tân Bình'},
    {stockname: 'TBT', companyname: 'CTCP Xây dựng Công trình Giao thông Bến Tre'},
    {stockname: 'TBX', companyname: 'CTCP Xi măng Thái Bình'},
    {stockname: 'TC6', companyname: 'CTCP Than Cọc Sáu - Vinacomin'},
    {stockname: 'TCB', companyname: 'Ngân hàng TMCP Kỹ thương Việt Nam'},
    {stockname: 'TCD', companyname: 'CTCP Đầu tư Phát triển Công nghiệp và Vận tải'},
    {stockname: 'TCH', companyname: 'CTCP Đầu tư Dịch vụ Tài chính Hoàng Huy'},
    {stockname: 'TCI', companyname: 'CTCP Chứng khoán Thành Công'},
    {stockname: 'TCJ', companyname: 'CTCP Tô Châu'},
    {stockname: 'TCK', companyname: 'Tổng Công ty Cơ khí Xây dựng - CTCP'},
    {stockname: 'TCL', companyname: 'CTCP Đại lý Giao nhận Vận tải Xếp dỡ Tân Cảng'},
    {stockname: 'TCM', companyname: 'CTCP Dệt may - Đầu tư - Thương mại Thành Công'},
    {stockname: 'TCO', companyname: 'CTCP Vận tải Đa phương thức Duyên Hải'},
    {stockname: 'TCR', companyname: 'CTCP Công nghiệp Gốm sứ Taicera'},
    {stockname: 'TCT', companyname: 'CTCP Cáp treo Núi Bà Tây Ninh'},
    {stockname: 'TCW', companyname: 'CTCP Kho Vận Tân Cảng'},
    {stockname: 'TDB', companyname: 'CTCP Thủy điện Định Bình'},
    {stockname: 'TDC', companyname: 'CTCP Kinh doanh và Phát triển Bình Dương'},
    {stockname: 'TDF', companyname: 'CTCP Trung Đô'},
    {stockname: 'TDG', companyname: 'CTCP Dầu khí Thái Dương'},
    {stockname: 'TDH', companyname: 'CTCP Phát triển Nhà Thủ Đức'},
    {stockname: 'TDM', companyname: 'CTCP Nước Thủ Dầu Một'},
    {stockname: 'TDN', companyname: 'CTCP Than Đèo Nai - Vinacomin'},
    {stockname: 'TDP', companyname: 'CTCP Thuận Đức'},
    {stockname: 'TDS', companyname: 'CTCP Thép Thủ Đức - VNSTEEL'},
    {stockname: 'TDT', companyname: 'CTCP Đầu tư và Phát triển TDT'},
    {stockname: 'TDW', companyname: 'CTCP Cấp nước Thủ Đức'},
    {stockname: 'TED', companyname: 'Tổng công ty Tư vấn thiết kế Giao thông vận tải - CTCP'},
    {stockname: 'TEG', companyname: 'CTCP Năng lượng và Bất động sản Trường Thành'},
    {stockname: 'TEL', companyname: 'CTCP Phát triển Công trình Viễn thông'},
    {stockname: 'TET', companyname: 'CTCP Vải sợi May mặc Miền Bắc'},
    {stockname: 'TFC', companyname: 'CTCP Trang'},
    {stockname: 'TGG', companyname: 'Công ty Cổ phần Louis Capital'},
    {stockname: 'TGP', companyname: 'CTCP Trường Phú'},
    {stockname: 'TH1', companyname: 'CTCP Xuất nhập khẩu tổng hợp 1 Việt Nam'},
    {stockname: 'THB', companyname: 'CTCP Bia Hà Nội - Thanh Hóa'},
    {stockname: 'THD', companyname: 'CTCP Thaiholdings'},
    {stockname: 'THG', companyname: 'CTCP Đầu tư và Xây dựng Tiền Giang'},
    {stockname: 'THI', companyname: 'CTCP Thiết bị Điện'},
    {stockname: 'THN', companyname: 'CTCP Cấp nước Thanh Hóa'},
    {stockname: 'THP', companyname: 'CTCP Thủy sản và Thương mại Thuận Phước'},
    {stockname: 'THS', companyname: 'CTCP Thanh Hoa - Sông Đà'},
    {stockname: 'THT', companyname: 'CTCP Than Hà Tu - Vinacomin'},
    {stockname: 'THU', companyname: 'CTCP Môi trường và Công trình Đô thị Thanh Hóa'},
    {stockname: 'THW', companyname: 'CTCP Cấp nước Tân Hòa'},
    {stockname: 'TID', companyname: 'CTCP Tổng Công ty Tín Nghĩa'},
    {stockname: 'TIE', companyname: 'CTCP TIE'},
    {stockname: 'TIG', companyname: 'CTCP Tập đoàn Đầu tư Thăng Long'},
    {stockname: 'TIN', companyname: 'Công ty Tài chính Cổ phần Tín Việt'},
    {stockname: 'TIP', companyname: 'CTCP Phát triển Khu Công nghiệp Tín Nghĩa'},
    {stockname: 'TIS', companyname: 'CTCP Gang thép Thái Nguyên'},
    {stockname: 'TIX', companyname: 'CTCP Sản xuất Kinh doanh Xuất nhập khẩu Dịch vụ và Đầu tư Tân Bình'},
    {stockname: 'TJC', companyname: 'CTCP Dịch vụ Vận tải và Thương mại'},
    {stockname: 'TKA', companyname: 'CTCP Bao bì Tân Khánh An'},
    {stockname: 'TKC', companyname: 'CTCP Xây dựng và Kinh doanh Địa ốc Tân Kỷ'},
    {stockname: 'TKG', companyname: 'CTCP Sản xuất và Thương mại Tùng Khánh'},
    {stockname: 'TKU', companyname: 'CTCP Công nghiệp Tung Kuang'},
    {stockname: 'TL4', companyname: 'Tổng Công ty cổ phần Xây dựng Thủy Lợi 4'},
    {stockname: 'TLD', companyname: 'CTCP Đầu tư Xây dựng và Phát triển Đô thị Thăng Long'},
    {stockname: 'TLG', companyname: 'CTCP Tập đoàn Thiên Long'},
    {stockname: 'TLH', companyname: 'CTCP Tập đoàn Thép Tiến Lên'},
    {stockname: 'TLI', companyname: 'CTCP May Quốc tế Thắng Lợi'},
    {stockname: 'TLP', companyname: 'Tổng Công ty Thương mại Xuất nhập khẩu Thanh Lễ - CTCP'},
    {stockname: 'TLT', companyname: 'CTCP Viglacera Thăng Long'},
    {stockname: 'TMB', companyname: 'CTCP Kinh doanh Than Miền Bắc - Vinacomin'},
    {stockname: 'TMC', companyname: 'CTCP Thương mại Xuất nhập khẩu Thủ Đức'},
    {stockname: 'TMG', companyname: 'CTCP Kim loại màu Thái Nguyên - Vimico'},
    {stockname: 'TMP', companyname: 'CTCP Thủy điện Thác Mơ'},
    {stockname: 'TMS', companyname: 'CTCP Transimex'},
    {stockname: 'TMT', companyname: 'CTCP Ô tô TMT'},
    {stockname: 'TMW', companyname: 'CTCP Tổng hợp Gỗ Tân Mai'},
    {stockname: 'TMX', companyname: 'CTCP VICEM Thương mại Xi măng'},
    {stockname: 'TN1', companyname: 'CTCP Thương mại Dịch vụ TNS Holdings'},
    {stockname: 'TNA', companyname: 'CTCP Thương mại Xuất nhập khẩu Thiên Nam'},
    {stockname: 'TNB', companyname: 'CTCP Thép Nhà Bè - VNSTEEL'},
    {stockname: 'TNC', companyname: 'CTCP Cao su Thống Nhất'},
    {stockname: 'TNG', companyname: 'CTCP Đầu tư và Thương mại TNG'},
    {stockname: 'TNH', companyname: 'Công ty Cổ phần Bệnh viện Quốc tế Thái Nguyên'},
    {stockname: 'TNI', companyname: 'CTCP Tập đoàn Thành Nam'},
    {stockname: 'TNM', companyname: 'CTCP Xuất nhập khẩu và Xây dựng Công trình'},
    {stockname: 'TNP', companyname: 'CTCP Cảng Thị Nại'},
    {stockname: 'TNS', companyname: 'CTCP Thép tấm lá Thống Nhất'},
    {stockname: 'TNT', companyname: 'CTCP Tài Nguyên'},
    {stockname: 'TNW', companyname: 'CTCP Nước sạch Thái Nguyên'},
    {stockname: 'TOP', companyname: 'CTCP Phân phối Top One'},
    {stockname: 'TOS', companyname: 'CTCP Dịch vụ biển Tân Cảng'},
    {stockname: 'TOT', companyname: 'CTCP Vận tải Transimex'},
    {stockname: 'TOW', companyname: 'CTCP Cấp nước Trà Nóc - Ô Môn'},
    {stockname: 'TPB', companyname: 'Ngân hàng TMCP Tiên Phong'},
    {stockname: 'TPC', companyname: 'CTCP Nhựa Tân Đại Hưng'},
    {stockname: 'TPH', companyname: 'CTCP In sách giáo khoa tại Thành phố Hà Nội'},
    {stockname: 'TPP', companyname: 'CTCP Nhựa Tân Phú'},
    {stockname: 'TPS', companyname: 'CTCP Bến Bãi Vận tải Sài Gòn'},
    {stockname: 'TQN', companyname: 'CTCP Thông Quảng Ninh'},
    {stockname: 'TQW', companyname: 'CTCP Cấp thoát nước Tuyên Quang'},
    {stockname: 'TR1', companyname: 'CTCP Vận tải 1 Traco'},
    {stockname: 'TRA', companyname: 'CTCP Traphaco'},
    {stockname: 'TRC', companyname: 'CTCP Cao su Tây Ninh'},
    {stockname: 'TRS', companyname: 'CTCP Vận tải và Dịch vụ Hàng hải'},
    {stockname: 'TRT', companyname: 'CTCP Trúc Thôn'},
    {stockname: 'TS3', companyname: 'CTCP Trường Sơn 532'},
    {stockname: 'TS4', companyname: 'CTCP Thủy sản Số 4'},
    {stockname: 'TSB', companyname: 'CTCP Ắc quy Tia Sáng'},
    {stockname: 'TSC', companyname: 'CTCP Vật tư Kỹ thuật nông nghiệp Cần Thơ'},
    {stockname: 'TSD', companyname: 'CTCP Du lịch Trường Sơn COECCO'},
    {stockname: 'TSG', companyname: 'CTCP Thông tin Tín hiệu Đường sắt Sài Gòn'},
    {stockname: 'TSJ', companyname: 'CTCP Du lịch Dịch vụ Hà Nội'},
    {stockname: 'TST', companyname: 'CTCP Dịch vụ Kỹ Thuật Viễn thông'},
    {stockname: 'TTA', companyname: 'Công ty Cổ phần Đầu tư Xây dựng và Phát triển Trường Thành'},
    {stockname: 'TTB', companyname: 'CTCP Tập đoàn Tiến Bộ'},
    {stockname: 'TTC', companyname: 'CTCP Gạch men Thanh Thanh'},
    {stockname: 'TTD', companyname: 'CTCP Bệnh viện tim Tâm Đức'},
    {stockname: 'TTE', companyname: 'CTCP Đầu tư Năng lượng Trường Thịnh'},
    {stockname: 'TTF', companyname: 'CTCP Tập đoàn Kỹ nghệ gỗ Trường Thành'},
    {stockname: 'TTG', companyname: 'CTCP May Thanh Trì'},
    {stockname: 'TTH', companyname: 'CTCP Thương mại và Dịch vụ Tiến Thành'},
    {stockname: 'TTL', companyname: 'Tổng Công ty Thăng Long - CTCP'},
    {stockname: 'TTN', companyname: 'CTCP Công nghệ và Truyền thông Việt Nam'},
    {stockname: 'TTP', companyname: 'CTCP Bao bì nhựa Tân Tiến'},
    {stockname: 'TTS', companyname: 'CTCP Cán Thép Thái Trung'},
    {stockname: 'TTT', companyname: 'CTCP Du lịch - Thương mại Tây Ninh'},
    {stockname: 'TTZ', companyname: 'CTCP Đầu tư Xây dựng và Công nghệ Tiến Trung'},
    {stockname: 'TUG', companyname: 'CTCP Lai Dắt và Vận tải Cảng Hải Phòng'},
    {stockname: 'TV1', companyname: 'CTCP Tư vấn Xây dựng Điện 1'},
    {stockname: 'TV2', companyname: 'CTCP Tư vấn Xây dựng Điện 2'},
    {stockname: 'TV3', companyname: 'CTCP Tư vấn Xây dựng Điện 3'},
    {stockname: 'TV4', companyname: 'CTCP Tư vấn Xây dựng Điện 4'},
    {stockname: 'TV6', companyname: 'CTCP Thương mại Đầu tư Xây lắp điện Thịnh Vượng'},
    {stockname: 'TVA', companyname: 'CTCP Sứ Viglacera Thanh Trì'},
    {stockname: 'TVB', companyname: 'CTCP Chứng khoán Trí Việt'},
    {stockname: 'TVC', companyname: 'CTCP Tập đoàn Quản lý Tài sản Trí Việt'},
    {stockname: 'TVD', companyname: 'CTCP Than Vàng Danh - Vinacomin'},
    {stockname: 'TVG', companyname: 'CTCP Tư vấn Đầu tư và Xây dựng Giao thông Vận tải'},
    {stockname: 'TVH', companyname: 'CTCP Tư vấn Xây dựng công trình Hàng hải'},
    {stockname: 'TVM', companyname: 'CTCP Tư vấn Đầu tư mỏ và Công Nghiệp - Vinacomin'},
    {stockname: 'TVN', companyname: 'Tổng Công ty Thép Việt Nam - CTCP'},
    {stockname: 'TVP', companyname: 'CTCP Dược phẩm TV.Pharm'},
    {stockname: 'TVS', companyname: 'CTCP Chứng khoán Thiên Việt'},
    {stockname: 'TVT', companyname: 'Tổng Công ty Việt Thắng - CTCP'},
    {stockname: 'TVW', companyname: 'CTCP Cấp thoát nước Trà Vinh'},
    {stockname: 'TW3', companyname: 'CTCP Dược Trung ương 3'},
    {stockname: 'TXM', companyname: 'CTCP VICEM Thạch cao Xi măng'},
    {stockname: 'TYA', companyname: 'CTCP Dây và Cáp Điện Taya Việt Nam'},
    {stockname: 'UCT', companyname: 'CTCP Đô thị Cần Thơ'},
    {stockname: 'UDC', companyname: 'CTCP Xây dựng và Phát triển Đô thị Tỉnh Bà Rịa Vũng Tàu'},
    {stockname: 'UDJ', companyname: 'CTCP Phát triển Đô thị'},
    {stockname: 'UDL', companyname: 'CTCP Đô thị và Môi trường Đắk Lắk'},
    {stockname: 'UEM', companyname: 'CTCP Cơ điện Uông Bí - Vinacomin'},
    {stockname: 'UIC', companyname: 'CTCP Đầu tư Phát triển Nhà và Đô thị IDICO'},
    {stockname: 'UMC', companyname: 'CTCP Công trình Đô thị Nam Định'},
    {stockname: 'UNI', companyname: 'CTCP Viễn Liên'},
    {stockname: 'UPC', companyname: 'CTCP Phát triển Công viên Cây xanh và Đô thị Vũng Tàu'},
    {stockname: 'UPH', companyname: 'CTCP Dược phẩm TW 25'},
    {stockname: 'USC', companyname: 'CTCP Khảo sát và Xây dựng -USCO'},
    {stockname: 'USD', companyname: 'CTCP Công trình Đô thị Sóc Trăng'},
    {stockname: 'USDCAD', companyname: 'USD/CAD'},
    {stockname: 'USDCHF', companyname: 'USD/CHF'},
    {stockname: 'USDJPY', companyname: 'USD/JPY'},
    {stockname: 'USOIL', companyname: 'Dầu'},
    {stockname: 'V11', companyname: 'CTCP Xây dựng Số 11'},
    {stockname: 'V12', companyname: 'CTCP Xây dựng Số 12'},
    {stockname: 'V15', companyname: 'CTCP Xây dựng Số 15'},
    {stockname: 'V21', companyname: 'CTCP Vinaconex 21'},
    {stockname: 'VAB', companyname: 'Ngân hàng Thương mại cổ phần Việt Á'},
    {stockname: 'VAF', companyname: 'CTCP Phân lân Nung chảy Văn Điển'},
    {stockname: 'VAT', companyname: 'CTCP Viễn thông Vạn Xuân'},
    {stockname: 'VAV', companyname: 'CTCP VIWACO'},
    {stockname: 'VBB', companyname: 'Ngân hàng TMCP Việt Nam Thương Tín'},
    {stockname: 'VBC', companyname: 'CTCP Nhựa - Bao bì Vinh'},
    {stockname: 'VBG', companyname: 'CTCP Địa chất Việt Bắc - TKV'},
    {stockname: 'VBH', companyname: 'CTCP Điện tử Bình Hòa'},
    {stockname: 'VC1', companyname: 'CTCP Xây dựng Số 1'},
    {stockname: 'VC2', companyname: 'CTCP Đầu tư và Xây dựng Vina2'},
    {stockname: 'VC3', companyname: 'CTCP Tập đoàn Nam Mê Kông'},
    {stockname: 'VC5', companyname: 'CTCP Xây dựng Số 5'},
    {stockname: 'VC6', companyname: 'CTCP Xây dựng và Đầu tư Visicons'},
    {stockname: 'VC7', companyname: 'CTCP Xây dựng Số 7'},
    {stockname: 'VC9', companyname: 'CTCP Xây dựng Số 9'},
    {stockname: 'VCA', companyname: 'CTCP Thép VICASA - VNSTEEL'},
    {stockname: 'VCB', companyname: 'Ngân hàng TMCP Ngoại thương Việt Nam'},
    {stockname: 'VCC', companyname: 'CTCP Vinaconex 25'},
    {stockname: 'VCE', companyname: 'CTCP Xây lắp Môi trường'},
    {stockname: 'VCF', companyname: 'CTCP Vinacafé Biên Hòa'},
    {stockname: 'VCG', companyname: 'Tổng Công ty cổ phần Xuất nhập khẩu và Xây dựng Việt Nam'},
    {stockname: 'VCI', companyname: 'CTCP Chứng khoán Bản Việt'},
    {stockname: 'VCM', companyname: 'CTCP Nhân lực và Thương mại Vinaconex'},
    {stockname: 'VCP', companyname: 'CTCP Tư Xây dựng và Phát triển Năng Lượng VCP'},
    {stockname: 'VCR', companyname: 'CTCP Đầu tư và Phát triển Du lịch Vinaconex'},
    {stockname: 'VCS', companyname: 'CTCP Vicostone'},
    {stockname: 'VCT', companyname: 'CTCP Tư vấn Xây dựng Vinaconex'},
    {stockname: 'VCW', companyname: 'CTCP Đầu tư Nước sạch Sông Đà'},
    {stockname: 'VCX', companyname: 'CTCP Xi măng Yên Bình'},
    {stockname: 'VDB', companyname: 'CTCP Vận tải và Chế biến Than Đông Bắc'},
    {stockname: 'VDL', companyname: 'CTCP Thực phẩm Lâm Đồng'},
    {stockname: 'VDN', companyname: 'CTCP Vinatex Đà Nẵng'},
    {stockname: 'VDP', companyname: 'CTCP Dược phẩm Trung ương VIDIPHA'},
    {stockname: 'VDS', companyname: 'CTCP Chứng khoán Rồng Việt'},
    {stockname: 'VDT', companyname: 'CTCP Lưới thép Bình Tây'},
    {stockname: 'VE1', companyname: 'CTCP Xây dựng Điện VNECO 1'},
    {stockname: 'VE2', companyname: 'CTCP Xây dựng Điện VNECO 2'},
    {stockname: 'VE3', companyname: 'CTCP Xây dựng Điện VNECO 3'},
    {stockname: 'VE4', companyname: 'CTCP Xây dựng Điện VNECO4'},
    {stockname: 'VE8', companyname: 'CTCP Xây dựng Điện VNECO 8'},
    {stockname: 'VE9', companyname: 'CTCP Đầu tư và Xây dựng VNECO 9'},
    {stockname: 'VEA', companyname: 'Tổng Công ty Máy động lực và Máy nông nghiệp Việt Nam – CTCP'},
    {stockname: 'VEC', companyname: 'Tổng Công ty cổ phần Điện tử và Tin học Việt Nam'},
    {stockname: 'VEF', companyname: 'CTCP Trung tâm Hội chợ Triển lãm Việt Nam'},
    {stockname: 'VES', companyname: 'CTCP Đầu tư và Xây dựng Điện Mêca Vneco'},
    {stockname: 'VET', companyname: 'CTCP Thuốc thú y Trung ương Navetco'},
    {stockname: 'VFC', companyname: 'CTCP Vinafco'},
    {stockname: 'VFG', companyname: 'CTCP Khử trùng Việt Nam'},
    {stockname: 'VFR', companyname: 'CTCP Vận tải và Thuê tàu'},
    {stockname: 'VFS', companyname: 'CTCP Chứng khoán Nhất Việt'},
    {stockname: 'VGC', companyname: 'Tổng Công ty Viglacera - CTCP'},
    {stockname: 'VGG', companyname: 'Tổng Công ty cổ phần May Việt Tiến'},
    {stockname: 'VGI', companyname: 'Tổng Công ty cổ phần Đầu tư Quốc tế Viettel'},
    {stockname: 'VGL', companyname: 'CTCP Mạ Kẽm Công Nghiệp Vingal - Vnsteel'},
    {stockname: 'VGP', companyname: 'CTCP Cảng Rau Quả'},
    {stockname: 'VGR', companyname: 'CTCP Cảng Xanh Vip'},
    {stockname: 'VGS', companyname: 'CTCP Ống thép Việt Đức VG PIPE'},
    {stockname: 'VGT', companyname: 'Tập đoàn Dệt May Việt Nam'},
    {stockname: 'VGV', companyname: 'Tổng Công ty Tư vấn Xây dựng Việt Nam - CTCP'},
    {stockname: 'VHC', companyname: 'CTCP Vĩnh Hoàn'},
    {stockname: 'VHD', companyname: 'CTCP Đầu tư Phát triển Nhà và Đô Thị Vinaconex'},
    {stockname: 'VHE', companyname: 'CTCP Dược liệu và Thực phẩm Việt Nam'},
    {stockname: 'VHF', companyname: 'CTCP Xây dựng và Chế biến Lương thực Vĩnh Hà'},
    {stockname: 'VHG', companyname: 'CTCP Đầu tư Cao su Quảng Nam'},
    {stockname: 'VHH', companyname: 'CTCP Đầu tư Kinh doanh Nhà Thành Đạt'},
    {stockname: 'VHL', companyname: 'CTCP Viglacera Hạ Long'},
    {stockname: 'VHM', companyname: 'CTCP Vinhomes'},
    {stockname: 'VIB', companyname: 'Ngân hàng TMCP Quốc tế Việt Nam'},
    {stockname: 'VIC', companyname: 'Tập đoàn VINGROUP - CTCP'},
    {stockname: 'VID', companyname: 'CTCP Đầu tư Phát triển Thương mại Viễn Đông'},
    {stockname: 'VIE', companyname: 'CTCP Công nghệ Viễn thông VITECO'},
    {stockname: 'VIF', companyname: 'Tổng Công ty Lâm nghiệp Việt Nam - CTCP'},
    {stockname: 'VIG', companyname: 'CTCP Chứng khoán Thương mại và Công nghiệp Việt Nam'},
    {stockname: 'VIH', companyname: 'CTCP Viglacera Hà Nội'},
    {stockname: 'VIM', companyname: 'CTCP Khoáng sản Viglacera'},
    {stockname: 'VIN', companyname: 'CTCP Giao nhận Kho vận Ngoại Thương Việt Nam'},
    {stockname: 'VIP', companyname: 'CTCP Vận tải Xăng dầu Vipco'},
    {stockname: 'VIR', companyname: 'CTCP Du lịch Quốc tế Vũng Tàu'},
    {stockname: 'VIT', companyname: 'CTCP Viglacera Tiên Sơn'},
    {stockname: 'VIW', companyname: 'Tổng Công ty Đầu tư Nước và Môi trường Việt Nam'},
    {stockname: 'VIX', companyname: 'CTCP Chứng khoán VIX'},
    {stockname: 'VJC', companyname: 'CTCP Hàng không Vietjet'},
    {stockname: 'VKC', companyname: 'CTCP Cáp - Nhựa Vĩnh Khánh'},
    {stockname: 'VKP', companyname: 'CTCP Nhựa Tân Hóa'},
    {stockname: 'VLA', companyname: 'CTCP Đầu tư và Phát triển Công nghệ Văn Lang'},
    {stockname: 'VLB', companyname: 'CTCP Xây dựng và Sản xuất Vật liệu xây dựng Biên Hòa'},
    {stockname: 'VLC', companyname: 'Tổng Công ty Chăn Nuôi Việt Nam - CTCP'},
    {stockname: 'VLF', companyname: 'CTCP Lương thực Thực phẩm Vĩnh Long'},
    {stockname: 'VLG', companyname: 'CTCP Vinalines Logistics - Việt Nam'},
    {stockname: 'VLP', companyname: 'CTCP Công trình Công cộng Vĩnh Long'},
    {stockname: 'VLW', companyname: 'CTCP Cấp nước Vĩnh Long'},
    {stockname: 'VMA', companyname: 'CTCP Công nghiệp Ô tô - Vinacomin'},
    {stockname: 'VMC', companyname: 'CTCP Vimeco'},
    {stockname: 'VMD', companyname: 'CTCP Y Dược phẩm Vimedimex'},
    {stockname: 'VMG', companyname: 'CTCP Thương mại và Dịch vụ Dầu khí Vũng Tàu'},
    {stockname: 'VMS', companyname: 'CTCP Phát triển Hàng Hải'},
    {stockname: 'VN30F1M', companyname: 'VN30F1M'},
    {stockname: 'VN30F1Q', companyname: 'VN30F1Q'},
    {stockname: 'VN30F2M', companyname: 'VN30F2M'},
    {stockname: 'VN30F2Q', companyname: 'VN30F2Q'},
    {stockname: 'VNA', companyname: 'CTCP Vận tải Biển Vinaship'},
    {stockname: 'VNB', companyname: 'CTCP Sách Việt Nam'},
    {stockname: 'VNC', companyname: 'CTCP Tập đoàn Vinacontrol'},
    {stockname: 'VND', companyname: 'CTCP Chứng khoán VNDirect'},
    {stockname: 'VND-X', companyname: 'USD/VND (Viet Nam Dong)'},
    {stockname: 'VNE', companyname: 'Tổng Công ty cổ phần Xây dựng Điện Việt Nam'},
    {stockname: 'VNF', companyname: 'CTCP Vinafreight'},
    {stockname: 'VNG', companyname: 'CTCP Du lịch Thành Thành Công'},
    {stockname: 'VNH', companyname: 'CTCP Đầu tư Việt Việt Nhật'},
    {stockname: 'VNI', companyname: 'CTCP Đầu tư Bất động sản Việt Nam'},
    {stockname: 'VNL', companyname: 'CTCP Logistics Vinalink'},
    {stockname: 'VNM', companyname: 'CTCP Sữa Việt Nam'},
    {stockname: 'VNP', companyname: 'CTCP Nhựa Việt Nam'},
    {stockname: 'VNR', companyname: 'Tổng Công ty cổ phần Tái Bảo hiểm Quốc gia Việt Nam'},
    {stockname: 'VNS', companyname: 'CTCP Ánh Dương Việt Nam'},
    {stockname: 'VNT', companyname: 'CTCP Giao nhận Vận tải Ngoại thương'},
    {stockname: 'VNX', companyname: 'CTCP Quảng cáo và Hội chợ Thương mại'},
    {stockname: 'VNY', companyname: 'CTCP Thuốc thú y Trung ương I'},
    {stockname: 'VNZ', companyname: 'Công ty cổ phần VNG'},
    {stockname: 'VOC', companyname: 'Tổng Công ty Công Nghiệp Dầu Thực Vật Việt Nam - CTCP'},
    {stockname: 'VOS', companyname: 'CTCP Vận tải Biển Việt Nam'},
    {stockname: 'VPA', companyname: 'CTCP Vận tải Hoá Dầu VP'},
    {stockname: 'VPB', companyname: 'Ngân hàng TMCP Việt Nam Thịnh Vượng'},
    {stockname: 'VPC', companyname: 'CTCP Đầu tư và Phát triển Năng lượng Việt Nam'},
    {stockname: 'VPD', companyname: 'CTCP Phát triển Điện lực Việt Nam'},
    {stockname: 'VPG', companyname: 'CTCP Đầu tư Thương mại Xuất nhập khẩu Việt Phát'},
    {stockname: 'VPH', companyname: 'CTCP Vạn Phát Hưng'},
    {stockname: 'VPI', companyname: 'CTCP Đầu tư Văn Phú - INVEST'},
    {stockname: 'VPR', companyname: 'CTCP VinaPrint'},
    {stockname: 'VPS', companyname: 'CTCP Thuốc sát trùng Việt Nam (VIPESCO)'},
    {stockname: 'VPW', companyname: 'CTCP Cấp thoát nước Số 1 Vĩnh Phúc'},
    {stockname: 'VQC', companyname: 'CTCP Giám định - Vinacomin'},
    {stockname: 'VRC', companyname: 'CTCP Bất động sản và Đầu tư VRC'},
    {stockname: 'VRE', companyname: 'CTCP Vincom Retail'},
    {stockname: 'VRG', companyname: 'CTCP Phát triển Đô thị và Khu công nghiệp Cao su Việt Nam'},
    {stockname: 'VSA', companyname: 'CTCP Đại lý Hàng hải Việt Nam'},
    {stockname: 'VSC', companyname: 'CTCP Container Việt Nam'},
    {stockname: 'VSE', companyname: 'CTCP Dịch vụ Đường cao tốc Việt Nam'},
    {stockname: 'VSF', companyname: 'Tổng Công ty Lương thực Miền Nam - CTCP'},
    {stockname: 'VSG', companyname: 'CTCP Container Phía Nam'},
    {stockname: 'VSH', companyname: 'CTCP Thủy điện Vĩnh Sơn - Sông Hinh'},
    {stockname: 'VSI', companyname: 'CTCP Đầu tư và Xây dựng Cấp thoát nước'},
    {stockname: 'VSM', companyname: 'CTCP Container Miền Trung'},
    {stockname: 'VSN', companyname: 'CTCP Việt Nam Kỹ nghệ Súc sản'},
    {stockname: 'VSP', companyname: 'CTCP Vận tải biển và Bất động sản Việt Hải'},
    {stockname: 'VST', companyname: 'CTCP Vận tải và Thuê tàu biển Việt Nam'},
    {stockname: 'VTA', companyname: 'CTCP Vitaly'},
    {stockname: 'VTB', companyname: 'CTCP Viettronics Tân Bình'},
    {stockname: 'VTC', companyname: 'CTCP Viễn thông VTC'},
    {stockname: 'VTD', companyname: 'CTCP Du lịch Vietourist'},
    {stockname: 'VTE', companyname: 'CTCP Viễn thông Điện tử VINACAP'},
    {stockname: 'VTG', companyname: 'CTCP Du lịch Tỉnh Bà Rịa - Vũng Tàu'},
    {stockname: 'VTH', companyname: 'CTCP Dây cáp Điện Việt Thái'},
    {stockname: 'VTI', companyname: 'CTCP Sản xuất - Xuất nhập khẩu Dệt may'},
    {stockname: 'VTJ', companyname: 'CTCP Thương mại và Đầu tư VI NA TA BA'},
    {stockname: 'VTK', companyname: 'CTCP Tư vấn Thiết kế Viettel'},
    {stockname: 'VTL', companyname: 'CTCP Vang Thăng Long'},
    {stockname: 'VTM', companyname: 'CTCP Vận tải và Đưa đón thợ mỏ - VINACOMIN'},
    {stockname: 'VTO', companyname: 'CTCP Vận tải Xăng dầu Vitaco'},
    {stockname: 'VTP', companyname: 'Tổng Công ty cổ phần Bưu chính Viettel'},
    {stockname: 'VTQ', companyname: 'Công ty Cổ phần Việt Trung Quảng Bình'},
    {stockname: 'VTR', companyname: 'CTCP Du lịch và Tiếp thị Giao thông Vận tải Việt Nam - Vietravel'},
    {stockname: 'VTS', companyname: 'CTCP Viglacera Từ Sơn'},
    {stockname: 'VTV', companyname: 'CTCP Năng lượng và Môi trường VICEM'},
    {stockname: 'VTX', companyname: 'CTCP Vận tải Đa Phương Thức Vietranstimex'},
    {stockname: 'VTZ', companyname: 'Công ty Cổ phần Sản xuất và Thương mại Nhựa Việt Thành'},
    {stockname: 'VUA', companyname: 'Công ty cổ phần Chứng khoán Stanley Brothers'},
    {stockname: 'VVN', companyname: 'Tổng Công ty cổ phần Xây dựng Công nghiệp Việt Nam'},
    {stockname: 'VVS', companyname: 'Công ty cổ phần Đầu tư Phát triển máy Việt Nam'},
    {stockname: 'VW3', companyname: 'CTCP Viwaseen3'},
    {stockname: 'VWS', companyname: 'CTCP Nước và Môi Trường Việt Nam'},
    {stockname: 'VXB', companyname: 'CTCP Vật liệu Xây dựng Bến Tre'},
    {stockname: 'VXP', companyname: 'CTCP Thuốc Thú y Trung ương VETVACO'},
    {stockname: 'VXT', companyname: 'CTCP Kho vận và Dịch vụ Thương mại'},
    {stockname: 'WCS', companyname: 'CTCP Bến xe Miền Tây'},
    {stockname: 'WSB', companyname: 'CTCP Bia Sài Gòn - Miền Tây'},
    {stockname: 'WSS', companyname: 'CTCP Chứng khoán Phố Wall'},
    {stockname: 'WTC', companyname: 'CTCP Vận tải Thủy - Vinacomin'},
    {stockname: 'X20', companyname: 'CTCP X20'},
    {stockname: 'X26', companyname: 'CTCP 26'},
    {stockname: 'X77', companyname: 'CTCP Thành An 77'},
    {stockname: 'XAGUSD', companyname: 'Silver - Bạc'},
    {stockname: 'XAUUSD', companyname: 'Gold - Vàng'},
    {stockname: 'XDH', companyname: 'CTCP Đầu tư Xây dựng Dân dụng Hà Nội'},
    {stockname: 'XHC', companyname: 'CTCP Xuân Hòa Việt Nam'},
    {stockname: 'XLV', companyname: 'CTCP Xây Lắp và Dịch vụ Sông Đà'},
    {stockname: 'XMC', companyname: 'CTCP Đầu tư và Xây dựng Xuân Mai'},
    {stockname: 'XMD', companyname: 'CTCP Xuân Mai - Đạo Tú'},
    {stockname: 'XMP', companyname: 'CTCP Thủy điện Xuân Minh'},
    {stockname: 'XPH', companyname: 'CTCP Xà phòng Hà Nội'},
    {stockname: 'XRP-USD', companyname: 'XRP USD'},
    {stockname: 'YBC', companyname: 'CTCP Xi măng và Khoáng sản Yên Bái'},
    {stockname: 'YBM', companyname: 'CTCP Khoáng sản Công nghiệp Yên Bái'},
    {stockname: 'YEG', companyname: 'CTCP Tập đoàn Yeah1'},
    {stockname: 'YTC', companyname: 'CTCP Xuất nhập khẩu Y tế Thành phố Hồ Chí Minh'},
    {stockname: 'ZAR-X', companyname: 'USD/ZAR (South African Rand)'},
    {stockname: '^AEX', companyname: 'AEX General (Amsterdam)'},
    {stockname: '^AORD', companyname: 'All Ordinaries (Australian)'},
    {stockname: '^ATX', companyname: 'ATX (Vienna)'},
    {stockname: '^BB', companyname: 'Bán buôn'},
    {stockname: '^BDS', companyname: 'Bất động sản'},
    {stockname: '^BFX', companyname: 'BEL-20 (Brussels)'},
    {stockname: '^BH', companyname: 'Bảo hiểm'},
    {stockname: '^BL', companyname: 'Bán lẻ'},
    {stockname: '^BSESN', companyname: 'BSE 30 (Bombay)'},
    {stockname: '^CAOSU', companyname: 'Cao Su'},
    {stockname: '^CBTS', companyname: 'Chế biến Thủy sản'},
    {stockname: '^CCSI', companyname: 'EGYPT CMA GENL INDX (Egypt)'},
    {stockname: '^CK', companyname: 'Chứng khoán'},
    {stockname: '^CNTT', companyname: 'Công nghệ và thông tin'},
    {stockname: '^CONGNGHE', companyname: 'Công Nghệ Viễn Thông'},
    {stockname: '^CSSK', companyname: 'Chăm sóc sức khỏe'},
    {stockname: '^DAUKHI', companyname: 'Nhóm Dầu Khí'},
    {stockname: '^DICHVU', companyname: 'Dịch vụ - Du lịch'},
    {stockname: '^DJI', companyname: 'Dow Jones Industrial Average (DJI)'},
    {stockname: '^DNP', companyname: 'Hệ sinh thái DNP-Tasco'},
    {stockname: '^DTPT', companyname: 'Đầu tư phát triển'},
    {stockname: '^DTXD', companyname: 'Đầu tư xây dựng'},
    {stockname: '^DUOCPHAM', companyname: 'Dược Phẩm / Y Tế / Hóa Chất'},
    {stockname: '^DVLTAUGT', companyname: 'Dịch vụ lưu trú, ăn uống, giải trí'},
    {stockname: '^DVTVHT', companyname: 'Dịch vụ tư vấn, hỗ trợ'},
    {stockname: '^ETF', companyname: 'Quỹ ETF - chứng chỉ quỹ'},
    {stockname: '^FCHI', companyname: 'CAC 40 (Paris)'},
    {stockname: '^FLC', companyname: 'Hệ sinh thái FLC'},
    {stockname: '^FTSE', companyname: 'FTSE 100 (London)'},
    {stockname: '^GDAXI', companyname: 'DAX (Germany)'},
    {stockname: '^GELEX', companyname: 'Hệ sinh thái Gelex'},
    {stockname: '^GIAODUC', companyname: 'Giáo Dục'},
    {stockname: '^GOLD', companyname: 'Giá Vàng hế Giới'},
    {stockname: '^GSPC', companyname: 'S&P 500 INDEX,RTH (SNP)'},
    {stockname: '^HASTC', companyname: 'HNX'},
    {stockname: '^HK', companyname: 'Hàng không'},
    {stockname: '^HNX', companyname: 'Sở Giao Dịch Chứng Khoán Hà Nội'},
    {stockname: '^HNX30', companyname: 'HNX30'},
    {stockname: '^HSI', companyname: 'HANG SENG (HongKong)'},
    {stockname: '^IXIC', companyname: 'NASDAQ Composite (Nasdaq)'},
    {stockname: '^JKSE', companyname: 'Jakarta Composite (Jakarta)'},
    {stockname: '^KHOANGSAN', companyname: 'Khoáng Sản'},
    {stockname: '^KK', companyname: 'Khai khoáng'},
    {stockname: '^KLSE', companyname: 'KLSE Composite (Kulalumpuar)'},
    {stockname: '^KS11', companyname: 'KOSPI Composite Index (Korea)'},
    {stockname: '^LARGECAP', companyname: 'LARGE CAPITAL'},
    {stockname: '^LOUIS', companyname: 'Hệ sinh thái Louis'},
    {stockname: '^MIDCAP', companyname: 'MIDDLE CAPITAL'},
    {stockname: '^N225', companyname: 'NIKKEI 225 (Osaka)'},
    {stockname: '^NANGLUONG', companyname: 'Năng lượng Điện/Khí/'},
    {stockname: '^NGANHANG', companyname: 'Ngân hàng- Bảo hiểm'},
    {stockname: '^NH', companyname: 'Ngân hàng'},
    {stockname: '^NHUA', companyname: 'Nhựa - Bao Bì'},
    {stockname: '^NLN', companyname: 'Nông - Lâm - Ngư'},
    {stockname: '^NYA', companyname: 'NYSE COMPOSITE INDEX (NEW METHO)'},
    {stockname: '^NZ50', companyname: 'NZSE 50 (New Zealand)'},
    {stockname: '^OIL', companyname: 'Giá Dầu Thế Giới'},
    {stockname: '^OMXSPI', companyname: 'Stockholm General (Stockholm)'},
    {stockname: '^OSEAX', companyname: 'OSE All Share (Oslo)'},
    {stockname: '^PHANBON', companyname: 'Phân bón'},
    {stockname: '^SET.BK', companyname: 'Stock Exchange of Thailand SET Index'},
    {stockname: '^SMALLCAP', companyname: 'SMALL CAPITAL'},
    {stockname: '^SPCS', companyname: 'Sản phẩm cao su'},
    {stockname: '^SSMI', companyname: 'Swiss Market (Voter Turnout - Social )'},
    {stockname: '^STI', companyname: 'STI (Singapore)'},
    {stockname: '^SXHGD', companyname: 'SX Hàng gia dụng'},
    {stockname: '^SXKD', companyname: 'Sản Xuất - Kinh doanh'},
    {stockname: '^SXNHC', companyname: 'SX Nhựa - Hóa chất'},
    {stockname: '^SXPT', companyname: 'SX Phụ trợ'},
    {stockname: '^SXTBMM', companyname: 'SX Thiết bị, máy móc'},
    {stockname: '^TA100', companyname: 'TEL-AV TASE-100 IND (Israel: Tel Aviv)'},
    {stockname: '^TBD', companyname: 'Thiết bị điện'},
    {stockname: '^TCK', companyname: 'Tài chính khác'},
    {stockname: '^THEP', companyname: 'Ngành Thép'},
    {stockname: '^THUCPHAM', companyname: 'Thực Phẩm'},
    {stockname: '^THUONGMAI', companyname: 'Thương Mại'},
    {stockname: '^THUYSAN', companyname: 'Thủy Sản'},
    {stockname: '^TI', companyname: 'Tiện ích'},
    {stockname: '^TPDU', companyname: 'Thực phẩm - Đồ uống'},
    {stockname: '^TWII', companyname: 'Taiwan Weighted (Taiwan)'},
    {stockname: '^UPCOM', companyname: 'UPCOM'},
    {stockname: '^VANTAI', companyname: 'Vận Tải/ Cảng / Taxi'},
    {stockname: '^VLXD', companyname: 'Vật liệu xây dựng'},
    {stockname: '^VN30', companyname: 'VN30'},
    {stockname: '^VN30_HOSE', companyname: 'HOSE-VN30'},
    {stockname: '^VNINDEX', companyname: 'VNINDEX'},
    {stockname: '^VTKB', companyname: 'Vận tải - kho bãi'},
    {stockname: '^XAYDUNG', companyname: 'Xây Dựng'},
    {stockname: '^XD', companyname: 'Xây dựng'}]
  }