
import { ICellRendererParams } from 'ag-grid-community';

export class ImageChart {
    private eGui!: HTMLElement;
    init(params: ICellRendererParams) {
      const element = document.createElement('div');
      element.style.position = 'relative';
      element.style.zIndex = '10';
      const imageElement = document.createElement('img');
      var date = new Date();
      let ticker = params.data.Ticker;
      imageElement.src = `https://www.cophieu68.vn/chartold/imagechart/${ticker?.toLowerCase()}.png`
      imageElement.style.width = '420px'
      imageElement.style.height = '250px'
      imageElement.style.padding = '5px'
      imageElement.style.borderRadius = '5px'
      imageElement.style.backgroundColor = '#454545'


      
      
  
      element.appendChild(imageElement);


      this.eGui = element;

    }
    getGui() {
      return this.eGui;
    }
  }