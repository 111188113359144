import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5stock from '@amcharts/amcharts5/stock'
import { useEffect, Component} from "react";
import React from "react";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { AppBar, Toolbar, Typography } from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

class MKStructureDistBySector extends Component {

    constructor(props){
        super(props); //
        this.state = {
          PctUp: 0,
          PctConst: 0,
          PctDn: 0,
          Up: 0,
          Const: 0,
          Dn: 0
        }
      }
    

    componentDidMount(){
        
    let root = am5.Root.new(this.props.id);

       





    root._logo.dispose();


    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
    am5themes_Dark.new(root)
    ]);

    
// Create chart
// https://www.amcharts.com/docs/v5/charts/xy-chart/
let chart = root.container.children.push(am5xy.XYChart.new(root, {
    panX: false,
    panY: false,
    paddingLeft: 0,
    wheelX: "panX",
    wheelY: "zoomX",
    layout: root.verticalLayout
  }));
  
  
  // Add legend
  // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
  let legend = chart.children.push(
    am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    })
  );

  
  
      

  

  
  
  // Create axes
  // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  let xRenderer = am5xy.AxisRendererX.new(root, {
    cellStartLocation: 0.1,
    cellEndLocation: 0.9,
    minorGridEnabled: true
  })
  
  let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
    categoryField: "year",
    renderer: xRenderer,
    tooltip: am5.Tooltip.new(root, {})
  }));
  
  xRenderer.grid.template.setAll({
    location: 1
  })
  
  
  let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
    renderer: am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    })
  }));
  
  
  
  
  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  chart.appear(1000, 100);

  chart.get("colors").set("colors", [
    am5.color('#00CED1'),
    am5.color('#8B0000'),
    am5.color('#DC143C'),
    am5.color('#D2691E'),
    am5.color('#DAA520'),
    am5.color('#2E8B57'),
    am5.color('#228B22'),
    am5.color('#006400'),
    am5.color('#800080')
]);

  var dataOri = this?.props?.data

  function data_MatrixFutureForceB(i2) {
    function getSectorDataB(SectorName, i) {
      var SectorScore = 
                      
                          (dataOri?.filter(x => x.Sector===SectorName)[0]?.LastDaysData?.Maker[i])
                      return SectorScore
    }
    return [
      
          {name: "BDS", sectorScore: getSectorDataB("BDS", i2)},
          {name: "BANLE", sectorScore: getSectorDataB("BAN LE", i2)},
          {name: "BAOHIEM", sectorScore: getSectorDataB("BAO HIEM", i2)},
          {name: "CHUNGKHOAN", sectorScore: getSectorDataB("CHUNG KHOAN", i2)},
          {name: "CONGNGHE", sectorScore: getSectorDataB("CONG NGHE", i2)},
          {name: "DETMAY", sectorScore: getSectorDataB("DET MAY", i2)},
          {name: "DIEN", sectorScore: getSectorDataB("DIEN", i2)},
          {name: "DUOCPHAM", sectorScore: getSectorDataB("DUOC PHAM", i2)},
          {name: "DAU", sectorScore: getSectorDataB("DAU KHI", i2)},
          {name: "DUONG", sectorScore: getSectorDataB("DUONG", i2)},
          {name: "H_TIEUDUNG"},
          {name: "HOACHAT", sectorScore: getSectorDataB("PHAN BON", i2)},
          {name: "KKTHAN", sectorScore: getSectorDataB("THAN", i2)},
          {name: "NGANHANG", sectorScore: getSectorDataB("NGAN HANG", i2)},
          {name: "NHUA", sectorScore: getSectorDataB("NHUA", i2)},
          {name: "NONGSAN", sectorScore: getSectorDataB("NONG SAN", i2)},
          {name: "THEP", sectorScore:getSectorDataB("THEP", i2)},
          {name: "THUCPHAM", sectorScore:getSectorDataB("THUC PHAM", i2)},
          {name: "THUYSAN", sectorScore: getSectorDataB("THUY SAN", i2)},
          {name: "VANTAI", sectorScore: getSectorDataB("VAN TAI BIEN", i2)},
          {name: "VLXD", sectorScore: getSectorDataB("VLXD", i2)},
          {name: "XAYDUNG", sectorScore: getSectorDataB("XAY DUNG", i2)},
          {name: "BDSKCN", sectorScore: getSectorDataB("BKCN", i2)},
          {name: "DTC", sectorScore: getSectorDataB("DTC", i2)},
          {name: "PENNY*", sectorScore: getSectorDataB("P_", i2)},
          {name: "MIDCAP*", sectorScore: getSectorDataB("M_", i2)},
          {name: "BLUECHIP*", sectorScore: getSectorDataB("B_", i2)},
          {name: "INDEX**", sectorScore: getSectorDataB("Index", i2)},
          {name: "FUTURE**", sectorScore: getSectorDataB("Future", i2)},
    ]
  }
  
  let data_ = []

  let data = [
  //   {
  //   "year": "T14",
  //   "DANGER": data_MatrixFutureForceB(25)?.filter(x => x.sectorScore<=-8)?.length+1,
  //   "SUPERWEAK": data_MatrixFutureForceB(25)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
  //   "WEAK": data_MatrixFutureForceB(25)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
  //   "MODERATE": data_MatrixFutureForceB(25)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
  //   "NEUTRAL": data_MatrixFutureForceB(25)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
  //   "FAIR": data_MatrixFutureForceB(25)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
  //   "STRONG": data_MatrixFutureForceB(25)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
  //   "SUPERSTRONG": data_MatrixFutureForceB(25)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
  //   "LEADING": data_MatrixFutureForceB(25)?.filter(x => x.sectorScore>=8)?.length+1
  // },{
  //   "year": "T13",
  //   "DANGER": data_MatrixFutureForceB(26)?.filter(x => x.sectorScore<=-8)?.length+1,
  //   "SUPERWEAK": data_MatrixFutureForceB(26)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
  //   "WEAK": data_MatrixFutureForceB(26)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
  //   "MODERATE": data_MatrixFutureForceB(26)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
  //   "NEUTRAL": data_MatrixFutureForceB(26)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
  //   "FAIR": data_MatrixFutureForceB(26)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
  //   "STRONG": data_MatrixFutureForceB(26)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
  //   "SUPERSTRONG": data_MatrixFutureForceB(26)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
  //   "LEADING": data_MatrixFutureForceB(26)?.filter(x => x.sectorScore>=8)?.length+1
  // },{
  //   "year": "T12",
  //   "DANGER": data_MatrixFutureForceB(27)?.filter(x => x.sectorScore<=-8)?.length+1,
  //   "SUPERWEAK": data_MatrixFutureForceB(27)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
  //   "WEAK": data_MatrixFutureForceB(27)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
  //   "MODERATE": data_MatrixFutureForceB(27)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
  //   "NEUTRAL": data_MatrixFutureForceB(27)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
  //   "FAIR": data_MatrixFutureForceB(27)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
  //   "STRONG": data_MatrixFutureForceB(27)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
  //   "SUPERSTRONG": data_MatrixFutureForceB(27)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
  //   "LEADING": data_MatrixFutureForceB(27)?.filter(x => x.sectorScore>=8)?.length+1
  // },{
  //   "year": "T11",
  //   "DANGER": data_MatrixFutureForceB(28)?.filter(x => x.sectorScore<=-8)?.length+1,
  //   "SUPERWEAK": data_MatrixFutureForceB(28)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
  //   "WEAK": data_MatrixFutureForceB(28)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
  //   "MODERATE": data_MatrixFutureForceB(28)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
  //   "NEUTRAL": data_MatrixFutureForceB(28)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
  //   "FAIR": data_MatrixFutureForceB(28)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
  //   "STRONG": data_MatrixFutureForceB(28)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
  //   "SUPERSTRONG": data_MatrixFutureForceB(28)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
  //   "LEADING": data_MatrixFutureForceB(28)?.filter(x => x.sectorScore>=8)?.length+1
  // },
  // {
  //   "year": "T10",
  //   "DANGER": data_MatrixFutureForceB(29)?.filter(x => x.sectorScore<=-8)?.length+1,
  //   "SUPERWEAK": data_MatrixFutureForceB(29)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
  //   "WEAK": data_MatrixFutureForceB(29)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
  //   "MODERATE": data_MatrixFutureForceB(29)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
  //   "NEUTRAL": data_MatrixFutureForceB(29)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
  //   "FAIR": data_MatrixFutureForceB(29)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
  //   "STRONG": data_MatrixFutureForceB(29)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
  //   "SUPERSTRONG": data_MatrixFutureForceB(29)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
  //   "LEADING": data_MatrixFutureForceB(29)?.filter(x => x.sectorScore>=8)?.length+1
  // },
  {
    "year": "T9",
    "DANGER": data_MatrixFutureForceB(30)?.filter(x => x.sectorScore<=-8)?.length+1,
    "SUPERWEAK": data_MatrixFutureForceB(30)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
    "WEAK": data_MatrixFutureForceB(30)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
    "MODERATE": data_MatrixFutureForceB(30)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
    "NEUTRAL": data_MatrixFutureForceB(30)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
    "FAIR": data_MatrixFutureForceB(30)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
    "STRONG": data_MatrixFutureForceB(30)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
    "SUPERSTRONG": data_MatrixFutureForceB(30)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
    "LEADING": data_MatrixFutureForceB(30)?.filter(x => x.sectorScore>=8)?.length+1
  },
  {
    "year": "T8",
    "DANGER": data_MatrixFutureForceB(31)?.filter(x => x.sectorScore<=-8)?.length+1,
    "SUPERWEAK": data_MatrixFutureForceB(31)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
    "WEAK": data_MatrixFutureForceB(31)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
    "MODERATE": data_MatrixFutureForceB(31)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
    "NEUTRAL": data_MatrixFutureForceB(31)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
    "FAIR": data_MatrixFutureForceB(31)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
    "STRONG": data_MatrixFutureForceB(31)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
    "SUPERSTRONG": data_MatrixFutureForceB(31)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
    "LEADING": data_MatrixFutureForceB(31)?.filter(x => x.sectorScore>=8)?.length+1
  },{
    "year": "T7",
    "DANGER": data_MatrixFutureForceB(32)?.filter(x => x.sectorScore<=-8)?.length+1,
    "SUPERWEAK": data_MatrixFutureForceB(32)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
    "WEAK": data_MatrixFutureForceB(32)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
    "MODERATE": data_MatrixFutureForceB(32)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
    "NEUTRAL": data_MatrixFutureForceB(32)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
    "FAIR": data_MatrixFutureForceB(32)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
    "STRONG": data_MatrixFutureForceB(32)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
    "SUPERSTRONG": data_MatrixFutureForceB(32)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
    "LEADING": data_MatrixFutureForceB(32)?.filter(x => x.sectorScore>=8)?.length+1
  },{
    "year": "T6",
    "DANGER": data_MatrixFutureForceB(33)?.filter(x => x.sectorScore<=-8)?.length+1,
    "SUPERWEAK": data_MatrixFutureForceB(33)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
    "WEAK": data_MatrixFutureForceB(33)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
    "MODERATE": data_MatrixFutureForceB(33)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
    "NEUTRAL": data_MatrixFutureForceB(33)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
    "FAIR": data_MatrixFutureForceB(33)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
    "STRONG": data_MatrixFutureForceB(33)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
    "SUPERSTRONG": data_MatrixFutureForceB(33)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
    "LEADING": data_MatrixFutureForceB(33)?.filter(x => x.sectorScore>=8)?.length+1
  },{
    "year": "T5",
    "DANGER": data_MatrixFutureForceB(34)?.filter(x => x.sectorScore<=-8)?.length+1,
    "SUPERWEAK": data_MatrixFutureForceB(34)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
    "WEAK": data_MatrixFutureForceB(34)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
    "MODERATE": data_MatrixFutureForceB(34)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
    "NEUTRAL": data_MatrixFutureForceB(34)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
    "FAIR": data_MatrixFutureForceB(34)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
    "STRONG": data_MatrixFutureForceB(34)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
    "SUPERSTRONG": data_MatrixFutureForceB(34)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
    "LEADING": data_MatrixFutureForceB(34)?.filter(x => x.sectorScore>=8)?.length+1
  },{
    "year": "T4",
    "DANGER": data_MatrixFutureForceB(35)?.filter(x => x.sectorScore<=-8)?.length+1,
    "SUPERWEAK": data_MatrixFutureForceB(35)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
    "WEAK": data_MatrixFutureForceB(35)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
    "MODERATE": data_MatrixFutureForceB(35)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
    "NEUTRAL": data_MatrixFutureForceB(35)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
    "FAIR": data_MatrixFutureForceB(35)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
    "STRONG": data_MatrixFutureForceB(35)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
    "SUPERSTRONG": data_MatrixFutureForceB(35)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
    "LEADING": data_MatrixFutureForceB(35)?.filter(x => x.sectorScore>=8)?.length+1
  },{
    "year": "T3",
    "DANGER": data_MatrixFutureForceB(36)?.filter(x => x.sectorScore<=-8)?.length+1,
    "SUPERWEAK": data_MatrixFutureForceB(36)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
    "WEAK": data_MatrixFutureForceB(36)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
    "MODERATE": data_MatrixFutureForceB(36)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
    "NEUTRAL": data_MatrixFutureForceB(36)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
    "FAIR": data_MatrixFutureForceB(36)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
    "STRONG": data_MatrixFutureForceB(36)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
    "SUPERSTRONG": data_MatrixFutureForceB(36)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
    "LEADING": data_MatrixFutureForceB(36)?.filter(x => x.sectorScore>=8)?.length+1
  },{
    "year": "T2",
    "DANGER": data_MatrixFutureForceB(37)?.filter(x => x.sectorScore<=-8)?.length+1,
    "SUPERWEAK": data_MatrixFutureForceB(37)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
    "WEAK": data_MatrixFutureForceB(37)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
    "MODERATE": data_MatrixFutureForceB(37)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
    "NEUTRAL": data_MatrixFutureForceB(37)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
    "FAIR": data_MatrixFutureForceB(37)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
    "STRONG": data_MatrixFutureForceB(37)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
    "SUPERSTRONG": data_MatrixFutureForceB(37)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
    "LEADING": data_MatrixFutureForceB(37)?.filter(x => x.sectorScore>=8)?.length+1
  },{
    "year": "T1",
    "DANGER": data_MatrixFutureForceB(38)?.filter(x => x.sectorScore<=-8)?.length+1,
    "SUPERWEAK": data_MatrixFutureForceB(38)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
    "WEAK": data_MatrixFutureForceB(38)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
    "MODERATE": data_MatrixFutureForceB(38)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
    "NEUTRAL": data_MatrixFutureForceB(38)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
    "FAIR": data_MatrixFutureForceB(38)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
    "STRONG": data_MatrixFutureForceB(38)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
    "SUPERSTRONG": data_MatrixFutureForceB(38)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
    "LEADING": data_MatrixFutureForceB(38)?.filter(x => x.sectorScore>=8)?.length+1
  }, {
    "year": "T0(TODAY)",
    "DANGER": data_MatrixFutureForceB(39)?.filter(x => x.sectorScore<=-8)?.length+1,
    "SUPERWEAK": data_MatrixFutureForceB(39)?.filter(x => x.sectorScore<=-4 && x.sectorScore>-8)?.length+1,
    "WEAK": data_MatrixFutureForceB(39)?.filter(x => x.sectorScore<=-2 && x.sectorScore>-4)?.length+1,
    "MODERATE": data_MatrixFutureForceB(39)?.filter(x => x.sectorScore<-0.5 && x.sectorScore>-2)?.length+1,
    "NEUTRAL": data_MatrixFutureForceB(39)?.filter(x => x.sectorScore<=0.5 && x.sectorScore>=-0.5)?.length+1,
    "FAIR": data_MatrixFutureForceB(39)?.filter(x => x.sectorScore<2 && x.sectorScore>0.5)?.length+1,
    "STRONG": data_MatrixFutureForceB(39)?.filter(x => x.sectorScore<4 && x.sectorScore>=2)?.length+1,
    "SUPERSTRONG": data_MatrixFutureForceB(39)?.filter(x => x.sectorScore<8 && x.sectorScore>=4)?.length+1,
    "LEADING": data_MatrixFutureForceB(39)?.filter(x => x.sectorScore>=8)?.length+1
  }]


  // Add series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  function makeSeries(name, fieldName,) {
    let series = chart?.series?.push(am5xy.ColumnSeries.new(root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: fieldName,
      categoryXField: "year"
    }));
  
    series?.columns?.template?.setAll({
      tooltipText: "{name}, {categoryX}:{valueY}",
      width: am5.percent(90),
      tooltipY: 0,
      strokeOpacity: 0
    });
  
  
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series?.appear();
  
    series?.bullets?.push(function () {
      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: am5.Label.new(root, {
          text: "{valueY}",
          fill: root.interfaceColors.get("alternativeText"),
          centerY: 0,
          centerX: am5.p50,
          populateText: true
        })
      });
    });
    
    series?.data?.setAll(data);
  
    legend?.data?.push(series);
    xAxis?.data?.setAll(data);
  }
    makeSeries("DANGER", "DANGER");
    makeSeries("SUPERWEAK", "SUPERWEAK");
    makeSeries("WEAK", "WEAK");
    makeSeries("MODERATE", "MODERATE");
    makeSeries("NEUTRAL", "NEUTRAL");
    makeSeries("FAIR", "FAIR");
    makeSeries("STRONG", "STRONG");
    makeSeries("SUPERSTRONG", "SUPERSTRONG");
    makeSeries("LEADING", "LEADING");
    

  
        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
    }


    
        
    


    render() {
      
  

      

        return <div id={this.props.id} style={{height: 400, width: '100%',}}>
                </div>
        ;
    }

    


}

export default MKStructureDistBySector;



