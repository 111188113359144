
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { useAutocomplete } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import CompanyNameList from '../data/CompanyNameList.js';

//Search TickerVN


const Label = styled('label')`
    padding: 0 0 0px;
    line-height: 2;
    display: block;
    color: #40a9ff;
    font-size: 25px;
`;

const InputWrapper = styled('div')(
    ({ theme }) => `
    border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
    background-color: #202020
    border-radius: 4px;
    padding: 3px;
    display: flex;
    flex-wrap: wrap;
    height: 180px;
    width: 400px;
    &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    }

    &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    & input {
    background-color: #303030
    color: #202020
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 5px;
    outline: 0;
    font-size: 11px;
    }
`,
);

function Tag(props) {
    const { label, onDelete, ...other } = props;
    return (
    <div {...other}>
        <span>{label}</span>
        <CloseIcon style = {{fontSize: '11px'}} onClick={onDelete} />
    </div>
    );
}

Tag.propTypes = {
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
    ({ theme }) => `
    display: flex;
    align-items: center;
    height: 30px;
    margin: 5px;
    line-height: 22px;
    font-size: 11px;
    background-color: #404040
    border: #909090
    border-radius: 3px;
    box-sizing: content-box;
    padding: 0 4px 0 10px;
    outline: 0;
    overflow: hidden;

    &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    font-size: 11px;
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    }

    & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 11px;
    
    }

    & svg {
    font-size: 11px;
    cursor: pointer;
    padding: 4px;
    }
`,
);

const Listbox = styled('ul')(
    ({ theme }) => `
    width: 300px;
    height: 600px;
    margin: 2px 0 0;
    padding: 0px;
    position: absolute;
    list-style: none;
    background-color: #303030;
    overflow: auto;
    max-height: 250px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(255, 0, 0, 0.15);
    z-index: 1;

    & li {
    padding: 5px 11px;
    display: flex;

    & span {
        flex-grow: 1;
    }

    & svg {
        color: transparent;
    }
    }

    & li[aria-selected='true'] {
    background-color: #2b2b2b ;
    font-weight: 600;

    & svg {
        color: #1890ff;
    }
    }

    & li.${autocompleteClasses.focused} {
    background-color:  #003b57 
    cursor: pointer;

    & svg {
        color: currentColor;
    }
    }
`,
);

export const TickerVNSearch = (params) => {

    const STOCK_DATA = params.data || {}
    const [isFinished, setIsFinished] = useState(true)


    
    const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
    } = useAutocomplete({
    id: 'customized-hook-demo',
    multiple: true,
    options: params.isSuccess&&params.data?Object(STOCK_DATA||{})?.filter((x) => {return x.Ticker.length===3})?.sort((a, b) => {
        if(Number(a.Volume) < Number(b.Volume)) { return 1; }
        if(Number(a.Volume) > Number(b.Volume)) { return -1; }
        return 0;
    }):[],
    getOptionLabel: (option) => option?.Ticker,
    });

    useEffect(() => {
        params.setSearchAll(prevState => 
            ({...prevState, Ticker: value})
        )
        setIsFinished(false)

    }, [value])

    function getColor(params){
        if(params>6){
            return 'violet'
        }
        if(params<-6){
            return 'aqua'
        }
        if(params>0){
            return 'limegreen'
        }
        
        else if(params==0){
            return 'gold'
        }
        else if(params<0){
            return 'crimson'
        }
    }


    function getColor2(params){
        if(params>1){
            return 'limegreen'
        }
        if(params<-1){
            return 'crimson'
        }
        else{
            return 'goldenrod'
        }
    }




    return (
        
    <div style={{marginLeft: '3%',flexDirection: 'column', minWidth: 150, maxWidth: 360}}>
        <div {...getRootProps()} style = {{  width: '100%', fontSize: 20}} >
            <Label {...getInputLabelProps()} style = {{fontWeight: 'bold', fontSize: '20px', textDecoration: 'underline'}}>Nhập tên mã Cổ Phiếu</Label>
            <div style = {{display: 'flex', flexDirection: 'row'}}>
                <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''} style = {{flex: 1, border: '3px solid royalblue', backgroundcolor: 'black'}}>
                    {value.map((option, index) => (
                    <>
                        <StyledTag style = {{fontSize: '25px'}} label={option.Ticker} {...getTagProps({ index })} />
                    </>    
                    ))}
                    <input 
                        placeholder='Điền Tên Mã Cổ Phiếu.....' 
                        {...getInputProps()}
                        style={{backgroundColor: '#303030'}}
                    />
                </InputWrapper>
            </div>
        </div>

        <div>{groupedOptions.length > 0 ? (
            <div style = {{marginLeft: '32px', backgroundColor: 'black'}}>
                <Listbox id = 'ListBox' {...getListboxProps()}>
                    {groupedOptions.map((option, index) => (
                        <li {...getOptionProps({ option, index })}>
                                {option.imagelogo?<img style = {{width: '50px', height: '25px', marginTop: 12}}src = {option.imagelogo }></img>:<div>-</div>}
                            <div style={{alignText: 'center', display: 'flex', flexDirection: 'column', color: getColor(option['Change(%)'])}}>
                                <div style = {{fontSize: '20px', fontWeight: 'bold', width: 50, fontSize: 20, alignText: 'right', marginLeft: '15px'}}>{option.Ticker||"N/A"}</div>
                                <div style={{marginLeft: '15px', fontSize: 10}}>
                                    ({option['Change(%)']}%)
                                </div>
                                <div style={{marginLeft: '15px', fontSize: 10}}>
                                    {String(Number(option.Volume).toLocaleString()).replaceAll('.',',')}
                                </div>
                            </div>
{/*                                 
                                <div style = {{marginRight: '6%', alignText:'center', fontWeight: 'bold', fontSize: '12px', color: getColor(option['Change(%)']), width: 60}}>{option['Change(%)']+'%'}</div>
                                <div style = {{marginRight: '6%', alignText:'right', fontSize: '10px'}}>{option.Volume}</div> */}
                                <div style = {{marginLeft: 15, alignText:'center', fontSize: '9px', width: 100, marginTop: 5}}>
                                    {Object(CompanyNameList())?.filter((x) => {return x?.stockname===option?.Ticker})?.[0]?.companyname||<div style={{color: 'darkgrey'}}>N/A</div>
                                    }
                                </div>
                                
                            <div style={{width: 70, alignText: 'center', marginTop: 5}}>
                                
                                <div style={{marginLeft: '15px', width: 35, fontSize: 10, color: getColor2(Number(option?.SB3SC0A))}}>
                                    S: {Math.ceil(Number(option?.SB3SC0A))}
                                </div>
                                <div style={{marginLeft: '15px', width: 35, fontSize: 10, color: getColor2(Number(option?.SB3SH0))}}>
                                    SL: {Math.ceil(Number(option?.SB3SH0))}
                                </div>
                                <div style={{marginLeft: '15px', width: 35, fontSize: 10, color: getColor2(Number(option?.SB3MK0A))}}>
                                    M: {Math.ceil(Number(option?.SB3MK0A))}
                                </div>
                            </div>
                                
                            <CheckIcon fontSize="medium" />
                        </li>
                    ))}
                </Listbox>
            </div>
        
        ) : null}</div>
    </div>
    );
}


