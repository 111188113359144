import { useState, useEffect } from "react";
import { useGetDividendQuery } from "../api/apiSlice.matrix";
import dayjs from "dayjs";
import './MatrixBoard.matrix.css'




export function StatBoard(params){

  function getCol0(V1,V0){
    if(V0>=0 & V1<0) return 'royalblue'
    if(V0<0 && V1>=0) return 'crimson'
    if(V0>=0 && V1>=0) return 'darkgreen'
    if(V0<0 && V1<0) return 'darkred'
    else return '#202020'
  }

  function getCol1(V0){
    if(V0>=2) return 'royalblue'
    if(V0>=1.2) return 'darkgreen'
    if(V0>=0.95) return 'darkgoldenrod'
    if(V0<0.95) return 'darkred'
    else return '#202020'
  }

  function getColD(V0){
    if(V0>=1) return 'lime'
    if(V0<1 && V0>-1) return 'gold'
    if(V0<=-1) return '#FF4500'
    else return '#202020'
  }

  
  function getColChg(V0){
    if(V0>=6) return '#663399'
    if(V0>=0) return 'darkgreen'
    if(V0>=0.95) return 'darkgoldenrod'
    if(V0<0.95) return 'darkred'
    else return '#202020'
  }

  
  function getColDeltaMoney(V0){
    if(V0>=100000) return '#663399'
    if(V0>=10000) return 'darkgreen'
    if(V0>0) return 'darkgoldenrod'
    if(V0<0) return 'darkred'
    else return '#202020'
  }

  
  function getColForeign(V0){
    if(V0>=1e10) return '#663399'
    if(V0>=1e9) return 'darkgreen'
    if(V0>0) return 'darkgoldenrod'
    if(V0<0) return 'darkred'
    else return '#202020'
  }

  function d(v0,v1){
    return (v0-v1)>=0?'+'+Number(v0-v1)?.toFixed(0):Number(v0-v1)?.toFixed(0)
  }

  function LNumFormatter(x){
    if(Math.abs(x)>=1e12){
      return String(Number((x/1e12)?.toFixed(0)))
    }
    else if(Math.abs(x)>=1e11 && Math.abs(x)<1e12){
      return String(Number((x/1e9)?.toFixed(0)))
    }
    else if(Math.abs(x)>=1e10 && Math.abs(x)<1e11){
      return String(Number((x/1e9)?.toFixed(0)))
    }
    else if(Math.abs(x)>=1e9 && Math.abs(x)<1e10){
      return String(Number((x/1e9)?.toFixed(0)))
    }
    else if(Math.abs(x)<1e9){
      return String('0')
    }
    else {
      return String(0)
    }
  }
  function getTPlus(v){
    if(String(v)?.includes('GREEN')) return 'G'
    if(String(v)?.includes('YELLOW')) return 'Y'
    if(String(v)?.includes('RED')) return 'R'
  }

  function getTPlus2(v){
    if(String(v)?.includes('CL:G')) return 'G'
    if(String(v)?.includes('CL:Y')) return 'Y'
    if(String(v)?.includes('CL:R')) return 'R'
  }

  function getTPlusCol(v,v1){
    if(!String(v1)?.includes('G') && String(v)?.includes('G')) return 'royalblue'
    if(!String(v1)?.includes('R') && String(v)?.includes('R')) return 'crimson'
    if(String(v)?.includes('G')) return 'darkgreen'
    if(String(v)?.includes('Y')) return 'darkgoldenrod'
    if(String(v)?.includes('R')) return 'darkred'
  }

  
  const [foreignData, setForeignData] = useState([])

  useEffect(() => {
    fetch(`https://finfo-api.vndirect.com.vn/v4/foreigns?sort=tradingDate&q=code:${params.data.Ticker}&size=50&page=1`)
    .then(res => res.json())
    .then((data) => {
      setForeignData(data?.data)
    })
  }, [])

  



  return <table style={{maxWidth: '300px', border: '1px solid white', borderCollapse: 'collapse', fontSize: '9.5px', lineHeight: 0.25}}>
    <tr>
      <th>Params</th>
      <th>15</th>
      <th>14</th>
      <th>13</th>
      <th>12</th>
      <th>11</th>
      <th>10</th>
      <th>09</th>
      <th>08</th>
      <th>07</th>
      <th>06</th>
      <th>05</th>
      <th>04</th>
      <th>03</th>
      <th>02</th>
      <th>01</th>
      <th>00</th>
    </tr>
    <tr style={{fontWeight: 600}}>
      <td>SCORE</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC16,params.data.SB3SC15)}}>{params.data.SB3SC15}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC15,params.data.SB3SC14)}}>{params.data.SB3SC14}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC14,params.data.SB3SC13)}}>{params.data.SB3SC13}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC13,params.data.SB3SC12)}}>{params.data.SB3SC12}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC12,params.data.SB3SC11)}}>{params.data.SB3SC11}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC11,params.data.SB3SC10)}}>{params.data.SB3SC10}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC10,params.data.SB3SC9)}}>{params.data.SB3SC9}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC9,params.data.SB3SC8)}}>{params.data.SB3SC8}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC8,params.data.SB3SC7)}}>{params.data.SB3SC7}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC7,params.data.SB3SC6)}}>{params.data.SB3SC6}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC6, params.data.SB3SC5)}}>{params.data.SB3SC5}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC5,params.data.SB3SC4)}}>{params.data.SB3SC4}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC4,params.data.SB3SC3)}}>{params.data.SB3SC3}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC3,params.data.SB3SC2)}}>{params.data.SB3SC2}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC2,params.data.SB3SC1)}}>{params.data.SB3SC1}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SC1,params.data.SB3SC0)}}>{params.data.SB3SC0}</td>
    </tr>
    <tr style={{backgroundColor: '#101010'}}>
      <td>DeltaS</td>
      <td style={{color: getColD(params.data.SB3SC15-params.data.SB3SC16)}}>{d(params.data.SB3SC15,params.data.SB3SC16)}</td>
      <td style={{color: getColD(params.data.SB3SC14-params.data.SB3SC15)}}>{d(params.data.SB3SC14,params.data.SB3SC15)}</td>
      <td style={{color: getColD(params.data.SB3SC13-params.data.SB3SC14)}}>{d(params.data.SB3SC13,params.data.SB3SC14)}</td>
      <td style={{color: getColD(params.data.SB3SC12-params.data.SB3SC13)}}>{d(params.data.SB3SC12,params.data.SB3SC13)}</td>
      <td style={{color: getColD(params.data.SB3SC11-params.data.SB3SC12)}}>{d(params.data.SB3SC11,params.data.SB3SC12)}</td>
      <td style={{color: getColD(params.data.SB3SC10-params.data.SB3SC11)}}>{d(params.data.SB3SC10,params.data.SB3SC11)}</td>
      <td style={{color: getColD(params.data.SB3SC9-params.data.SB3SC10)}}>{d(params.data.SB3SC9,params.data.SB3SC10)}</td>
      <td style={{color: getColD(params.data.SB3SC8-params.data.SB3SC9)}}>{d(params.data.SB3SC8,params.data.SB3SC9)}</td>
      <td style={{color: getColD(params.data.SB3SC7-params.data.SB3SC8)}}>{d(params.data.SB3SC7,params.data.SB3SC8)}</td>
      <td style={{color: getColD(params.data.SB3SC6-params.data.SB3SC7)}}>{d(params.data.SB3SC6,params.data.SB3SC7)}</td>
      <td style={{color: getColD(params.data.SB3SC5-params.data.SB3SC6)}}>{d(params.data.SB3SC5,params.data.SB3SC6)}</td>
      <td style={{color: getColD(params.data.SB3SC4-params.data.SB3SC5)}}>{d(params.data.SB3SC4,params.data.SB3SC5)}</td>
      <td style={{color: getColD(params.data.SB3SC3-params.data.SB3SC4)}}>{d(params.data.SB3SC3,params.data.SB3SC4)}</td>
      <td style={{color: getColD(params.data.SB3SC2-params.data.SB3SC3)}}>{d(params.data.SB3SC2,params.data.SB3SC3)}</td>
      <td style={{color: getColD(params.data.SB3SC1-params.data.SB3SC2)}}>{d(params.data.SB3SC1,params.data.SB3SC2)}</td>
      <td style={{color: getColD(params.data.SB3SC0-params.data.SB3SC1)}}>{d(params.data.SB3SC0,params.data.SB3SC1)}</td>
    </tr>
    
    
    <tr style={{fontWeight: 600}}>
      <td>MAKER</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK16,params.data.SB3MK15)}}>{params.data.SB3MK15}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK15,params.data.SB3MK14)}}>{params.data.SB3MK14}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK14,params.data.SB3MK13)}}>{params.data.SB3MK13}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK13,params.data.SB3MK12)}}>{params.data.SB3MK12}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK12,params.data.SB3MK11)}}>{params.data.SB3MK11}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK11,params.data.SB3MK10)}}>{params.data.SB3MK10}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK10,params.data.SB3MK9)}}>{params.data.SB3MK9}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK9,params.data.SB3MK8)}}>{params.data.SB3MK8}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK8,params.data.SB3MK7)}}>{params.data.SB3MK7}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK7,params.data.SB3MK6)}}>{params.data.SB3MK6}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK6, params.data.SB3MK5)}}>{params.data.SB3MK5}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK5,params.data.SB3MK4)}}>{params.data.SB3MK4}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK4,params.data.SB3MK3)}}>{params.data.SB3MK3}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK3,params.data.SB3MK2)}}>{params.data.SB3MK2}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK2,params.data.SB3MK1)}}>{params.data.SB3MK1}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3MK1,params.data.SB3MK0)}}>{params.data.SB3MK0}</td>
    </tr>

    <tr style={{backgroundColor: '#101010'}}>
      <td>DeltaM</td>
      <td style={{color: getColD(params.data.SB3MK15-params.data.SB3MK16)}}>{d(params.data.SB3MK15,params.data.SB3MK16)}</td>
      <td style={{color: getColD(params.data.SB3MK14-params.data.SB3MK15)}}>{d(params.data.SB3MK14,params.data.SB3MK15)}</td>
      <td style={{color: getColD(params.data.SB3MK13-params.data.SB3MK14)}}>{d(params.data.SB3MK13,params.data.SB3MK14)}</td>
      <td style={{color: getColD(params.data.SB3MK12-params.data.SB3MK13)}}>{d(params.data.SB3MK12,params.data.SB3MK13)}</td>
      <td style={{color: getColD(params.data.SB3MK11-params.data.SB3MK12)}}>{d(params.data.SB3MK11,params.data.SB3MK12)}</td>
      <td style={{color: getColD(params.data.SB3MK10-params.data.SB3MK11)}}>{d(params.data.SB3MK10,params.data.SB3MK11)}</td>
      <td style={{color: getColD(params.data.SB3MK9-params.data.SB3MK10)}}>{d(params.data.SB3MK9,params.data.SB3MK10)}</td>
      <td style={{color: getColD(params.data.SB3MK8-params.data.SB3MK9)}}>{d(params.data.SB3MK8,params.data.SB3MK9)}</td>
      <td style={{color: getColD(params.data.SB3MK7-params.data.SB3MK8)}}>{d(params.data.SB3MK7,params.data.SB3MK8)}</td>
      <td style={{color: getColD(params.data.SB3MK6-params.data.SB3MK7)}}>{d(params.data.SB3MK6,params.data.SB3MK7)}</td>
      <td style={{color: getColD(params.data.SB3MK5-params.data.SB3MK6)}}>{d(params.data.SB3MK5,params.data.SB3MK6)}</td>
      <td style={{color: getColD(params.data.SB3MK4-params.data.SB3MK5)}}>{d(params.data.SB3MK4,params.data.SB3MK5)}</td>
      <td style={{color: getColD(params.data.SB3MK3-params.data.SB3MK4)}}>{d(params.data.SB3MK3,params.data.SB3MK4)}</td>
      <td style={{color: getColD(params.data.SB3MK2-params.data.SB3MK3)}}>{d(params.data.SB3MK2,params.data.SB3MK3)}</td>
      <td style={{color: getColD(params.data.SB3MK1-params.data.SB3MK2)}}>{d(params.data.SB3MK1,params.data.SB3MK2)}</td>
      <td style={{color: getColD(params.data.SB3MK0-params.data.SB3MK1)}}>{d(params.data.SB3MK0,params.data.SB3MK1)}</td>
    </tr>
    
    <tr style={{fontWeight: 600}}>
      <td>SLONG</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH16,params.data.SB3SH15)}}>{params.data.SB3SH15}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH15,params.data.SB3SH14)}}>{params.data.SB3SH14}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH14,params.data.SB3SH13)}}>{params.data.SB3SH13}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH13,params.data.SB3SH12)}}>{params.data.SB3SH12}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH12,params.data.SB3SH11)}}>{params.data.SB3SH11}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH11,params.data.SB3SH10)}}>{params.data.SB3SH10}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH10,params.data.SB3SH9)}}>{params.data.SB3SH9}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH9,params.data.SB3SH8)}}>{params.data.SB3SH8}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH8,params.data.SB3SH7)}}>{params.data.SB3SH7}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH7,params.data.SB3SH6)}}>{params.data.SB3SH6}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH6, params.data.SB3SH5)}}>{params.data.SB3SH5}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH5,params.data.SB3SH4)}}>{params.data.SB3SH4}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH4,params.data.SB3SH3)}}>{params.data.SB3SH3}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH3,params.data.SB3SH2)}}>{params.data.SB3SH2}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH2,params.data.SB3SH1)}}>{params.data.SB3SH1}</td>
      <td style={{backgroundColor: getCol0(params.data.SB3SH1,params.data.SB3SH0)}}>{params.data.SB3SH0}</td>
    </tr>
    
    <tr style={{backgroundColor: '#101010'}}>
      <td>DeltaL</td>
      <td style={{color: getColD(params.data.SB3SH15-params.data.SB3SH16)}}>{d(params.data.SB3SH15,params.data.SB3SH16)}</td>
      <td style={{color: getColD(params.data.SB3SH14-params.data.SB3SH15)}}>{d(params.data.SB3SH14,params.data.SB3SH15)}</td>
      <td style={{color: getColD(params.data.SB3SH13-params.data.SB3SH14)}}>{d(params.data.SB3SH13,params.data.SB3SH14)}</td>
      <td style={{color: getColD(params.data.SB3SH12-params.data.SB3SH13)}}>{d(params.data.SB3SH12,params.data.SB3SH13)}</td>
      <td style={{color: getColD(params.data.SB3SH11-params.data.SB3SH12)}}>{d(params.data.SB3SH11,params.data.SB3SH12)}</td>
      <td style={{color: getColD(params.data.SB3SH10-params.data.SB3SH11)}}>{d(params.data.SB3SH10,params.data.SB3SH11)}</td>
      <td style={{color: getColD(params.data.SB3SH9-params.data.SB3SH10)}}>{d(params.data.SB3SH9,params.data.SB3SH10)}</td>
      <td style={{color: getColD(params.data.SB3SH8-params.data.SB3SH9)}}>{d(params.data.SB3SH8,params.data.SB3SH9)}</td>
      <td style={{color: getColD(params.data.SB3SH7-params.data.SB3SH8)}}>{d(params.data.SB3SH7,params.data.SB3SH8)}</td>
      <td style={{color: getColD(params.data.SB3SH6-params.data.SB3SH7)}}>{d(params.data.SB3SH6,params.data.SB3SH7)}</td>
      <td style={{color: getColD(params.data.SB3SH5-params.data.SB3SH6)}}>{d(params.data.SB3SH5,params.data.SB3SH6)}</td>
      <td style={{color: getColD(params.data.SB3SH4-params.data.SB3SH5)}}>{d(params.data.SB3SH4,params.data.SB3SH5)}</td>
      <td style={{color: getColD(params.data.SB3SH3-params.data.SB3SH4)}}>{d(params.data.SB3SH3,params.data.SB3SH4)}</td>
      <td style={{color: getColD(params.data.SB3SH2-params.data.SB3SH3)}}>{d(params.data.SB3SH2,params.data.SB3SH3)}</td>
      <td style={{color: getColD(params.data.SB3SH1-params.data.SB3SH2)}}>{d(params.data.SB3SH1,params.data.SB3SH2)}</td>
      <td style={{color: getColD(params.data.SB3SH0-params.data.SB3SH1)}}>{d(params.data.SB3SH0,params.data.SB3SH1)}</td>
    </tr>
    <tr>
      <td>Status</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T15),getTPlus2(params.data.T16))}}>{getTPlus2(params.data.T15)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T14),getTPlus2(params.data.T15))}}>{getTPlus2(params.data.T14)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T13),getTPlus2(params.data.T14))}}>{getTPlus2(params.data.T13)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T12),getTPlus2(params.data.T13))}}>{getTPlus2(params.data.T12)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T11),getTPlus2(params.data.T12))}}>{getTPlus2(params.data.T11)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T10),getTPlus2(params.data.T11))}}>{getTPlus2(params.data.T10)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T9),getTPlus2(params.data.T10))}}>{getTPlus2(params.data.T9)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T8),getTPlus2(params.data.T9))}}>{getTPlus2(params.data.T8)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T7),getTPlus2(params.data.T8))}}>{getTPlus2(params.data.T7)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T6),getTPlus2(params.data.T7))}}>{getTPlus2(params.data.T6)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus2(params.data.T5),getTPlus2(params.data.T6))}}>{getTPlus2(params.data.T5)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus(params.data.T4),getTPlus2(params.data.T5))}}>{getTPlus(params.data.T4)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus(params.data.T3),getTPlus(params.data.T4))}}>{getTPlus(params.data.T3)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus(params.data.T2),getTPlus(params.data.T3))}}>{getTPlus(params.data.T2)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus(params.data.T1),getTPlus(params.data.T2))}}>{getTPlus(params.data.T1)}</td>
      <td style={{backgroundColor: getTPlusCol(getTPlus(params.data.T0),getTPlus(params.data.T1))}}>{getTPlus(params.data.T0)}</td>
    </tr>
    <tr style={{lineHeight: 1, backgroundColor: '#404040'}}>VolR</tr>
    <tr>
      <td>VolR5</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg515)}}>{Number(params.data.VolRAvg515)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg514)}}>{Number(params.data.VolRAvg514)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg513)}}>{Number(params.data.VolRAvg513)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg512)}}>{Number(params.data.VolRAvg512)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg511)}}>{Number(params.data.VolRAvg511)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg510)}}>{Number(params.data.VolRAvg510)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg59)}}>{Number(params.data.VolRAvg59)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg58)}}>{Number(params.data.VolRAvg58)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg57)}}>{Number(params.data.VolRAvg57)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg56)}}>{Number(params.data.VolRAvg56)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg55)}}>{Number(params.data.VolRAvg55)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg54)}}>{Number(params.data.VolRAvg54)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg53)}}>{Number(params.data.VolRAvg53)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg52)}}>{Number(params.data.VolRAvg52)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg51)}}>{Number(params.data.VolRAvg51)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg5)}}>{Number(params.data.VolRAvg5)?.toFixed(0)}</td>
    </tr>
    <tr>
      <td>VolR10</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg1015)}}>{Number(params.data.VolRAvg1015)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg1014)}}>{Number(params.data.VolRAvg1014)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg1013)}}>{Number(params.data.VolRAvg1013)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg1012)}}>{Number(params.data.VolRAvg1012)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg1011)}}>{Number(params.data.VolRAvg1011)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg1010)}}>{Number(params.data.VolRAvg1010)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg109)}}>{Number(params.data.VolRAvg109)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg108)}}>{Number(params.data.VolRAvg108)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg107)}}>{Number(params.data.VolRAvg107)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg106)}}>{Number(params.data.VolRAvg106)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1( params.data.VolRAvg105)}}>{Number(params.data.VolRAvg105)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg104)}}>{Number(params.data.VolRAvg104)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg103)}}>{Number(params.data.VolRAvg103)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg102)}}>{Number(params.data.VolRAvg102)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg101)}}>{Number(params.data.VolRAvg101)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg10)}}>{Number(params.data.VolRAvg10)?.toFixed(0)}</td>
    </tr>
    <tr>
      <td>VolR20</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg2015)}}>{Number(params.data.VolRAvg2015)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg2014)}}>{Number(params.data.VolRAvg2014)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg2013)}}>{Number(params.data.VolRAvg2013)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg2012)}}>{Number(params.data.VolRAvg2012)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg2011)}}>{Number(params.data.VolRAvg2011)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg2010)}}>{Number(params.data.VolRAvg2010)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg209)}}>{Number(params.data.VolRAvg209)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg208)}}>{Number(params.data.VolRAvg208)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg207)}}>{Number(params.data.VolRAvg207)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg206)}}>{Number(params.data.VolRAvg206)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg205)}}>{Number(params.data.VolRAvg205)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg204)}}>{Number(params.data.VolRAvg204)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg203)}}>{Number(params.data.VolRAvg203)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg202)}}>{Number(params.data.VolRAvg202)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg201)}}>{Number(params.data.VolRAvg201)?.toFixed(0)}</td>
      <td style={{backgroundColor: getCol1(params.data.VolRAvg20)}}>{Number(params.data.VolRAvg20)?.toFixed(0)}</td>
    </tr>    <tr style={{lineHeight: 1, backgroundColor: '#404040'}}>Chg</tr>
    <tr>
      <td>Chg(%)</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C15)}}>{Number(params.data.SB3C15)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C14)}}>{Number(params.data.SB3C14)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C13)}}>{Number(params.data.SB3C13)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C12)}}>{Number(params.data.SB3C12)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C11)}}>{Number(params.data.SB3C11)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C10)}}>{Number(params.data.SB3C10)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C9)}}>{Number(params.data.SB3C9)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C8)}}>{Number(params.data.SB3C8)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C7)}}>{Number(params.data.SB3C7)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C6)}}>{Number(params.data.SB3C6)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg( params.data.SB3C5)}}>{Number(params.data.SB3C5)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C4)}}>{Number(params.data.SB3C4)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C3)}}>{Number(params.data.SB3C3)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C2)}}>{Number(params.data.SB3C2)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C1)}}>{Number(params.data.SB3C1)?.toFixed(0)}</td>
      <td style={{backgroundColor: getColChg(params.data.SB3C0)}}>{Number(params.data.SB3C0)?.toFixed(0)}</td>
    </tr>
     <tr style={{lineHeight: 1, backgroundColor: '#404040'}}>Cash</tr>
    <tr>
      <td>D2(B)</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta15)}}>{LNumFormatter(Number(params.data.CB2Delta15*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta14)}}>{LNumFormatter(Number(params.data.CB2Delta14*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta13)}}>{LNumFormatter(Number(params.data.CB2Delta13*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta12)}}>{LNumFormatter(Number(params.data.CB2Delta12*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta11)}}>{LNumFormatter(Number(params.data.CB2Delta11*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta10)}}>{LNumFormatter(Number(params.data.CB2Delta10*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta9)}}>{LNumFormatter(Number(params.data.CB2Delta9*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta8)}}>{LNumFormatter(Number(params.data.CB2Delta8*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta7)}}>{LNumFormatter(Number(params.data.CB2Delta7*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta6)}}>{LNumFormatter(Number(params.data.CB2Delta6*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney( params.data.CB2Delta5)}}>{LNumFormatter(Number(params.data.CB2Delta5*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta4)}}>{LNumFormatter(Number(params.data.CB2Delta4*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta3)}}>{LNumFormatter(Number(params.data.CB2Delta3*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta2)}}>{LNumFormatter(Number(params.data.CB2Delta2*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta1)}}>{LNumFormatter(Number(params.data.CB2Delta1*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB2Delta0)}}>{LNumFormatter(Number(params.data.CB2Delta0*1e6))}</td>
    </tr>
    
    <tr>
      <td>D5(B)</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta15)}}>{LNumFormatter(Number(params.data.CB5Delta15*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta14)}}>{LNumFormatter(Number(params.data.CB5Delta14*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta13)}}>{LNumFormatter(Number(params.data.CB5Delta13*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta12)}}>{LNumFormatter(Number(params.data.CB5Delta12*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta11)}}>{LNumFormatter(Number(params.data.CB5Delta11*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta10)}}>{LNumFormatter(Number(params.data.CB5Delta10*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta9)}}>{LNumFormatter(Number(params.data.CB5Delta9*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta8)}}>{LNumFormatter(Number(params.data.CB5Delta8*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta7)}}>{LNumFormatter(Number(params.data.CB5Delta7*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta6)}}>{LNumFormatter(Number(params.data.CB5Delta6*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney( params.data.CB5Delta5)}}>{LNumFormatter(Number(params.data.CB5Delta5*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta4)}}>{LNumFormatter(Number(params.data.CB5Delta4*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta3)}}>{LNumFormatter(Number(params.data.CB5Delta3*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta2)}}>{LNumFormatter(Number(params.data.CB5Delta2*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta1)}}>{LNumFormatter(Number(params.data.CB5Delta1*1e6))}</td>
      <td style={{backgroundColor: getColDeltaMoney(params.data.CB5Delta0)}}>{LNumFormatter(Number(params.data.CB5Delta0*1e6))}</td>
    </tr>

    <tr>
      <td>NN(B)</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[15]?.netVal))}}>{LNumFormatter(Number(foreignData[15]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[14]?.netVal))}}>{LNumFormatter(Number(foreignData[14]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[13]?.netVal))}}>{LNumFormatter(Number(foreignData[13]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[12]?.netVal))}}>{LNumFormatter(Number(foreignData[12]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[11]?.netVal))}}>{LNumFormatter(Number(foreignData[11]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[10]?.netVal))}}>{LNumFormatter(Number(foreignData[10]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[9]?.netVal))}}>{LNumFormatter(Number(foreignData[9]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[8]?.netVal))}}>{LNumFormatter(Number(foreignData[8]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[7]?.netVal))}}>{LNumFormatter(Number(foreignData[7]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[6]?.netVal))}}>{LNumFormatter(Number(foreignData[6]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[5]?.netVal))}}>{LNumFormatter(Number(foreignData[5]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[4]?.netVal))}}>{LNumFormatter(Number(foreignData[4]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[3]?.netVal))}}>{LNumFormatter(Number(foreignData[3]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[2]?.netVal))}}>{LNumFormatter(Number(foreignData[2]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[1]?.netVal))}}>{LNumFormatter(Number(foreignData[1]?.netVal))}</td>
      <td style={{backgroundColor: getColForeign(Number(foreignData[0]?.netVal))}}>{LNumFormatter(Number(foreignData[0]))}</td>
    </tr>
  </table>

  
}

export function SymbolCalendarWarning(params, setTicker){
  // const dividendData = useGetDividendQuery()?.data?.slice(0,1000)

  const [dividendData, setDividendData] = useState([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/data/stockdividend?code=${params}`)
    .then(res => res.json())
    .then((data) => {
      const DataOri = (value) => {
          
        const today = dayjs(new Date());
        const tommorrow = new Date(today);
        tommorrow.setDate(tommorrow.getDate()+value)
        const newTMR = dayjs(tommorrow)
        const dataFilter = data?.filter((x) => {
        let fulldateStock = new Date(String(x?.effectiveDate))
        let startingDate = new Date(`${today?.['$y']}-${today?.['$M']+1}-${today?.['$D']}`)
        let endingDate = new Date(`${newTMR?.['$y']}-${newTMR?.['$M']+1}-${newTMR?.['$D']}`)
  
        return fulldateStock<=endingDate && fulldateStock>=startingDate
    })?.reverse()
        return dataFilter
    }
  
    const DataOriRV = (value) => {
            
        const today = dayjs(new Date());
        const tommorrow = new Date(today);
        tommorrow.setDate(tommorrow.getDate()+value)
        const newTMR = dayjs(tommorrow)
        const dataFilter = data?.filter((x) => {
        let fulldateStock = new Date(String(x?.effectiveDate))
        let startingDate = new Date(`${today?.['$y']}-${today?.['$M']+1}-${today?.['$D']}`)
        let endingDate = new Date(`${newTMR?.['$y']}-${newTMR?.['$M']+1}-${newTMR?.['$D']}`)
  
        return fulldateStock>endingDate && fulldateStock<startingDate
    })?.reverse()
        return dataFilter
      }
  
    
      if(DataOri(20)?.filter((x) => x.group==='stockAlert')?.length>0){
          setDividendData(<span style={{fontSize:18, color: 'violet'}}><span>(</span><span>!</span><span>)</span></span>)
      }
    
      
      
      else if(DataOriRV(-10)?.length>0){
          setDividendData(<span style={{fontSize:18, color: 'royalblue'}}><span>(</span><span>^</span><span>)</span></span>)
      }
    
      else if(DataOri(5)?.length>0){
          setDividendData(<span style={{fontSize:18, color: 'red'}}><span>(</span><span>***</span><span>)</span></span>)
      }
    
      else if(DataOri(15)?.length>0){
          setDividendData(<span style={{fontSize:18, color: 'orange'}}><span>(</span><span>**</span><span>)</span></span>)
      }
    
      
      else if(DataOri(30)?.length>0){
          setDividendData(<span style={{fontSize:18, color: 'yellow'}}><span>(</span><span>*</span><span>)</span></span>)
      }
    
    
      else{
          setDividendData(<span style={{fontSize:18, color: 'violet'}}><span></span><span></span><span></span></span>)
      }
    })
  }, [])
  

  return dividendData
  
  
}

export function Valuation(ticker,sector,close){

  const [DG0, setDG0] = useState(null)
  const [DG1, setDG1] = useState(null)
  const [DG2, setDG2] = useState(null)
  const [DG3, setDG3] = useState(null)
  const [DG4, setDG4] = useState(null)
  
  const [DG0SM, setDG0SM] = useState(null)
  const [DG1SM, setDG1SM] = useState(null)
  const [DG2SM, setDG2SM] = useState(null)
  const [DG3SM, setDG3SM] = useState(null)
  const [DG4SM, setDG4SM] = useState(null)

  useEffect(() => {
    fetch(`https://finfo-api.vndirect.com.vn/v4/ratios?sort=reportDate:desc&q=code:${ticker}~reportType:QUARTER&period=8&size=1000`)
    .then((response) => response.json())
    .then((data2) =>{
      fetch(`https://finfo-api.vndirect.com.vn/v4/ratios/latest?filter=code:${sector}&where=ratioCode:PRICE_TO_EARNINGS~group:INDUSTRY&order=reportDate`)
      .then((response) => response.json())
      .then((PEindustry) =>{
        fetch(`https://finfo-api.vndirect.com.vn/v4/ratios/latest?filter=code:${sector}&where=ratioCode:PRICE_TO_BOOK~group:INDUSTRY&order=reportDate`)
        .then((response) => response.json())
        .then((PBindustry) =>{

      //DG0
      var EPS_TR = data2?.data?.filter(function(item){return item.ratioCode==="EPS_TR"})
      EPS_TR = EPS_TR[0]?.value

      var NET_PROFIT_TR_GR3YR = data2?.data?.filter(function(item){return item.ratioCode==="NET_PROFIT_TR_GR3YR"})
      NET_PROFIT_TR_GR3YR = (NET_PROFIT_TR_GR3YR[0]?.value)
      var NET_SALES_TR_GR3YR = data2?.data?.filter(function(item){return item.ratioCode==="NET_SALES_TR_GR3YR"})
      NET_SALES_TR_GR3YR = (NET_SALES_TR_GR3YR[0]?.value)
      var NET_PROFIT_TR_GRYOY = data2?.data?.filter(function(item){return item.ratioCode==="NET_PROFIT_TR_GRYOY"})
      NET_PROFIT_TR_GRYOY = (NET_PROFIT_TR_GRYOY[0]?.value)
      var NET_SALES_TR_GRYOY = data2?.data?.filter(function(item){return item.ratioCode==="NET_SALES_TR_GRYOY"})
      NET_SALES_TR_GRYOY = (NET_SALES_TR_GRYOY[0]?.value)
      var GROWTH_PROJECTION = (NET_PROFIT_TR_GRYOY+NET_SALES_TR_GRYOY)/2

      var DG0 = Number(((EPS_TR*(7+GROWTH_PROJECTION)*4.4/4.36)/1000)?.toFixed(2))
      var DG0_BAT = Number(((DG0-Number(close))/Number(close)*100)?.toFixed(2))


      var getIndustryPE = PEindustry?.data?.filter(function(item){return item.code===sector})
      var getTickerPE = data2?.data?.filter(function(item){return item.code===ticker && item.ratioCode==='PRICE_TO_EARNINGS_AVG_CR_3M'})
      var getTickerEPS = data2?.data?.filter(function(item){return item.ratioCode==='EPS_TR'})
              
      var getIndustryPB = PBindustry?.data?.filter(function(item){return item.code===sector })||null
      var getTickerPB = data2?.data?.filter(function(item){return item.code===ticker && item.ratioCode==='PRICE_TO_BOOK_AVG_CR_3M'})
      var getTickerBV = data2?.data?.filter(function(item){return item.ratioCode==='BVPS_CR'})

      var getIndustryPE_v = getIndustryPE[0]?.value
      var getTickerPE_v = getTickerPE[0]?.value
      var PEIndustryTickerRatio = (getIndustryPE_v+getTickerPE_v)/2

      var getIndustryPB_v = getIndustryPB[0]?.value
      var getTickerPB_v = getTickerPB[0]?.value
      var PBIndustryTickerRatio = (getIndustryPB_v+getTickerPB_v)/2

      var pricePE = Number(((PEIndustryTickerRatio*(getTickerEPS[0]?.value))/1000)?.toFixed(2))
      var pricePE_BAT = Number(((pricePE-Number(close))/Number(close)*100)?.toFixed(2))

      var pricePB = Number(((PBIndustryTickerRatio*(getTickerBV[0]?.value))/1000)?.toFixed(2))
      var pricePB_BAT = Number(((pricePB-Number(close))/Number(close)*100)?.toFixed(2))

      var DG3 = Number((EPS_TR/1000*(7+1.5*GROWTH_PROJECTION))?.toFixed(2))
      var DG3_BAT = Number(((DG3-Number(close))/Number(close)*100)?.toFixed(2))

      var DG4 = Number(((Math.sqrt(22.5*EPS_TR*getTickerPB_v))/10)?.toFixed(2))
      var DG4_BAT = Number(((DG4-Number(close))/Number(close)*100)?.toFixed(2))

      setDG0(DG0)
      setDG0SM(DG0_BAT)

      setDG1(pricePE)
      setDG1SM(pricePE_BAT)

      setDG2(pricePB)
      setDG2SM(pricePB_BAT)

      setDG3(DG3)
      setDG3SM(DG3_BAT)
      setDG4(DG4)
      setDG4SM(DG4_BAT)

            
        })
      })
      
    })
  }, [])

  function ColByScore(x){
    if(x<0) return '#990000'
    if(x<=5) return '#CC6600'
    if(x<=20) return '#999900'
    if(x>20 && x<50) return '#4C9900'
    if(x>=50) return '#009900'
    else return '#404040'
  }


  return <table style={{width: '250px', border: '1px solid white', borderCollapse: 'collapse', fontSize: '14px', height: 100}}>
    <tr style={{height: 10, fontSize: 17}}>
      <th style={{textDecoration: 'underline', fontStyle:'italic'}}>Criteria</th>
      <th style={{textDecoration: 'underline', fontStyle:'italic'}}>Valuation</th>
      <th style={{textDecoration: 'underline', fontStyle:'italic'}}>SM (%)</th>
    </tr>
    <tr>
      <td>BG1</td>
      <td>{DG0||'N/A'}</td>
      <td style={{backgroundColor: ColByScore(DG0SM)}}>{DG0SM||'N/A'}</td>
    </tr>
    <tr>
      <td>BG2</td>
      <td>{DG3||'N/A'}</td>
      <td style={{backgroundColor: ColByScore(DG3SM)}}>{DG3SM||'N/A'}</td>
    </tr>
    <tr>
      <td>BG3</td>
      <td>{DG4||'N/A'}</td>
      <td style={{backgroundColor: ColByScore(DG4SM)}}>{DG4SM||'N/A'}</td>
    </tr>
    <tr>
      <td>PE</td>
      <td>{DG1||'N/A'}</td>
      <td style={{backgroundColor: ColByScore(DG1SM)}}>{DG1SM||'N/A'}</td>
    </tr>
    <tr>
      <td>PB</td>
      <td>{DG2||'N/A'}</td>
      <td style={{backgroundColor: ColByScore(DG2SM)}}>{DG2SM||'N/A'}</td>
    </tr>
  </table>
  
}


export const OrderFlow = (ticker,data_) => {
  
  const FetchOrder = (tickerforData) => {
      const [l1, setL1] = useState(0)
      const [l2, setL2] = useState(0)
      const [l3, setL3] = useState(0)
      const [l4, setL4] = useState(0)
      const [l5, setL5] = useState(0)
      const [l6, setL6] = useState(0)
      const [lastVol, setLastVol] = useState(0)
      const [lastPrice, setLastPrice] = useState(0)
      const [ceilPrice, setCeilPrice] = useState(0)
      const [flPrice, setFlPrice] = useState(0)
      const [refPrice, setRefPrice] = useState(0)
      const [avgPrice, setAvgPrice] = useState(0)
      const [chgPrice, setChgPrice] = useState(0)
      const [chgPctPrice, setChgPctPrice] = useState(0)
      const [frgNetVol, setFrgNetVol] = useState(0)
      const [totVol, setTotVol] = useState(0)

      const [totalOrder, setTotalOrder] = useState(0)
      const [listOfOrder, setListOfOrder] = useState([])

      
          useEffect(() => {
              //Fetch OrderFlow
              fetch(`https://bgapidatafeed.vps.com.vn/getliststockdata/${tickerforData}`)
              .then((res) => res.json())
              .then((data) => {
                  setL1(data?.[0]?.g3||'N/A')
                  setL2(data?.[0]?.g2||'N/A')
                  setL3(data?.[0]?.g1||'N/A')
                  setL4(data?.[0]?.g4||'N/A')
                  setL5(data?.[0]?.g5||'N/A')
                  setL6(data?.[0]?.g6||'N/A')
                  setLastVol(data?.[0]?.lastVolume||'N/A')
                  setLastPrice(data?.[0]?.lastPrice||'N/A')
                  setCeilPrice(data?.[0]?.c||'N/A')
                  setFlPrice(data?.[0]?.f||'N/A')
                  setRefPrice(data?.[0]?.r||'N/A')
                  setAvgPrice(data?.[0]?.avePrice||'N/A')
                  setChgPctPrice(Number(data?.[0]?.c)>=Number(data?.[0]?.r)?data?.[0]?.changePc:-data?.[0]?.changePc||'N/A')
                  setFrgNetVol(Number(data?.[0]?.fBVol)-Number(data?.[0]?.fSVolume)||'N/A')
              })

          }, [])
      
      
  
      const volArr = [Number(String(l1).split('|')[1]), Number(String(l2).split('|')[1]), Number(String(l3).split('|')[1]), Number(String(l4).split('|')[1]), Number(String(l5).split('|')[1]), Number(String(l6).split('|')[1])]
  
      return {
          L1: [String(l1).split('|')[0]!=='0.00'?String(l1).split('|')[0]:'', String(l1).split('|')[1]!=='0'?String(l1).split('|')[1]:''],
          L2: [String(l2).split('|')[0]!=='0.00'?String(l2).split('|')[0]:'', String(l2).split('|')[1]!=='0'?String(l2).split('|')[1]:''],
          L3: [String(l3).split('|')[0]!=='0.00'?String(l3).split('|')[0]:'', String(l3).split('|')[1]!=='0'?String(l3).split('|')[1]:''],
          L4: [String(l4).split('|')[0]!=='0.00'?String(l4).split('|')[0]:'', String(l4).split('|')[1]!=='0'?String(l4).split('|')[1]:''],
          L5: [String(l5).split('|')[0]!=='0.00'?String(l5).split('|')[0]:'', String(l5).split('|')[1]!=='0'?String(l5).split('|')[1]:''],
          L6: [String(l6).split('|')[0]!=='0.00'?String(l6).split('|')[0]:'', String(l6).split('|')[1]!=='0'?String(l6).split('|')[1]:''],
          LastVol: lastVol,
          LastPrice: lastPrice,
          CeilP: ceilPrice,
          FloorP: flPrice,
          RefP: refPrice,
          maxVol: Math.max(...volArr),
          ChangePPct: chgPctPrice,
          Foreign: frgNetVol,
          OrdList: listOfOrder
      }
  
  }
  var dataOrder = FetchOrder(ticker)

  function getDistanceMargin(v,v1,v2){
    if(v1[4]>=0){
        if(v>=v1[0]){return v2[0]}
        else if(v>=v1[1]&&v<v1[0]){return v2[1]}
        else if(v>=v1[2]&&v<v1[1]){return v2[2]}
        else {return v2[3]}
    }
    if(v1[3]>=0 && !v1[4]>=0){
        if(v>=v1[0]){return v2[0]}
        else if(v>=v1[1]&&v<v1[0]){return v2[1]}
        else if(v>=v1[2]&&v<v1[1]){return v2[2]}
        else {return v2[3]}
    }
    else{
        if(v>=v1[0]){return v2[0]}
        else if(v>=v1[1]&&v<v1[0]){return v2[1]}
        else {return v2[2]}
    }
    
}

function getColorByPrice(cl,r,ce,fl){
    if(cl>r && cl<ce){return '#32CD32'}
    else if(cl===r){return 'gold'}
    else if(cl===ce){return 'violet'}
    else if(cl===fl){return 'aqua'}
    else if(cl<r && cl>fl){return 'red'}
}

  
  return  <div style={{zoom: '50%'}} ><div style={{marginLeft: 30}}>
 
  
  <div style={{width: 600,  height: 200, alignItems: 'center'}}>
      <div style={{fontWeight: 600, fontSize: 35}}> 
          <div style={{color: getColorByPrice(Number(dataOrder.LastPrice),Number(dataOrder.RefP),Number(dataOrder.CeilP),Number(dataOrder.FloorP)), fontWeight: 600, padding: 15}}>Close: {dataOrder.LastPrice} {`(${dataOrder.ChangePPct}%)`}</div>
          <div style={{fontWeight: 100}}> - LVol: {dataOrder.LastVol}</div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', padding: 30}}>
          <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: 260, fontSize: 22}}>
              <div style={{border: '1px solid black', minWidth: 250, height: 42}}>
                  <span>&nbsp;{dataOrder.L3[1]*10}</span>
                  <span style={{float: 'right', backgroundColor: getColorByPrice(Number(dataOrder.L3[0]),Number(dataOrder.RefP),Number(dataOrder.CeilP),Number(dataOrder.FloorP)), width: 100*(dataOrder.L3[1]/dataOrder.maxVol), height: 40}}>
                      <span style={{marginLeft: getDistanceMargin(Number(dataOrder.L3[0]),[100,10,0],[-85,-70,-60])+(100*(dataOrder.L3[1]/dataOrder.maxVol)), fontWeight: 600}}>{dataOrder.L3[0]}</span>
                  </span>
              </div>
                  
              <div style={{border: '1px solid black', minWidth: 250, height: 42}}>
                  <span>&nbsp;{dataOrder.L2[1]*10}</span>
                  <span style={{float: 'right', backgroundColor: getColorByPrice(Number(dataOrder.L2[0]),Number(dataOrder.RefP),Number(dataOrder.CeilP),Number(dataOrder.FloorP)), width: 100*(dataOrder.L2[1]/dataOrder.maxVol), height: 40}}>
                      <span style={{marginLeft: getDistanceMargin(Number(dataOrder.L2[0]),[100,10,0],[-85,-70,-60])+(100*(dataOrder.L2[1]/dataOrder.maxVol)), fontWeight: 600}}>{dataOrder.L2[0]}</span>
                  </span>
              </div>
              <div style={{border: '1px solid black', minwidth: 250, height: 42}}>
                  <span>&nbsp;{dataOrder.L1[1]*10}</span>
                  <span style={{float: 'right', backgroundColor: getColorByPrice(Number(dataOrder.L1[0]),Number(dataOrder.RefP),Number(dataOrder.CeilP),Number(dataOrder.FloorP)), width: 100*(dataOrder.L1[1]/dataOrder.maxVol), height: 40}}>
                      <span style={{marginLeft: getDistanceMargin(Number(dataOrder.L1[0]),[100,10,0],[-85,-70,-60])+(100*(dataOrder.L1[1]/dataOrder.maxVol)), fontWeight: 600}}>{dataOrder.L1[0]}</span>
                  </span>
              </div>
          </div>



          <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: 260, fontSize: 22}}>
              <div style={{border: '1px solid black', minWidth: 250, height: 42, justifyContent: 'space-between'}}>
                      <span style={{float: 'left', backgroundColor: getColorByPrice(Number(dataOrder.L4[0]),Number(dataOrder.RefP),Number(dataOrder.CeilP),Number(dataOrder.FloorP)), width: 100*(dataOrder.L4[1]/dataOrder.maxVol), height: 40}}>
                          <span style={{fontWeight: 600, paddingLeft: 5}}>{dataOrder.L4[0]}</span>
                          <span style={{ marginLeft: 100*(dataOrder.L4[1]/dataOrder.maxVol)+0.5*(200-220*(dataOrder.L4[1]/dataOrder.maxVol)+getDistanceMargin(Number(dataOrder.L4[1]),[1000000,100000,10000,1000,10],[-10,-10,40,40,60]))}}>{Number(dataOrder.L4[1])*10}</span>
                      </span>
                  
                  </div>
                      
                  <div style={{border: '1px solid black', minWidth: 250, height: 42, justifyContent: 'space-between'}}>
                      <span style={{float: 'left', backgroundColor: getColorByPrice(Number(dataOrder.L5[0]),Number(dataOrder.RefP),Number(dataOrder.CeilP),Number(dataOrder.FloorP)), width: 100*(dataOrder.L5[1]/dataOrder.maxVol), height: 40}}>
                          <span style={{fontWeight: 600, paddingLeft: 5}}>{dataOrder.L5[0]}</span>
                          <span style={{ marginLeft: 100*(dataOrder.L5[1]/dataOrder.maxVol)+0.5*(200-220*(dataOrder.L5[1]/dataOrder.maxVol)+getDistanceMargin(Number(dataOrder.L5[1]),[1000000,100000,10000,1000,10],[-10,-10,40,40,60]))}}>{Number(dataOrder.L5[1])*10}</span>
                      </span>
                  
                  </div>
                  <div style={{border: '1px solid black', minWidth: 250, height: 42, justifyContent: 'space-between'}}>
                      <span style={{float: 'left', backgroundColor: getColorByPrice(Number(dataOrder.L6[0]),Number(dataOrder.RefP),Number(dataOrder.CeilP),Number(dataOrder.FloorP)), width: 100*(dataOrder.L6[1]/dataOrder.maxVol), height: 40}}>
                          <span style={{fontWeight: 600, paddingLeft: 5}}>{dataOrder.L6[0]}</span>
                          <span style={{ marginLeft: 100*(dataOrder.L6[1]/dataOrder.maxVol)+0.5*(200-220*(dataOrder.L6[1]/dataOrder.maxVol)+getDistanceMargin(Number(dataOrder.L6[1]),[1000000,100000,10000,1000,10],[-10,-10,40,40,60]))}}>{Number(dataOrder.L6[1])*10}</span>
                      </span>
                  
                  </div>
              </div>
              
          </div>
      </div>
      
</div>
<div style={{marginTop: 120, fontSize: 30}}>
  <span style={{fontWeight: 'bold'}}>Foreign Net Buy Sell Vol: </span> 
  <span>{dataOrder.Foreign*10} </span>
  
</div>
<div style={{marginTop: 25, fontSize: 30}}>Levels: 
  <span style={{color: 'aqua'}}>{dataOrder.FloorP}</span>|
  <span style={{color: 'gold'}}>{dataOrder.RefP}</span>|
  <span style={{color: '#FF00FF'}}>{dataOrder.CeilP}</span>
</div>
</div>
}

export function GetFundaScore(ticker){

  const [overallScore, setOverallScore] = useState(undefined)
  const [growthScore, setGrowthScore] = useState(undefined)
  const [profitScore, setProfitScore] = useState(undefined)
  const [finRiskScore, setFinRiskScore] = useState(undefined)
  const [dividendScore, setDividendScore] = useState(undefined)
  const [sustainScore, setSustainScore] = useState(undefined)

  useEffect(() => {
    fetch(`https://finfo-api.vndirect.com.vn/v4/scorings?q=locale:VN~fiscalDate:2024-06-30&size=1000000&sort=point:asc`)
      .then(res => res.json())
      .then(dataOverallNew => {
        setOverallScore(
          dataOverallNew?.data?.filter(x => 
            x.code === ticker && x.criteriaCode?.includes('00000')
          )?.[0]?.point
        )
        setGrowthScore(
          dataOverallNew?.data?.filter(x => 
            x.code === ticker && x.criteriaCode?.includes('02000')
          )?.[0]?.point
        )
        setProfitScore(
          dataOverallNew?.data?.filter(x => 
            x.code === ticker && x.criteriaCode?.includes('03000')
          )?.[0]?.point
        )
        setDividendScore(
          dataOverallNew?.data?.filter(x => 
            x.code === ticker && x.criteriaCode?.includes('04000')
          )?.[0]?.point
        )
        setFinRiskScore(
          dataOverallNew?.data?.filter(x => 
            x.code === ticker && x.criteriaCode?.includes('05000')
          )?.[0]?.point
        )
        setSustainScore(
          dataOverallNew?.data?.filter(x => 
            x.code === ticker && x.criteriaCode?.includes('06000')
          )?.[0]?.point
        )
      
      
    })
  }, [])

  function ColByScore(x){
    if(x<=2&&x>=0) return '#990000'
    if(x<=3.5) return '#CC6600'
    if(x<=5) return '#999900'
    if(x<6) return '#4C9900'
    if(x>=6) return '#009900'
    else return '#404040'
  }



  return <table style={{width: '160px', border: '1px solid white', borderCollapse: 'collapse', fontSize: '14px', height: 100}}>
  <tr style={{height: 10, fontSize: 17}}>
    <th style={{textDecoration: 'underline', fontStyle:'italic'}}>Criteria</th>
    <th style={{textDecoration: 'underline', fontStyle:'italic'}}>Score</th>
  </tr>
  <tr>
    <td>Growth</td>
    <td 
      style={{backgroundColor: growthScore>0?ColByScore(growthScore):'#404040'}}
      >
      {growthScore||'N/A'}
    </td>
  </tr>
  <tr>
    <td>Profit</td>
    <td 
      style={{backgroundColor: profitScore>0?ColByScore(profitScore):'#404040'}}
      >
      {profitScore||'N/A'}
    </td>
  </tr>
  <tr>
    <td>FinRisk</td>
    <td 
      style={{backgroundColor: finRiskScore>0?ColByScore(finRiskScore):'#404040'}}
      >
      {finRiskScore||'N/A'}
    </td>
  </tr>
  <tr>
    <td>Dividend</td>
    <td 
      style={{backgroundColor: dividendScore>0?ColByScore(dividendScore):'#404040'}}
      >
      {dividendScore||'N/A'}
    </td>
  </tr>
  <tr>
    <td>Sustain</td>
    <td 
      style={{backgroundColor: sustainScore>0?ColByScore(sustainScore):'#404040'}}
      >
      {sustainScore||'N/A'}
    </td>
  </tr>
  <tr>
    <td>Overall</td>
    <td 
      style={{backgroundColor: overallScore>0?ColByScore(overallScore):'#404040'}}
      >
      {overallScore||'N/A'}
    </td>
  </tr>
  </table>

  
}



export function GetTickerMV(ticker) {
  const [mvSort, setMvSort] = useState();
  const [mvSort2, setMvSort2] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/data/fetch/marketcap/hose`)
      .then(res => res.json())
      .then(data_ => { 


        setMvSort(data_)
      })
  }, [])

  
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/data/fetch/marketcap/hnx`)
      .then(res => res.json())
      .then(data_ => {


        setMvSort2(data_)
      })
  }, [])

  
  function getMV (data_, x){
    return data_?.map((e) => {return String(e.symbol)}).indexOf(String(
      x.TICKERVN.charAt(0)+x.TICKERVN.charAt(1)+x.TICKERVN.charAt(2))
    )
  }
  
  

  
    function getTickerAtDiffRange(data, max, min, MV, MV2){ 
        
        var StringTicker;
        var dataIn = data


        var dataInMapTickerName = dataIn?.map(x => 
          {
            return getMV(MV, x)>100?
            x.TICKERVN?.replace('_VN',`_${String(getMV(MV, x))}(VN)`):
            getMV(MV, x)>=50&&getMV(MV, x)<100&&x.TICKERVN?.includes('_VN')?
            x.TICKERVN?.replace('_VN',`_${String(getMV(MV, x))}(VN100)`):
            getMV(MV, x)>0&&getMV(MV, x)<50&&x.TICKERVN?.includes('_VN')?
            x.TICKERVN?.replace('_VN',`_${String(getMV(MV, x))}(VN50)`):
            (getMV(MV, x)>0?
            (x.TICKERVN?.replace('(VN30)',`_${String(getMV(MV, x))}(VN30)`)?.replace('_VN',`_${String(getMV(MV, x))}(VN)`)):
            (getMV(MV2, x)>0&&getMV(MV2, x)<=200&&x.TICKERVN?.includes('HN')?
            x.TICKERVN?.replace('_HN',`_${String(getMV(MV2, x))}(HN)`)?.replace('(HN30)',`_${String(getMV(MV2, x))}(HN30)`):x.TICKERVN?.replace('_HN',`_${String(getMV(MV2, x))}(HN)`)))
          }
          
        )


        

        
        return <>
        
          {dataInMapTickerName?.map( (x) => {
            if(x?.includes('(VN)')){
              return(
                  <span key={x} style={{color: 'gold', fontWeight: 'bold', fontSize: 18, bottom: 10}}>{x} </span> 
              )
            
          }
            if(x?.includes('VN100')){
              return(
                  <span key={x} style={{color: 'goldenrod', fontWeight: 'bold', fontSize: 18, bottom: 10}}>{x} </span> 
              )
            
          }

            if(x?.includes('VN50')){
              return(
                  <span key={x} style={{color: 'orangered', fontWeight: 'bold', fontSize: 18, bottom: 10}}>{x} </span> 
              )
            
          }

            else if(x?.includes('VN30')){
              return(
                  <span key={x} style={{color: 'red', fontWeight: 'bold', fontSize: 18, bottom: 10}}>{x} </span> 
              )
            
          }

          else if(x?.includes('(HN)')){
            return(
                <span key={x} style={{color: '#9ACD32', fontWeight: 600, fontSize: 18, bottom: 10}}>{x} </span> 
            )
          
        }

          else if(x?.includes('HN30')){
            return(
                <span key={x} style={{color: 'orange', fontWeight: 600, fontSize: 18, bottom: 10}}>{x} </span> 
            )
          
        }
          else{
            return(
                  <span key={x} style={{color: 'lightgrey', fontSize: 18}}>{String(x)}</span>
              )
          }
          })}
        </>
  }
  return getTickerAtDiffRange(ticker, -99999999999, 999999999999, mvSort, mvSort2)
}

export function Box1(){

}