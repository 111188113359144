import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5stock from '@amcharts/amcharts5/stock'
import { useEffect, Component} from "react";
import React from "react";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import BulletChartSector from "./BulletChartSector.dashboard";
import {Typography} from '@mui/material'



class ChartBubbleSector extends Component {


    

    componentDidMount(){


        let root = am5.Root.new(this.props.id);
        root.setThemes([
            am5themes_Dark.new(root)
          ]);
          
    root._logo.dispose();
          
          // Create chart
          // https://www.amcharts.com/docs/v5/charts/xy-chart/
          let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelY: "zoomXY",
            pinchZoomX:true,
            pinchZoomY:true
          }));
          
          
          // Create axes
          // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
          let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            strictMinMax: true,
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {}),
            max: 10,
            min: -5
          }));
          
          
          let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            
            strictMinMaxSelection: true,
            renderer: am5xy.AxisRendererY.new(root, {
              inversed: false
            }),
            tooltip: am5.Tooltip.new(root, {}),
            max: 10,
            min: -5
          }));
          
          
          
          
          // Create series
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
          let series = chart.series.push(am5xy.LineSeries.new(root, {
            calculateAggregates: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "MakerAverage",
            valueXField: "ScoreAverage",
            valueField: "Volume",
            seriesTooltipTarget:"bullet",
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText: "[underline][bold]SECTOR: {Sector} \n[bold]Top Cổ Phiếu[/]: {ListTickerMaker}\n------------------------------------------------------\nScore: {ScoreAverage.formatNumber('#.0')}\nĐội Lái: {MakerAverage.formatNumber('#.0')}\nVolume: {Volume.formatNumber('#.')}\nChu Kì Tăng: {ChuKiTangGiam.formatNumber('#.0')}%"
            })
            
          }));
          series.bullets.push(function(root, series, dataItem) {
            return am5.Bullet.new(root, {
              sprite: am5.Label.new(root, {
                text: "{Sector}",
                fill: am5.color(0xffffff),
                populateText: true,
                centerX: am5.p50,
                centerY: am5.p50,
                textAlign: "center",
                fontSize: 10,
                fontWeight: 600,
                fontFamily: 'Arial'
              }),
              dynamic: true
            });
          });
          
          
          series.strokes.template.set("visible", false);
          
          
          // Add bullet
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
          let circleTemplate = am5.Template.new({});
          circleTemplate.adapters.add("fill", function(fill, target) {
            let dataItem = target.dataItem;
            if (dataItem) {
              return am5.Color.fromString(dataItem.dataContext.color);
            }
            return fill
          });
          series.bullets.push(function() {
            let bulletCircle = am5.Circle.new(root, {
              fill: series.get("fill"),
              radius: 10,
              fillOpacity: 0.4
            }, circleTemplate);
            return am5.Bullet.new(root, {
              sprite: bulletCircle
            });
          });
          
          
          // Add heat rule
          // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
          series.set("heatRules", [{
            target: circleTemplate,
            min: 0.3,
            max: 36,
            dataField: "value",
            key: "radius"
          }]);

          chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            snapToSeries: [series]
          }));
          
          
          // Add scrollbars
          // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
          // chart.set("scrollbarX", am5.Scrollbar.new(root, {
          //   orientation: "horizontal"
          // }));
          
          // chart.set("scrollbarY", am5.Scrollbar.new(root, {
          //   orientation: "vertical"
          // }));

          xAxis.labelsContainer.set("tooltip", am5.Tooltip.new(root, {
            pointerOrientation: "down"
          }));
          
          let xRenderer = xAxis.get("renderer");
          
          xRenderer.labels.template.setAll({
            tooltipText: "Sector",
            oversizedBehavior: "truncate",
            maxWidth: 50
          });

          series.data.processor = am5.DataProcessor.new(root, {
            numericFields: ["MakerAverage","ScoreAverage","VolRAverage",'Volume'],
            
          });  
          
          xRenderer.labels.template.setup = function(target) {
            target.set("background", am5.Rectangle.new(root, {
              fill: am5.color('#999933'),
              fillOpacity: 0
            }));
          };

          var series3 = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339966")
          }));
          series3.fills.template.setAll({ fillOpacity: 0.5, visible: true });
          series3.strokes.template.set("forceHidden", true);
          series3.data.setAll([
            { ax: 2, ay: 5 },
            { ax: 10, ay: 5 },
            { ax: 10, ay: 25 },
            { ax: 2, ay: 25 }
          ]);

          var series3b = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339966")
          }));
          series3b.fills.template.setAll({ fillOpacity: 0.5, visible: true });
          series3b.strokes.template.set("forceHidden", true);
          series3b.data.setAll([
            { ax: 10, ay: 6 },
            { ax: 10, ay: 6 },
            { ax: 10, ay: 5 },
            { ax: 10, ay: 5 }
          ]);

          var series0a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339999")
          }));
          series0a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series0a.strokes.template.set("forceHidden", true);
          series0a.data.setAll([
            { ax: 1, ay: 5 },
            { ax: 10, ay: 5 },
            { ax: 10, ay: 1 },
            { ax: 0, ay: 1 }
          ]);

          var series0b = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339999")
          }));
          series0b.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series0b.strokes.template.set("forceHidden", true);
          series0b.data.setAll([
            { ax: 1, ay: 5 },
            { ax: 2, ay: 5 },
            { ax: 2, ay: 25 },
            { ax: 0, ay: 25 }
          ]);

          var series0c = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339999")
          }));
          series0c.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series0c.strokes.template.set("forceHidden", true);
          series0c.data.setAll([
            { ax: 10, ay: 0 },
            { ax: 10, ay: 6 },
            { ax: 10, ay: 6 },
            { ax: 10, ay: 0 }
          ]);
          
          var series1 = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#993333")
          }));
          series1.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series1.strokes.template.set("forceHidden", true);
          series1.data.setAll([
            { ax: -1, ay: -3 },
            { ax: -8, ay: -3 },
            { ax: -8, ay: 25 },
            { ax: -1, ay: 25 }
          ]);


          var series1a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#993333")
          }));
          series1a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series1a.strokes.template.set("forceHidden", true);
          series1a.data.setAll([
            { ax: 10, ay: -5 },
            { ax: -5, ay: -5 },
            { ax: -5, ay: -3 },
            { ax: 10, ay: -3 }
          ]);

          
          
          var series2a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#999933")
          }));
          series2a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series2a.strokes.template.set("forceHidden", true);
          series2a.data.setAll([
            { ax: 1, ay: -3 },
            { ax: -1, ay: -3 },
            { ax: -1, ay: 25 },
            { ax: 0, ay: 25 }
          ]);

          var series2b = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#999933")
          }));
          series2b.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series2b.strokes.template.set("forceHidden", true);
          series2b.data.setAll([
            { ax: 10, ay: -3 },
            { ax: 1, ay: -3 },
            { ax: 1, ay: 1 },
            { ax: 10, ay: 1 }
          ]);

          var series2c = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#999933")
          }));
          series2c.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series2c.strokes.template.set("forceHidden", true);
          series2c.data.setAll([
            { ax: 10, ay: -3 },
            { ax: 10, ay: -3 },
            { ax: 10, ay: 0 },
            { ax: 10, ay: 0 },
          ]);
        
        function getDataBySector(data,SectorName,OtherName){
            var color,name
            var scoreAspect = (data?.filter(x => {return x.Sector===SectorName})?.[0]?.MakerAverage*2+data?.filter(x => {return x.Sector===SectorName})?.[0]?.ScoreAverage)/3
            if(scoreAspect>=8) color='#FF00FF'
            else if(scoreAspect>=5) color='#00FFFF'
            else if(scoreAspect>=3) color='#00FF00'
            else if(scoreAspect>=1) color='#FFA500'
            else if(scoreAspect<=0) color='#FF4500'
            else if(scoreAspect<=-0.5) color='#FF0000'
            else if(scoreAspect<=-1) color='#B22222'
            else color = '#404040'
            if(OtherName.length!==0) name = OtherName
            else name = SectorName

            var arrListMaker = []
            var arrListScore = []
            var arrListTrend = []
            for(var i=0; i<=5; i++){
                var dataM_ = data?.filter(x => {return x.Sector===SectorName})?.[0]?.SortedTickerBy?.Maker?.[0]
                arrListMaker.push(String(`${dataM_?.Ticker?.[i]} (${Math.round(dataM_?.Value?.[i])})`))
            }
            return {
                Sector: name,
                color: color,
                MakerAverage: data?.filter(x => {return x.Sector===SectorName})?.[0]?.MakerAverage,
                ScoreAverage: data?.filter(x => {return x.Sector===SectorName})?.[0]?.ScoreAverage,
                VolRAverage: data?.filter(x => {return x.Sector===SectorName})?.[0]?.VolRAverage,
                Volume: data?.filter(x => {return x.Sector===SectorName})?.[0]?.Volume,
                ChuKiTangGiam: data?.filter(x => {return x.Sector===SectorName})?.[0]?.ChuKiTangGiamAverage,
                ListTickerMaker: arrListMaker,
                ListTickerTrend: data?.filter(x => {return x.Sector===SectorName})?.[0]?.SortedTickerBy?.ChuKiTangGiam?.[0].Ticker?.slice(0,5),
            }
        }

          
        fetch(`${process.env.REACT_APP_BASE_URL}/data/sectordata`)
        .then((res) => res.json())
        .then((data) => { 
        
        const realData = [getDataBySector(data,'DTC',''),getDataBySector(data,'BKCN','BDSKCN'),getDataBySector(data,'B_','--BLUE--'),getDataBySector(data,'M_','--MIDCAP--'),getDataBySector(data,'P_','--PENNY--'),getDataBySector(data,'DAU KHI','DẦU'),getDataBySector(data,'NHUA','NHỰA'),getDataBySector(data,'CAO SU','CAO SU'),getDataBySector(data,'PHAN BON','PHÂN ĐẠM'),getDataBySector(data,'THEP','THÉP'),getDataBySector(data,'VAN TAI BIEN','VẬN TẢI'),getDataBySector(data,'VLXD','VLXD'),getDataBySector(data,'XAY DUNG','XÂY DỰNG'),getDataBySector(data,'DIEN','ĐIỆN'),getDataBySector(data,'THUY SAN','THỦY SẢN'),getDataBySector(data,'NONG SAN','NÔNG SẢN'),getDataBySector(data,'DUONG','ĐƯỜNG'),getDataBySector(data,'THUC PHAM','THỰC PHẨM'),getDataBySector(data,'DET MAY','DỆT MAY'),getDataBySector(data,'DUOC PHAM','DƯỢC'),getDataBySector(data,'BAN LE','BÁN LẺ'),getDataBySector(data,'NGAN HANG','-@BANK-'),getDataBySector(data,'BAO HIEM','BẢO HIỂM'),getDataBySector(data,'-@BDS-',''),getDataBySector(data,'CHUNG KHOAN','-@CHỨNG KHOÁN-'),getDataBySector(data,'CONG NGHE','TECH'),getDataBySector(data,'VIN','G-VIN'),getDataBySector(data,'MASAN','G-MASAN'),getDataBySector(data,'FPT','G-FPT'),getDataBySector(data,'APEC!','G-APEC'),getDataBySector(data,'VIETTEL','G-VIETTEL'),getDataBySector(data,'GELEX','G-GELEX'),getDataBySector(data,'HAGL','G-HAGL'),getDataBySector(data,'SH','G-SH'),getDataBySector(data,'HANGKHONG','HÀNG KHÔNG')]
        series.data.setAll(realData)
        this.setState({
          data: realData
        })
        }


    )


// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/

        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
    }


    
        
    


    render() {
      function BubbleChartParam(data_){
       
        var STOCK_FAIR = data_?.filter(x => {
          return Number(x.ScoreAverage)>-2 && Number(x.MakerAverage)>-2
        })?.length / data_?.length
        
        var STOCK_STRONGPARTIAL = data_?.filter(x => {
          return Number(x.ScoreAverage)>0 || Number(x.MakerAverage)>0
        })?.length / data_?.length
    
        var STOCK_STRONGFULL = data_?.filter(x => {
          return Number(x.ScoreAverage)>0 && Number(x.MakerAverage)>0
        })?.length / data_?.length
        function calculateDensityScore(array, param) {
          const benchmark = Number(this?.props?.dataSector?.filter(x => x.Sector === 'Index')?.[0]?.[param])
          const totalCount = array?.length;
      
          if (totalCount === 0) return 0; // Handle empty array
      
          // Calculate the sum of absolute distances from the benchmark
          const totalDistance = array?.reduce((acc, num) => acc + Math.abs(num - benchmark), 0);
      
          // Calculate average distance from the benchmark
          const averageDistance = totalDistance / totalCount;
      
          // Normalize the score to a range of -1 to +1
          // Assuming maxDistance is the furthest distance from the benchmark you want to consider
          var maxDistance = array?Math.max(...(array?.map(num => Math.abs(num - benchmark)))):null;
      
          // Calculate the density score
          const densityScore = 1 - (averageDistance / maxDistance);
    
          return densityScore*10;
        }
    
        var array1 = data_?.map(x => Number(x.MakerAverage))
        var array1_Q1 = array1?.[Math.round((array1?.length+1)/4)]
        var array1_Q2 = array1?.[Math.round((array1?.length+1)/2)]
        var array1_Q3 = array1?.[Math.round(3*(array1?.length+1)/4)]
        var array1_IQR = array1_Q3-array1_Q1;
        var array1_Outliner = data_?.filter(x => {
          return x.MakerAverage > (array1_Q3 + array1_IQR*1.5) || x.MakerAverage < (array1_Q1 - array1_IQR*1.5)
        })

        var array2 = data_?.map(x => Number(x.ScoreAverage))
        var array2_Q1 = array2?.[Math.round((array2?.length+1)/4)]
        var array2_Q2 = array2?.[Math.round((array2?.length+1)/2)]
        var array2_Q3 = array2?.[Math.round(3*(array2?.length+1)/4)]
        var array2_IQR = array2_Q3-array2_Q1;
        var array2_Outliner = data_?.filter(x => {
          return x.ScoreAverage > (array2_Q3 + array2_IQR*1.5) || x.ScoreAverage < (array2_Q1 - array2_IQR*1.5)
        })
    
    
    
        
        
    
        return {
          Benchmark: ((STOCK_FAIR+STOCK_STRONGPARTIAL*0.7+STOCK_STRONGFULL*0.3)/2*10)?.toFixed(2),
          Density: ((calculateDensityScore(data_?.map(x => Number(x.MakerAverage)),'MakerAverage')+calculateDensityScore(data_?.map(x => Number(x.ScoreAverage)), 'ScoreAverage'))/2)?.toFixed(2),
          Outliner: {
            length: array1_Outliner?.length,
            pct: ((array1_Outliner?.length / array1?.length + array2_Outliner?.length / array2?.length)/2*10)?.toFixed(2)
          }
         
        }
      }

      function commentOverall(data_){
        if(data_?.Benchmark>=7){
          if(data_?.Outliner?.pct>3){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & PH MẠNH (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & PH MẠNH (BAL {'<'} 1/2)
              </span>
            }
            
          }
          else if(data_?.Outliner?.pct>=1){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & PH KHÁ (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & PH KHÁ (BAL {'<'} 1/2)
              </span>
            }
          }
          
          else{
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & ĐỀU (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHỎE & ĐỀU(BAL {'<'} 1/2)
              </span>
            }
          }
        }


        else if (data_?.Benchmark>=5){
          if(data_?.Outliner?.pct>3){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & PH MẠNH (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & PH MẠNH (BAL {'<'} 1/2)
              </span>
            }
            
          }
          else if(data_?.Outliner?.pct>=1){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & PH KHÁ (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & PH KHÁ (BAL {'<'} 1/2)
              </span>
            }
          }
          
          else{
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & ĐỀU(BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                KHÁ & ĐỀU (BAL {'<'} 1/2)
              </span>
            }
          }
        }

        
        else{
          if(data_?.Outliner?.pct>3){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & PH MẠNH (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & PH MẠNH (BAL {'<'} 1/2)
              </span>
            }
            
          }
          else if(data_?.Outliner?.pct>=1){
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & PH KHÁ (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & PH KHÁ (BAL {'<'} 1/2)
              </span>
            }
          }
          
          else{
            if(data_?.Density>5){
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & ĐỀU (BAL {'>'} 1/2)
              </span>
            }
            else{
              return <span style = {{
                color: 'lightgrey'
              }}>
                YẾU & ĐỀU (BAL {'<'} 1/2)
              </span>
            }
          }
        }
        
      }



        return (

            
            <React.Fragment>

{this?.state?.data?<div style={{
                backgroundColor: '#101010',
                padding: 10,
                display: 'flex',
                flexDirection: 'row',
                margin: 'auto',
                width: '100%',
                justifyContent: 'space-around',
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}>
                  <Typography sx = {{
                    color: 'beige'
                  }}
                    variant={'h6'}
                    fontWeight={600}
                  >
                    BENCHMARK(10)
                  </Typography>
                  <div>
                  {/*Benchmark*/}
                  {
                    BubbleChartParam(this?.state?.data)?.Benchmark>=7?
                      <span style={{color: 'forestgreen', fontWeight: 'bold'}}>
                        {BubbleChartParam(this?.state?.data)?.Benchmark} {'-'} {'CAO'}
                      </span>
                      
                    :
                      (
                        BubbleChartParam(this?.state?.data)?.Benchmark>=5?
                          <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                            {BubbleChartParam(this?.state?.data)?.Benchmark} {'-'} {'KHÁ'}
                          </span>
                          :
                          (
                            BubbleChartParam(this?.state?.data)?.Benchmark>=3?
                            <span style={{color: 'darkorange', fontWeight: 'bold'}}>
                              {BubbleChartParam(this?.state?.data)?.Benchmark} {'-'} {'KÉM'}
                            </span>
                            :
                            <span style={{color: 'red', fontWeight: 'bold'}}>
                              {BubbleChartParam(this?.state?.data)?.Benchmark} {'-'} {'THẤP'}
                            </span>
                          )
                      )
                      
                  }
                  
                  
                </div>

          
          
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  }}>
                    <Typography sx = {{
                      color: 'beige'
                    }}
                      variant={'h6'}
                      fontWeight={600}
                    >
                      DENSITY(10)
                    </Typography>
                    {/*Density*/}
                    {
                      BubbleChartParam(this?.state?.data)?.Density>=7?
                        <span style={{color: 'forestgreen', fontWeight: 'bold'}}>
                          {BubbleChartParam(this?.state?.data)?.Density} {'-'} {'CAO'}
                        </span>
                        
                      :
                        (
                          BubbleChartParam(this?.state?.data)?.Density>=5?
                            <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                              {BubbleChartParam(this?.state?.data)?.Density} {'-'} {'KHÁ'}
                            </span>
                            :
                            (
                              BubbleChartParam(this?.state?.data)?.Density>=3?
                              <span style={{color: 'darkorange', fontWeight: 'bold'}}>
                                {BubbleChartParam(this?.state?.data)?.Density} {'-'} {'KÉM'}
                              </span>
                              :
                              <span style={{color: 'red', fontWeight: 'bold'}}>
                                {BubbleChartParam(this?.state?.data)?.Density} {'-'} {'THẤP'}
                              </span>
                            )
                        )
                        
                    }
                  </div>
                  <div style={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    textAlign: 'center',
                  }}>
                    <Typography sx = {{
                      color: 'beige'
                    }}
                      variant={'h6'}
                      fontWeight={600}
                    >
                      OUTLINER(10)
                    </Typography>
                    {
                      Number(BubbleChartParam(this?.state?.data)?.Outliner?.pct)>=5?
                        <span style={{color: 'forestgreen', fontWeight: 'bold'}}>
                          {BubbleChartParam(this?.state?.data)?.Outliner?.pct} {'-'} {'CAO'}
                        </span>
                        
                      :
                        (
                          Number(BubbleChartParam(this?.state?.data)?.Outliner?.pct)>=2?
                            <span style={{color: 'goldenrod', fontWeight: 'bold'}}>
                              {BubbleChartParam(this?.state?.data)?.Outliner?.pct} {'-'} {'VỪA'}
                            </span>
                            :
                            (
                              Number(BubbleChartParam(this?.state?.data)?.Outliner?.pct)<=0.5?
                              <span style={{color: 'red', fontWeight: 'bold'}}>
                                {BubbleChartParam(this?.state?.data)?.Outliner?.pct} {'-'} {'THẤP'}
                              </span>
                              :
                              <span style={{color: 'darkorange', fontWeight: 'bold'}}>
                                {BubbleChartParam(this?.state?.data)?.Outliner?.pct} {'-'} {'KÉM'}
                              </span>
                            )
                            
                        )
                        
                    }

                  </div>

                  <div style={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    textAlign: 'center',
                  }}>
                    <Typography sx = {{
                      color: 'beige'
                    }}
                      variant={'h6'}
                      fontWeight={600}
                    >
                      OVERALL
                    </Typography>
                    {
                      commentOverall(BubbleChartParam(this?.state?.data))
                    }
                  </div>
        
        
              </div>
              
              
              
              :<></>}

            <div id={this.props.id} style={{height: 700, width: '100%', backgroundColor: '#070812', border: '1px solid gray', borderRadius: 5 }}>
            </div>

            <BulletChartSector id={'BulletChartSector'} data = {this.props.data}/>
            
            
          </React.Fragment>

          
        );
    }

    


}

export default ChartBubbleSector;



