import { apiSlice } from "../../../app/api/apiSlice"
export const apiSliceMatrix = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStockData: builder.query({
            query: () => `data/stockdata`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getYahooData: builder.query({
            query: () => `data/datayahoo`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getFutureData: builder.query({
            query: () => `data/datafuture`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getDividend: builder.query({
            query: () => `data/stockdividend`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getMarketCapHose: builder.query({
            query: () => `data/fetch/marketcap/hose`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getMarketCapHnx: builder.query({
            query: () => `data/fetch/marketcap/hnx`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getSector: builder.query({
            query: () => `data/sectordata`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        
        getVolatility: builder.query({
            query: () => `data/info/volatility`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getParam: builder.query({
            query: () => `data/info/param`,
            method: "GET",
            providesTags: ["StockData"],
        }),
    })
})

export const {
    useGetStockDataQuery,
    useGetDividendQuery,
    useGetFutureDataQuery,
    useGetMarketCapHoseQuery,
    useGetMarketCapHnxQuery,
    useGetSectorQuery,
    useGetYahooDataQuery,
    useGetVolatilityQuery,
    useGetParamQuery
} = apiSliceMatrix 