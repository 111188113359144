import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {Component} from "react";
import React from "react";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { Button, FormControl, InputLabel, makeStyles, MenuItem, NativeSelect, Select, Typography } from "@mui/material";
import BulletChart from "./BulletChart.dashboard";
import ChartBubbleSector from "./BubbleChartSector.dashboard";
import { MdOutlineShowChart,MdOutlineStackedLineChart  } from "react-icons/md";




class BubbleChartForEachSector extends Component {

    constructor(props) {
      super(props);
      this.state = {
        series: null,
        VolumeAverage: 50000,
        Close: 5,
        Sector: this?.props?.currentSector,
        Chart: 'Stock',
        data: null
      }
    }


    

    componentDidMount(){


        let root = am5.Root.new(this.props.id);
        root.setThemes([
            am5themes_Dark.new(root)
          ]);
          
    root._logo.dispose();
          
          // Create chart
          // https://www.amcharts.com/docs/v5/charts/xy-chart/
          let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelY: "zoomXY",
            pinchZoomX:true,
            pinchZoomY:true
          }));
          
          
          // Create axes
          // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
          let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            strictMinMax: true,
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {}),
            max: 70,
            
          }));
          
          
          let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            
            strictMinMaxSelection: true,
            renderer: am5xy.AxisRendererY.new(root, {
              inversed: false
            }),
            tooltip: am5.Tooltip.new(root, {}),
            max: 45
          }));
          
          
          
          
          
          // Create series
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
          let series = chart.series.push(am5xy.LineSeries.new(root, {
            calculateAggregates: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "SB3MK0A",
            valueXField: "AllAspect",
            valueField: "vol0",
            seriesTooltipTarget:"bullet",
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText: "[fontSize: 13px ][bold]TICKER: {TICKERVN} | {S2Col}{S2No}[/]\n[fontSize: 12px]SCORE Động Lượng: {AllAspect.formatNumber('#.0')}\nĐội Lái Trung Bình 5 Phiên: {AvgDoiLai5.formatNumber('#.0')}\nKhối Lượng Trung Bình: {VolumeAverage}\n%Change Weekly: {Weekly}%\n%Change Monthly: {Monthly}%\nChu Kì Tăng: {ChuKiTangGiam}%"
            })
            
          }));
          series.bullets.push(function(root, series, dataItem) {
            return am5.Bullet.new(root, {
              sprite: am5.Label.new(root, {
                text: "{Ticker}",
                fill: am5.color(0xffffff),
                populateText: true,
                centerX: am5.p50,
                centerY: am5.p50,
                textAlign: "center",
                fontSize: 11,
                fillOpacity: 4,
                fontWeight: 600
              }),
              dynamic: true
            });
          });
          
          series.strokes.template.set("visible", false);
          
          
          // Add bullet
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
          let circleTemplate = am5.Template.new({});
          circleTemplate.adapters.add("fill", function(fill, target) {
            let dataItem = target.dataItem;
            if (dataItem) {
              return am5.Color.fromString(dataItem.dataContext.color);
            }
            return fill
          });
          series.bullets.push(function() {
            let bulletCircle = am5.Circle.new(root, {
              fill: series.get("fill"),
              radius: 5,
              fillOpacity: 0.45
            }, circleTemplate);
            return am5.Bullet.new(root, {
              sprite: bulletCircle
            });
          });
          
          
          // Add heat rule
          // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
          series.set("heatRules", [{
            target: circleTemplate,
            min: 0.3,
            max: 36,
            dataField: "value",
            key: "radius"
          }]);

          chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            snapToSeries: [series]
          }));
          
          
          // Add scrollbars
          // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
          // chart.set("scrollbarX", am5.Scrollbar.new(root, {
          //   orientation: "horizontal"
          // }));
          
          // chart.set("scrollbarY", am5.Scrollbar.new(root, {
          //   orientation: "vertical"
          // }));

          xAxis.labelsContainer.set("tooltip", am5.Tooltip.new(root, {
            pointerOrientation: "down"
          }));
          
          let xRenderer = xAxis.get("renderer");
          
          xRenderer.labels.template.setAll({
            tooltipText: "TICKERVN",
            oversizedBehavior: "truncate",
            maxWidth: 100
          });

          series.data.processor = am5.DataProcessor.new(root, {
            numericFields: ["Close","vol0","AllAspect","AvgDoiLai5",'SB3MK0A'],
            
          });  
          
          xRenderer.labels.template.setup = function(target) {
            target.set("background", am5.Rectangle.new(root, {
              fill: am5.color('#999933'),
              fillOpacity: 0
            }));
          };

          var series3 = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339966")
          }));
          series3.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series3.strokes.template.set("forceHidden", true);
          series3.data.setAll([
            { ax: 60, ay: 10 },
            { ax: 10, ay: 10 },
            { ax: 10, ay: 25 },
            { ax: 60, ay: 25 }
          ]);

          var series3a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339966")
          }));
          series3a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series3a.strokes.template.set("forceHidden", true);
          series3a.data.setAll([
            { ax: 10, ay: 25 },
            { ax: 70, ay: 25 },
            { ax: 70, ay: 45 },
            { ax: 10, ay: 45 }
          ]);

          var series0a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339999")
          }));
          series0a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series0a.strokes.template.set("forceHidden", true);
          series0a.data.setAll([
            { ax: 0, ay: 25 },
            { ax: 10, ay: 25 },
            { ax: 10, ay: 0 },
            { ax: 0, ay: 0 }
          ]);

          var series0b = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#339999")
          }));
          series0b.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series0b.strokes.template.set("forceHidden", true);
          series0b.data.setAll([
            { ax: 10, ay: 10 },
            { ax: 50, ay: 10 },
            { ax: 50, ay: 0 },
            { ax: 10, ay: 0 }
          ]);
          
          var series1 = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#993333")
          }));
          series1.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series1.strokes.template.set("forceHidden", true);
          series1.data.setAll([
            { ax: -3, ay: -3 },
            { ax: -30, ay: -3 },
            { ax: -30, ay: 25 },
            { ax: -3, ay: 25 }
          ]);


          var series1a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#993333")
          }));
          series1a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series1a.strokes.template.set("forceHidden", true);
          series1a.data.setAll([
            { ax: 50, ay: -10 },
            { ax: -30, ay: -10 },
            { ax: -30, ay: -3 },
            { ax: 50, ay: -3 }
          ]);

          
          
          var series2a = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#999933")
          }));
          series2a.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series2a.strokes.template.set("forceHidden", true);
          series2a.data.setAll([
            { ax: 0, ay: -3 },
            { ax: -3, ay: -3 },
            { ax: -3, ay: 25 },
            { ax: 0, ay: 25 }
          ]);

          var series2b = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color("#999933")
          }));
          series2b.fills.template.setAll({ fillOpacity: 0.4, visible: true });
          series2b.strokes.template.set("forceHidden", true);
          series2b.data.setAll([
            { ax: 50, ay: -3 },
            { ax: -3, ay: -3 },
            { ax: -3, ay: 0 },
            { ax: 50, ay: 0 }
          ]);

   

    this.setState({series: series})

    fetch(`${process.env.REACT_APP_BASE_URL}/data/stockdatashort`)
    .then(res => res.json())
    .then(data => {
      if(this?.props?.currentSector==="ALL") 
        {
          series?.data?.setAll(am5.JSONParser.parse(data)?.filter(datax => {return Number(datax.VolumeAverage)>this.state.VolumeAverage && Number(datax.Close)>this.state.Close}))
          this.setState({data: am5.JSONParser.parse(data)?.filter(datax => {return Number(datax.VolumeAverage)>this.state.VolumeAverage && Number(datax.Close)>this.state.Close})})
          
        }
        else 
        {
          series?.data?.setAll(am5.JSONParser.parse(data)?.filter(datax => {return Number(datax.VolumeAverage)>this.state.VolumeAverage && Number(datax.Close)>this.state.Close && datax.Sector?.includes(this?.props?.currentSector)}))
        
        this.setState({data: am5.JSONParser.parse(data)?.filter(datax => {return Number(datax.VolumeAverage)>this.state.VolumeAverage && Number(datax.Close)>this.state.Close && datax.Sector?.includes(this?.props?.currentSector)})})
        }
    })
        
      

    

    

    


// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/

        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
    }


    
        
    


    render() {

      var series = this.state.series;
      var data = this?.props?.data
      
      


      
        return (

            
            <React.Fragment>
              

              
              
                

            
            <>
            
              <div id={this.props.id} style={{height: this.props.height, width: this.props.width, backgroundColor: '#070812', border: '1px solid gray', borderRadius: 5, marginTop: 5 }}>
              </div>
              <BulletChart data = {this.state.data} id={'BulletChart'} height={100}/>
            </>
              
            

              
            
            
          </React.Fragment>

          
        );
    }

    


}

export default BubbleChartForEachSector;



