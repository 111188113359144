
import ReactApexChart from "react-apexcharts";
import React from "react";
export default class MKStructureDistBoxPlot extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: [
          {
            data: this?.props?.data
          }
        ],
        options: {
            chart: {
              type: 'boxPlot',
              height: '20%',
              max: 5, 
              min: -5
            },
            title: {
              text: 'Distribution T0',
              align: 'center'
            },
            plotOptions: {bar: {
                horizontal: true,
                barHeight: '80%'
              },
              boxPlot: {
                colors: {
                  upper: '#D2691E',
                  lower: '#D2691E'
                }
              }
            },

          


          },
      
      
      };
    }

  

    render() {
      return (
        <div>
          <div id="chart" style={{

          }}>
            <ReactApexChart options={this.state.options} series={this.state.series} type="boxPlot" height={150 } />
          </div>
          <div id="html-dist"></div>
        </div>
      );
    }
  }
