import { apiSlice } from "../../../app/api/apiSlice"
export const apiSliceMatrix = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStockData: builder.query({
            query: () => `data/stockdata`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getDividend: builder.query({
            query: () => `data/stockdividend`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getMarketCapHose: builder.query({
            query: () => `data/fetch/marketcap/hose`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getMarketCapHnx: builder.query({
            query: () => `data/fetch/marketcap/hnx`,
            method: "GET",
            providesTags: ["StockData"],
        }),
        getFundamental: builder.query({
            query: () => `data/fetch/fundamental`,
            method: "GET",
            providesTags: ["StockData"],
        }),
    })
})

export const {
    useGetStockDataQuery,
    useGetDividendQuery,
    useGetMarketCapHoseQuery,
    useGetMarketCapHnxQuery,
    useGetFundamentalQuery
} = apiSliceMatrix 