import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5stock from '@amcharts/amcharts5/stock'
import { useEffect, Component} from "react";
import React from "react";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";




class BulletChartSector extends Component {


    

    componentDidMount(){


        let root = am5.Root.new(this.props.id);
        root.setThemes([
            am5themes_Dark.new(root)
          ]);
          
    root._logo.dispose();
          
            let colors = [
                am5.color('rgb(153, 0, 0)'),
                am5.color('rgb(153, 76, 0)'),
                am5.color('rgb(153, 153, 0)'),
                am5.color('rgb(76, 153, 0)'),
                am5.color('rgb(0, 153, 0)'),
                am5.color('rgb(0, 153, 76)'),
              
            ];
          
          
          
            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            let chart = root.container.children.push(
              am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                arrangeTooltips: false
              })
            );
          
            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            let yAxis = chart.yAxes.push(
              am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: am5xy.AxisRendererY.new(root, {})
              })
            );
          
          
            let xRenderer = am5xy.AxisRendererX.new(root, {});
            xRenderer.grid.template.set("forceHidden", true);
          
            let xAxis = chart.xAxes.push(
              am5xy.ValueAxis.new(root, {
                renderer: xRenderer,
                min: 0,
                max: 100,
                numberFormat: "#.'%'"
              })
            );
          
            // Add range
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
              let rangeDataItem = xAxis.makeDataItem({ value: 0, endValue: 100 });
              let range = xAxis.createAxisRange(rangeDataItem);
          
              rangeDataItem.get("axisFill").setAll({
                visible: true, 
                fillOpacity:1
              });
          
              let stops = [];
              for (var i = 0; i < colors.length; i++) {
                stops.push({ color: colors[i] });
              }
          
              let linearGradient = am5.LinearGradient.new(root, {
                rotation: 0,
                stops: stops
              });
          
              rangeDataItem.get("axisFill").set("fillGradient", linearGradient);

            let series = chart.series.push(
              am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "value",
                categoryYField: "category",
                fill: am5.color('rgb(255,255,255)'),
                stroke: am5.color('rgb(255,255,255)'),
                tooltip: am5.Tooltip.new(root, {
                  pointerOrientation: "left",
                  labelText: "{valueX}%"
                })      
              })
            );
          
            

            
          
            
          
            let stepSeries = chart.series.push(
              am5xy.StepLineSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "value",
                categoryYField: "category",
                stroke: am5.color('rgb(255, 0, 0)'),
                fill: am5.color('rgb(255, 0, 0)'),
                noRisers: true,
                stepWidth: am5.p50,
                tooltip: am5.Tooltip.new(root, {
                  pointerOrientation: "left",
                  labelText: "{valueX}%"
                })
              })
            );

            let stepSeries2 = chart.series.push(
                am5xy.StepLineSeries.new(root, {
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueXField: "value",
                  categoryYField: "category",
                  stroke: am5.color('rgb(255, 255, 0)'),
                  fill: am5.color('rgb(255, 255, 0)'),
                  noRisers: true,
                  stepWidth: am5.p50,
                  tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "left",
                    labelText: "{valueX}%"
                  })
                })
              );
            let stepSeries3 = chart.series.push(
                am5xy.StepLineSeries.new(root, {
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueXField: "value",
                  categoryYField: "category",
                  stroke: am5.color('rgb(0, 255, 255)'),
                  fill: am5.color('rgb(0, 255, 255)'),
                  noRisers: true,
                  stepWidth: am5.p50,
                  tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "left",
                    labelText: "{valueX}%"
                  })
                })
              );
        let stepSeries4 = chart.series.push(
                am5xy.StepLineSeries.new(root, {
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueXField: "value",
                  categoryYField: "category",
                  stroke: am5.color('rgb(50, 205, 50)'),
                  fill: am5.color('rgb(50, 205, 50)'),
                  noRisers: true,
                  stepWidth: am5.p50,
                  tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "left",
                    labelText: "{valueX}%"
                  })
                })
              );
          
            stepSeries.strokes.template.set("strokeWidth", 5);stepSeries2.strokes.template.set("strokeWidth", 5);stepSeries3.strokes.template.set("strokeWidth", 5);stepSeries4.strokes.template.set("strokeWidth", 5);

            series.data.processor = am5.DataProcessor.new(root, {
                numericFields: ["DoiLai(A5)","BM(20)","Close","vol0","ScoreAverage","AvgDoiLai5"],
                
              });  

              
            
          
            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
              behavior: "none"
            })
            );
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);
          
            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            chart.appear(1000, 100);
          
            series.appear();
            stepSeries.appear();
            stepSeries2.appear();
            stepSeries3.appear();
            stepSeries4.appear();

            this.setState({
              stepSeries: stepSeries,
              stepSeries2: stepSeries2,
              stepSeries3: stepSeries3,
              stepSeries4: stepSeries4,
              yAxis: yAxis,
              rangeDataItem: rangeDataItem,
              series: series
            })
          

          
        


// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/

        this.root = root;

    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
    }


    
        
    


    render() {

      
      var stepSeries = this?.state?.stepSeries
      var stepSeries2 =  this?.state?.stepSeries2
      var stepSeries3 = this?.state?.stepSeries3
      var stepSeries4 = this?.state?.stepSeries4
      var rangeDataItem = this?.state?.rangeDataItem
      var series = this?.state?.series
      var yAxis = this?.state?.yAxis
      series?.columns?.template?.setAll({
        height: am5.p50
      });

      const data_ = this?.props?.data

       
        
        yAxis?.data?.setAll([{ category: "Evaluation" }]);
        rangeDataItem?.get("axisFill")?.setAll({
          visible: true, 
          fillOpacity:1
        });
        stepSeries?.data?.setAll([{ category: "Evaluation", value: Number(Number(data_?.filter(x => {return x['ScoreAverage'] <=-2 || x['MakerAverage'] <=-2 })?.length)/data_?.length*100) }]);
        
        stepSeries2?.data?.setAll([{ category: "Evaluation", value: Number(data_?.filter(x => {return (x['ScoreAverage'] <0&& x['ScoreAverage'] >-2) || (x['MakerAverage'] <0 &&x['MakerAverage'] >-2)})?.length)/data_?.length*100 }]);
        stepSeries3?.data?.setAll([{ category: "Evaluation", value: Number(data_?.filter(x => {return (x['ScoreAverage'] <10&& x['ScoreAverage'] >=0) || (x['MakerAverage'] <10 &&x['MakerAverage'] >=0)})?.length)/data_?.length*100 }]);
        stepSeries4?.data?.setAll([{ category: "Evaluation", value: Number(data_?.filter(x => {return x['ScoreAverage'] >=10 && x['MakerAverage'] >=10})?.length)/data_?.length*100 }]);
    
        return (

            
            <React.Fragment>

            <div id={this.props.id} style={{height: 100, width: '100%', backgroundColor: '#070812', border: '1px solid gray', borderRadius: 5 }}>
                <div></div>
            </div>
            
            
          </React.Fragment>

          
        );
    }

    


}

export default BulletChartSector;



